import { gql } from 'apollo-boost';


export const GET_WAREHOUSE_ANIMALS = gql`
{
  animalSemen
  {
    items
    {
      semenID
      animalID
      semenCode
      dateInput
      semenQuantity
      price
      container
      canister
      animal
      {
        animalId
        lID
        vID
        dateOfBirth
        sex
        countryFrom
        breed
        {
          breedShort
          breedName
        }
        countryFrom
      }
      supplier
      {
        farmClientID
        farmClientName
      }
    }
  }
}
`;
export const ADD_WAREHOUSE_ANIMALS = gql`
  mutation addSemen($dataItem: animalSemenInput) {
    addSemen(semen: $dataItem) {
      semenCode
    }
  }
`;

export const UPDATE_WAREHOUSE_ANIMALS = gql`
  mutation editSemen($dataItem: animalSemenInput) {
    editSemen(semen: $dataItem) {
      semenCode
    }
  }
`;
