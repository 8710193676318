/* eslint-disable no-shadow */
/* eslint-disable max-len */
/* eslint-disable no-plusplus */
import { SEXES } from './translation-constants';
import { getAnimalAgeInMonths } from '../components/views/animals/animal-utilities';

export const deepCopy = item => JSON.parse(JSON.stringify(item));

export const isActionTypeAndGetQuery = (action, actionNameToCompare, queryTrue, queryFalse) => {
  return action === actionNameToCompare ? { isActionType: true, query: queryTrue, } : { isActionType: false, query: queryFalse, };
};

export const mapAnimalForListBox = item => {
  const missing = '--';
  const getTranslation = (value, translations) => { const result = translations.find((item) => item.id === value); return result ? result.text : ''; };
  return {
    id: item.id,
    text: `${item.lID ? item.lID : missing}(${item.vID ? item.vID : missing} | ${item.breed.breedShort ? item.breed.breedShort : missing} | ${item.sex ? getTranslation(item.sex, SEXES) : missing} | ${item.color.colorName ? item.color.colorName : missing} | ${item.aClass.className} | ${getAnimalAgeInMonths(item.dateOfBirth)}м.)`,
  };
};

export const mapAnimalsForListBox = animals => animals.map(mapAnimalForListBox);

export const checkIsFormForGroupDataValid = (obj) => {
  return !!(obj.groupTypeID && obj.managementGroupsName && obj.managementGroupsName !== '' && obj.groupDate && obj.groupDate !== '');
};

export const selectedValue = (val, array, prop) => {
  const selected = array.find((element) => {
    return element[prop] === val;
  });
  return selected;
};

export const animalSexTranslation = (animalSex) => {
  const sex = SEXES.find(s => s.id === animalSex) || { text: '', };
  return sex.text;
};

// Get the translation for ......
export const getTranslation = (value, translations) => { const result = translations.find((item) => item.id === value); return result ? result.text : ''; };

export const dateFormatForTableFilters = (dateString) => {
  const splitedDate = dateString.split('-');
  return new Date(parseInt(splitedDate[0], 10), parseInt(splitedDate[1], 10) - 1, parseInt(splitedDate[2], 10));
};

export const reportAnimalSummary = (animal, sexTranslation) => {
  return `${animal.lID ? animal.lID : '--'}(${animal.vID ? animal.vID : '--'} | ${animal.breed && animal.breed.breedShort ? animal.breed.breedShort : '--'} | ${sexTranslation || '--'} | ${animal.dateOfBirth ? getAnimalAgeInMonths(animal.dateOfBirth, animal.fateDate) : '--'}м.)`;
};

export const generateCustomString = (str, i, onlyNumber = false) => {
  const letter = str.match(/[a-zA-Z]+/g) ? str.match(/[a-zA-Z]+/g).join('') : '';
  const number = str.replace(/\D/g, '') ? parseInt(str.replace(/\D/g, ''), 10) + i : '';
  if (!onlyNumber) {
    return `${letter}${number}`.trim();
  }
  return number;
};

export const generateLIDString = (currAnimal, i, onlyNumber = false) => {
  const number = generateCustomString(currAnimal.lID, i, onlyNumber);
  if (!onlyNumber) {
    return `${currAnimal.vetisPrefix ? currAnimal.vetisPrefix : ''}${number}`.trim();
  }
  return number;
};

const compareFarmTypes = (curFarmClient, curType, FARM_CLIENT_TYPE) => {
  for (let i = 0; i < FARM_CLIENT_TYPE.length; i++) {
    if (curType === FARM_CLIENT_TYPE[i].toLocaleLowerCase()) {
      return curFarmClient;
    }
  }
  return null;
};

export const filterFarmClients = (farmClients, FARM_CLIENT_TYPE) => {
  const result = farmClients.items.filter((el) => {
    const curFarmClient = el;
    return el.farmClientsInType.find((type) => {
      const curType = type.toLowerCase();
      return compareFarmTypes(curFarmClient, curType, FARM_CLIENT_TYPE);
    });
  }).map((el) => {
    return {
      farmClientID: el.farmClientID,
      farmClientName: el.farmClientName,
    };
  });
  return result;
};
