import { gql } from 'apollo-boost';


export const GET_ROLES = gql`
{
  userRole
  {
    name
    value
  }
}
`;

export const GET_USERS = gql`
{
  farmUsers
  {
    items
      {
        id
        farmId
        role
        userName
        email
      }
  }
}
`;

export const ADD_USER = gql`
  mutation addUser($dataItem: userInput) {
    addUser(userInput: $dataItem){
      userName
      id
    }
  }
`;

export const EDIT_USER = gql`
  mutation editUser($dataItem: userInput) {
    editUser(userInput: $dataItem){
      id,
      email,
      userName
    }
  }
`;
