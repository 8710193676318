/* eslint react/no-access-state-in-setstate:0 */
/* eslint react/no-array-index-key:0 */
/* eslint class-methods-use-this:0 */
/* eslint react/sort-comp:0 */
/* eslint max-len:0 */
/* eslint react/no-did-update-set-state:0 */
/* eslint react/destructuring-assignment:0 */
/* eslint react/no-unused-state:0 */
/* eslint max-classes-per-file:0 */
import * as React from 'react';

import {
  TreeList, mapTree, extendDataItem, TreeListCell
} from '@progress/kendo-react-treelist';
import MapLocationsModal from './map-locations-modal';
import { deepCopy, selectedValue } from '../../../../tools/function-helpers';
import { LOCATIONS_TYPES_TRANSLATIONS } from '../../../../tools/translation-constants';


const subItemsField = 'children';
const expandField = 'expanded';

class MapLocations extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      data: props.locations,
      animalsObjects: props.animalsObjects,
      barnsLocations: props.barnsLocations,
      sectorsMapped: props.sectorsMapped,
      itemInEdit: undefined,
      expanded: [0],
    };

    this.onExpandChange = this.onExpandChange.bind(this);
    this.processData = this.processData.bind(this);
    this.actionsCell = this.actionsCell.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.locations !== prevProps.locations) {
      this.setState({
        data: this.props.locations,
        animalsObjects: this.props.animalsObjects,
        barnsLocations: this.props.barnsLocations,
        sectorsMapped: this.props.sectorsMapped,
      });
    }
  }

    actionsCell = (data, animalsObjects, barnsLocations, sectorsMapped) => {
      return class extends TreeListCell {
        render() {
          const { id, } = this.props.dataItem;
          const copyData = deepCopy(data);
          const { parentId, } = this.props.dataItem;
          const locationType = this.props.dataItem.type;
          let type = 'parent';
          let dataItem = {};
          if (locationType === 'SECTOR') {
            type = 'sector';
            dataItem = copyData.find(item => item.locationID === parentId).children.find(child => child.locationID === id);
          } else if (locationType === 'BOX') {
            copyData.forEach(barn => {
              const currentItem = barn.children.find(sector => sector.locationID === parentId);
              if (currentItem !== undefined) {
                type = 'box';
                dataItem = currentItem.children.find(child => child.locationID === id);
              }
            });
          } else {
            dataItem = copyData.find(item => item.locationID === id);
          }
          return (
            <td>
              <div className="d-flex justify-content-center">
                <MapLocationsModal dataItem={dataItem} type={type} animalsObjects={animalsObjects} barnsLocations={barnsLocations} sectorsMapped={sectorsMapped} />
              </div>
            </td>
          );
        }
      };
    }

    descriptionCell(data) {
      const { description, } = data.dataItem;
      return (
        <td>
          {
                 description.map((el, index) => {
                   return (
                     <p key={index}>
                       {el.name}
                       {' '}
                       :
                       {' '}
                       {el.value}
                     </p>
                   );
                 })
               }
        </td>
      );
    }

    onExpandChange = (e) => {
      this.setState({
        expanded: e.value
          ? this.state.expanded.filter(id => id !== e.dataItem.id)
          : [...this.state.expanded, e.dataItem.id],
      });
    }

    processData = () => {
      const { expanded, data, } = this.state;
      const mappedData = data.map((item) => {
        return {
          id: item.locationID,
          name: item.locationName ? item.locationName : '(празно)',
          description: [
            { name: 'Вид', value: item.locationType ? selectedValue(item.locationType, LOCATIONS_TYPES_TRANSLATIONS, 'id').text : '(празно)', },
            { name: 'Площ(м2)', value: item.locationSpace ? item.locationSpace : '(празно)', },
            { name: 'Площ(ха)', value: item.locationSpaceHec ? item.locationSpaceHec : '(празно)', },
            { name: 'Култура/Настилка', value: item.groundType ? item.groundType : '(празно)', },
            { name: 'Координати', value: `${item.latitude ? item.latitude : '(празно)'} ${item.longitude ? item.longitude : '(празно)'}`, },
            { name: 'Собственик', value: item.animalObject ? item.animalObject.animalObjectNumber : '(празно)', },
            { name: 'Бележка', value: item.comment ? item.comment : '(празно)', }
          ],
          type: 'BARN',
          children: item.children && item.children.length > 0 ? item.children.map((childrenItem) => {
            return {
              id: childrenItem.locationID,
              parentId: childrenItem.parentId,
              name: `(Сектор) "${childrenItem.locationName ? childrenItem.locationName : '(празно)'}"`,
              description: [
                { name: 'Площ(м2)', value: childrenItem.locationSpace ? childrenItem.locationSpace : '(празно)', },
                { name: 'Площ(ха)', value: childrenItem.locationSpaceHec ? childrenItem.locationSpaceHec : '(празно)', },
                { name: 'Бележка', value: childrenItem.comment ? childrenItem.comment : '(празно)', }
              ],
              type: 'SECTOR',
              children: childrenItem.children && childrenItem.children.length > 0 ? childrenItem.children.map((childrenSecondItem) => {
                return {
                  id: childrenSecondItem.locationID,
                  parentId: childrenSecondItem.parentId,
                  name: `(Бокс/Падок) "${childrenSecondItem.locationName ? childrenSecondItem.locationName : '(празно)'}"`,
                  description: [
                    { name: 'Площ(м2)', value: childrenSecondItem.locationSpace ? childrenSecondItem.locationSpace : '(празно)', },
                    { name: 'Площ(ха)', value: childrenSecondItem.locationSpaceHec ? childrenSecondItem.locationSpaceHec : '(празно)', },
                    { name: 'Бележка', value: childrenSecondItem.comment ? childrenSecondItem.comment : '(празно)', }
                  ],
                  type: 'BOX',
                };
              }) : [],
            };
          }) : [],
        };
      });

      return mapTree(mappedData, subItemsField, item => extendDataItem(item, subItemsField, {
        [expandField]: expanded.includes(item.id),
      }));
    }

    render() {
      const columns = [
        {
          field: 'name', title: 'Име', expandable: true, width: '30%',
        },
        { field: '', title: 'Описание', cell: (props) => this.descriptionCell(props), },
        { title: 'Действия', cell: this.actionsCell(this.state.data, this.state.animalsObjects, this.state.barnsLocations, this.state.sectorsMapped), width: '1px', }
      ];

      return (
        <div>
          <div className="d-flex justify-content-start">
            <MapLocationsModal dataItem={{}} type="parent" action="add" animalsObjects={this.state.animalsObjects} />
            <MapLocationsModal dataItem={{}} type="sector" action="add" barnsLocations={this.state.barnsLocations} />
            <MapLocationsModal dataItem={{}} type="box" action="add" sectorsMapped={this.state.sectorsMapped} />
          </div>
          <TreeList
            className="table"
            data={this.processData()}
            style={{ maxHeight: '100%', overflow: 'auto', }}
            expandField={expandField}
            subItemsField={subItemsField}
            onExpandChange={this.onExpandChange}
            columns={columns}
          />
        </div>
      );
    }
}

export default MapLocations;
