/* eslint react/button-has-type:0 */
/* eslint max-len:0 */
/* eslint react/no-access-state-in-setstate:0 */
/* eslint react/destructuring-assignment:0 */
/* eslint no-prototype-builtins:0 */
/* eslint no-restricted-syntax:0 */
import * as React from 'react';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Row, Col } from 'reactstrap';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Input } from '@progress/kendo-react-inputs';
import Mutation from '../../../../../../data/mutation';
import { UPDATE_ANIMAL } from '../../../../../../data/animal/animal-query';
import { EID_TYPES } from '../../../../../../tools/translation-constants';
import { deepCopy } from '../../../../../../tools/function-helpers';
import { DatePicker } from '../../../../../utils/date-picker';


const genetateChangedProps = (animalNewData, animalOldData) => {
  const reTag = {};
  for (const key in animalNewData) {
    if (animalNewData.hasOwnProperty(key)) {
      reTag[key] = animalOldData[key];
    }
  }
  return reTag;
};

class RetagModal extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      animal: props.animal,
      animalNewData: {},
      submitDisabled: true,
    };
    this.toggleDialog = this.toggleDialog.bind(this);
    this.handleFormChange = this.handleFormChange.bind(this);
  }

  toggleDialog() {
    this.setState({
      visible: !this.state.visible,
    });

    if (!this.state.visible) {
      this.resetState();
    }
  }


  handleFormChange(e) {
    const objCopy = deepCopy(this.state.animalNewData);
    let submitDisabled = true;
    if (e.target.name === 'eIDType') {
      objCopy[e.target.name] = e.target.value.id;
    } else {
      objCopy[e.target.name] = e.target.value;
    }

    if ((objCopy.eIDType && objCopy.eIDType !== '') || (objCopy.lID && objCopy.lID !== '') || (objCopy.vID && objCopy.vID !== '') || (objCopy.electronicId && objCopy.electronicId !== '')) {
      submitDisabled = false;
    }

    this.setState({
      animalNewData: objCopy,
      submitDisabled,
    });
  }

  handleSubmit(e, mutationTrigger) {
    e.preventDefault();

    const dataItem = {
      animalId: this.state.animal.animalId,
      ...this.state.animalNewData,
      animalReTag: [{
        animalID: this.state.animal.animalId,
        ...genetateChangedProps(this.state.animalNewData, this.state.animal),
        dateReTag: this.state.animalNewData.dateReTag ? this.state.animalNewData.dateReTag : new Date(),
      }],
    };

    delete dataItem.dateReTag;
    this.toggleDialog();
    mutationTrigger({
      variables:
            {
              animalUpdates: dataItem,
            },
    });
  }

  resetState() {
    const { animal, } = this.state;
    this.setState({
      animal,
      submitDisabled: true,
    });
  }


  render() {
    return (
      <Mutation
        mutation={UPDATE_ANIMAL}
        awaitRefetchQueries
                // refetchQueries={[{ query: GET_ANIMAL_DETAILS, variables: { id: this.state.animal.animalId } },{query:GET_ANIMAL_RETAGS, variables: { id: this.state.animal.animalId }}]}
        onCompleted={() => window.location.reload()}
      >
        {(mutationTrigger) => (
          <div>
            <button className="btn btn-primary" onClick={this.toggleDialog}>ДОБАВИ СЪБИТИЕ</button>
            {this.state.visible && (
            <form onSubmit={e => this.handleSubmit(e, mutationTrigger)} className="health-form">
              <Dialog className="health-modal" title="Добави събитие" onClose={this.toggleDialog} minWidth="500px" height="70%">
                <Row>
                  <Col lg={{ size: 8, offset: 2, }}>
                    <div className="form-group">
                      <label>ВЕТИС №</label>
                      <Input
                        name="lID"
                        onChange={this.handleFormChange}
                      />
                    </div>
                    <div className="form-group">
                      <label>ВИН №</label>
                      <Input
                        name="vID"
                        onChange={this.handleFormChange}
                      />
                    </div>
                    <div className="form-group">
                      <label>EID №</label>
                      <Input
                        name="electronicId"
                        onChange={this.handleFormChange}
                      />
                    </div>
                    <div className="form-group">
                      <label>Вид EID</label>
                      <DropDownList
                        style={{ width: '100%', }}
                        data={EID_TYPES}
                        dataItemKey="id"
                        textField="text"
                        name="eIDType"
                        onChange={this.handleFormChange}
                      />
                    </div>
                    <div className="form-group">
                      <label>Дата</label>
                      <DatePicker
                        value={this.state.animalNewData?.dateReTag}
                        name="dateReTag"
                        onChange={this.handleFormChange}
                        min={new Date(this.props.animaldateOfBirth)}
                        max={new Date()}
                      />
                    </div>
                  </Col>
                </Row>

                <DialogActionsBar>
                  <div className="d-flex">
                    <button className="btn btn-secondary" onClick={this.toggleDialog}>Отказ</button>
                    <button type="submit" disabled={this.state.submitDisabled} className="btn btn-primary ml-3">Добави</button>
                  </div>
                </DialogActionsBar>
              </Dialog>
            </form>
            )}
          </div>
        )}
      </Mutation>
    );
  }
}

export default RetagModal;
