/* eslint react/prop-types:0 */
/* eslint react/destructuring-assignment:0 */
import * as React from 'react';
import { differenceBy } from 'lodash';
import { Row, Col } from 'reactstrap';
import ListBoxComponent from '../../../../list-box/list-box';
import { mapAnimalsForListBox } from '../../../../../tools/function-helpers';
import ListBoxFiltersComponent from '../../../../list-box-filters/list-box-filters';
import { DatePicker } from '../../../../utils/date-picker';

const ServiceBullChoice = props => {
  const {
    aClasses, breeds, color, groups,
  } = props.otherProps;
  const { dateInBulls, } = props;
  const renderListView = animals => {
    return (
      <>
        <ListBoxFiltersComponent
          animals={props.allBulls}
          aClasses={aClasses}
          breeds={breeds}
          colors={color}
          locations={props.allLocations}
          hideGroupSelect
          hideClassNameSelect
          groups={groups}
          updateAnimalsToMap={filteredBulls => props.setBullState({
            availableBulls: filteredBulls,
            allBulls: props.allBulls,
            selectedBulls: props.selectedBulls,
          })}
        />
        {props.inModal && (
        <Row>
          <Col lg="3">
            <div className="form-group">
              <label>Дата на влизане в сървис</label>
              <DatePicker
                name="dateInAnimals"
                onChange={(e) => dateInBulls.setDateIn(e.target.value)}
                value={dateInBulls.dateIn}
              />
            </div>
          </Col>
        </Row>
        )}
        <ListBoxComponent
          items={mapAnimalsForListBox(animals)}
          listTitle="Изберете животни:"
          groupTitle="Брой животни:"
          getGroup={() => props.selectedBulls}
          updateGroup={selectedBulls => props.setBullState({
            allBulls: props.allBulls,
            availableBulls: props.availableBulls,
            selectedBulls,
          })}
        />
      </>
    );
  };
  return renderListView(differenceBy(props.availableBulls, props.selectedBulls, 'id'));
};

export default ServiceBullChoice;
