/* eslint react/button-has-type:0 */
/* eslint max-len:0 */
/* eslint react/no-access-state-in-setstate:0 */
/* eslint react/destructuring-assignment:0 */
/* eslint no-unused-vars:0 */
/* eslint no-param-reassign:0 */
import * as React from 'react';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Row, Col } from 'reactstrap';
import { Input, NumericTextBox } from '@progress/kendo-react-inputs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CustomComboBox from '../tabs/data/custom-combo-box';
import Mutation from '../../../../data/mutation';
import FarmClientDropdown from '../tabs/health/farm-client-dropdown';

import { ADD_WAREHOUSE_ANIMALS, GET_WAREHOUSE_ANIMALS, UPDATE_WAREHOUSE_ANIMALS } from '../../../../data/warehouse-animals/warehouse-animals-queries';

import { deepCopy, isActionTypeAndGetQuery } from '../../../../tools/function-helpers';
import { DatePicker } from '../../../utils/date-picker';

const keysToRemove = ['sireID', 'farmClient', 'animal', 'supplier', 'animalSummary', 'animalBreedName', 'countryFromTranslation', '__typename'];

const removeObjKeys = (obj, arrayKeys) => {
  arrayKeys.forEach(element => {
    delete obj[element];
  });
};

const selectedValue = (val, array, prop) => {
  const selected = array.find((element) => {
    return element[prop] === val;
  });
  return selected;
};

class WarehouseTableModal extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      dataItem: {
        ...props.dataItem,
      },
      submitDisabled: true,
      tableType: 'entries',
    };
    this.toggleDialog = this.toggleDialog.bind(this);
    this.handleFormChange = this.handleFormChange.bind(this);
  }

  toggleDialog() {
    this.setState({
      visible: !this.state.visible,
    });

    if (!this.state.visible) {
      this.resetState();
    }
  }

  handleFormChange(e) {
    const objCopy = deepCopy(this.state.dataItem);
    let isSubmitDisabled = this.state.submitDisabled;
    this.state.dataItem.semenID = this.props.semenID;

    if (e.target.name === 'semenCode') {
      objCopy[e.target.name] = e.target.value;
    } else if (e.target.name === 'animal') {
      objCopy[e.target.name] = e.target.value;
      objCopy.animalID = e.target.value.animalId;
    } else if (e.target.name === 'farmClient') {
      objCopy[e.target.name] = e.target.value;
      objCopy.farmClientID = e.target.value.farmClientID;
    } else if (e.target.name === 'semenQuantity') {
      objCopy[e.target.name] = e.target.value;
    } else if (e.target.name === 'price') {
      objCopy[e.target.name] = e.target.value;
    } else if (e.target.name === 'container') {
      objCopy[e.target.name] = e.target.value;
    } else if (e.target.name === 'canister') {
      objCopy[e.target.name] = e.target.value;
    } else if (e.target.name === 'dateInput') {
      objCopy[e.target.name] = e.target.value;
    } else {
      objCopy[e.target.name] = e.target.value;
    }
    if (!objCopy.semenCode || !objCopy.animalID || !objCopy.semenQuantity) {
      isSubmitDisabled = true;
    } else {
      isSubmitDisabled = false;
    }
    this.setState({
      dataItem: objCopy,
      submitDisabled: isSubmitDisabled,
    });
  }

  handleSubmit(e, mutationTrigger) {
    e.preventDefault();
    this.toggleDialog();
    const objCopy = { ...this.state.dataItem, };
    removeObjKeys(objCopy, keysToRemove);

    if (!objCopy.dateInput) {
      objCopy.dateInput = new Date();
    }
    mutationTrigger({
      variables:
            {
              dataItem: objCopy,
            },
    });
  }

  resetState() {
    const copyData = {
      ...this.props.dataItem,
    };
    this.setState({
      dataItem: this.props.dataItem,
      submitDisabled: true,
    });
  }

  render() {
    const isAddActionType = isActionTypeAndGetQuery(this.props.action, 'add', ADD_WAREHOUSE_ANIMALS, UPDATE_WAREHOUSE_ANIMALS);
    const { dataItem, } = this.state;

    return (
      <Mutation
        mutation={isAddActionType.query}
        awaitRefetchQueries
        refetchQueries={[{ query: GET_WAREHOUSE_ANIMALS, variables: {}, }]}
      >
        {(mutationTrigger) => (
          <div className="d-inline-block">
            <button className={isAddActionType.isActionType ? 'btn btn-primary mr-2' : 'button-icon'} onClick={this.toggleDialog}>{isAddActionType.isActionType ? 'Добави нов запис' : <FontAwesomeIcon icon="pen" />}</button>
            {this.state.visible && (
            <form onSubmit={e => this.handleSubmit(e, mutationTrigger)}>
              <Dialog title={`${isAddActionType.isActionType ? 'Добави' : 'Редактирай'} семенен материал`} onClose={this.toggleDialog} minWidth="500px" height="70%">
                <Row>
                  <Col lg={{ size: 8, offset: 2, }}>
                    <div className="form-group">
                      {
                                                isAddActionType.isActionType
                                                && (
                                                <div className="form-group">
                                                  <label>БИК*</label>
                                                  <CustomComboBox
                                                    name="animal"
                                                    onChange={this.handleFormChange}
                                                    value={dataItem.animal}
                                                    data={this.props.sires}
                                                    textField="lID"
                                                    dataItemKey="animalId"
                                                  />
                                                </div>
                                                )
                                            }
                      <div className="form-group">
                        <label>Код Спрема*</label>
                        <Input type="text" name="semenCode" value={dataItem.semenCode ? dataItem.semenCode : ''} onChange={this.handleFormChange} />
                      </div>
                      <div className="form-group">
                        <label>Доставчик*</label>
                        <FarmClientDropdown name="farmClient" tableType={this.state.tableType} handleFormChange={this.handleFormChange} farmClientID={dataItem?.supplier?.farmClientID} />
                      </div>
                      <div className="form-group">
                        <label>Дата на придобиване</label>
                        <DatePicker
                          name="dateInput"
                          onChange={this.handleFormChange}
                          value={dataItem.dateInput}
                        />
                      </div>
                      <div className="form-group">
                        <label>Количество*</label>
                        <NumericTextBox defaultValue={0} min={0} name="semenQuantity" value={dataItem.semenQuantity ? dataItem.semenQuantity : ''} onChange={this.handleFormChange} />
                      </div>
                      <div className="form-group">
                        <label>Цена</label>
                        <NumericTextBox defaultValue={0} min={0} name="price" value={dataItem.price ? dataItem.price : ''} onChange={this.handleFormChange} />
                      </div>
                      <div className="form-group">
                        <label>Съд</label>
                        <Input type="text" name="container" value={dataItem.container ? dataItem.container : ''} onChange={this.handleFormChange} />
                      </div>
                      <div className="form-group">
                        <label>Канистър</label>
                        <Input type="text" name="canister" value={dataItem.canister ? dataItem.canister : ''} onChange={this.handleFormChange} />
                      </div>
                    </div>
                  </Col>
                </Row>
                <DialogActionsBar>
                  <div className="d-flex">
                    <button className="btn btn-secondary" onClick={this.toggleDialog}>Отказ</button>
                    <button type="submit" disabled={this.state.submitDisabled} className="btn btn-primary ml-3">{isAddActionType.isActionType ? 'Добави' : 'Редактирай'}</button>
                  </div>
                </DialogActionsBar>
              </Dialog>
            </form>
            )}
          </div>
        )}
      </Mutation>
    );
  }
}

export default WarehouseTableModal;
