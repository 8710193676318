/* eslint react/no-array-index-key:0 */
/* eslint no-plusplus:0 */
/* eslint no-shadow:0 */
/* eslint react/no-did-update-set-state:0 */
/* eslint react/destructuring-assignment:0 */
import * as React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import DatesHelper from '../../../../../../tools/DatesHelper';
import { mapSTOType, mapSexType } from '../../../animal-mapping-functions';
import { generateBreedCompositionString } from '../../../animal-utilities';
import { deepCopy } from '../../../../../../tools/function-helpers';

class BirthTable extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      animalObj: props.animalObj,
      birthEvents: props.birthEvents,
    };

    this.generateTableRows = this.generateTableRows.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.animalObj !== prevProps.animalObj || this.props.birthEvents !== prevProps.birthEvents) {
      this.setState({
        animalObj: this.props.animalObj,
        birthEvents: this.props.birthEvents,
      });
    }
  }

  generateTableRows = () => {
    const animal = deepCopy(this.state.animalObj);
    const birthEvents = deepCopy(this.state.birthEvents);

    if (birthEvents.length <= 0) {
      return null;
    }

    return animal.children
      .sort((a, b) => {
        return new Date(a.dateOfBirth).getTime() - new Date(b.dateOfBirth).getTime();
      })
      .map((children, i) => {
        const { dateOfBirth, } = children;
        const birthEvent = birthEvents.find((b) => b.dateBirth === dateOfBirth);

        const { weights, } = children;
        let birthWeight = '-';
        let weaningWeight = '-';
        let yearlingWeight = '-';

        if (weights.length > 0) {
          if (weights.find((w) => w.weightType === 'BIRTH_WEIGHT')) {
            birthWeight = weights.find((w) => w.weightType === 'BIRTH_WEIGHT').weight;
          }
          if (weights.find((w) => w.weightType === 'WEANING_WEIGHT')) {
            weaningWeight = weights.find((w) => w.weightType === 'WEANING_WEIGHT').weight;
          }
          if (weights.find((w) => w.weightType === 'YEARLING_WEIGHT')) {
            yearlingWeight = weights.find((w) => w.weightType === 'YEARLING_WEIGHT').weight;
          }
        }

        const dateOfBirthPlus205 = moment(dateOfBirth).clone().add(205, 'days');
        const dateOfBirthPlus365 = moment(dateOfBirth).clone().add(365, 'days');

        let adg205 = null;
        let adg365 = null;

        if (children.animalADG) {
          for (let i = 0; i < children.animalADG.length; i++) {
            if (moment(children.animalADG[i].aNGdate).isBetween(dateOfBirth, dateOfBirthPlus205, null, '(]')) {
              adg205 = children.animalADG[i].animalANG;
            } else if (moment(children.animalADG[i].aNGdate).isBetween(dateOfBirthPlus205, dateOfBirthPlus365, null, '[]')) {
              adg365 = children.animalADG[i].animalANG;
            }
          }
        }

        let oldBirthDate = animal.children[i - 1]?.dateOfBirth;

        if (oldBirthDate === dateOfBirth) {
          oldBirthDate = animal.children[i - 2]?.dateOfBirth;
        }

        const daysDiff = oldBirthDate ? DatesHelper.differenceBetweenDates(dateOfBirth, oldBirthDate) : 0;

        return (
          <tr key={i}>
            <td>{DatesHelper.parseAndFormatDate(dateOfBirth)}</td>
            <td>{daysDiff}</td>
            <td>
              <Link className="text-inf" to={`/animals/list/${children.animalId}`}>{`${children.lID}`}</Link>
            </td>
            <td>{mapSexType(children.sex)}</td>
            <td>{birthEvent ? mapSTOType(birthEvent.sTO) : null}</td>
            {/* <td>
              {children.sire ? `${children.sire.lID} ` : ''}
              {children.sire ? generateBreedCompositionString(children.sire) : ''}
            </td> */}
            <td>
              {children.sire ? (
                <Link className="text-inf" to={`/animals/list/${children.sire.animalId}`}>
                  {`${children.sire.lID} 
              ${generateBreedCompositionString(children.sire)}`}
                </Link>
              ) : (
                <span className="text-inf">-</span>
              )}
            </td>
            <td>{birthWeight}</td>
            <td>{weaningWeight}</td>
            <td>{yearlingWeight}</td>
            <td>{adg205 ? Number.parseFloat(adg205).toFixed(2) : '-'}</td>
            <td>{adg365 ? Number.parseFloat(adg365).toFixed(2) : '-'}</td>
            <td>{birthEvent ? birthEvent.comment : ''}</td>
          </tr>
        );
      });
  };

  render() {
    return (
      <table className="table">
        <thead>
          <tr>
            <th>Отелване дата</th>
            <th>ИНАО</th>
            <th>Теле УМ</th>
            <th>ПОЛ</th>
            <th>СТО</th>
            <th>Баща</th>
            <th>КГ 0 (д)</th>
            <th>КГ 205 (д).</th>
            <th>КГ 365 (д).</th>
            <th>СДП кг/д 0 -205 (д).</th>
            <th>СДП кг/д 205 -365 (д).</th>
            <th>Бележка</th>
          </tr>
        </thead>
        <tbody>{this.generateTableRows()}</tbody>
      </table>
    );
  }
}

export default BirthTable;
