/* eslint max-len:0 */
/* eslint react/button-has-type:0 */
/* eslint no-prototype-builtins:0 */
/* eslint react/no-access-state-in-setstate:0 */
/* eslint react/destructuring-assignment:0 */
import * as React from 'react';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Row, Col } from 'reactstrap';
import { Input } from '@progress/kendo-react-inputs';

import Mutation from '../../../../../../data/mutation';
import { ADD_ANIMAL_VACCINE, GET_ANIMAL_VACCINE } from '../../../../../../data/animal/animal-query';
import { GET_REVACCINATION_DATA } from '../../../../../../data/reports/reports-query';
import FarmClientDropdown from '../farm-client-dropdown';
import { DatePicker } from '../../../../../utils/date-picker';

class AddVaccineModal extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      vaccineObj: props,
      submitDisabled: true,
      tableType: 'vaccine',
    };
    this.toggleDialog = this.toggleDialog.bind(this);
    this.handleFormChange = this.handleFormChange.bind(this);
  }

  toggleDialog() {
    this.setState({
      visible: !this.state.visible,
    });

    if (!this.state.visible) {
      this.resetState();
    }
  }


  handleFormChange(e) {
    const objCopy = { ...this.state.vaccineObj, };

    if (e.target.name === 'farmClientID') {
      objCopy[e.target.name] = e.target.value.farmClientID;
    } else {
      objCopy[e.target.name] = e.target.value;
    }
    const isFormSubmitable = !(Object.keys(objCopy).length > 1);
    this.setState({
      vaccineObj: objCopy,
      submitDisabled: isFormSubmitable,
    });
  }

  handleSubmit(e, addAnimalVaccineTrigger) {
    e.preventDefault();
    const stateCopy = { ...this.state.vaccineObj, };
    let dataToSave;

    if (!stateCopy.hasOwnProperty('animalVaccineDate')) {
      stateCopy.animalVaccineDate = new Date();
      dataToSave = { ...stateCopy, };
    } else {
      dataToSave = { ...this.state.vaccineObj, };
    }

    if (Object.keys(dataToSave).length > 1) {
      addAnimalVaccineTrigger({
        variables:
                {
                  animalVaccineAdd: dataToSave,
                },
      });
    }
  }

  resetState() {
    const { animalID, } = this.state.vaccineObj;
    this.setState({
      vaccineObj: {
        animalID,
      },
      submitDisabled: true,
    });
  }

  render() {
    return (
      <Mutation
        mutation={ADD_ANIMAL_VACCINE}
        awaitRefetchQueries
        refetchQueries={[{ query: GET_ANIMAL_VACCINE, variables: { id: this.state.vaccineObj.animalID, }, }, { query: GET_REVACCINATION_DATA, }]}
        onCompleted={() => this.toggleDialog()}
      >
        {(addAnimalVaccineTrigger) => (
          <div>
            <button className="btn btn-primary" onClick={this.toggleDialog}>ДОБАВИ СЪБИТИЕ</button>
            {this.state.visible && (
            <Dialog className="health-modal" title="Добави събитие" onClose={this.toggleDialog} minWidth="500px" height="80%">
              <form className="health-form">
                <Row>
                  <Col lg={{ size: 8, offset: 2, }}>

                    <div className="form-group">
                      <label>Тип</label>
                      <Input type="text" name="animalVaccineDrug" onChange={this.handleFormChange} />
                    </div>

                    <div className="form-group">
                      <label>Ветеринарен лекар</label>
                      <FarmClientDropdown tableType={this.state.tableType} handleFormChange={this.handleFormChange} />
                    </div>
                    <div className="form-group info-margin-bottom">
                      <label>Дата</label>
                      <DatePicker
                        value={this.state.vaccineObj?.animalVaccineDate}
                        name="animalVaccineDate"
                        onChange={this.handleFormChange}
                        min={new Date(this.props.animaldateOfBirth)}
                        max={new Date()}
                      />
                    </div>

                    <div>
                      Информация
                      <hr />
                      <div className="form-group">
                        <label>Доза</label>
                        <Input type="text" name="animalVaccineDose" onChange={this.handleFormChange} />
                      </div>
                      <div className="form-group">
                        <label>Дни до следваща ваксина</label>
                        <Input type="number" name="animalVaccineEndMonths" onChange={this.handleFormChange} />
                      </div>
                      <div className="form-group">
                        <label>Цена</label>
                        <Input type="number" name="animalVaccinePrice" onChange={this.handleFormChange} />
                      </div>
                      <div className="form-group">
                        <label>Карентен срок (дни)</label>
                        <Input type="number" name="animalVaccineKarentDays" onChange={this.handleFormChange} />
                      </div>
                      <div className="form-group">
                        <label>Бележка</label>
                        <textarea className="k-textarea" name="animalVaccineComment" onChange={this.handleFormChange} />
                      </div>
                    </div>
                  </Col>
                </Row>
                <DialogActionsBar>
                  <div className="d-flex">
                    <button className="btn btn-secondary" onClick={this.toggleDialog}>Отказ</button>
                    <button type="submit" onClick={e => this.handleSubmit(e, addAnimalVaccineTrigger)} disabled={this.state.submitDisabled} className="btn btn-primary ml-3">Добави</button>
                  </div>
                </DialogActionsBar>
              </form>

            </Dialog>
            )}
          </div>
        )}
      </Mutation>
    );
  }
}

export default AddVaccineModal;
