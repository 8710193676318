/* eslint max-len:0 */
/* eslint react/button-has-type:0 */
/* eslint jsx-a11y/control-has-associated-label:0 */
/* eslint react/no-access-state-in-setstate:0 */
/* eslint react/destructuring-assignment:0 */
import * as React from 'react';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Row, Col } from 'reactstrap';
import { Input } from '@progress/kendo-react-inputs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Mutation from '../../../../../../data/mutation';
import { UPDATE_ANIMAL_VACCINE, GET_ANIMAL_VACCINE } from '../../../../../../data/animal/animal-query';
import { GET_REVACCINATION_DATA } from '../../../../../../data/reports/reports-query';
import FarmClientDropdown from '../farm-client-dropdown';
import { DatePicker } from '../../../../../utils/date-picker';


class EditVaccineModal extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      vaccineObj: props.vaccineObj,
      changes: {
        animalID: props.vaccineObj.animalID,
        animalVaccineID: props.vaccineObj.animalVaccineID,
      },
      initialState: props.vaccineObj,
      submitDisabled: true,
      tableType: 'vaccine',
    };
    this.toggleDialog = this.toggleDialog.bind(this);
    this.handleFormChange = this.handleFormChange.bind(this);
  }

  toggleDialog() {
    this.setState({
      visible: !this.state.visible,
    });

    if (!this.state.visible) {
      this.resetChanges();
    }
  }


  handleFormChange(e) {
    const objCopy = { ...this.state.vaccineObj, };
    const changes = { ...this.state.changes, };
    if (e.target.name === 'farmClientID') {
      objCopy[e.target.name] = e.target.value.farmClientID;
      changes[e.target.name] = e.target.value.farmClientID;
    } else {
      objCopy[e.target.name] = e.target.value;
      changes[e.target.name] = e.target.value;
    }
    const isFormSubmitable = !(Object.keys(changes).length > 1);
    this.setState({
      vaccineObj: objCopy,
      changes,
      submitDisabled: isFormSubmitable,
    });
  }

  handleSubmit(e, updateAnimalVaccineTrigger) {
    e.preventDefault();

    updateAnimalVaccineTrigger({
      variables:
            {
              animalVaccineUpdates: this.state.changes,
            },
    });
    this.setState({
      initialState: this.state.vaccineObj,
    });
    this.resetChanges();
  }

  resetChanges() {
    this.setState({
      changes: {
        animalID: this.state.vaccineObj.animalID,
        animalVaccineID: this.state.vaccineObj.animalVaccineID,
      },
      submitDisabled: true,
      vaccineObj: this.state.initialState,
    });
  }

  render() {
    const { vaccineObj, } = this.state;
    return (
      <Mutation
        mutation={UPDATE_ANIMAL_VACCINE}
        awaitRefetchQueries
        refetchQueries={[{ query: GET_ANIMAL_VACCINE, variables: { id: this.state.vaccineObj.animalID, }, }, { query: GET_REVACCINATION_DATA, }]}
        onCompleted={() => this.toggleDialog()}
      >
        {(updateAnimalVaccineTrigger) => (
          <div className="d-inline-block">
            <button className="button-icon" onClick={this.toggleDialog}><FontAwesomeIcon icon="pen" /></button>
            {this.state.visible && (
            <Dialog className="health-modal" title="Редактирай събитие" onClose={this.toggleDialog} minWidth="500px" height="80%">
              <form className="health-form">
                <Row>
                  <Col lg={{ size: 8, offset: 2, }}>

                    <div className="form-group">
                      <label>Тип</label>
                      <Input type="text" name="animalVaccineDrug" value={this.state.vaccineObj.animalVaccineDrug ? this.state.vaccineObj.animalVaccineDrug : ''} onChange={this.handleFormChange} />
                    </div>

                    <div className="form-group">
                      <label>Ветеринарен лекар</label>
                      <FarmClientDropdown tableType={this.state.tableType} farmClientID={this.state.vaccineObj.farmClientID} handleFormChange={this.handleFormChange} />
                    </div>
                    <div className="form-group">
                      <label>Дата</label>
                      <DatePicker
                        value={vaccineObj?.animalVaccineDate}
                        onChange={this.handleFormChange}
                        name="animalVaccineDate"
                        min={new Date(this.props.animaldateOfBirth)}
                        max={new Date()}
                      />
                    </div>

                    <div>
                      Информация
                      <hr />
                      <div className="form-group">
                        <label>Доза</label>
                        <Input type="text" name="animalVaccineDose" value={this.state.vaccineObj.animalVaccineDose ? this.state.vaccineObj.animalVaccineDose : ''} onChange={this.handleFormChange} />
                      </div>
                      <div className="form-group">
                        <label>Дни до следваща ваксина</label>
                        <Input type="number" name="animalVaccineEndMonths" value={this.state.vaccineObj.animalVaccineEndMonths} onChange={this.handleFormChange} />
                      </div>
                      <div className="form-group">
                        <label>Цена</label>
                        <Input type="number" name="animalVaccinePrice" value={this.state.vaccineObj.animalVaccinePrice} onChange={this.handleFormChange} />
                      </div>
                      <div className="form-group">
                        <label>Карентен срок</label>
                        <Input type="number" name="animalVaccineKarentDays" value={this.state.vaccineObj.animalVaccineKarentDays} onChange={this.handleFormChange} />
                      </div>
                      <div className="form-group">
                        <label>Бележка</label>
                        <textarea className="k-textarea" name="animalVaccineComment" value={this.state.vaccineObj.animalVaccineComment ? this.state.vaccineObj.animalVaccineComment : ''} onChange={this.handleFormChange} />
                      </div>
                    </div>
                  </Col>
                </Row>
              </form>
              <DialogActionsBar>
                <div className="d-flex">
                  <button className="btn btn-secondary" onClick={this.toggleDialog}>Отказ</button>
                  <button onClick={e => this.handleSubmit(e, updateAnimalVaccineTrigger)} type="submit" disabled={this.state.submitDisabled} className="btn btn-primary ml-3">Добави</button>
                </div>
              </DialogActionsBar>
            </Dialog>
            )}
          </div>
        )}
      </Mutation>
    );
  }
}

export default EditVaccineModal;
