/* eslint no-param-reassign:0 */
/* eslint no-prototype-builtins:0 */
/* eslint max-len:0 */
/* eslint react/prop-types:0 */
/* eslint react/destructuring-assignment:0 */
import * as React from 'react';

import Query from '../../../data/query';
import { GET_ANIMAL_PEDIGREE } from '../../../data/animal/animal-query';
import DateHelter from '../../../tools/DatesHelper';
import { generateBreedCompositionString } from '../../views/animals/animal-utilities';
import './pedigree.css';
// @ts-ignore
import cowRedLeft from '../../../assets/img/icons/cow_red_left.png';
// @ts-ignore
import cowBlueLeft from '../../../assets/img/icons/cow_blue_left.png';
// @ts-ignore
import cowBlackLeft from '../../../assets/img/icons/cow_black_left.png';
// @ts-ignore
import cowBlackRight from '../../../assets/img/icons/cow_black_right.png';

const PedigreeBox = (props) => (
  <div className={`pdg-box ${props.noInfo ? 'no-info' : ''} pdg-${props.father ? 'father' : 'mother'}`}>
    <img className="cow-image" src={props.father ? cowRedLeft : cowBlueLeft} alt="cow" />
    <div className="details">
      <div>
        <strong>{props.parentTitle}</strong>
        <span className="ml-3">{props.prop1}</span>
      </div>
      <span>{props.cowId}</span>
      <strong>{props.breed}</strong>
      <span>{props.date}</span>
    </div>
  </div>
);

const LevelOne = (props) => {
  return (
    <div className="col-md-3 d-flex flex-column justify-content-around">
      <div className="main-parent">
        <PedigreeBox
          noInfo={!(props.f && props.f.hasOwnProperty('animalId'))}
          father
          parentTitle="Баща"
          prop1={props.f && props.f.hasOwnProperty('animalId') ? generateBreedCompositionString(props.f) : ''}
          cowId={props.f && props.f.hasOwnProperty('animalId') ? props.f.lID : ''}
          breed=""
          date={props.f && props.f.hasOwnProperty('animalId') ? DateHelter.parseAndFormatDate(props.f.dateOfBirth) : ''}
        />
      </div>
      <div className="main-parent">
        <PedigreeBox
          noInfo={!(props.m && props.m.hasOwnProperty('animalId'))}
          father={false}
          parentTitle="Майка"
          prop1={props.m && props.m.hasOwnProperty('animalId') ? generateBreedCompositionString(props.m) : ''}
          cowId={props.m && props.m.hasOwnProperty('animalId') ? props.m.lID : ''}
          breed=""
          date={props.m && props.m.hasOwnProperty('animalId') ? DateHelter.parseAndFormatDate(props.m.dateOfBirth) : ''}
        />
      </div>
    </div>
  );
};

const LevelTwo = (props) => {
  return (
    <div className="col-md-3 d-flex flex-column justify-content-around">
      <div className="grandparents">
        <PedigreeBox
          noInfo={!(props.ff && props.ff.hasOwnProperty('animalId'))}
          father
          parentTitle="ББ"
          prop1={props.ff && props.ff.hasOwnProperty('animalId') ? generateBreedCompositionString(props.ff) : ''}
          cowId={props.ff && props.ff.hasOwnProperty('animalId') ? props.ff.lID : ''}
          breed=""
          date={props.ff && props.ff.hasOwnProperty('animalId') ? DateHelter.parseAndFormatDate(props.ff.dateOfBirth) : ''}
        />
        <PedigreeBox
          noInfo={!(props.mf && props.mf.hasOwnProperty('animalId'))}
          father={false}
          parentTitle="МБ"
          prop1={props.mf && props.mf.hasOwnProperty('animalId') ? generateBreedCompositionString(props.mf) : ''}
          cowId={props.mf && props.mf.hasOwnProperty('animalId') ? props.mf.lID : ''}
          breed=""
          date={props.mf && props.mf.hasOwnProperty('animalId') ? DateHelter.parseAndFormatDate(props.mf.dateOfBirth) : ''}
        />
      </div>
      <div className="grandparents">
        <PedigreeBox
          noInfo={!(props.fm && props.fm.hasOwnProperty('animalId'))}
          father
          parentTitle="БМ"
          prop1={props.fm && props.fm.hasOwnProperty('animalId') ? generateBreedCompositionString(props.fm) : ''}
          cowId={props.fm && props.fm.hasOwnProperty('animalId') ? props.fm.lID : ''}
          breed=""
          date={props.fm && props.fm.hasOwnProperty('animalId') ? DateHelter.parseAndFormatDate(props.fm.dateOfBirth) : ''}
        />
        <PedigreeBox
          noInfo={!(props.mm && props.mm.hasOwnProperty('animalId'))}
          father={false}
          parentTitle="ММ"
          prop1={props.mm && props.mm.hasOwnProperty('animalId') ? generateBreedCompositionString(props.mm) : ''}
          cowId={props.mm && props.mm.hasOwnProperty('animalId') ? props.mm.lID : ''}
          breed=""
          date={props.mm && props.mm.hasOwnProperty('animalId') ? DateHelter.parseAndFormatDate(props.mm.dateOfBirth) : ''}
        />
      </div>
    </div>
  );
};

const LevelThree = (props) => {
  return (
    <div className="col-md-3 d-flex flex-column justify-content-around">
      <div className="grand-grandparents">
        <PedigreeBox
          noInfo={!props.ff.sire}
          father
          parentTitle="БББ"
          prop1={props.ff.sire ? generateBreedCompositionString(props.ff.sire) : ''}
          cowId={props.ff.sire ? props.ff.sire.lID : ''}
          breed=""
          date={props.ff.sire ? DateHelter.parseAndFormatDate(props.ff.sire.dateOfBirth) : ''}
        />
        <PedigreeBox
          noInfo={!props.ff.dam}
          father={false}
          parentTitle="МББ"
          prop1={props.ff.dam ? generateBreedCompositionString(props.ff.dam) : ''}
          cowId={props.ff.dam ? props.ff.dam.lID : ''}
          breed=""
          date={props.ff.dam ? DateHelter.parseAndFormatDate(props.ff.dam.dateOfBirth) : ''}
        />
      </div>
      <div className="grand-grandparents">
        <PedigreeBox
          noInfo={!props.mf.sire}
          father
          parentTitle="БМБ"
          prop1={props.mf.sire ? generateBreedCompositionString(props.mf.sire) : ''}
          cowId={props.mf.sire ? props.mf.sire.lID : ''}
          breed=""
          date={props.mf.sire ? DateHelter.parseAndFormatDate(props.mf.sire.dateOfBirth) : ''}
        />
        <PedigreeBox
          noInfo={!props.mf.dam}
          father={false}
          parentTitle="ММБ"
          prop1={props.mf.dam ? generateBreedCompositionString(props.mf.dam) : ''}
          cowId={props.mf.dam ? props.mf.dam.lID : ''}
          breed=""
          date={props.mf.dam ? DateHelter.parseAndFormatDate(props.mf.dam.dateOfBirth) : ''}
        />
      </div>
      <div className="grand-grandparents">
        <PedigreeBox
          noInfo={!props.fm.sire}
          father
          parentTitle="ББМ"
          prop1={props.fm.sire ? generateBreedCompositionString(props.fm.sire) : ''}
          cowId={props.fm.sire ? props.fm.sire.lID : ''}
          breed=""
          date={props.fm.sire ? DateHelter.parseAndFormatDate(props.fm.sire.dateOfBirth) : ''}
        />
        <PedigreeBox
          noInfo={!props.fm.dam}
          father={false}
          parentTitle="МБМ"
          prop1={props.fm.dam ? generateBreedCompositionString(props.fm.dam) : ''}
          cowId={props.fm.dam ? props.fm.dam.lID : ''}
          breed=""
          date={props.fm.dam ? DateHelter.parseAndFormatDate(props.fm.dam.dateOfBirth) : ''}
        />
      </div>
      <div className="grand-grandparents">
        <PedigreeBox
          noInfo={!props.mm.sire}
          father
          parentTitle="БММ"
          prop1={props.mm.sire ? generateBreedCompositionString(props.mm.sire) : ''}
          cowId={props.mm.sire ? props.mm.sire.lID : ''}
          breed=""
          date={props.mm.sire ? DateHelter.parseAndFormatDate(props.mm.sire.dateOfBirth) : ''}
        />
        <PedigreeBox
          noInfo={!props.mm.dam}
          father={false}
          parentTitle="МММ"
          prop1={props.mm.dam ? generateBreedCompositionString(props.mm.dam) : ''}
          cowId={props.mm.dam ? props.mm.dam.lID : ''}
          breed=""
          date={props.mm.dam ? DateHelter.parseAndFormatDate(props.mm.dam.dateOfBirth) : ''}
        />
      </div>
    </div>
  );
};

const Pedigree = (props) => {
  const id = props.currentAnimalId;

  return (
    <div className="pedigree">
      <Query {...props} query={GET_ANIMAL_PEDIGREE} variables={{ id, }}>
        {({ animal, }) => {
          if (animal.sire === null) {
            animal.sire = { dam: {}, sire: {}, };
          }

          if (animal.sire.sire === null || !animal.sire.sire.hasOwnProperty('sire')) {
            animal.sire.sire = { dam: null, sire: null, };
          }

          if (animal.sire.dam === null || !animal.sire.dam.hasOwnProperty('dam')) {
            animal.sire.dam = { dam: null, sire: null, };
          }

          if (animal.dam === null) {
            animal.dam = { dam: {}, sire: {}, };
          }

          if (animal.dam.sire === null || !animal.dam.sire.hasOwnProperty('sire')) {
            animal.dam.sire = { dam: null, sire: null, };
          }

          if (animal.dam.dam === null || !animal.dam.dam.hasOwnProperty('dam')) {
            animal.dam.dam = { dam: null, sire: null, };
          }

          return (
            <div style={{ minHeight: '1000px', }} className="row align-items-stretch py-5">
              <div className="col-md-3 d-flex flex-column justify-content-around">
                <div className="main-child">
                  <img className="cow-image" src={cowBlackLeft} alt="cow" />
                  <div className="mx-3 text-center">
                    <h4>{animal.name}</h4>
                    <h5>{animal.lID}</h5>
                  </div>
                  <img className="cow-image" src={cowBlackRight} alt="cow" />
                </div>
              </div>
              <LevelOne m={animal.dam} f={animal.sire} />
              <LevelTwo mf={animal.sire.dam} ff={animal.sire.sire} fm={animal.dam.sire} mm={animal.dam.dam} />
              <LevelThree mf={animal.sire.dam} ff={animal.sire.sire} fm={animal.dam.sire} mm={animal.dam.dam} />
            </div>
          );
        }}
      </Query>
    </div>
  );
};

export default Pedigree;
