/* eslint-disable no-restricted-globals,no-param-reassign */
import moment from 'moment';
import { DATE_FORMAT } from '../components/utils/date-picker';

export const appDateFormat = 'DD.MM.YYYY';
const apiDateFormat = 'YYYY-MM-DD';

const formats = [
  moment.ISO_8601,
  appDateFormat,
  apiDateFormat,
  DATE_FORMAT
];
class DatesHelper {
  /**
   * Returns the current date with the default format for the application
   */
  static getCurrentDate() {
    return moment().format(appDateFormat);
  }

  /**
   * Returns a Moment object from the passed in date string
   *
   * @param {string} dateString
   */
  static parseDate(dateString) {
    return moment(dateString);
  }

  /**
   * Formats the passed in dateString to the default application date format
   *
   * @param {string} dateString
   */
  static parseAndFormatDate(dateString) {
    return moment(dateString).format(appDateFormat);
  }

  /**
  * Adds number of months to the dateString to the default application date format
  *
  * @param {string} dateString
  * @param {number} months
  */

  static addMonthsToDate(dateString, months) {
    return moment(dateString).add(months, 'months').format(appDateFormat);
  }

  /**
  * Adds number of days to the dateString to the default application date format
  *
  * @param {string} dateString
  * @param {number} months
  */

  static addDaysToDate(dateString, days) {
    return moment(dateString).add(days, 'days').format(appDateFormat);
  }

  /**
  * Adds number of days to the dateString to the default api date format
  *
  * @param {string} dateString
  * @param {number} months
  */

  static addDaysToDateAPIformat(dateString, days) {
    return moment(dateString).add(days, 'days').format(apiDateFormat);
  }

  /**
  * Subtract number of months to the dateString to the default application date format
  *
  * @param {string} dateString
  * @param {number} months
  */

  static subtractMonthsFromDate(dateString, months) {
    return moment(dateString).subtract(months, 'months').format(appDateFormat);
  }

  /**
   * Converts a date to the string format, required by the Rodeo API
   *
   * @param {Date} date
   *
   * @returns {String}
   */
  static dateToApiFormat(date) {
    return moment(date).format(apiDateFormat);
  }

  /**
   * Converts a date to the string format, required by the Rodeo APP
   *
   * @param {Date} date
   *
   * @returns {String}
   */
  static dateToAppFormat(date) {
    return this.isValidDate(date) ? moment(date).format(appDateFormat) : '';
  }

  /**
   * Returns the difference between two dates in days
   */
  static differenceBetweenDates(date1, date2) {
    if (date1 === date2) {
      return moment(date1).diff(moment(date2), 'days');
    }
    return moment(date1).diff(moment(date2), 'days');
  }

  /**
   * Check is string valid date. Retuns boolean
   */
  static isValidDate(date) {
    const timestamp = Date.parse(date);
    return !isNaN(timestamp);
  }

  static setDateToCorrectISOString = (obj) => {
    try {
      if (obj && typeof obj === 'object') {
        Object.keys(obj).forEach(key => {
          const value = obj[key];

          if (value instanceof Date) {
            obj[key] = new Date(value.getTime() - value.getTimezoneOffset() * 60000);
          } else if (typeof value === 'string' && DatesHelper.isValidDate(value) && moment(value, formats, true).isValid()) {
            const date = new Date(value);
            obj[key] = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
          } else if (typeof value === 'object') {
            if (value instanceof Array) {
              value.forEach(o => DatesHelper.setDateToCorrectISOString(o));
            } else {
              DatesHelper.setDateToCorrectISOString(value);
            }
          }
        });
      }
    } catch (ex) {
      console.error(ex);
    }
  };
}

export default DatesHelper;
