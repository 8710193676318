/* eslint max-len:0 */
import * as React from 'react';
import ReTagReportTable from './retag-table';
import Query from '../../../../data/query';
import { GET_ANIMALS_RETAG_DATA } from '../../../../data/reports/reports-query';
import {
  animalSexTranslation, dateFormatForTableFilters, reportAnimalSummary
} from '../../../../tools/function-helpers';

const RetagReport = props => {
  return (
    <Query query={GET_ANIMALS_RETAG_DATA}>
      {({ animals, }) => {
        const retaggedAnimals = [];
        animals.items.forEach((anyAnimal) => {
          if (anyAnimal.reTag.length > 0) {
            retaggedAnimals.push(anyAnimal);
          }
        });
        const mappedAnimals = retaggedAnimals.map((animal) => {
          const sexTranslation = animalSexTranslation(animal.sex);
          const retagLen = animal.reTag.length;
          const lastreTag = animal.reTag[retagLen - 1];
          return {
            ...animal,
            animalSummary: reportAnimalSummary(animal, sexTranslation),
            lastlID: retagLen > 0 ? lastreTag.lID : '',
            lastvID: retagLen > 0 ? lastreTag.vID : '',
            lasteID: retagLen > 0 ? lastreTag.electronicId : '',
            dateReTag: retagLen > 0 ? dateFormatForTableFilters(lastreTag.dateReTag) : '',
          };
        });
        return (
          <ReTagReportTable {...props} animals={mappedAnimals} />
        );
      }}
    </Query>
  );
};

export default RetagReport;
