/* eslint react/button-has-type:0 */
/* eslint react/destructuring-assignment:0 */
/* eslint react/sort-comp:0 */
/* eslint react/static-property-placement:0 */
/* eslint no-use-before-define:0 */
/* eslint no-unused-vars:0 */
import * as React from 'react';
import RGL, { WidthProvider } from 'react-grid-layout';
import RodeoTableLogs from './tables/rodeo-table-logs';
import RodeoTableReminders from './tables/rodeo-table-reminders';
import RodeoChartCalvesProduction from './charts/rodeo-chart-calves-production';
import DashboardCard from '../../lib/dashboard-card/dashboard-card';
import AllAnimalsAggregate from './data-rows/all-animals-aggregate';
import Query from '../../../data/query';
import { GET_ANIMAL_MAIN_BOARD } from '../../../data/animal/animal-query';
import CowHeiferAggregate from './data-rows/cow-heifer-aggregate';
import AnimalsFattening from './data-rows/rodeo-row-fattening';

const ReactGridLayout = WidthProvider(RGL);
const originalLayout = getFromLS('layout') || [];

export default class Home extends React.Component<any, any> {
    static defaultProps = {
      className: 'layout',
      cols: 12,
      rowHeight: 30,
      onLayoutChange() {},
    };

    constructor(props) {
      super(props);

      this.state = {
        layout: JSON.parse(JSON.stringify(originalLayout)),
      };

      this.onLayoutChange = this.onLayoutChange.bind(this);
      this.resetLayout = this.resetLayout.bind(this);
    }

    resetLayout() {
      this.setState({
        layout: [],
      });
    }

    onLayoutChange(layout) {
      saveToLS('layout', layout);
      this.setState({ layout, });
      this.props.onLayoutChange(layout);
    }

    render() {
      return (
        <Query query={GET_ANIMAL_MAIN_BOARD} {...this.props}>
          {({ animals, }) => {
            return (
              <>
                <button onClick={this.resetLayout}>Reset Layout</button>
                <ReactGridLayout
                  {...this.props}
                  layout={this.state.layout}
                  onLayoutChange={this.onLayoutChange}
                >
                  <div
                    key="1"
                    data-grid={{
                      w: 12, h: 5, x: 0, y: 0,
                    }}
                  >
                    <AllAnimalsAggregate animals={animals.items} />
                  </div>
                  <div
                    key="2"
                    data-grid={{
                      w: 12, h: 5, x: 0, y: 5,
                    }}
                  >
                    <CowHeiferAggregate animals={animals.items} />
                  </div>
                  <div
                    key="3"
                    data-grid={{
                      w: 6, h: 12, x: 6, y: 10,
                    }}
                  >
                    <RodeoTableLogs {...this.props} />
                  </div>
                  <div
                    key="4"
                    data-grid={{
                      w: 6, h: 12, x: 0, y: 10,
                    }}
                  >
                    <RodeoChartCalvesProduction animals={animals.items} />
                  </div>
                  <div
                    key="5"
                    data-grid={{
                      w: 12, h: 5, x: 0, y: 22,
                    }}
                  >
                    <AnimalsFattening animals={animals.items} />
                  </div>

                </ReactGridLayout>
              </>
            );
          } }

        </Query>
      );
    }
}

function getFromLS(key) {
  let ls = {};
  if (window.localStorage) {
    try {
      ls = JSON.parse(window.localStorage.getItem('rgl-7')) || {};
    } catch (e) {
      /* Ignore */
    }
  }
  return ls[key];
}

function saveToLS(key, value) {
  if (window.localStorage) {
    window.localStorage.setItem(
      'rgl-7',
      JSON.stringify({
        [key]: value,
      })
    );
  }
}
