/* eslint react/button-has-type:0 */
/* eslint no-prototype-builtins:0 */
/* eslint no-restricted-syntax:0 */
/* eslint guard-for-in:0 */
/* eslint react/no-access-state-in-setstate:0 */
/* eslint no-param-reassign:0 */
/* eslint react/destructuring-assignment:0 */
/* eslint react/state-in-constructor:0 */
/* eslint no-underscore-dangle:0 */
/* eslint react/sort-comp:0 */
import * as React from 'react';
import {
  Grid, GridColumn as Column, GridToolbar, GridColumnMenuFilter
} from '@progress/kendo-react-grid';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { process } from '@progress/kendo-data-query';
import {
  LocalizationProvider, loadMessages, load, IntlProvider
} from '@progress/kendo-react-intl';
import CustomColumnModal from '../../../custom-column/custom-column';
import {
  TABLE_BG_MESSAGES, bgDateFields, bgGregorian, weekData, numbers
} from '../../../../tools/translation-constants';


load(
  weekData,
  numbers,
  bgDateFields,
  bgGregorian
);

loadMessages(TABLE_BG_MESSAGES, 'bg-BG');
const PAGE_TAKE = 10;

class RevaccinationTable extends React.Component<any, any> {
    _export;

    export = () => {
      this._export.save();
    }

    state = {
      ...this.createAppState({
        take: PAGE_TAKE,
        skip: 0,
        group: [{ field: 'animalSummary', }],
      }),
      columns: {
        animalSummary: {
          title: 'Животно',
          visible: true,
          field: 'animalSummary',
          width: '250px',
          filter: false,
        },
        animalVaccineDate: {
          title: 'Дата на ваксинация',
          visible: true,
          field: 'animalVaccineDate',
          width: '240px',
          filter: true,
        },
        animalVaccineDrug: {
          title: 'Препарат',
          visible: true,
          field: 'animalVaccineDrug',
          width: '195px',
          filter: true,
        },
        animalVaccineDose: {
          title: 'Доза',
          visible: true,
          field: 'animalVaccineDose',
          width: '120px',
          filter: true,
        },
        dateNextVaccination: {
          title: 'Дата следваща ваксина',
          visible: true,
          field: 'dateNextVaccination',
          filter: true,
          width: '300px',
        },
      },
    }


    createAppState(dataState) {
      const excelDataState = {
        filter: dataState.filter,
        sort: dataState.sort,
        group: dataState.group,
      };

      return {
        result: process(this.props.animals, dataState),
        dataState,
        excelItems: process(this.props.animals, excelDataState),
      };
    }

    dataStateChange = (event) => {
      this.setState(this.createAppState(event.data));
    };

    expandChange = (event) => {
      event.dataItem[event.target.props.expandField] = event.value;
      this.setState({
        result: { ...this.state.result, },
        dataState: this.state.dataState,
      });
    }

    updateTableVisibleCols = (columns) => {
      this.setState({
        columns,
      });
    }

    printCols = () => {
      const reVaccinationCols = [];
      for (const key in this.state.columns) {
        const curCol = this.state.columns[key];
        if (this.state.columns.hasOwnProperty(key) && curCol.visible) {
          if (key === 'animalVaccineDate' || key === 'dateNextVaccination') {
            reVaccinationCols.push(
              <Column
                key={key}
                field={curCol.field}
                title={curCol.title}
                width={curCol.width}
                filter="date"
                format="{0:dd.MM.yyy}"
                columnMenu={props => <GridColumnMenuFilter {...props} />}
              />
            );
          } else {
            reVaccinationCols.push(
              <Column key={key} field={curCol.field} title={curCol.title} width={curCol.width} />
            );
          }
        }
      }
      return reVaccinationCols;
    }

    render() {
      return (
        <div className="container-fluid">
          <h3 className="mb-4">Справка РЕВАКСИНАЦИЯ</h3>
          <LocalizationProvider language="bg-BG">
            <IntlProvider locale="bg">
              <ExcelExport
                data={this.state.excelItems.data}
                fileName="report-revaccination.xlsx"
                group={this.state.dataState.group && this.state.dataState.group.length ? this.state.dataState.group : false}
                ref={exporter => { this._export = exporter; }}
              >
                <Grid
                  resizable
                  reorderable
                  filterable
                  sortable
                  pageable={{ pageSizes: true, }}
                  skip={this.state?.dataState?.skip}
                  groupable
                  data={this.state.result}
                  onDataStateChange={this.dataStateChange}
                  {...this.state.dataState}

                  onExpandChange={this.expandChange}
                  expandField="expanded"
                  className="table table-advanced"
                >
                  <GridToolbar>
                    <div className="d-flex justify-content-between align-items-center">
                      <span className="list-counter">
                        Брой Животни:
                        {this.state.result.total}
                      </span>
                      <div className="d-flex d-inline-block">
                        <CustomColumnModal columns={this.state.columns} updateTableVisibleCols={this.updateTableVisibleCols} />
                        <button
                          title="Export Excel"
                          className="btn btn-secondary ml-2"
                          onClick={this.export}
                        >
                          Експорт в Excel
                        </button>
                      </div>
                    </div>
                  </GridToolbar>
                  {this.printCols()}
                </Grid>
              </ExcelExport>
            </IntlProvider>
          </LocalizationProvider>
        </div>
      );
    }
}

export default RevaccinationTable;
