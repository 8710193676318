/* eslint-disable */
import * as React from 'react';
import { Grid, GridColumn as Column, GridToolbar, GridColumnMenuFilter } from '@progress/kendo-react-grid';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { filterBy, orderBy } from '@progress/kendo-data-query';

import { LocalizationProvider, loadMessages, load, IntlProvider } from '@progress/kendo-react-intl';
import CustomColumnModal from '../../../custom-column/custom-column';
import Mutation from '../../../../data/mutation';
import { TABLE_BG_MESSAGES, bgDateFields, bgGregorian, weekData } from '../../../../tools/translation-constants';
import { dropdownFilterCell } from '../../../../tools/dropdown-filter-cell';
import { EDIT_ANIMAL_TUBERCULOSIS, SINGLE_DPP_GROUP } from '../../../../data/dpp-group/dpp-group-mutations';
import { deepCopy } from '../../../../tools/function-helpers';
load(weekData, bgDateFields, bgGregorian);

loadMessages(TABLE_BG_MESSAGES, 'bg-BG');
const PAGE_TAKE = 20;

class DppEditTable extends React.Component<any, any> {
  _export;
  preventExit: any;
  preventExitTimeout: any;
  blurTimeout: any;

  constructor(props) {
    super(props);
    this.state = {
      animals: props.animals,
      editField: undefined,
      filter: undefined,
      sort: [],
      sortable: {
        allowUnsort: true,
        mode: 'single',
      },
      skip: 0,
      take: PAGE_TAKE,
      pageSize: PAGE_TAKE,
      pageable: {
        buttonCount: 5,
        info: true,
        type: 'numeric',
        previousNext: true,
        pageSizes: [5, 10, 20, props.animals.length],
      },
      columns: {
        lID: {
          title: 'Животно',
          visible: true,
          field: 'lID',
          width: '150px',
          type: 'rodeo',
          edit: false,
        },
        dateApply: {
          title: 'Дата(апликация)',
          visible: true,
          field: 'animalTuberculosis.dateApply',
          width: '200px',
          type: 'tub',
          edit: false,
          format: '{0:d}',
        },
        applySize: {
          title: 'Размер(мм)',
          visible: true,
          field: 'animalTuberculosis.applySize',
          width: '150px',
          type: 'tub',
          editor: 'numeric',
          edit: true,
        },
        dateCheck: {
          title: 'Дата(отчитане)',
          visible: true,
          field: 'animalTuberculosis.dateCheck',
          width: '200px',
          type: 'tub',
          edit: false,
        },
        checkSize: {
          title: 'Размер(отчитане мм.)',
          visible: true,
          field: 'animalTuberculosis.checkSize',
          width: '200px',
          type: 'tub',
          edit: true,
          editor: 'numeric',
        },
        tuberculosisCheckTypeID: {
          title: 'Резултат',
          visible: true,
          field: 'animalTuberculosis.tuberculosisCheckTypeID',
          width: '150px',
          type: 'tub',
          edit: false,
        },
        animalBrucellosisDate: {
          title: 'Кръвна проба дата',
          visible: true,
          field: 'animalBrucellosis.animalBrucellosisDate',
          width: '190px',
          type: 'brut',
          edit: false,
        },
        brucellosisCheckTypeID: {
          title: 'Резултат',
          visible: true,
          field: 'animalBrucellosis.brucellosisCheckTypeID',
          width: '150px',
          type: 'brut',
          edit: true,
        },
      },
    };
    this.enterEdit.bind(this);
    this.itemChange.bind(this);
    this.pageChange = this.pageChange.bind(this);
    this.printCols = this.printCols.bind(this);
  }

  export = () => {
    this._export.save();
  };

  pageChange = (event) => {
    this.setState({
      skip: event.page.skip,
      take: event.page.take,
      pageSize: event.page.take,
    });
  };

  filterChange = (event) => {
    const { animals, sort } = this.state;
    this.setState({
      animals: filterBy(orderBy(animals, sort), event.filter),
      filter: event.filter,
      skip: 0,
      take: PAGE_TAKE,
    });
  };

  sortChange = (event) => {
    const { animals } = this.state;
    this.setState({
      animals: orderBy(animals, event.sort),
      sort: event.sort,
    });
  };

  printCols() {
    const dropCols = [];
    const tubCols = [];
    const brutCols = [];
    for (const key in this.state.columns) {
      const curCol = this.state.columns[key];
      if (this.state.columns.hasOwnProperty(key) && this.state.columns[key].type === 'rodeo' && this.state.columns[key].visible) {
        dropCols.push(<Column key={key} field={curCol.field} title={curCol.title} width={curCol.width} editable={curCol.edit} />);
      }
      if (this.state.columns.hasOwnProperty(key) && this.state.columns[key].type === 'tub' && this.state.columns[key].visible) {
        if (key === 'dateApply' || key === 'dateCheck') {
          tubCols.push(
            <Column
              field={this.state.columns[key].field}
              title={this.state.columns[key].title}
              editable={this.state.columns[key].edit}
              width={this.state.columns[key].width}
              filter="date"
              format="{0:dd.MM.yyy}"
              columnMenu={(props) => <GridColumnMenuFilter {...props} />}
            />
          );
        } else {
          tubCols.push(
            <Column
              field={this.state.columns[key].field}
              title={this.state.columns[key].title}
              width={this.state.columns[key].width}
              editor={this.state.columns[key].editor}
              editable={this.state.columns[key].edit}
            />
          );
        }
      }
      if (this.state.columns.hasOwnProperty(key) && this.state.columns[key].type === 'brut' && this.state.columns[key].visible) {
        if (key === 'animalBrucellosisDate') {
          brutCols.push(
            <Column
              field={this.state.columns[key].field}
              title={this.state.columns[key].title}
              editable={this.state.columns[key].edit}
              width={this.state.columns[key].width}
              filter="date"
              format="{0:dd.MM.yyy}"
              columnMenu={(props) => <GridColumnMenuFilter {...props} />}
            />
          );
        } else {
          brutCols.push(
            <Column field={this.state.columns[key].field} title={this.state.columns[key].title} editable={this.state.columns[key].edit} />
          );
        }
      }
    }
    return {
      dropCols,
      tubCols,
      brutCols,
    };
  }

  cellRender(tdElement, cellProps) {
    const dataItem = cellProps.dataItem;
    const cellField = cellProps.field;
    const inEditField = cellProps.dataItem['inEdit'];
    const additionalProps =
      cellField && cellField === inEditField
        ? {
            ref: (td) => {
              const input = td && td.querySelector('input');
              const activeElement = document.activeElement;

              if (!input || !activeElement || input === activeElement || !activeElement.contains(input)) {
                return;
              }

              if (input.type === 'checkbox') {
                input.focus();
              } else {
                input.select();
              }
            },
          }
        : {
            onClick: () => {
              this.enterEdit(dataItem, cellField);
            },
          };
    return React.cloneElement(tdElement, { ...tdElement.props, ...additionalProps }, tdElement.props.children);
  }

  rowRender = (trElement) => {
    const trProps = {
      ...trElement.props,
      onMouseDown: () => {
        this.preventExit = true;
        clearTimeout(this.preventExitTimeout);
        this.preventExitTimeout = setTimeout(() => {
          this.preventExit = undefined;
        });
      },
      onBlur: () => {
        clearTimeout(this.blurTimeout);
        if (!this.preventExit) {
          this.blurTimeout = setTimeout(() => {
            this.exitEdit();
          });
        }
      },
      onFocus: () => {
        clearTimeout(this.blurTimeout);
      },
    };
    return React.cloneElement(trElement, { ...trProps }, trElement.props.children);
  };

  render() {
    const { dropCols } = this.printCols();
    const { tubCols } = this.printCols();
    const { brutCols } = this.printCols();

    return (
      <Mutation
        mutation={EDIT_ANIMAL_TUBERCULOSIS}
        awaitRefetchQueries
        //refetchQueries={[{ query: SINGLE_DPP_GROUP, variables: {id: parseInt(this.state.animals.animalTuberculosis[0].find(a => a.groupZPPID)),}, }]}
      >
        {(mutationTrigger) => (
          <div className="container-fluid">
            <h3 className="mb-4">Отчитане на резултати ДПП</h3>
            <LocalizationProvider language="bg-BG">
              <IntlProvider locale="bg">
                <ExcelExport
                  data={this.state.animals}
                  fileName="rodeo-dpp.xlsx"
                  ref={(exporter) => {
                    this._export = exporter;
                  }}
                >
                  <Grid
                    data={this.state.animals.slice(this.state.skip, this.state.take + this.state.skip)}
                    className="table table-advanced"
                    skip={this.state.skip}
                    total={this.state.animals.length}
                    onPageChange={this.pageChange}
                    onFilterChange={this.filterChange}
                    filter={this.state.filter}
                    filterable
                    pageable={this.state.pageable}
                    pageSize={this.state.pageSize}
                    onSortChange={this.sortChange}
                    sort={this.state.sort}
                    sortable={this.state.sortable}
                    resizable
                    reorderable
                    onItemChange={this.itemChange}
                    cellRender={this.cellRender.bind(this)}
                    rowRender={this.rowRender.bind(this)}
                    editField="inEdit"
                  >
                    <GridToolbar>
                      <div className="d-flex justify-content-between align-items-center">
                        <span className="list-counter">
                          Брой Животни:
                          {this.state.animals.length}
                        </span>
                        <div className="d-flex d-inline-block">
                          <button
                            title="Save Changes"
                            className="btn btn-secondary ml-2"
                            onClick={(e) => this.handleSubmit(e, mutationTrigger)}
                            type="submit"
                          >
                            Запази промените
                          </button>

                          <button title="Export Excel" className="btn btn-secondary ml-2" onClick={this.export}>
                            Експорт в Excel
                          </button>
                        </div>
                      </div>
                    </GridToolbar>
                    {dropCols}
                    {tubCols.length > 0 ? <Column title="Туберкулинизация" children={tubCols} /> : <Column title="Туберкулинизация" field="" />}
                    {brutCols.length > 0 ? <Column title="Бруцелоза" children={brutCols} /> : <Column title="Бруцелоза" field="" />}
                  </Grid>
                </ExcelExport>
              </IntlProvider>
            </LocalizationProvider>
          </div>
        )}
      </Mutation>
    );
  }

  enterEdit = (dataItem, field) => {
    const animals = this.state.animals.map((item) => ({
      ...item,
      inEdit: item.animalId === dataItem.animalId ? field : undefined,
    }));
    this.setState({
      animals,
      editField: field,
    });
  };

  exitEdit() {
    this.state.animals.forEach((d) => {
      d.inEdit = undefined;
    });
    this.setState({
      animals: this.state.animals,
      editField: undefined,
    });
  }

  itemChange = (event) => {
    const [obj, prop] = event.field.split('.');
    event.dataItem[obj][prop] = event.value;
    event.dataItem.changes = true;
    this.setState({
      changes: true,
    });
  };

  handleSubmit = (e, mutationTrigger) => {
    e.preventDefault();
    const data = this.state.animals
      .filter((a) => a.changes)
      .map((animals) => {
        return {
          animalTuberculosisID: animals.animalTuberculosis.animalTuberculosisID,
          animalID: animals.animalId,
          applySize: animals.animalTuberculosis.applySize,
          checkSize: animals.animalTuberculosis.checkSize,
        };
      });
    mutationTrigger({
      variables: {
        dataItem: data,
      },
    });

    this.setState({
      isSubmited: true,
    });
  };
}

export default DppEditTable;
