/* eslint max-len:0 */
/* eslint react/button-has-type:0 */
/* eslint no-prototype-builtins:0 */
/* eslint react/no-access-state-in-setstate:0 */
/* eslint react/destructuring-assignment:0 */
/* eslint react/no-unused-state:0 */
import * as React from 'react';
import { Input, NumericTextBox } from '@progress/kendo-react-inputs';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Row, Col } from 'reactstrap';
import Mutation from '../../../../../data/mutation';
import { ADD_ANIMAL_ASSIS, GET_ANIMAL_DETAILS } from '../../../../../data/animal/animal-query';
import { DatePicker } from '../../../../utils/date-picker';

class AddAssisModal extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      assisObj: props,
      submitDisabled: true,
      tableType: 'heat',
    };
    this.toggleDialog = this.toggleDialog.bind(this);
    this.handleFormChange = this.handleFormChange.bind(this);
  }

  toggleDialog() {
    this.setState({
      visible: !this.state.visible,
    });

    if (!this.state.visible) {
      this.resetState();
    }
  }

  handleFormChange(e) {
    const objCopy = { ...this.state.assisObj, };

    if (e.target.name === 'assisYear') {
      objCopy[e.target.name] = e.target.value.value;
      objCopy.assisYear = e.target.value.id;
    } else {
      objCopy[e.target.name] = e.target.value;
    }

    const isFormSubmitable = !(Object.keys(objCopy).length > 1);
    this.setState({
      assisObj: objCopy,
      submitDisabled: isFormSubmitable,
    });
  }

  handleSubmit(e, addAnimalAssisTrigger) {
    e.preventDefault();
    const stateCopy = { ...this.state.assisObj, };
    let dataToSave;

    if (!stateCopy.hasOwnProperty('assistanceDateIn')) {
      stateCopy.assistanceDateIn = new Date();
      dataToSave = { ...stateCopy, };
    } else {
      dataToSave = { ...this.state.assisObj, };
    }

    if (Object.keys(dataToSave).length > 1) {
      addAnimalAssisTrigger({
        variables:
                {
                  animalAssisAdd: dataToSave,
                },
      });
    }
  }

  resetState() {
    const { animalID, } = this.state.assisObj;
    this.setState({
      assisObj: {
        animalID,
      },
      submitDisabled: true,
    });
  }

  render() {
    return (
      <Mutation
        mutation={ADD_ANIMAL_ASSIS}
        awaitRefetchQueries
        refetchQueries={[{ query: GET_ANIMAL_DETAILS, variables: { id: this.state.assisObj.animalID, }, }]}
        onCompleted={() => this.toggleDialog()}
      >
        {(addAnimalAssisTrigger) => (
          <div>
            <button className="btn btn-primary" onClick={this.toggleDialog}>ДОБАВИ СЪБИТИЕ</button>
            {this.state.visible && (
            <form onSubmit={e => this.handleSubmit(e, addAnimalAssisTrigger)}>
              <Dialog title="Добави събитие" onClose={this.toggleDialog} minWidth="500px" height="60%">
                <Row>
                  <Col lg={{ size: 8, offset: 2, }}>

                    <div className="form-group">
                      <label>Дата на заявяване</label>
                      <DatePicker
                        value={this.state.assisObj?.assistanceDateIn}
                        name="assistanceDateIn"
                        onChange={this.handleFormChange}
                      />
                    </div>

                    <div className="form-group">
                      <label>Мярка</label>
                      <Input type="text" name="assistanceName" value={this.state.assisObj.assistanceName ? this.state.assisObj.assistanceName : ''} onChange={this.handleFormChange} />
                    </div>

                    <div className="form-group">
                      <label>Задържане</label>
                      <NumericTextBox defaultValue={0} min={0} name="assistanceDays" value={this.state.assisObj.assistanceDays ? this.state.assisObj.assistanceDays : ''} onChange={this.handleFormChange} />
                    </div>


                  </Col>
                </Row>
                <DialogActionsBar>
                  <div className="d-flex">
                    <button className="btn btn-secondary" onClick={this.toggleDialog}>Отказ</button>
                    <button type="submit" disabled={this.state.submitDisabled} className="btn btn-primary ml-3">Добави</button>
                  </div>
                </DialogActionsBar>
              </Dialog>
            </form>
            )}
          </div>
        )}
      </Mutation>
    );
  }
}

export default AddAssisModal;
