/* eslint max-len:0 */
/* eslint no-unused-vars:0 */
import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import Companies from './companies/companies';
import Contragents from './contragents/contragents';
import MapLocations from './map-locations/map-locations';
import Users from './users/users';
import Query from '../../../data/query';
import { GET_FIRMS_IN_FARMS } from '../../../data/companies/companies-queries';
import { GET_CONTRAGENTS } from '../../../data/contragents/contragents-queries';
import { GET_LOCATIONS_BY_TYPE, GET_ANIMAL_OBJECTS } from '../../../data/map-locations/map-locations-query';
import { GET_USERS } from '../../../data/users/users-queries';
import { LOCATION_TYPES } from '../../../tools/translation-constants';
import { GET_ALL_TASKS } from '../../../data/scheduler/scheduler-query';
import SchedulerView from './scheduler/scheduler';


const Settings = props => (
  <Switch>
    <Route
      path="/settings/locations"
      render={() => {
        return (
          <Query query={GET_ANIMAL_OBJECTS}>
            {({ animalsObjects, }) => (
              <Query query={GET_LOCATIONS_BY_TYPE(LOCATION_TYPES.BARN.text)}>
                {({ locations: barnLocations, }) => (
                  <Query query={GET_LOCATIONS_BY_TYPE(LOCATION_TYPES.PASTURE.text)}>
                    {({ locations: pastureLocations, }) => {
                      const locations = [
                        ...pastureLocations.items,
                        ...barnLocations.items
                      ];
                      const sectorsMapped = [];
                      const barnsMapped = locations.map(location => {
                        if (location.children.length > 0) {
                          location.children.forEach(sector => sectorsMapped.push({
                            id: sector.locationID,
                            value: sector.locationName ? `${location.locationName}, ${sector.locationName}` : '-',
                            mainParentType: location.locationType,
                          }));
                        }
                        return {
                          id: location.locationID,
                          value: location.locationName,
                        };
                      });

                      const mappedAnimalsObjects = animalsObjects.items.map((obj) => {
                        return {
                          id: obj.animalObjectID,
                          value: obj.animalObjectNumber ? (`${obj.animalObjectNumber} - ${obj.parentFirm.firmName ? obj.parentFirm.firmName : ' '}`) : '-',
                        };
                      });

                      return (
                        <MapLocations locations={locations} animalsObjects={mappedAnimalsObjects} barnsLocations={barnsMapped} sectorsMapped={sectorsMapped} />
                      );
                    }}
                  </Query>
                )}
              </Query>
            )}
          </Query>
        );
      }}
    />
    <Route
      path="/settings/companies"
      render={() => {
        return (
          <Query query={GET_FIRMS_IN_FARMS}>
            {({ firmsInFarms, }) => <Companies firmsInFarms={firmsInFarms.items} />}
          </Query>
        );
      }}
    />
    <Route
      path="/settings/contragents"
      render={() => {
        return (
          <Query query={GET_CONTRAGENTS}>
            {({ farmClients, }) => <Contragents contragents={farmClients.items} />}
          </Query>
        );
      }}
    />
    <Route
      path="/settings/users"
      render={() => {
        return (
          <Query query={GET_USERS}>
            {({ farmUsers, }) => <Users users={farmUsers.items} />}
          </Query>
        );
      }}
    />
    <Route
      path="/settings/scheduler"
      render={() => {
        return (
          <Query query={GET_ALL_TASKS}>
            {({ task, }) => {
              const currentYear = new Date().getFullYear();
              const parseAdjust = (eventDate) => {
                const date = new Date(eventDate);
                date.setFullYear(currentYear);
                return date;
              };

              const sampleData = task.items.map(dataItem => (
                {
                  id: dataItem.taskID,
                  start: parseAdjust(dataItem.start),
                  startTimezone: dataItem.startTimezone,
                  end: parseAdjust(dataItem.end),
                  endTimezone: dataItem.endTimezone,
                  isAllDay: dataItem.isAllDay,
                  title: dataItem.title,
                  description: dataItem.description,
                  recurrenceRule: dataItem.recurrenceRule,
                  recurrenceId: dataItem.recurrenceID,
                  recurrenceExceptions: dataItem.RecurrenceException,
                  roomId: dataItem.roomID,
                  ownerID: dataItem.ownerID,
                  personId: dataItem.ownerID,
                }
              ));
              return (
                <SchedulerView tasks={sampleData} />
              );
            }}
          </Query>
        );
      }}
    />
  </Switch>
);

export default Settings;
