/* eslint-disable no-param-reassign */
/* eslint no-shadow:0 */
/* eslint no-unused-vars:0 */
/* eslint react/destructuring-assignment:0 */
/* eslint react/prefer-stateless-function:0 */
import * as React from 'react';
import { connect } from 'react-redux';
import Query from '../../../../../data/query';
import {
  GET_ANIMAL_EVENTS, GET_ANIMAL_CHILDREN_WEANING_DATA, GET_SIRES_AND_DAMS, GET_ANIMAL_SERVICE_GROUPS, GET_SERVICE_GROUPS, GET_ANIMAL_AI_DATA
} from '../../../../../data/animal/animal-query';
import { DATA_TAB_ENUMS } from '../../../../../data/animal/tabs-queries';
import PregnancyTable from './pregnancy-table/pregnancy-table';
import BirthTable from './birth-table/birth-table';
import AddBirthModal from './birth-table/add-birth-modal';
import AddPregnancyModal from './pregnancy-table/add-pregnancy-modal';
import HeatTable from './heat-table/heat-table';
import AddHeatModal from './heat-table/add-heat-modal';
import WeaningModal from './weaning/weaning-modal';
import ServiceTable from './service-table/service-table';
import WeaningTable from './weaning/weaning-table';
import AITable from './insemination-table/insemination-table';


class ReproductionTab extends React.Component<any, any> {
  render = () => {
    return (
      <Query query={GET_ANIMAL_EVENTS} variables={{ id: this.props.animal.animalId, }}>
        {({ animal, }) => {
          const { pregnancyEvents, } = animal.events;
          const abortsCount = pregnancyEvents.filter(p => p.pregnancyCheck === 'ABORT').length;

          let birthStats = null;
          if (this.props.animal.sex === 'FEMALE') {
            birthStats = (
              <div>
                <strong className="ml-3">
                  | БРЕМЕННОСТИ |
                  <span className="text-success">
                    {' '}
                    {pregnancyEvents.length}
                    {' '}
                  </span>
                  |
                  {' '}
                </strong>
                <strong className="ml-2">
                  | СЪРВИСИ |
                  <span className="text-success"> - </span>
                  |
                  {' '}
                </strong>
                <strong className="ml-2">
                  | АБОРТИ |
                  <span className="text-accent">
                    {' '}
                    {abortsCount}
                    {' '}
                  </span>
                  |
                  {' '}
                </strong>
              </div>
            );
          }

          return (
            <div className="tab">
              <h3 className="mb-4">РЕПРОДУКЦИЯ</h3>
              <Query query={GET_ANIMAL_SERVICE_GROUPS} variables={{ id: this.props.animal.animalId, }} fetchPolicy="network-only">
                {({ animal, }) => (
                  <Query query={GET_SERVICE_GROUPS}>
                    {({ serviceGroups, }) => {
                      return (
                        <div className="table-inner mb-5">
                          <h4>СЪРВИСИ</h4>
                          <p className="mb-3">
                            {' '}
                            <small>Сървиси от сървис групи се записват автоматично!</small>
                          </p>
                          <ServiceTable animal={animal} serviceGroups={serviceGroups.items} />
                        </div>
                      );
                    }}
                  </Query>
                )}
              </Query>
              <div className="table-inner mb-5">
                <h4>БРЕМЕННОСТ АБОРТИ</h4>
                <p className="mb-3"><small>Тук добавяте установени бременности!</small></p>
                <AddPregnancyModal animalID={this.props.animal.animalId} animaldateOfBirth={this.props.animal.dateOfBirth} />
                <PregnancyTable pregnancyEvents={animal.events.pregnancyEvents} />
              </div>
              <Query query={GET_ANIMAL_AI_DATA} variables={{ id: this.props.animal.animalId, }}>
                {({ animal, }) => (
                  <div className="table-inner mb-5">
                    <h4>ИЗКУСТВЕНО ОСЕМЕНЯВАНЕ</h4>
                    <p className="mb-3"><small>Тук са всички осеменявания на животното!</small></p>
                    <AITable aIEvent={animal.animalAI} />
                  </div>
                )}
              </Query>
              <Query query={GET_SIRES_AND_DAMS}>
                {({ sires, }) => {
                  return (
                    <Query query={DATA_TAB_ENUMS}>
                      {({ aClasses, breeds, color, }) => (
                        <div className="table-inner mb-5">
                          <h4>ОТЕЛВАНЕ</h4>
                          <p className="mb-3"><small className="mb-1">Тук добавяте новите телета!</small></p>
                          <Query query={GET_ANIMAL_SERVICE_GROUPS} variables={{ id: this.props.animal.animalId, }}>
                            {({ animal, }) => (
                              <Query query={GET_SERVICE_GROUPS}>
                                {({ serviceGroups, }) => {
                                  const mappedAnimalsGroups = animal.animalInService.map((animalGroup) => {
                                    const bulls = serviceGroups.items.find(group => group.groupID === animalGroup.groupID)
                                      .animalServiceProp
                                      .filter(animalInGroup => animalInGroup.animals.classID === 7)
                                      .map((bull) => (bull.animals.lID));
                                    return {
                                      name: animalGroup.group.groupName,
                                      bulls,
                                    };
                                  });

                                  const matchedSires = sires.items;

                                  matchedSires.forEach((sire) => {
                                    mappedAnimalsGroups.forEach((group) => {
                                      group.bulls.forEach((bullId) => {
                                        if (sire.lID === bullId) {
                                          if (!sire.serviceGroupName) {
                                            sire.serviceGroupName = group.name;
                                          }
                                        }
                                      });
                                    });
                                  });

                                  const mappedSires = matchedSires.map((sire) => {
                                    if (sire.serviceGroupName) {
                                      return ({
                                        animalId: sire.animalId,
                                        lID: `${sire.lID} | ${sire.name} | ${sire.serviceGroupName}`,
                                      });
                                    }
                                    return ({
                                      animalId: sire.animalId,
                                      lID: `${sire.lID} | ${sire.name}`,
                                    });
                                  });
                                  return (
                                    <AddBirthModal
                                      animalID={this.props.animal.animalId}
                                      sires={mappedSires}
                                      aClasses={aClasses.items}
                                      breeds={breeds.items}
                                      color={color.items}
                                      firmID={this.props.animal.animalOwner ? this.props.animal.animalOwner.firmID : null}
                                      animalDamLocation={this.props.animal.animalLocation}
                                      animaldateOfBirth={this.props.animal.dateOfBirth}
                                    />
                                  );
                                }}
                              </Query>
                            )}
                          </Query>
                          <BirthTable animalObj={this.props.animal} birthEvents={animal.events.birthEvents} />
                        </div>
                      )}
                    </Query>
                  );
                }}
              </Query>
              <Query query={GET_ANIMAL_CHILDREN_WEANING_DATA} variables={{ id: this.props.animal.animalId, }}>
                {({ animal, }) => (
                  <div className="table-inner mb-5">
                    <h4>ОТБИВАНЕ</h4>
                    <p className="mb-3"><small>Тук отбиваме телета!</small></p>
                    <WeaningModal
                      animalID={this.props.animal.animalId}
                      animalChildren={animal.children}
                      animaldateOfBirth={this.props.animal.dateOfBirth}
                    />
                    <WeaningTable weaningEvents={animal.events.weaningEvents} />
                  </div>
                )}
              </Query>
              <div className="table-inner mb-5">
                <h4>ЕСТРУС</h4>
                <p className="mb-3">
                  {' '}
                  <small>Тук добавяте еструс и синхронизация!</small>
                </p>
                <AddHeatModal animalID={this.props.animal.animalId} animaldateOfBirth={this.props.animal.dateOfBirth} />
                <HeatTable heatEvents={animal.events.heatEvents} />
              </div>
            </div>
          );
        }}
      </Query>
    );
  }
}

const mapStateToProps = ({ animal, }) => ({ animal, });

export default connect(mapStateToProps)(ReproductionTab);
