/* eslint-disable no-restricted-globals */
/* eslint-disable no-unused-vars */
import { reportAnimalSummary, animalSexTranslation } from '../../../../tools/function-helpers';

export const SHEET_TITLE = 'Групово претегляне';

export enum VALIDATION_TYPE {
    Reject = 'reject'
  }

export enum VALIDATION_DATA_TYPE {
    List = 'list',
    Custom = 'custom',
    Date = 'date'
  }

const VALIDATION_MESSAGE_TITLE = 'Невалидна стойност';

export const VALIDATION_MESSAGES = {
  animalData: {
    title: VALIDATION_MESSAGE_TITLE,
    message: 'Няма животно с такъв ВЕТИС, ВИД, ЕИД',
  },
  animalWeight: {
    title: VALIDATION_MESSAGE_TITLE,
    message: 'Теглото трябва да е число.',
  },
  animalsDateWeight: {
    title: VALIDATION_MESSAGE_TITLE,
    message: 'Полето е дата с формат - dd.MM.yyyy',
  },
  animalsWeightType: {
    title: VALIDATION_MESSAGE_TITLE,
    message: 'Невалиден вид на теглене',
  },
};

export const INVALID_CELL_VALUE = 'invalid';

export const HEADER_COMMON_SETTINGS = {
  background: 'white',
  textAlign: 'center',
  color: 'black',
  enable: false,
  bold: true,
};

const COLUMN_TITLES_COMMON_PROPS = {
  background: 'white',
  textAlign: 'center',
  color: 'black',
  enable: false,
  borderBottom: ({ size: 1, color: 'black', }),
  bold: true,
  fontSize: 14,
};

export const COLUMNS_SETTINGS = [
  {
    value: 'ВЕТИС №, ВИН, ЕИД',
    ...COLUMN_TITLES_COMMON_PROPS,
  },
  {
    value: 'ТЕГЛО - КГ',
    ...COLUMN_TITLES_COMMON_PROPS,
  },
  {
    value: 'БЕЛЕЖКА',
    ...COLUMN_TITLES_COMMON_PROPS,
  },
  {
    value: 'ДАННИ ЗА ЖИВОТНОТО',
    ...COLUMN_TITLES_COMMON_PROPS,
  }
];

export const COLUMN_WIDTHS = [
  {
    width: 200,
  },
  {
    width: 150,
  },
  {
    width: 150,
  },
  {
    width: 400,
  }
];

export const CELL_COMMON_SETTINGS = {
  background: 'white',
  color: 'black',
  textAlign: 'left',
  fontSize: 14,
};

export const generateAnimalSummary = (animal) => {
  const animalMainInfo = reportAnimalSummary(animal, animalSexTranslation(animal.sex)).replace(')', ' |').replace('(', ' | ');
  const animalAClassInfo = animal.aClass && animal.aClass.className ? ` ${animal.aClass.className}` : '';
  return animalMainInfo + animalAClassInfo;
};

export const checkValidCell = (animal) => {
  return (animal.lID === INVALID_CELL_VALUE && (animal.weight === INVALID_CELL_VALUE || animal.weight === null))
    || (animal.lID === INVALID_CELL_VALUE && (animal.weight !== INVALID_CELL_VALUE && animal.weight !== null))
    || (animal.lID !== INVALID_CELL_VALUE && animal.lID !== null && (animal.weight === INVALID_CELL_VALUE || animal.weight === null))
    || (animal.weight === INVALID_CELL_VALUE && (animal.lID === INVALID_CELL_VALUE || animal.lID === null))
    || (animal.weight === INVALID_CELL_VALUE && (animal.lID !== INVALID_CELL_VALUE && animal.lID !== null))
    || (animal.weight !== INVALID_CELL_VALUE && animal.weight !== null && (animal.lID === INVALID_CELL_VALUE || animal.lID === null));
};

export const generateDropdownData = (animals) => {
  const dropdownData = [];

  animals.forEach(animal => {
    if (animal.lID) {
      dropdownData.push(isNaN(animal.lID) ? `"${animal.lID}"` : `${animal.lID}`);
    }

    if (animal.electronicId) {
      dropdownData.push(isNaN(animal.electronicId) ? `"${animal.electronicId}"` : `${animal.electronicId}`);
    }

    if (animal.vID) {
      dropdownData.push(isNaN(animal.vID) ? `"${animal.vID}"` : `${animal.vID}`);
    }
  });

  return {
    allData: `{ ${dropdownData.sort().join(', ')} }`,
  };
};
