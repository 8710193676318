import * as React from 'react';
import RodeoVetisTable from './rodeo-vetis-table';
import Query from '../../../../data/query';
import { GET_RODEO_VETIS_DATA } from '../../../../data/reports/reports-query';
import { animalSexTranslation, dateFormatForTableFilters, reportAnimalSummary } from '../../../../tools/function-helpers';

const RodeoVetisReport = props => {
  return (
    <Query query={GET_RODEO_VETIS_DATA}>
      {({ animals, }) => {
        const mappedAnimals = animals.items.map((animal) => {
          const curVetis = animal.vetis.length > 0 ? { ...animal.vetis, } : {};
          const vetisSexTranslation = curVetis[0] ? animalSexTranslation(curVetis[0].animalVetisSex) : '';
          const sexTranslation = animalSexTranslation(animal.sex);
          return {
            ...animal,
            dateOfBirth: animal.dateOfBirth ? dateFormatForTableFilters(animal.dateOfBirth) : null,
            vetis: {
              ...curVetis[0],
              vetisSexTranslation,
              animalVetisDOB: curVetis[0] && curVetis[0].animalVetisDOB ? dateFormatForTableFilters(curVetis[0].animalVetisDOB) : null,
            },
            sexTranslation,
            animalSummary: reportAnimalSummary(animal, sexTranslation),
          };
        });
        return (
          <RodeoVetisTable {...props} animals={mappedAnimals} />
        );
      }}
    </Query>
  );
};

export default RodeoVetisReport;
