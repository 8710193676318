/* eslint max-len:0 */
/* eslint react/button-has-type:0 */
/* eslint react/no-access-state-in-setstate:0 */
/* eslint react/destructuring-assignment:0 */
import * as React from 'react';
import { Row, Col } from 'reactstrap';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Input } from '@progress/kendo-react-inputs';
import Mutation from '../../../../../data/mutation';
import { ADD_NOTE, GET_ANIMAL_NOTES } from '../../../../../data/animal/animal-query';
import { DatePicker } from '../../../../utils/date-picker';

class AddNoteModal extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      animalID: props.animalId,
      noteToAdd: {
        animalID: props.animalId,
        dateNote: new Date(),
        noteTopic: '',
        note: '',
      },
    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  openModal() {
    this.setState({
      visible: true,
    });
  }

  closeModal() {
    this.setState({
      visible: false,
    });

    this.resetState();
  }

  handleChange(e) {
    const { value, } = e.target;
    const { name, } = e.target;
    const currentNoteToAdd = { ...this.state.noteToAdd, };
    currentNoteToAdd[name] = value;
    this.setState({ noteToAdd: currentNoteToAdd, });
  }

  handleSubmit(e, addNote) {
    e.preventDefault();
    addNote({
      variables:
        { noteToAdd: this.state.noteToAdd, },
    });
  }

  submitDisabled() {
    return this.state.noteToAdd.dateNote === null || this.state.noteToAdd.noteTopic.length === 0
      || this.state.noteToAdd.note.length === 0;
  }

  resetState() {
    const { animalID, } = this.state;
    this.setState({
      animalID,
      noteToAdd: {
        animalID,
        dateNote: new Date(),
        noteTopic: '',
        note: '',
      },
    });
  }

  render() {
    return (
      <Mutation
        mutation={ADD_NOTE}
        awaitRefetchQueries
        refetchQueries={[{ query: GET_ANIMAL_NOTES, variables: { id: this.state.animalID, }, }]}
        onCompleted={() => this.closeModal()}
      >
        {(addNoteTrigger) => (
          <div>
            <button className="btn btn-primary" onClick={this.openModal}>ДОБАВИ БЕЛЕЖКА</button>
            {this.state.visible && (
            <Dialog className="health-modal" title="Добави събитие" onClose={this.closeModal} minWidth="500px" height="70%">
              <form className="health-form">
                <Row>
                  <Col lg={{ size: 8, offset: 2, }}>
                    <div className="form-group">
                      <label>Дата</label>
                      <DatePicker
                        value={this.state.noteToAdd.dateNote}
                        name="dateNote"
                        onChange={e => this.handleChange(e)}
                        min={new Date(this.props.animaldateOfBirth)}
                      />
                    </div>
                    <div className="form-group">
                      <label>Тема</label>
                      <Input type="text" name="noteTopic" onChange={e => this.handleChange(e)} value={this.state.noteToAdd.noteTopic || ''} />
                    </div>
                    <div className="form-group">
                      <label>Бележка</label>
                      <textarea className="k-textarea" name="note" onChange={e => this.handleChange(e)} value={this.state.noteToAdd.note} />
                    </div>
                  </Col>
                </Row>

              </form>
              <DialogActionsBar>
                <div className="d-flex">
                  <button type="button" className="btn btn-secondary" onClick={this.closeModal}>Отказ</button>
                  <button type="submit" onClick={e => this.handleSubmit(e, addNoteTrigger)} disabled={this.submitDisabled()} className="btn btn-primary ml-3">Добави</button>
                </div>
              </DialogActionsBar>
            </Dialog>
            )}
          </div>
        )}
      </Mutation>
    );
  }
}

export default AddNoteModal;
