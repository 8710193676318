/* eslint react/no-array-index-key:0 */
/* eslint no-param-reassign:0 */

import * as React from 'react';
import { Input } from '@progress/kendo-react-inputs';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import moment from 'moment';
import TabNavButtons from '../../tab-nav-buttons';
import { SEXES, MALEACLASSES, FEMALEACLASSES } from '../../../../../../../tools/translation-constants';
import {
  deepCopy, selectedValue, generateLIDString, generateCustomString
} from '../../../../../../../tools/function-helpers';
import { DatePicker } from '../../../../../../utils/date-picker';

class TableTab extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      animal: props.getGroupObject(),
      animalGroup: [],
      errors: {
        errorsMsg: [],
        isError: false,
      },
    };
    this.generateAnimalGroup = this.generateAnimalGroup.bind(this);
    this.generateTableRows = this.generateTableRows.bind(this);
    this.handleFormChange = this.handleFormChange.bind(this);
    this.getAnimalGroup = this.getAnimalGroup.bind(this);
    this.generateErrors = this.generateErrors.bind(this);
  }

  componentDidMount() {
    this.generateErrors();
    this.generateAnimalGroup();
  }

  getAnimalGroup() {
    const { animalGroup, } = this.state;
    return animalGroup;
  }

  generateTableRows() {
    const { animalGroup, } = this.state;
    const { breeds, color, } = this.props;

    return animalGroup.map((animal, i) => {
      let dropdown;
      if (animal.sex === 'FEMALE') {
        dropdown = (
          <DropDownList
            style={{ width: '100%', }}
            data={FEMALEACLASSES}
            dataItemKey="id"
            textField="text"
            name="classID"
            onChange={this.handleFormChange}
            value={animal.classID ? selectedValue(animal.classID, FEMALEACLASSES, 'id') : ''}
            tabIndex={i}
          />
        );
      } else if (animal.sex === 'MALE') {
        dropdown = (
          <DropDownList
            style={{ width: '100%', }}
            data={MALEACLASSES}
            dataItemKey="id"
            textField="text"
            name="classID"
            onChange={this.handleFormChange}
            value={animal.classID ? selectedValue(animal.classID, MALEACLASSES, 'id') : ''}
            tabIndex={i}
          />
        );
      } else {
        dropdown = (
          <DropDownList
            disabled
            style={{ width: '100%', }}
          />
        );
      }
      return (
        <tr key={i}>
          <td><Input name="lID" data-index={i} onChange={this.handleFormChange} value={animal.lID ? animal.lID : ''} /></td>
          <td><Input name="name" data-index={i} onChange={this.handleFormChange} value={animal.name ? animal.name : ''} /></td>
          <td><Input name="vID" data-index={i} onChange={this.handleFormChange} value={animal.vID ? animal.vID : ''} /></td>
          <td>
            <DatePicker
              value={animal.dateOfBirth}
              name="dateOfBirth"
              onChange={this.handleFormChange}
              index={i}
            />
          </td>
          <td>
            <DropDownList
              style={{ width: '100%', }}
              data={breeds}
              dataItemKey="breedsId"
              textField="breedName"
              name="breedsID"
              onChange={this.handleFormChange}
              value={animal.breedsID ? selectedValue(animal.breedsID, breeds, 'breedsId') : ''}
              tabIndex={i}
            />
          </td>
          <td>
            {dropdown}
          </td>
          <td>
            <DropDownList
              style={{ width: '100%', }}
              data={SEXES}
              dataItemKey="id"
              textField="text"
              name="sex"
              onChange={this.handleFormChange}
              value={animal.sex ? selectedValue(animal.sex, SEXES, 'id') : ''}
              tabIndex={i}
            />
          </td>
          <td>
            <DropDownList
              style={{ width: '100%', }}
              data={color}
              dataItemKey="colorID"
              textField="colorName"
              name="colorID"
              onChange={this.handleFormChange}
              value={animal.colorID ? selectedValue(animal.colorID, color, 'colorID') : ''}
              tabIndex={i}
            />
          </td>
        </tr>
      );
    });
  }

  handleFormChange(e) {
    const { animalGroup, animal, } = this.state;

    const arrayCopy = [...animalGroup];
    const index = e.target.props['data-index'] === 0
      ? e.target.props['data-index']
      : e.target.props['data-index']
            || e.target.props.index
            || e.target.props.tabIndex;

    switch (e.target.name) {
      case 'sex':
        arrayCopy[index][e.target.name] = e.target.value.id;
        break;
      case 'colorID':
        arrayCopy[index][e.target.name] = e.target.value.colorID;
        break;
      case 'classID':
        arrayCopy[index][e.target.name] = e.target.value.id;
        break;
      case 'breedsID':
        arrayCopy[index][e.target.name] = e.target.value.breedsId;
        break;
      case 'lID':
        arrayCopy[index][e.target.name] = e.target.value;
        arrayCopy[index].name = `${animal.name} ${e.target.value.slice(e.target.value.length - 3).trim()}`;
        break;
      default:
        arrayCopy[index][e.target.name] = e.target.value;
        break;
    }
    this.setState({
      animalGroup: arrayCopy,
    });
  }

  generateAnimalGroup() {
    const { animalGroup, animal, } = this.state;

    const animalGroupCopy = animalGroup.slice();
    const len = animal.numberAnimals
      && animal.sex
      && animal.classID
      && animal.lID
      && animal.animalLocation[0].locationId ? animal.numberAnimals : 0;

    for (let i = 0; i < len; i = i + 1) {
      const currAnimal = deepCopy(animal);
      const nameLid = animal.lID ? generateLIDString(currAnimal, i, true) : '';
      // eslint-disable-next-line max-len
      currAnimal.name = `${animal.name ? animal.name : ''} ${animal.lID ? `${nameLid}`.slice(`${nameLid}`.length - 3) : ''}`.trim();
      currAnimal.vID = animal.vID ? generateCustomString(animal.vID, i) : '';
      currAnimal.lID = animal.lID ? generateLIDString(currAnimal, i) : '';
      currAnimal.dateOfBirth = animal.dateOfBirth ? moment(animal.dateOfBirth, 'DD-MM-YYYY').format() : new Date();

      // console.log(new Date(animal.dateOfBirth.split('.').reverse().join('.')));

      delete currAnimal.selectedLocations;
      delete currAnimal.numberAnimals;
      delete currAnimal.vetisPrefix;
      delete currAnimal.dateOfBirthNumber;
      if (currAnimal.breedComposition) {
        currAnimal.breedComposition.forEach(item => {
          delete item.breed;
        });
        currAnimal.animalBreedComposition = currAnimal.breedComposition;
        delete currAnimal.breedComposition;
      }

      if (currAnimal.typeInput && currAnimal.typeInput !== 'PURCHASE') {
        delete currAnimal.animalPurchase;
      }


      animalGroupCopy.push(currAnimal);
    }

    this.setState({
      animalGroup: animalGroupCopy,
    });
  }

  generateErrors() {
    const { errors, animal, } = this.state;

    if (!animal.numberAnimals || parseInt(animal.numberAnimals, 10) < 1) {
      errors.errorsMsg.push('Не е избран брой животни за вписване. Моля, попълнете полето "Брой животни" на стъпка 1.');
    }
    if (!animal.name) {
      errors.errorsMsg.push('Не е попълнено име за животно. Моля попълнете полето "Име" на стъпка 1.');
    }
    if (!animal.sex) {
      errors.errorsMsg.push('Не е попълнено пол за животно. Моля попълнете полето "Пол" на стъпка 1.');
    }
    if (!animal.classID) {
      errors.errorsMsg.push('Не е попълнено категория за животно. Моля попълнете полето "Категория" на стъпка 1.');
    }
    if (!animal.lID || !animal.vetisPrefix) {
      errors.errorsMsg.push('Не е попълнено ветис номер за животно. Моля попълнете полето "ВЕТИС №" на стъпка 1.');
    }
    if (!animal.animalLocation[0].locationId) {
      errors.errorsMsg.push('Не е попълнено локация за животно. Моля попълнете поне полето "Обор / Пасище" на стъпка 2.');
    }

    if (errors.errorsMsg.length > 0) {
      errors.isError = true;
    }

    this.setState({
      errors,
    });
  }

  render() {
    const { errors, } = this.state;

    return (
      <div className="tab">
        <form>
          <table className="table">
            <thead>
              <tr>
                <th>ВЕТИС №</th>
                <th>Име</th>
                <th>ВИН №</th>
                <th>Дата на раждане</th>
                <th>Порода</th>
                <th>Категория</th>
                <th>Пол</th>
                <th>Цвят</th>
              </tr>
            </thead>
            <tbody>
              {
                !errors.isError ? this.generateTableRows() : errors.errorsMsg.map((erMsg, index) => {
                  return (<tr key={index} className="error"><td colSpan={8} className="text-center"><p>{erMsg}</p></td></tr>);
                })
              }
            </tbody>
          </table>
          <TabNavButtons parentProps={this.props} getAnimalGroup={this.getAnimalGroup} />
        </form>
      </div>
    );
  }
}
export default TableTab;
