/* eslint react/prop-types:0 */
import * as React from 'react';
import { Row, Col } from 'reactstrap';
import { Input } from '@progress/kendo-react-inputs';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import TabNavButtons from './tab-nav-vacc';
import FarmClientDropdown from '../../../animals/tabs/health/farm-client-dropdown';

export default props => {
  const [animalsData, setAnimalData] = React.useState(props?.getAnimalsData() || { });
  const isFormValid = !!animalsData?.animalVaccineDrug;

  const handleFormChange = event => {
    const animalsDataCopy = { ...animalsData, };

    const { value, name, } = event.target;
    animalsDataCopy[name] = value;
    if (value?.hasOwnProperty('farmClientID')) {
      animalsDataCopy[name] = value.farmClientID;
    }
    setAnimalData(animalsDataCopy);
    props.updateAnimalsData(animalsDataCopy);
  };

  return (
    <div>
      <Row>
        <Col lg="6">
          <h3>ИЗБОР НА ВАКСИНА</h3>
        </Col>
        <Col lg="6">
          <h3>ИНФОРМАЦИЯ</h3>
        </Col>
      </Row>
      <form>
        <Row>
          <Col lg="6">
            <div className="form-group">
              <label>Тип</label>
              <Input
                name="animalVaccineDrug"
                onChange={handleFormChange}
                value={animalsData?.animalVaccineDrug}
              />
            </div>

            <div className="form-group">
              <label>Ветеринарен лекар</label>
              <FarmClientDropdown
                tableType="vaccine"
                farmClientID={animalsData?.farmClientID}
                handleFormChange={handleFormChange}
              />
            </div>
            <div className="form-group info-margin-bottom">
              <label>Дата</label>
              <DatePicker
                format="dd.MM.yyyy"
                defaultValue={new Date()}
                value={animalsData?.animalVaccineDate}
                name="animalVaccineDate"
                onChange={handleFormChange}
              />
            </div>
          </Col>
          <Col lg="6">
            <div className="form-group">
              <label>Доза</label>
              <Input
                type="text"
                value={animalsData?.animalVaccineDose}
                name="animalVaccineDose"
                onChange={handleFormChange}
              />
            </div>
            <div className="form-group">
              <label>Дни до следваща ваксина</label>
              <Input
                type="number"
                value={animalsData?.animalVaccineEndMonths}
                name="animalVaccineEndMonths"
                onChange={handleFormChange}
              />
            </div>
            <div className="form-group">
              <label>Цена</label>
              <Input
                type="number"
                value={animalsData?.animalVaccinePrice}
                name="animalVaccinePrice"
                onChange={handleFormChange}
              />
            </div>
            <div className="form-group">
              <label>Карентен срок (дни)</label>
              <Input
                type="number"
                value={animalsData?.animalVaccineKarentDays}
                name="animalVaccineKarentDays"
                onChange={handleFormChange}
              />
            </div>
            <div className="form-group">
              <label>Бележка</label>
              <textarea
                className="k-textarea"
                name="animalVaccineComment"
                value={animalsData?.animalVaccineComment}
                onChange={handleFormChange}
              />
            </div>
          </Col>
        </Row>
      </form>
      <TabNavButtons parentProps={props} isFormValid={isFormValid} />
    </div>
  );
};
