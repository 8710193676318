/* eslint max-len:0 */
/* eslint consistent-return:0 */
/* eslint react/no-did-update-set-state:0 */
/* eslint react/destructuring-assignment:0 */
import * as React from 'react';
import { NavLink, Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';
import Mutation from '../../../../../data/mutation';
import { GROUP_INSERT_ANIMAL } from '../../../../../data/entries/entries-queries';
import { ANIMALS_GRID } from '../../../../../data/animal/animal-query';


class TabNavButtons extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      getAnimalGroup: props.getAnimalGroup,
      isDisabled: props.getAnimalGroup ? !(props.getAnimalGroup().length > 0) : true,
      isSubmitted: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.renderRedirect = this.renderRedirect.bind(this);
  }


  componentDidUpdate(prevProps) {
    if (this.props !== prevProps && this.props.getAnimalGroup) {
      this.setState({
        getAnimalGroup: this.props.getAnimalGroup,
        isDisabled: this.props.getAnimalGroup ? !(this.props.getAnimalGroup().length > 0) : true,
      });
    }
  }

  handleSubmit = (e, mutationTrigger) => {
    e.preventDefault();

    mutationTrigger({
      variables:
      {
        dataItem: this.state.getAnimalGroup(),
      },
    });

    this.setState({
      isSubmitted: true,
    });
  };

  renderRedirect = () => {
    if (this.state.isSubmitted) {
      return <Redirect to={{ pathname: '/animals/list', state: { savedEntries: this.state.getAnimalGroup().length, }, }} />;
    }
  };

  onError = (error) => {
    if (error.toString().includes('LID_Unique')) {
      const re = /\((.*)\)/i;
      // eslint-disable-next-line prefer-destructuring
      const textError = error.toString().match(re)[1].split(',')[0];
      toast.error(`Има дуплиращ ВЕТИС номер - ${textError}`, {
        position: toast.POSITION.TOP_LEFT,
      });
    }
  }

  render() {
    const links = this.props.parentProps.tabsLinks;
    const index = links.findIndex((p) => p === this.props.parentProps.props.location.pathname);
    const next = index !== links.length - 1 ? links[index + 1] : links[links.length - 1];
    const prev = index !== 0 ? links[index - 1] : links[0];
    return (
      <Mutation
        mutation={GROUP_INSERT_ANIMAL}
        awaitRefetchQueries
        refetchQueries={[{ query: ANIMALS_GRID, variables: {}, }]}
        onError={this.onError}
      >
        {(mutationTrigger) => (
          <div className="steps-action-btns d-flex justify-content-end mt-5">
            {this.renderRedirect()}
            {index > 0 && <NavLink exact className="btn btn-secondary mr-2" to={prev}>НАЗАД</NavLink>}
            {index < (links.length - 1) && <NavLink exact className="btn btn-primary" to={next}>НАПРЕД</NavLink>}
            {index === (links.length - 1) && <button disabled={this.state.isDisabled} onClick={e => this.handleSubmit(e, mutationTrigger)} type="submit" className="btn btn-primary">ЗАПИШИ</button>}
          </div>
        )}
      </Mutation>
    );
  }
}

export default TabNavButtons;
