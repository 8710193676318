/* eslint react/button-has-type:0 */
/* eslint max-len:0 */
/* eslint react/no-array-index-key:0 */
/* eslint no-underscore-dangle:0 */
/* eslint react/no-access-state-in-setstate:0 */
/* eslint react/destructuring-assignment:0 */
import * as React from 'react';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Row, Col } from 'reactstrap';
import { Input } from '@progress/kendo-react-inputs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Mutation from '../../../../data/mutation';
import { GET_CONTRAGENTS, ADD_CONTRAGENT, EDIT_CONTRAGENT } from '../../../../data/contragents/contragents-queries';
import { FARM_CONTRAGENTS_TYPES } from '../../../../tools/translation-constants';
import { deepCopy, isActionTypeAndGetQuery } from '../../../../tools/function-helpers';


class ContragentsTableModal extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      dataItem: props.dataItem,
      submitDisabled: true,
    };
    this.toggleDialog = this.toggleDialog.bind(this);
    this.handleFormChange = this.handleFormChange.bind(this);
  }

  toggleDialog() {
    this.setState({
      visible: !this.state.visible,
    });

    if (!this.state.visible) {
      this.resetState();
    }
  }


  handleFormChange(e) {
    const objCopy = deepCopy(this.state.dataItem);
    delete objCopy.__typename;

    if (!objCopy.farmClientsInType) {
      objCopy.farmClientsInType = [];
    }

    if (e.target.name === 'farmClientsInType') {
      if (e.target.checked) {
        objCopy[e.target.name].push(e.target.value);
      } else {
        objCopy[e.target.name].splice(objCopy[e.target.name].indexOf(e.target.value), 1);
      }
    } else {
      objCopy[e.target.name] = e.target.value;
    }


    this.setState({
      dataItem: objCopy,
      submitDisabled: false,
    });
  }

  handleSubmit(e, mutationTrigger) {
    e.preventDefault();
    this.toggleDialog();
    mutationTrigger({
      variables:
            {
              dataItem: this.state.dataItem,
            },
    });
  }

  resetState() {
    const copyData = { ...this.props.dataItem, };
    this.setState({
      dataItem: copyData,
      submitDisabled: true,
    });
  }

  render() {
    const genContragentsTypesCheckboxes = () => {
      return FARM_CONTRAGENTS_TYPES.map((type, index) => {
        const objCopy = this.state.dataItem.farmClientsInType ? this.state.dataItem.farmClientsInType : [];
        const isChecked = objCopy.indexOf(type.id);
        return (
          <div key={index}>
            <label className="k-form-field">
              <input type="checkbox" checked={isChecked !== -1} className="k-checkbox" id={type.id} name="farmClientsInType" value={type.id} onChange={this.handleFormChange} />
              <label className="k-checkbox-label" htmlFor={type.id}>{type.text}</label>
            </label>
          </div>
        );
      });
    };

    const isAddActionType = isActionTypeAndGetQuery(this.props.action, 'add', ADD_CONTRAGENT, EDIT_CONTRAGENT);

    return (
      <Mutation
        mutation={isAddActionType.query}
        awaitRefetchQueries
        refetchQueries={[{ query: GET_CONTRAGENTS, variables: {}, }]}
      >
        {(mutationTrigger) => (
          <div className="d-inline-block">
            <button className={isAddActionType.isActionType ? 'btn btn-primary mr-2' : 'button-icon'} onClick={this.toggleDialog}>{isAddActionType.isActionType ? 'Добави контрагент' : <FontAwesomeIcon icon="pen" />}</button>
            {this.state.visible && (
            <form onSubmit={e => this.handleSubmit(e, mutationTrigger)}>
              <Dialog title={`${isAddActionType.isActionType ? 'Добави' : 'Редактирай'} контрагент`} onClose={this.toggleDialog} minWidth="500px" height="70%">
                <Row>
                  <Col lg={{ size: 8, offset: 2, }}>
                    <div className="form-group">
                      <label>Име</label>
                      <Input type="text" name="farmClientName" value={this.state.dataItem.farmClientName ? this.state.dataItem.farmClientName : ''} onChange={this.handleFormChange} />
                    </div>
                    <div className="form-group">
                      <label>Фирма</label>
                      <Input type="text" name="farmClientFirmName" value={this.state.dataItem.farmClientFirmName ? this.state.dataItem.farmClientFirmName : ''} onChange={this.handleFormChange} />
                    </div>
                    <div className="form-group">
                      <label>ЕИК/ЕГН</label>
                      <Input type="text" name="farmClientEIK" value={this.state.dataItem.farmClientEIK ? this.state.dataItem.farmClientEIK : ''} onChange={this.handleFormChange} />
                    </div>
                    <div className="form-group">
                      <label>МОЛ</label>
                      <Input type="text" name="farmClientMOL" value={this.state.dataItem.farmClientMOL ? this.state.dataItem.farmClientMOL : ''} onChange={this.handleFormChange} />
                    </div>
                    <div className="form-group">
                      <label>Адрес</label>
                      <Input type="text" name="farmClientAddress" value={this.state.dataItem.farmClientAddress ? this.state.dataItem.farmClientAddress : ''} onChange={this.handleFormChange} />
                    </div>
                    <div className="form-group">
                      <label>Телефон</label>
                      <Input type="text" name="farmClientTel" value={this.state.dataItem.farmClientTel ? this.state.dataItem.farmClientTel : ''} onChange={this.handleFormChange} />
                    </div>
                    <div className="form-group">
                      <label>E-mail</label>
                      <Input type="email" name="farmClientEmail" value={this.state.dataItem.farmClientEmail ? this.state.dataItem.farmClientEmail : ''} onChange={this.handleFormChange} />
                    </div>
                    <div className="form-group">
                      <label className="d-block">Дейности</label>
                      {genContragentsTypesCheckboxes()}
                    </div>
                    <div className="form-group">
                      <label>Бележка</label>
                      <textarea className="k-textarea" name="comment" value={this.state.dataItem.comment ? this.state.dataItem.comment : ''} onChange={this.handleFormChange} />
                    </div>
                  </Col>
                </Row>
                <DialogActionsBar>
                  <div className="d-flex">
                    <button className="btn btn-secondary" onClick={this.toggleDialog}>Отказ</button>
                    <button type="submit" disabled={this.state.submitDisabled} className="btn btn-primary ml-3">{isAddActionType.isActionType ? 'Добави' : 'Редактирай'}</button>
                  </div>
                </DialogActionsBar>
              </Dialog>
            </form>
            )}
          </div>
        )}
      </Mutation>
    );
  }
}

export default ContragentsTableModal;
