/* eslint-disable */
import * as React from 'react';
import Query from '../../../../data/query';
import { SINGLE_WEIGHT_SESSION } from '../../../../data/reports/reports-query';
import WeightSessionTable from './weight-session-table';
import { animalSexTranslation, dateFormatForTableFilters, reportAnimalSummary } from '../../../../tools/function-helpers';
import { getAnimalAgeInMonths } from '../../animals/animal-utilities';
// import DatesHelper from '../../../../tools/DatesHelper';


const WeightSession = props => { 
  const id = props.match.params.id;
  return (
    <Query query={SINGLE_WEIGHT_SESSION} variables={{ id, }}>
      {({ weightSession, }) => {
        const mappedAnimals = weightSession.weights.map(weight => { 
        const sexTranslation = animalSexTranslation(weight.animal.sex);
        return {
              ...weight,
              animalSummary: reportAnimalSummary(weight.animal, sexTranslation),
              dateWeight: dateFormatForTableFilters(weight.dateWeight),
              ageInMonths: weight.animal.dateOfBirth ? getAnimalAgeInMonths(weight.animal.dateOfBirth) : '',
              adgOverall: parseFloat(weight.animal.adgOverall.toFixed(2)),
              }
        });
        return (
          <WeightSessionTable {...props} animals={mappedAnimals} />
        );
      }}
    </Query>
  );
};

export default WeightSession;
