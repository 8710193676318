/* eslint no-shadow:0 */
/* eslint max-len:0 */
/* eslint react/button-has-type:0 */
/* eslint no-underscore-dangle:0 */
/* eslint no-param-reassign:0 */
/* eslint react/prop-types:0 */
/* eslint react/destructuring-assignment:0 */
import * as React from 'react';
import { Grid, GridColumn as Column, GridToolbar } from '@progress/kendo-react-grid';
import { Col, Row } from 'reactstrap';
import { process } from '@progress/kendo-data-query';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import ServiceGroupData from '../add-service-group/add-service-group-data';
import { mapAnimalForListBox } from '../../../../../tools/function-helpers';
import './edit-service-group.css';
import DeleteAnimalsInGroupModal from '../../groups-managment/edit-group-modals/delete-animal-modal';
import {
  EDIT_SERVICE_GROUP_DATA,
  GET_ALL_SERVICE_GROUPS
} from '../../../../../data/groups/groups-query';
import { GET_SERVICE_GROUPS } from '../../../../../data/animal/animal-query';
import Mutation from '../../../../../data/mutation';
import AddAnimalInServiceGroupModal from './add-animal-in-service-group-modal';
import DatesHelper from '../../../../../tools/DatesHelper';

const ServiceGroupEdit = props => {
  const [group, setGroup] = React.useState(props.group);
  const data = props.group.animalServiceProp.map(asp => {
    if (asp.dateOut) {
      asp.group = 'Излезли от сървис';
    } else if (asp.animals.sex === 'FEMALE') {
      asp.group = 'Активни за групата';
    } else {
      asp.group = 'Разплодници';
    }
    asp.name = mapAnimalForListBox(asp.animals).text;

    return asp;
  });

  const createAppState = (dataState, calculatedDate) => {
    const res = process(calculatedDate, dataState);
    const [first, second, third] = res.data;
    second.value = `${second.value}:  ${second.items.length}`;
    first.value = `${first.value}:  ${first.items.length}`;

    const animalLeftGroup = !!third;

    if (animalLeftGroup) {
      third.value = `${third.value}:  ${third.items.length}`;
      res.data = [third, first, second];
    } else {
      res.data = [second, first];
    }

    return {
      result: res,
      animalLeftGroup,
      dataState,
    };
  };

  let _export = null;

  const exportFunc = () => _export.save();

  const onFormSubmit = (e, mutationTrigger) => {
    e.preventDefault();

    mutationTrigger({
      variables: {
        serviceGroup: {
          groupName: group.groupName,
          groupID: group.groupID,
          daysLong: group.daysLong,
          dateCreate: group.dateCreate,
          comment: group.comment,
          active: group.active,
        },
      },
    });
  };

  const initData = createAppState({ group: [{ field: 'group', }], }, data);

  return (
    <Row>
      <Col lg="12">
        <Mutation
          mutation={EDIT_SERVICE_GROUP_DATA}
          awaitRefetchQueries
          refetchQueries={[{ query: GET_ALL_SERVICE_GROUPS, }, { query: GET_SERVICE_GROUPS, }]}
        >
          { mutationTrigger => (
            <form onSubmit={(e) => onFormSubmit(e, mutationTrigger)}>
              <ServiceGroupData group={props.group} setGroupData={setGroup} />
              <button type="submit" className="btn btn-primary mb-2">Запази</button>
              <AddAnimalInServiceGroupModal group={group} />
            </form>
          )}
        </Mutation>
        <ExcelExport
          data={initData.result.data}
          group={initData.dataState.group}
          ref={exporter => { _export = exporter; }}
        >
          <Grid
            data={initData.result.data}
            {...initData.dataState}
            filterable={false}
            className="table"
            groupable
          >
            <GridToolbar>
              <div className="d-flex justify-content-end">
                <button
                  title="Export Excel"
                  className="btn btn-secondary"
                  onClick={exportFunc}
                >
                  Експорт в Excel
                </button>
              </div>
            </GridToolbar>
            <Column field="name" title="Име" width="610px" />
            { initData.animalLeftGroup && <Column field="comment" title="Причини за отпадането" /> }
            <Column field="dateIn" title="Дата на влизане в сървис" width="300px" cell={props => { return <td>{props.dataItem.dateIn ? DatesHelper.dateToAppFormat(props.dataItem.dateIn) : ''}</td>; }} />
            <Column field="dateOut" title="Дата на излизане от сървис" width="300px" cell={props => { return <td>{props.dataItem.dateOut ? DatesHelper.dateToAppFormat(props.dataItem.dateOut) : ''}</td>; }} />
            <Column
              field=""
              title="Действия"
              width="200px"
              cell={props => {
                if (!!props.dataItem.animalID && !props.dataItem.dateOut) {
                  return (
                    <td className="d-flex justify-content-center">
                      <DeleteAnimalsInGroupModal isService {...props} />
                    </td>
                  );
                }
                return <></>;
              }}
            />
          </Grid>
        </ExcelExport>

      </Col>
    </Row>
  );
};

export default ServiceGroupEdit;
