import { gql } from 'apollo-boost';


export const GET_EXTERNAL_ANIMALS = gql`
{
 animalsReff{
    items{
      animalId
      name
      vID
      lID
      dateOfBirth
      sex
      classID
      colorID
      breedsID
      comment
      typeExistID
      sireID
      countryFrom
      damID
      sire{
      lID
      }
      dam{
       lID
      }
      color{
       colorName
      }
      breed{
        breedName
      }
      aClass{
        className
      }
    }
  }
}
`;

export const ADD_EXTERNAL_ANIMAL = gql`
  mutation AddAnimal($dataItem: animalInput) {
    addAnimal(animal: $dataItem) {
      animalId
    }
  }
`;

export const UPDATE_EXTERNAL_ANIMAL = gql`
  mutation UpdateAnimal($dataItem: animalInput) {
    updateAnimal(animal: $dataItem) {
      animalId
    }
  }
`;
