/* eslint no-plusplus:0 */
/* eslint class-methods-use-this:0 */
/* eslint prefer-destructuring:0 */
/* eslint react/sort-comp:0 */
/* eslint react/destructuring-assignment:0 */

import * as React from 'react';

import { Row, Col } from 'reactstrap';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { getLocationsByType } from '../views/animals/animal-utilities';

class LocationsSelect extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      sectorsByByre: {
        allSectors:
          props.selectedLocations && props.selectedLocations.byreLocation
            ? this.getSectorsOrBoxesByParentId(this.props.selectedLocations.byreLocation.locationID, this.props.locations, 'sector')
            : [],
      },
      boxesBySector: {
        allBoxes:
          props.selectedLocations && props.selectedLocations.sectorLocation
            ? this.getSectorsOrBoxesByParentId(this.props.selectedLocations.sectorLocation.locationID, this.props.locations, 'box')
            : [],
      },
      selected: props.selectedLocations
        ? props.selectedLocations
        : {
          byreLocation: '',
          sectorLocation: '',
          boxLocation: '',
        },
      locations: getLocationsByType(props.locations),
    };
    this.handleFormChange = this.handleFormChange.bind(this);
  }

  handleFormChange(e) {
    let sector = [...this.state.sectorsByByre.allSectors];
    let box = [...this.state.boxesBySector.allBoxes];
    const objCopy = { ...this.state.selected, };
    let selectedSector;
    let selectedBox;
    let selectedByre;
    let locationID;
    if (e.target.name === 'byre') {
      sector = this.getSectorsOrBoxesByParentId(e.target.value.locationID, this.props.locations, 'sector');
      selectedByre = e.target.value;
      objCopy.byreLocation = selectedByre;
      delete objCopy.sectorLocation;
      delete objCopy.boxLocation;
      locationID = selectedByre.locationID;
      this.setState({
        sectorsByByre: {
          allSectors: sector,
        },
        boxesBySector: {
          allBoxes: [],
        },
        selected: objCopy,
      });
    } else if (e.target.name === 'sector') {
      box = this.getSectorsOrBoxesByParentId(e.target.value.locationID, this.props.locations, 'box');
      selectedSector = e.target.value;
      objCopy.sectorLocation = selectedSector;
      delete objCopy.boxLocation;
      locationID = selectedSector.locationID;
      this.setState({
        sectorsByByre: {
          allSectors: sector,
        },
        boxesBySector: {
          allBoxes: box,
        },
        selected: objCopy,
      });
    } else if (e.target.name === 'box') {
      selectedBox = e.target.value;
      objCopy.boxLocation = selectedBox;
      locationID = selectedBox.locationID;
      this.setState({
        boxesBySector: {
          allBoxes: box,
        },
        selected: objCopy,
      });
    }

    if (typeof this.props.updateLocationIDandSelected === 'function' && this.props.updateLocationIDandSelected) {
      this.props.updateLocationIDandSelected({
        locationId: locationID,
        selected: objCopy,
      });
    }
  }

  getSectorsOrBoxesByParentId(id, locations, type) {
    const data = [];
    for (let i = 0; i < locations.length; i++) {
      const currElement = {
        locationID: locations[i].locationID,
        locationName: locations[i].locationName ? locations[i].locationName : '-',
      };
      if (locations[i]?.locationParent?.locationID === id && type === 'sector') {
        data.push({
          ...currElement,
          locationName: `${locations[i]?.locationParent?.locationName || ''} - ${currElement?.locationName}`,
        });
      }

      if (locations[i]?.locationParent?.locationID === id && type === 'box') {
        if (this.state) {
          const {
            sectorsByByre: { allSectors, },
          } = this.state;
          const sector = allSectors.find((sec) => sec.locationID === id);
          data.push({
            ...currElement,
            locationName: `${sector?.locationName || ''} - ${currElement?.locationName}`,
          });
        }
      }
    }
    return data;
  }

  render() {
    return (
      <form>
        <Row>
          <Col lg="12">
            <Row>
              <Col lg="2">
                <div className="form-group">
                  <label>Обор / Пасище</label>
                  <DropDownList
                    data={this.state.locations.byreGrassland}
                    dataItemKey="locationID"
                    textField="locationName"
                    name="byre"
                    onChange={this.handleFormChange}
                    value={this.state.selected.byreLocation ? this.state.selected.byreLocation : ''}
                  />
                </div>
              </Col>
              <Col lg="2">
                <div className="form-group">
                  <label>Сектор</label>
                  <DropDownList
                    data={this.state.sectorsByByre.allSectors}
                    dataItemKey="locationID"
                    textField="locationName"
                    name="sector"
                    onChange={this.handleFormChange}
                    value={this.state.selected.sectorLocation ? this.state.selected.sectorLocation : ''}
                    disabled={this.state.sectorsByByre.allSectors.length === 0}
                  />
                </div>
              </Col>
              <Col lg="2">
                <div className="form-group">
                  <label>Бокс / Падок</label>
                  <DropDownList
                    data={this.state.boxesBySector.allBoxes}
                    dataItemKey="locationID"
                    textField="locationName"
                    name="box"
                    onChange={this.handleFormChange}
                    value={this.state.selected.boxLocation ? this.state.selected.boxLocation : ''}
                    disabled={this.state.boxesBySector.allBoxes.length === 0}
                  />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </form>
    );
  }
}

export default LocationsSelect;
