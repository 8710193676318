/* eslint react/button-has-type:0 */
/* eslint react/no-access-state-in-setstate:0 */
/* eslint react/no-unused-state:0 */
/* eslint no-underscore-dangle:0 */
/* eslint react/sort-comp:0 */
/* eslint react/prop-types:0 */
/* eslint react/destructuring-assignment:0 */
/* eslint max-len:0 */
import * as React from 'react';
import {
  Grid, GridColumn as Column, GridToolbar, GridColumnMenuFilter
} from '@progress/kendo-react-grid';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { withLocalize } from 'react-localize-redux';
import { filterBy, orderBy } from '@progress/kendo-data-query';

import { NavLink } from 'react-router-dom';
import {
  LocalizationProvider, loadMessages, load, IntlProvider
} from '@progress/kendo-react-intl';
import AlertEntriesComponent from '../../alert-entries/alert-entries';
import CustomColumnModal from '../../custom-column/custom-column';

import {
  TABLE_BG_MESSAGES, bgDateFields, bgGregorian, weekData, numbers
} from '../../../tools/translation-constants';
import { dropdownFilterCell } from '../../../tools/dropdown-filter-cell';
import LocationDropDownFilterCell from '../../../tools/locations-dropdown-filter-cell';

const PAGE_TAKE = 10;
const DataCell = (props) => <td><NavLink className="link-color-primary" to={`/animals/list/${props.dataItem.id}`}>{props.dataItem.lID ? props.dataItem.lID : '(празно)'}</NavLink></td>;

load(
  weekData,
  numbers,
  bgDateFields,
  bgGregorian
);

loadMessages(TABLE_BG_MESSAGES, 'bg-BG');

export class AnimalsList extends React.Component<any, any> {
  _export;

  export = () => {
    this._export.save();
  };

  constructor(props) {
    super(props);
    this.state = {
      animals: props.animals.items,
      breeds: props.breeds,
      aClasses: props.aClasses,
      sexes: props.sexes,
      breedingFor: props.breedingFor,
      forScrap: props.forScrap,
      underSelection: props.underSelection,
      colors: props.colors,
      filter: undefined,
      sort: [],
      sortable: {
        allowUnsort: true,
        mode: 'single',
      },
      skip: 0,
      take: PAGE_TAKE,
      pageSize: PAGE_TAKE,
      pageable: {
        buttonCount: 5,
        info: true,
        type: 'numeric',
        previousNext: true,
        pageSizes: [5, 10, 20, props.animals.items.length],
      },
      columns: {
        vID: {
          title: 'ВИН',
          visible: true,
        },
        lID: {
          title: 'Ветис №',
          visible: true,
        },
        breedBreedName: {
          title: 'Порода',
          visible: true,
        },
        breedingForTranslation: {
          title: 'Направление',
          visible: false,
        },
        dateOfBirthFormat: {
          title: 'Дата на раждане',
          visible: true,
        },
        ageInMonths: {
          title: 'Възраст(м.)',
          visible: false,
        },
        sexTranslation: {
          title: 'Пол',
          visible: true,
        },
        name: {
          title: 'Име',
          visible: true,
        },
        aClassClassName: {
          title: 'Категория',
          visible: true,
        },
        damlID: {
          title: 'Майка',
          visible: true,
        },
        sirelID: {
          title: 'Баща',
          visible: true,
        },
        location: {
          title: 'Локация',
          visible: true,
        },
        animalOwner: {
          title: 'Собственик',
          visible: false,
        },
        forScrapTranslation: {
          title: 'За Брак',
          visible: false,
        },
        color: {
          title: 'Цвят',
          visible: true,
        },
        comment: {
          title: 'Бележка',
          visible: true,
        },
        underSelectionTranslation: {
          title: 'Под СК',
          visible: false,
        },
        registrationDateFormat: {
          title: 'Дата придобиване',
          visible: false,
        },
      },
    };
    this.pageChange = this.pageChange.bind(this);
    this.updateTableVisibleCols = this.updateTableVisibleCols.bind(this);
  }

  pageChange = (event) => {
    this.setState({
      skip: event.page.skip,
      take: event.page.take,
      pageSize: event.page.take,
    });
  };

  filterChange = (event) => {
    this.setState({
      animals: filterBy(this.props.animals.items, event.filter),
      filter: event.filter,
      skip: 0,
      tate: PAGE_TAKE,
    });
  };

  sortChange = (event) => {
    this.setState({
      animals: orderBy(this.state.animals, event.sort),
      sort: event.sort,
    });
  };

  updateTableVisibleCols = (columns) => {
    this.setState({
      columns,
    });
  };

  render() {
    return (
      <div className="container-fluid">
        <AlertEntriesComponent {...this.props} text="Успешно вписахте" />
        <h3 className="mb-4">Списък на животните във фермата</h3>
        <LocalizationProvider language="bg-BG">
          <IntlProvider locale="bg">
            <ExcelExport
              data={this.state.animals}
              fileName="animal-list.xlsx"
              ref={exporter => { this._export = exporter; }}
            >
              <Grid
                data={this.state.animals.slice(this.state.skip, this.state.take + this.state.skip)}
                className="table table-advanced"
                skip={this.state.skip}
                total={this.state.animals.length}
                onPageChange={this.pageChange}
                onFilterChange={this.filterChange}
                filter={this.state.filter}
                filterable
                pageable={this.state.pageable}
                pageSize={this.state.pageSize}
                onSortChange={this.sortChange}
                sort={this.state.sort}
                sortable={this.state.sortable}
                resizable
                reorderable
              >
                <GridToolbar>
                  <div className="d-flex justify-content-between align-items-center">
                    <span className="list-counter">
                      Брой Животни:
                      {this.state.animals.length}
                    </span>
                    <div className="d-flex d-inline-block">
                      <CustomColumnModal columns={this.state.columns} updateTableVisibleCols={this.updateTableVisibleCols} />
                      <button
                        title="Export Excel"
                        className="btn btn-secondary ml-2"
                        onClick={this.export}
                      >
                        Експорт в Excel
                      </button>
                    </div>
                  </div>
                </GridToolbar>
                { this.state.columns.lID.visible
                  && (
                  <Column
                    field="lID"
                    title="Ветис №"
                    width="150px"
                    cell={(props) => <DataCell {...props} />}
                  />
                  )}
                { this.state.columns.vID.visible
                  && (
                  <Column
                    field="vID"
                    title="ВИН"
                    width="120px"
                  />
                  )}
                { this.state.columns.breedBreedName.visible
                  && (
                  <Column
                    field="breed.breedName"
                    title="Порода"
                    width="160px"
                    filterCell={(props) => dropdownFilterCell(props, this.state.breeds)}
                  />
                  )}
                { this.state.columns.breedingForTranslation.visible
                  && (
                  <Column
                    field="breedingForTranslation"
                    title="Направление"
                    width="180px"
                    filterCell={(props) => dropdownFilterCell(props, this.state.breedingFor)}
                  />
                  )}
                { this.state.columns.dateOfBirthFormat.visible
                  && (
                  <Column
                    field="dateOfBirthFormat"
                    filter="date"
                    format="{0:dd.MM.yyy}"
                    title="Дата на раждане"
                    width="215px"
                    columnMenu={props => <GridColumnMenuFilter {...props} />}
                  />
                  )}
                { this.state.columns.ageInMonths.visible
                  && (
                  <Column
                    field="ageInMonths"
                    title="Възраст(м.)"
                    width="160px"
                    filter="numeric"
                    columnMenu={props => <GridColumnMenuFilter {...props} />}
                  />
                  )}
                { this.state.columns.sexTranslation.visible
                  && (
                  <Column
                    field="sexTranslation"
                    title="Пол"
                    width="110px"
                    filterCell={(props) => dropdownFilterCell(props, this.state.sexes)}
                  />
                  )}
                { this.state.columns.name.visible
                  && (
                  <Column
                    field="name"
                    title="Име"
                    width="120px"
                  />
                  )}
                { this.state.columns.aClassClassName.visible
                  && (
                  <Column
                    field="aClass.className"
                    title="Категория"
                    width="155px"
                    filterCell={(props) => dropdownFilterCell(props, this.state.aClasses)}
                  />
                  )}
                { this.state.columns.damlID.visible
                  && (
                  <Column
                    field="dam.lID"
                    title="Майка"
                    width="120px"
                  />
                  )}
                { this.state.columns.sirelID.visible
                  && (
                  <Column
                    field="sire.lID"
                    title="Баща"
                    width="120px"
                  />
                  )}
                { this.state.columns.location.visible
                  && (
                  <Column
                    field="location"
                    title="Локация"
                    width="140px"
                    filterCell={props => <LocationDropDownFilterCell {...props} locations={this.props.locations} />}
                  />
                  )}
                { this.state.columns.animalOwner.visible
                  && (
                  <Column
                    field="animalOwner.firmName"
                    title="Собственик"
                    width="140px"
                  />
                  )}
                { this.state.columns.forScrapTranslation.visible
                  && (
                  <Column
                    field="forScrapTranslation"
                    title="За Брак"
                    width="140px"
                    filterCell={(props) => dropdownFilterCell(props, this.state.forScrap)}
                  />
                  )}
                { this.state.columns.color.visible
                  && (
                  <Column
                    field="color.colorName"
                    title="Цвят"
                    width="200px"
                    filterCell={(props) => dropdownFilterCell(props, this.state.colors, true)}
                  />
                  ) }
                { this.state.columns.comment.visible
                  && (
                  <Column
                    field="comment"
                    title="Бележка"
                    width="200px"
                  />
                  ) }
                { this.state.columns.underSelectionTranslation.visible
                  && (
                  <Column
                    field="underSelectionTranslation"
                    title="Под СК"
                    width="140px"
                    filterCell={(props) => dropdownFilterCell(props, this.state.underSelection)}
                  />
                  )}
                { this.state.columns.registrationDateFormat.visible
                  && (
                  <Column
                    field="registrationDateFormat"
                    filter="date"
                    format="{0:dd.MM.yyy}"
                    title="Дата придобиване"
                    width="215px"
                    columnMenu={props => <GridColumnMenuFilter {...props} />}
                  />
                  )}
              </Grid>
            </ExcelExport>
          </IntlProvider>
        </LocalizationProvider>
      </div>
    );
  }
}

export default withLocalize(AnimalsList);
