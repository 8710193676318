/* eslint react/no-array-index-key:0 */
/* eslint class-methods-use-this:0 */
/* eslint react/no-did-update-set-state:0 */
/* eslint react/destructuring-assignment:0 */
import * as React from 'react';
import ContragentsTableModal from './contragents-table-modal';
import { FARM_CONTRAGENTS_TYPES } from '../../../../tools/translation-constants';

class Contragents extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      contragents: props.contragents,
    };
    this.generateTableRows = this.generateTableRows.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.contragents !== prevProps.contragents) {
      this.setState({
        contragents: this.props.contragents,
      });
    }
  }

  getFarmContragentsTranslations(contragent) {
    const result = [];
    contragent.farmClientsInType.forEach(element => {
      result.push(FARM_CONTRAGENTS_TYPES.find((type => type.id === element)).text);
    });
    return result;
  }

    generateTableRows = () => {
      return this.state.contragents.map((contragent, i) => {
        return (
          <tr key={i}>
            <td>
              {contragent.farmClientName}
            </td>
            <td>
              Име:
              {' '}
              {contragent.farmClientFirmName ? contragent.farmClientFirmName : '(празно)'}
              <br />
              ЕИК:
              {' '}
              {contragent.farmClientEIK ? contragent.farmClientEIK : '(празно)'}
              <br />
              МОЛ:
              {' '}
              {contragent.farmClientMOL ? contragent.farmClientMOL : '(празно)'}
              <br />
            </td>
            <td>
              тел:
              {' '}
              {contragent.farmClientTel ? contragent.farmClientTel : '(празно)'}
              <br />
              E-mail:
              {' '}
              {contragent.farmClientEmail ? contragent.farmClientEmail : '(празно)'}
              <br />
            </td>
            <td style={{ width: 1, }}>
              {this.getFarmContragentsTranslations(contragent).join(', ')}
            </td>
            <td style={{ width: 1, }}>
              <div className="d-flex justify-content-center">
                <ContragentsTableModal dataItem={contragent} />
              </div>
            </td>
          </tr>
        );
      });
    }

    render() {
      return (
        <div>
          <ContragentsTableModal action="add" />
          <table className="table">
            <thead>
              <tr>
                <th>Име</th>
                <th>Фирма</th>
                <th>Контакти</th>
                <th>Дейности</th>
                <th>Действия</th>
              </tr>
            </thead>
            <tbody>
              {this.generateTableRows()}
            </tbody>
          </table>
        </div>
      );
    }
}

export default Contragents;
