/* eslint react/destructuring-assignment:0 */
/* eslint no-unused-vars:0 */
import * as React from 'react';
import { Card } from './cow-heifer-aggregate';
import { Animal } from '../../../../data/domains/Animal';
import DashboardCard from '../../../lib/dashboard-card/dashboard-card';

type FatteningProps = {
    animals: Animal[]
}
export default (props: FatteningProps) => {
  const ani = props.animals.filter(a => a.breedingFor === 'FATTENING');

  const lessThan300: Animal[] = [];
  const greaterThan300: Animal[] = [];
  const greaterThan400: Animal[] = [];
  const greaterThan500: Animal[] = [];
  const greaterThan600: Animal[] = [];

  ani.forEach(a => {
    const { length, [length - 1]: weight, } = a.weights;

    if (weight < 300) {
      lessThan300.push(a);
    }
    if (weight > 300 && weight < 400) {
      greaterThan300.push(a);
    }
    if (weight > 400 && weight < 500) {
      greaterThan400.push(a);
    }
    if (weight > 500 && weight < 600) {
      greaterThan500.push(a);
    }
    if (weight > 600) {
      greaterThan600.push(a);
    }
  });

  const style = {
    text: {
      color: '#fff',
    },
    container: {
      backgroundColor: '#ffb826',
      margin: '5px',
      borderRadius: '4.5px',
    },
  };

  return (
    <DashboardCard className="mb-5">
      <h5 className="text-center mb-4">Угоявне (животни в направление угояване)</h5>
      <Card
        style={style}
        textCenter={lessThan300.length}
        textBottom="< 300"
      />
      <Card
        style={style}
        textCenter={greaterThan400.length}
        textBottom="> 400"
      />
      <Card
        style={style}
        textCenter={greaterThan500.length}
        textBottom="> 500"
      />
      <Card
        style={style}
        textCenter={greaterThan600.length}
        textBottom="> 600"
      />
    </DashboardCard>
  );
};
