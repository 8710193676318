/* eslint react/prop-types:0 */
/* eslint react/destructuring-assignment:0 */
import * as React from 'react';

const DashboardCard = props => {
  return (
    <div className={`card-group ${props.className ? props.className : ''}`}>
      {props.children}
    </div>
  );
};

export default DashboardCard;
