/* eslint react/no-children-prop:0 */
/* eslint react/button-has-type:0 */
/* eslint max-len:0 */
/* eslint no-prototype-builtins:0 */
/* eslint react/destructuring-assignment:0 */
/* eslint no-restricted-syntax:0 */
/* eslint guard-for-in:0 */
/* eslint no-underscore-dangle:0 */
import * as React from 'react';
import {
  Grid, GridColumn as Column, GridToolbar, GridColumnMenuFilter
} from '@progress/kendo-react-grid';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { filterBy, orderBy } from '@progress/kendo-data-query';

import {
  LocalizationProvider, loadMessages, load, IntlProvider
} from '@progress/kendo-react-intl';
import CustomColumnModal from '../../../custom-column/custom-column';

import {
  TABLE_BG_MESSAGES, bgDateFields, bgGregorian, weekData
} from '../../../../tools/translation-constants';


load(
  weekData,
  bgDateFields,
  bgGregorian
);

loadMessages(TABLE_BG_MESSAGES, 'bg-BG');
const PAGE_TAKE = 10;

class RodeoVetisTable extends React.Component<any, any> {
  _export;

  constructor(props) {
    super(props);
    this.state = {
      animals: props.animals,
      filter: undefined,
      sort: [],
      sortable: {
        allowUnsort: true,
        mode: 'single',
      },
      skip: 0,
      take: PAGE_TAKE,
      pageSize: PAGE_TAKE,
      pageable: {
        buttonCount: 5,
        info: true,
        type: 'numeric',
        previousNext: true,
        pageSizes: [5, 10, 20, props.animals.length],
      },
      columns: {
        animalSummary: {
          title: 'Животно',
          visible: true,
          field: 'animalSummary',
          width: '150px',
          type: 'rodeo',
        },
        dateOfBirth: {
          title: 'ДНР',
          visible: true,
          field: 'dateOfBirth',
          width: '120px',
          type: 'rodeo',
        },
        breedBreedName: {
          title: 'Порода',
          visible: true,
          field: 'breed.breedName',
          width: '125px',
          type: 'rodeo',
        },
        colorColorName: {
          title: 'Цвят',
          visible: true,
          field: 'color.colorName',
          width: '95px',
          type: 'rodeo',
        },
        sexTranslation: {
          title: 'Пол',
          visible: true,
          field: 'sexTranslation',
          width: '95px',
          type: 'rodeo',
        },
        electronicId: {
          title: 'ЕИД',
          visible: true,
          field: 'electronicId',
          width: '120px',
          type: 'rodeo',
        },
        damlID: {
          title: 'Майка',
          visible: true,
          field: 'dam.lID',
          width: '120px',
          type: 'rodeo',
        },
        animalVetisLID: {
          title: 'Ветис №',
          visible: true,
          field: 'vetis.animalVetisLID',
          width: '150px',
          type: 'vetis',
        },
        animalVetisDOB: {
          title: 'ДНР',
          visible: true,
          field: 'vetis.animalVetisDOB',
          width: '120px',
          type: 'vetis',
        },
        animalVetisbreedName: {
          title: 'Порода',
          visible: true,
          field: 'vetis.breed.breedName',
          width: '125px',
          type: 'vetis',
        },
        animalVetisColorName: {
          title: 'Цвят',
          visible: true,
          field: 'vetis.color.colorName',
          width: '95px',
          type: 'vetis',
        },
        vetisSexTranslation: {
          title: 'Пол',
          visible: true,
          field: 'vetis.vetisSexTranslation',
          type: 'vetis',
          width: '95px',
        },
        animalVetisEID: {
          title: 'ЕИД',
          visible: true,
          field: 'vetis.animalVetisEID',
          width: '120px',
          type: 'vetis',
        },
        animalVetisDamLID: {
          title: 'Майка',
          visible: true,
          field: 'vetis.animalVetisDamLID',
          width: '120px',
          type: 'vetis',
        },
      },
    };
    this.pageChange = this.pageChange.bind(this);
    this.updateTableVisibleCols = this.updateTableVisibleCols.bind(this);
    this.printCols = this.printCols.bind(this);
  }

  export = () => {
    this._export.save();
  }

  pageChange = (event) => {
    this.setState({
      skip: event.page.skip,
      take: event.page.take,
      pageSize: event.page.take,
    });
  }

  filterChange = (event) => {
    const { animals, sort, } = this.state;
    this.setState({
      animals: filterBy(orderBy(animals, sort), event.filter),
      filter: event.filter,
      skip: 0,
      take: PAGE_TAKE,
    });
  }

  sortChange = (event) => {
    const { animals, } = this.state;
    this.setState({
      animals: orderBy(animals, event.sort),
      sort: event.sort,
    });
  }

  updateTableVisibleCols = (columns) => {
    this.setState({
      columns,
    });
  }

  printCols() {
    const rodeoCols = [];
    const vetisCols = [];
    for (const key in this.state.columns) {
      if (this.state.columns.hasOwnProperty(key) && this.state.columns[key].type === 'rodeo' && this.state.columns[key].visible) {
        if (key === 'dateOfBirth') {
          rodeoCols.push(
            <Column field={this.state.columns[key].field} title={this.state.columns[key].title} width={this.state.columns[key].width} filter="date" format="{0:dd.MM.yyy}" columnMenu={props => <GridColumnMenuFilter {...props} />} />
          );
        } else {
          rodeoCols.push(
            <Column field={this.state.columns[key].field} title={this.state.columns[key].title} width={this.state.columns[key].width} />
          );
        }
      }
      if (this.state.columns.hasOwnProperty(key) && this.state.columns[key].type === 'vetis' && this.state.columns[key].visible) {
        if (key === 'animalVetisDOB') {
          vetisCols.push(
            <Column field={this.state.columns[key].field} title={this.state.columns[key].title} width={this.state.columns[key].width} filter="date" format="{0:dd.MM.yyy}" columnMenu={props => <GridColumnMenuFilter {...props} />} />
          );
        } else {
          vetisCols.push(
            <Column field={this.state.columns[key].field} title={this.state.columns[key].title} width={this.state.columns[key].width} />
          );
        }
      }
    }

    return {
      rodeoCols,
      vetisCols,
    };
  }

  render() {
    const { rodeoCols, } = this.printCols();
    const { vetisCols, } = this.printCols();
    return (
      <div className="container-fluid">
        <h3 className="mb-4">Справка РОДЕО - ВЕТИС</h3>
        <LocalizationProvider language="bg-BG">
          <IntlProvider locale="bg">
            <ExcelExport
              data={this.state.animals}
              fileName="report-rodeo-vetis.xlsx"
              ref={exporter => { this._export = exporter; }}
            >
              <Grid
                data={this.state.animals.slice(this.state.skip, this.state.take + this.state.skip)}
                className="table table-advanced"
                skip={this.state.skip}
                total={this.state.animals.length}
                onPageChange={this.pageChange}
                onFilterChange={this.filterChange}
                filter={this.state.filter}
                filterable
                pageable={this.state.pageable}
                pageSize={this.state.pageSize}
                onSortChange={this.sortChange}
                sort={this.state.sort}
                sortable={this.state.sortable}
                resizable
                reorderable
              >
                <GridToolbar>
                  <div className="d-flex justify-content-between align-items-center">
                    <span className="list-counter">
                      Брой Животни:
                      {this.state.animals.length}
                    </span>
                    <div className="d-flex d-inline-block">
                      <CustomColumnModal columns={this.state.columns} updateTableVisibleCols={this.updateTableVisibleCols} />
                      <button
                        title="Export Excel"
                        className="btn btn-secondary ml-2"
                        onClick={this.export}
                      >
                        Експорт в Excel
                      </button>
                    </div>
                  </div>
                </GridToolbar>
                {rodeoCols.length > 0 ? <Column title="Родео данни" children={rodeoCols} /> : <Column title="Родео данни" field="" />}
                {vetisCols.length > 0 ? <Column title="Ветис данни" children={vetisCols} /> : <Column title="Ветис данни" field="" />}
              </Grid>
            </ExcelExport>
          </IntlProvider>
        </LocalizationProvider>
      </div>
    );
  }
}

export default RodeoVetisTable;
