/* eslint react/no-array-index-key:0 */
/* eslint react/prop-types:0 */
import * as React from 'react';

import DatesHelper from '../../../../../../tools/DatesHelper';

import EditVaccineModal from './edit-vaccine-modal';
import DeleteVaccineModal from './delete-vaccine-modal';


const VaccineTable = props => {
  const generateTableRows = () => {
    return props.vaccine.map((vaccineObj, i) => {
      return (
        <tr key={i}>
          <td>{DatesHelper.parseAndFormatDate(vaccineObj.animalVaccineDate)}</td>
          <td>{vaccineObj.animalVaccineDrug}</td>
          <td>{vaccineObj.vet ? vaccineObj.vet.farmClientName : ''}</td>
          <td>
            <div>
              {' '}
              Доза |
              {vaccineObj.animalVaccineDose}
            </div>
            <div>
              {' '}
              Дата на следваща ваксинация |
              {DatesHelper.addDaysToDate(vaccineObj.animalVaccineDate, vaccineObj.animalVaccineEndMonths)}
            </div>
            <div>
              {' '}
              Цена |
              {vaccineObj.animalVaccinePrice}
            </div>
            <div>
              {' '}
              Бележка |
              {vaccineObj.animalVaccineComment}
            </div>
            <div>
              {' '}
              Карентен срок |
              {vaccineObj.animalVaccineKarentDays}
            </div>
          </td>
          <td className="d-flex justify-content-center">
            <DeleteVaccineModal vaccineObj={vaccineObj} />
            <EditVaccineModal vaccineObj={vaccineObj} animaldateOfBirth={props.animaldateOfBirth} />
          </td>
        </tr>
      );
    });
  };

  return (

    <table className="table">
      <thead>
        <tr>
          <th>Дата</th>
          <th>Тип</th>
          <th>Ветеринарен лекар</th>
          <th>Информация</th>
          <th>Инструменти</th>
        </tr>
      </thead>
      <tbody>
        {generateTableRows()}
      </tbody>
    </table>
  );
};

export default VaccineTable;
