/* eslint no-param-reassign:0 */
/* eslint react/no-access-state-in-setstate:0 */
/* eslint react/destructuring-assignment:0 */
/* eslint guard-for-in:0 */
/* eslint no-restricted-syntax:0 */
/* eslint class-methods-use-this:0 */
/* eslint no-underscore-dangle:0 */
import * as React from 'react';

import { ONE_TO_NINE, MARBLINGS } from '../../../../../tools/translation-constants';
import breedingFormTemplate from './breeding-form-template';

class BreedingForm extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = this._initializeState(props.animalId, props.breedingData.animal);

    this.toggleEditing = this.toggleEditing.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  _initializeState(animalId, breedingData) {
    const customProps = {
      changes: { animalId, },
      editing: false,
      breedingData: {
        ...breedingData,
        animalFunctionality: ONE_TO_NINE.find(n => n.id === breedingData.animalFunctionality),
        animalSkeleton: ONE_TO_NINE.find(n => n.id === breedingData.animalSkeleton),
        animalMusculature: ONE_TO_NINE.find(n => n.id === breedingData.animalMusculature),
        animalTemperament: ONE_TO_NINE.find(n => n.id === breedingData.animalTemperament),
        animalMarbling: MARBLINGS.find(m => m.id === breedingData.animalMarbling),
      },
    };

    for (const key in customProps.breedingData) {
      customProps.breedingData[key] = customProps.breedingData[key] != null ? customProps.breedingData[key] : '';
    }
    return customProps;
  }

  toggleEditing() {
    const isEditing = !this.state.editing;
    this.setState({ editing: isEditing, });
    if (!isEditing) {
      this.setState(this._initializeState(this.props.animalId, this.props.breedingData.animal));
    }
  }

  handleChange(event) {
    const targetName = event.target.name;
    const targetVal = event.target.value;

    this.setState(prevState => {
      const updatedChanges = this._registerChanges(prevState.changes, targetName, targetVal);
      return {
        breedingData: {
          ...prevState.breedingData,
          [targetName]: targetVal,
        },
        changes: updatedChanges,
      };
    });
  }

  _registerChanges(allChanges, prop, val) {
    const customObjects = ['animalFunctionality', 'animalSkeleton',
      'animalMusculature', 'animalTemperament', 'animalMarbling'];

    if (customObjects.indexOf(prop) >= 0) {
      allChanges[prop] = val.id;
    } else {
      allChanges[prop] = val;
    }
    return allChanges;
  }

  handleSubmit(event, updateBreedingData) {
    event.preventDefault();
    if (Object.keys(this.state.changes).length > 1) {
      updateBreedingData({
        variables:
          { breedingDataUpdates: this.state.changes, },
      });
    }
  }

  render = breedingFormTemplate;
}

export default BreedingForm;
