/* eslint react/button-has-type:0 */
/* eslint no-prototype-builtins:0 */
/* eslint react/no-access-state-in-setstate:0 */
/* eslint react/destructuring-assignment:0 */
import * as React from 'react';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Row, Col } from 'reactstrap';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import Mutation from '../../../../../../data/mutation';
import { ADD_ANIMAL_BCS, GET_ANIMAL_BCS } from '../../../../../../data/animal/animal-query';
import FarmClientDropdown from '../../health/farm-client-dropdown';
import { ANIMAL_OTS_TYPES } from '../../../../../../tools/translation-constants';
import { GET_ANIMALS_BCS_DATA } from '../../../../../../data/reports/reports-query';
import { DatePicker } from '../../../../../utils/date-picker';

class AddBcsModal extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      bCSObj: props,
      submitDisabled: true,
      tableType: 'bCS',
    };
    this.toggleDialog = this.toggleDialog.bind(this);
    this.handleFormChange = this.handleFormChange.bind(this);
  }

  toggleDialog() {
    this.setState({
      visible: !this.state.visible,
    });

    if (!this.state.visible) {
      this.resetState();
    }
  }


  handleFormChange(e) {
    const objCopy = { ...this.state.bCSObj, };

    if (e.target.name === 'animalOTS') {
      objCopy[e.target.name] = e.target.value.id;
    } else if (e.target.name === 'farmClientID') {
      objCopy[e.target.name] = e.target.value.farmClientID;
    } else {
      objCopy[e.target.name] = e.target.value;
    }
    const isFormSubmitable = !(Object.keys(objCopy).length > 1);
    this.setState({
      bCSObj: objCopy,
      submitDisabled: isFormSubmitable,
    });
  }

  handleSubmit(e, addAnimalBCSTrigger) {
    e.preventDefault();
    const stateCopy = { ...this.state.bCSObj, };
    let dataToSave;

    if (!stateCopy.hasOwnProperty('bCSDate')) {
      stateCopy.bCSDate = new Date();
      dataToSave = { ...stateCopy, };
    } else {
      dataToSave = { ...this.state.bCSObj, };
    }

    if (Object.keys(dataToSave).length > 1) {
      addAnimalBCSTrigger({
        variables:
                {
                  animalAddBCS: dataToSave,
                },
      });
    }
  }

  resetState() {
    const { animalID, } = this.state.bCSObj;
    this.setState({
      bCSObj: {
        animalID,
      },
      submitDisabled: true,
    });
  }

  render() {
    return (
      <Mutation
        mutation={ADD_ANIMAL_BCS}
        awaitRefetchQueries
        refetchQueries={[{ query: GET_ANIMAL_BCS, variables: { id: this.state.bCSObj.animalID, }, }, { query: GET_ANIMALS_BCS_DATA, }]}
        onCompleted={() => this.toggleDialog()}
      >
        {(addAnimalBCSTrigger) => (
          <div>
            <button className="btn btn-primary" onClick={this.toggleDialog}>ДОБАВИ СЪБИТИЕ</button>
            {this.state.visible && (
            <form onSubmit={e => this.handleSubmit(e, addAnimalBCSTrigger)} className="health-form">
              <Dialog className="health-modal" title="Добави събитие" onClose={this.toggleDialog} minWidth="500px" height="70%">
                <Row>
                  <Col lg={{ size: 8, offset: 2, }}>
                    <div className="form-group">
                      <label>ОТС</label>
                      <DropDownList
                        style={{ width: '100%', }}
                        data={ANIMAL_OTS_TYPES}
                        dataItemKey="id"
                        textField="text"
                        name="animalOTS"
                        required
                        onChange={this.handleFormChange}
                      />
                    </div>
                    <div className="form-group">
                      <label>Отговорник</label>
                      <FarmClientDropdown tableType={this.state.tableType} handleFormChange={this.handleFormChange} />
                    </div>
                    <div className="form-group">
                      <label>Дата</label>
                      <DatePicker
                        value={this.state.bCSObj?.bCSDate}
                        name="bCSDate"
                        onChange={this.handleFormChange}
                        min={new Date(this.props.animaldateOfBirth)}
                        max={new Date()}
                      />
                    </div>
                    <div className="form-group">
                      <label>Бележка</label>
                      <textarea className="k-textarea" name="comment" onChange={this.handleFormChange} />
                    </div>
                  </Col>
                </Row>

                <DialogActionsBar>
                  <div className="d-flex">
                    <button className="btn btn-secondary" onClick={this.toggleDialog}>Отказ</button>
                    <button type="submit" disabled={this.state.submitDisabled} className="btn btn-primary ml-3">Добави</button>
                  </div>
                </DialogActionsBar>
              </Dialog>
            </form>
            )}
          </div>
        )}
      </Mutation>
    );
  }
}

export default AddBcsModal;
