/* eslint react/button-has-type:0 */
/* eslint max-len:0 */
/* eslint no-nested-ternary:0 */
/* eslint no-underscore-dangle:0 */
/* eslint react/destructuring-assignment:0 */
/* eslint react/no-access-state-in-setstate:0 */
import * as React from 'react';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Row, Col } from 'reactstrap';
import { Input } from '@progress/kendo-react-inputs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import Mutation from '../../../../data/mutation';
import { deepCopy, isActionTypeAndGetQuery, selectedValue } from '../../../../tools/function-helpers';
import { LOCATIONS_TYPES_TRANSLATIONS, LOCATION_TYPES } from '../../../../tools/translation-constants';
import {
  GET_LOCATIONS_BY_TYPE, GET_ANIMAL_OBJECTS, ADD_LOCATION, EDIT_LOCATION
} from '../../../../data/map-locations/map-locations-query';

const getQuery = (action) => {
  if (action === 'add') {
    return ADD_LOCATION;
  }
  return EDIT_LOCATION;
};

const LOCATION_BARN_TYPES = LOCATIONS_TYPES_TRANSLATIONS.slice(0, 2).map(barn => {
  return {
    id: barn.id,
    value: barn.text,
  };
});

const LOCATION_BOX_TYPES = LOCATIONS_TYPES_TRANSLATIONS.slice(3, LOCATIONS_TYPES_TRANSLATIONS.length).map(box => {
  return {
    id: box.id,
    value: box.text,
  };
});

const checkIsFormSubmitable = (objCopy, type) => {
  if (type === 'parent') {
    return !(objCopy.locationName && objCopy.locationName !== '' && objCopy.locationType && objCopy.locationType !== '');
  }
  return !(objCopy.locationName && objCopy.locationName !== '' && objCopy.parentId && objCopy.parentId !== '');
};

class MapLocationsModal extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      dataItem: props.dataItem,
      submitDisabled: true,
    };
    this.toggleDialog = this.toggleDialog.bind(this);
    this.handleFormChange = this.handleFormChange.bind(this);
  }

  toggleDialog() {
    this.setState({
      visible: !this.state.visible,
    });

    if (!this.state.visible) {
      this.resetState();
    }
  }

  handleFormChange(e) {
    const objCopy = deepCopy(this.state.dataItem);
    let isFormSubmitable = false;
    if (typeof e.target.value === 'object') {
      if (e.target.name === 'parentId' && this.props.type === 'box') {
        objCopy.mainParentType = e.target.value.mainParentType;
      }
      objCopy[e.target.name] = e.target.value.id;
    } else {
      objCopy[e.target.name] = e.target.value;
    }

    isFormSubmitable = checkIsFormSubmitable(objCopy, this.props.type);
    this.setState({
      dataItem: objCopy,
      submitDisabled: isFormSubmitable,
    });
  }

  handleSubmit(e, mutationTrigger) {
    e.preventDefault();
    const objCopy = deepCopy(this.state.dataItem);
    delete objCopy.children;
    delete objCopy.animalObject;
    delete objCopy.__typename;
    if (this.props.type === 'sector' && this.props.action === 'add') {
      objCopy.locationType = LOCATION_TYPES.SECTOR.text;
    }

    if (this.props.type === 'box' && objCopy.mainParentType) {
      if (objCopy.mainParentType.toLowerCase() === LOCATION_TYPES.BARN.text.toLowerCase()) {
        objCopy.locationType = objCopy.locationType ? objCopy.locationType : LOCATION_TYPES.BOX.text;
      } else {
        objCopy.locationType = objCopy.locationType ? objCopy.locationType : LOCATION_TYPES.PADDOCK.text;
      }
      delete objCopy.mainParentType;
    }

    this.toggleDialog();
    mutationTrigger({
      variables:
            {
              dataItem: objCopy,
            },
    });
  }

  resetState() {
    const copyData = { ...this.props.dataItem, };
    this.setState({
      dataItem: copyData,
      submitDisabled: true,
    });
  }

  render() {
    const isAddActionType = isActionTypeAndGetQuery(this.props.action, 'add', '', '');
    const getTitle = (type, action, modal = false) => {
      if (modal) {
        return `${action.isActionType ? 'Добави' : 'Редактирай'} ${type === 'parent' ? 'обор/пасище' : type === 'sector' ? 'сектор' : 'бокс/падок'}`;
      }
      return action.isActionType ? type === 'parent' ? 'Добави обор/пасище' : type === 'sector' ? 'Добави сектор' : 'Добави бокс/падок' : <FontAwesomeIcon icon="pen" />;
    };
    const animalObjectID = this.state.dataItem.animalObjectID ? this.state.dataItem.animalObjectID : this.state.dataItem.animalObject ? this.state.dataItem.animalObject.animalObjectID : null;

    return (
      <Mutation
        mutation={getQuery(this.props.action)}
        awaitRefetchQueries
        refetchQueries={[{ query: GET_ANIMAL_OBJECTS, variables: {}, }, { query: GET_LOCATIONS_BY_TYPE(LOCATION_TYPES.BARN.text), }, { query: GET_LOCATIONS_BY_TYPE(LOCATION_TYPES.PASTURE.text), }]}
      >
        {(mutationTrigger) => (
          <div className="d-inline-block">
            <button className={isAddActionType.isActionType ? 'btn btn-primary mr-2' : 'button-icon'} onClick={this.toggleDialog}>{getTitle(this.props.type, isAddActionType)}</button>
            {this.state.visible && (
            <form onSubmit={e => this.handleSubmit(e, mutationTrigger)}>
              <Dialog title={getTitle(this.props.type, isAddActionType, true)} onClose={this.toggleDialog} minWidth="500px" height="70%">
                <Row>
                  <Col lg={{ size: 8, offset: 2, }}>
                    <div className="form-group">
                      <label>Име</label>
                      <Input type="text" required name="locationName" value={this.state.dataItem.locationName ? this.state.dataItem.locationName : ''} onChange={this.handleFormChange} />
                    </div>
                    <div className="form-group">
                      <label>Площ(м2)</label>
                      <Input type="number" name="locationSpace" value={this.state.dataItem.locationSpace ? this.state.dataItem.locationSpace : ''} onChange={this.handleFormChange} />
                    </div>
                    <div className="form-group">
                      <label>Площ(ха)</label>
                      <Input type="number" name="locationSpaceHec" value={this.state.dataItem.locationSpaceHec ? this.state.dataItem.locationSpaceHec : ''} onChange={this.handleFormChange} />
                    </div>
                    {(this.props.type === 'parent' || (this.props.type === 'box' && this.props.action !== 'add')) && (
                    <div className="form-group">
                      <label>Тип на обекта:</label>
                      <DropDownList
                        data={this.props.type === 'parent' ? LOCATION_BARN_TYPES : LOCATION_BOX_TYPES}
                        dataItemKey="id"
                        textField="value"
                        name="locationType"
                        value={this.state.dataItem.locationType ? this.props.type === 'parent' ? selectedValue(this.state.dataItem.locationType, LOCATION_BARN_TYPES, 'id') : selectedValue(this.state.dataItem.locationType, LOCATION_BOX_TYPES, 'id') : null}
                        onChange={this.handleFormChange}
                        required={this.props.type === 'parent'}
                      />
                    </div>
                    )}
                    {this.props.type === 'parent' && (
                    <div>
                      <div className="form-group">
                        <label>Собственик на обекта:</label>
                        <DropDownList
                          data={this.props.animalsObjects}
                          dataItemKey="id"
                          textField="value"
                          name="animalObjectID"
                          value={animalObjectID ? selectedValue(animalObjectID, this.props.animalsObjects, 'id') : null}
                          onChange={this.handleFormChange}
                        />
                      </div>
                      <div className="form-group">
                        <label>Култура/Настилка</label>
                        <Input type="text" name="groundType" value={this.state.dataItem.groundType ? this.state.dataItem.groundType : ''} onChange={this.handleFormChange} />
                      </div>
                      <div className="form-group">
                        <label>Географска ширина</label>
                        <Input type="text" name="latitude" value={this.state.dataItem.latitude ? this.state.dataItem.latitude : ''} onChange={this.handleFormChange} />
                      </div>
                      <div className="form-group">
                        <label>Географска дължина</label>
                        <Input type="text" name="longitude" value={this.state.dataItem.longitude ? this.state.dataItem.longitude : ''} onChange={this.handleFormChange} />
                      </div>
                    </div>
                    )}
                    {(this.props.type === 'sector' || this.props.type === 'box') && (
                    <div>
                      <div className="form-group">
                        <label>{this.props.type === 'sector' ? 'Към обор/пасище:' : 'Към сектор:'}</label>
                        <DropDownList
                          data={this.props.type === 'sector' ? this.props.barnsLocations : this.props.sectorsMapped}
                          dataItemKey="id"
                          textField="value"
                          name="parentId"
                          value={this.state.dataItem.parentId ? this.props.type === 'sector' ? selectedValue(this.state.dataItem.parentId, this.props.barnsLocations, 'id') : selectedValue(this.state.dataItem.parentId, this.props.sectorsMapped, 'id') : null}
                          onChange={this.handleFormChange}
                          required
                        />
                      </div>
                    </div>
                    )}
                    <div className="form-group">
                      <label>Бележка</label>
                      <textarea className="k-textarea" name="comment" value={this.state.dataItem.comment ? this.state.dataItem.comment : ''} onChange={this.handleFormChange} />
                    </div>
                  </Col>
                </Row>
                <DialogActionsBar>
                  <div className="d-flex">
                    <button className="btn btn-secondary" onClick={this.toggleDialog}>Отказ</button>
                    <button type="submit" disabled={this.state.submitDisabled} className="btn btn-primary ml-3">{isAddActionType.isActionType ? 'Добави' : 'Редактирай'}</button>
                  </div>
                </DialogActionsBar>
              </Dialog>
            </form>
            )}
          </div>
        )}
      </Mutation>
    );
  }
}

export default MapLocationsModal;
