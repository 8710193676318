/* eslint react/button-has-type:0 */
/* eslint jsx-a11y/control-has-associated-label:0 */
/* eslint react/no-array-index-key:0 */
/* eslint react/prop-types:0 */
/* eslint react/destructuring-assignment:0 */
import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Query from '../../../../../data/query';
import { GET_ANIMAL_NOTES } from '../../../../../data/animal/animal-query';
import DatesHelper from '../../../../../tools/DatesHelper';
import AddNoteModal from './add-note-modal';
import UpdateNoteModal from './update-note-modal';

const NotesTable = (props) => {
  const id = props.animalId;
  const generateTableRows = (notes) => {
    return notes.map((n, i) => (
      <tr key={i}>
        <td>{DatesHelper.parseAndFormatDate(n.dateNote)}</td>
        <td>{n.noteTopic ? n.noteTopic : ''}</td>
        <td>{n.note ? n.note : ''}</td>
        <td>
          <div className="d-flex justify-content-center">
            <button className="button-icon mr-4"><FontAwesomeIcon icon="trash" /></button>
            <UpdateNoteModal currentNote={n} animalId={id} animaldateOfBirth={props.animaldateOfBirth} />
          </div>
        </td>
      </tr>
    ));
  };

  return (
    <div className="table-inner mb-5">
      <h4 className="mb-1">БЕЛЕЖКИ</h4>
      <AddNoteModal animalId={id} animaldateOfBirth={props.animaldateOfBirth} />
      <Query query={GET_ANIMAL_NOTES} variables={{ id, }}>
        {({ animal: { notes, }, }) => {
          return (
            <div>
              {notes && notes.length > 0
                ? (
                  <p className="mb-1">
                    {' '}
                    <small>Тук добавяте бележки</small>
                  </p>
                )
                : ''}
              {notes && notes.length > 0
                ? (
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Дата</th>
                        <th>Тема</th>
                        <th>Бележка</th>
                        <th>Инструменти</th>
                      </tr>
                    </thead>
                    <tbody>
                      {generateTableRows(notes)}
                    </tbody>
                  </table>
                )
                : <p>Няма бележки за това животно.</p>}
            </div>
          );
        }}
      </Query>
    </div>
  );
};

export default NotesTable;
