/* eslint jsx-a11y/no-static-element-interactions:0 */
/* eslint jsx-a11y/click-events-have-key-events:0 */
/* eslint max-len:0 */
/* eslint react/no-array-index-key:0 */
/* eslint react/no-access-state-in-setstate:0 */
/* eslint react/destructuring-assignment:0 */
/* eslint react/state-in-constructor:0 */
/* eslint react/sort-comp:0 */
import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// @ts-ignore
import logo from '../../assets/img/icons/RodeoLogo.svg';

const secondsLinks = [
  [],
  [
    { title: 'Избор на животни', link: '/animals/list', },
    { title: 'Външни животни', link: '/animals/external-animals', },
    { title: 'Отпаднали животни', link: '/reports/dropped-animals', },
    { title: 'Семенен материал', link: '/animals/warehouse', }
  ],
  [
    { title: 'Вписване на животни', link: '/animals/movement/group-entries', },
    { title: 'Отпадане на животни', link: '/movements/drop-animals', },
    { title: 'Смяна на локация', link: '/movements/animals-change-location', }
  ],
  [
    { title: 'Мениджмънт групи', link: '/groups/groups-managment', },
    { title: 'Сървис групи', link: '/groups/groups-service', },
    { title: 'ДПП групи', link: '/groups/dpp', }

  ],
  [
    { title: 'Групово претегляне', link: '/events/group-weighting', },
    { title: 'Групово ваксиниране', link: '/events/vaccination', },
    { title: 'Туберкулинизация и Бруцелоза', link: '/events/tuberculinum', },
    { title: 'Изкуствено осеменяване', link: '/events/insemination', }

  ],
  [
    { title: 'Родео ветис', link: '/reports/rodeo-vetis', },
    { title: 'Реваксинация', link: '/reports/revaccination', },
    { title: 'Тегла', link: '/reports/weights', },
    { title: 'Раждания и отелвания', link: '/reports/pregnancy', },
    { title: 'Животни по локация', link: '/reports/locations', },
    { title: 'ОТС', link: '/reports/bcs', },
    { title: 'Мъртво родени', link: '/reports/dead-born', },
    { title: 'Подпомагане', link: '/reports/assist', },
    { title: 'Теглови сесии', link: '/reports/sessions', },
    { title: 'Премаркирани животни', link: '/reports/retag-report', }

  ],
  [
    { title: 'Карта/Локации', link: '/settings/locations', },
    { title: 'Контрагенти', link: '/settings/contragents', },
    { title: 'Фирми/Животновъдни обекти', link: '/settings/companies', },
    { title: 'Календар', link: '/settings/scheduler', },
    { title: 'Потребители', link: '/settings/users', }
  ]
];

export default class Header extends React.Component<any, any> {
  componentDidMount() {
    const activeLink = document.querySelector('.main-menu .active');
    document.addEventListener('mousedown', this.handleClickOutsideUserConfug);
    if (activeLink) {
      this.setState({
        index: activeLink.getAttribute('data-index'),
      });
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutsideUserConfug);
  }

  dropdownRef = null;

  state = {
    index: 0,
    isDropdownOpen: false,
  }


  handleClickOutsideUserConfug = (event) => {
    if (this.dropdownRef && !this.dropdownRef.contains(event.target)) {
      this.setState({
        isDropdownOpen: false,
      });
    }
  }

  render = () => {
    const user = JSON.parse(localStorage.getItem('user')) || {};

    const changeIndex = (index) => {
      this.setState({
        index,
      });
    };

    const isActive = (match, location, pathMatch, exeptionOut?, exeptionIn?) => {
      if (location.pathname.includes(exeptionOut)) {
        return false;
      }
      if (location.pathname.includes(pathMatch) || location.pathname.includes(exeptionIn)) {
        return true;
      }
      return false;
    };

    let secondMenu = null;

    const links = secondsLinks[this.state.index].map((l, i) => {
      return (
        <li key={i}>
          <NavLink to={l.link}>{l.title}</NavLink>
        </li>
      );
    });

    if (links.length > 0) {
      secondMenu = (
        <ul className="second-menu">
          {links}
        </ul>
      );
    }

    const toggleDropdown = () => {
      this.setState({
        isDropdownOpen: !this.state.isDropdownOpen,
      });
    };

    return (
      <header className="header-wrapper">
        <div className="box-wrapper">
          <div className="container">
            <ul className="main-menu">
              <li>
                <NavLink style={{ color: 'black', }} data-index="0" onClick={() => changeIndex(0)} exact to="/">
                  RODEO
                  <img style={{ marginLeft: '8px', }} src={logo} alt="Logo" />
                </NavLink>
              </li>
              <li>
                <NavLink
                  data-index="1"
                  onClick={() => changeIndex(1)}
                  to="/animals/list"
                  isActive={(match, location) => {
                    return isActive(match, location, '/animals/', secondsLinks[2][0].link, secondsLinks[1][2].link);
                  }}
                >
                  животни
                </NavLink>
              </li>
              <li>
                <NavLink
                  data-index="2"
                  onClick={() => changeIndex(2)}
                  to="/animals/movement/group-entries"
                  isActive={(match, location) => {
                    return isActive(match, location, '/movements/', null, secondsLinks[2][0].link);
                  }}
                >
                  движения
                </NavLink>
              </li>
              <li>
                <NavLink
                  data-index="3"
                  onClick={() => changeIndex(3)}
                  to="/groups/groups-managment"
                  isActive={(match, location) => {
                    return isActive(match, location, '/groups/', null, null);
                  }}
                >
                  групи
                </NavLink>
              </li>
              <li>
                <NavLink
                  data-index="4"
                  onClick={() => changeIndex(4)}
                  to="/events/group-weighting"
                  isActive={(match, location) => {
                    return isActive(match, location, '/events/', null, null);
                  }}
                >
                  събития
                </NavLink>
              </li>
              <li>
                <NavLink
                  data-index="5"
                  onClick={() => changeIndex(5)}
                  to="/reports/rodeo-vetis"
                  isActive={(match, location) => {
                    return isActive(match, location, '/reports/', secondsLinks[1][2].link, null);
                  }}
                >
                  отчети
                </NavLink>
              </li>
              <li>
                <NavLink
                  style={{ fontSize: '24px', }}
                  data-index="6"
                  onClick={() => changeIndex(6)}
                  to="/settings/locations"
                  isActive={(match, location) => {
                    return isActive(match, location, '/settings/', null, null);
                  }}
                >
                  <FontAwesomeIcon icon="cog" />
                </NavLink>
              </li>
              <li>
                <div className="dropdown" ref={(drRef) => { this.dropdownRef = drRef; }}>
                  <span onClick={toggleDropdown} style={{ fontSize: '24px', }}>
                    <FontAwesomeIcon icon="user-cog" />
                  </span>
                  <div className={`dropdown-menu${this.state.isDropdownOpen ? ' show' : ''}`}>
                    <div className="dropdown-item">
                      <p>Потребител:</p>
                      <h4>{user.nickname}</h4>
                      <p>{user.email}</p>
                    </div>
                    <button onClick={this.props.auth.logout} className="btn btn-primary" type="button">Изход</button>
                  </div>
                </div>
              </li>
            </ul>
            {secondMenu}
          </div>
        </div>
      </header>
    );
  }
}
