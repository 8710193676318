/* eslint react/button-has-type:0 */
/* eslint no-return-assign:0 */
/* eslint no-underscore-dangle:0 */
/* eslint react/no-access-state-in-setstate:0 */
/* eslint react/sort-comp:0 */
/* eslint react/destructuring-assignment:0 */
/* eslint react/no-did-update-set-state:0 */
/* eslint max-len:0 */
import * as React from 'react';
import { Row, Col } from 'reactstrap';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Input } from '@progress/kendo-react-inputs';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, GridColumn as Column, GridToolbar } from '@progress/kendo-react-grid';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { filterBy } from '@progress/kendo-data-query';
import { GROUP_TYPES, SEXES } from '../../../../tools/translation-constants';
import Mutation from '../../../../data/mutation';
import { EDIT_GROUP_DATA, GET_ALL_ANIMAL_GROUPS } from '../../../../data/groups/groups-query';
import { deepCopy, checkIsFormForGroupDataValid } from '../../../../tools/function-helpers';
import { getAnimalAgeInMonths } from '../../animals/animal-utilities';
import AddAnimalsInGroupModal from './edit-group-modals/add-animals-in-group-modal';
import DeleteAnimalsInGroupModal from './edit-group-modals/delete-animal-modal';
import DatesHelper from '../../../../tools/DatesHelper';
import { DatePicker } from '../../../utils/date-picker';


export const mapAnimals = (animals) => {
  const missing = '--';
  return animals.map((animal) => {
    return {
      dateOfBirth: DatesHelper.dateToAppFormat(animal.dateOfBirth),
      text: `${animal.lID ? animal.lID : missing}(${animal.vID ? animal.vID : missing} | ${animal.sex ? SEXES.find(sex => sex.id === animal.sex).text : missing} | ${getAnimalAgeInMonths(animal.dateOfBirth)}м.)`,
      animalID: animal.id,
      animalBreed: animal.breed.breedName,
    };
  });
};

class EditGroup extends React.Component<any, any> {
    _export;

    constructor(props) {
      super(props);
      this.state = {
        group: props.location.state ? props.groups[props.location.state.indexGroup] : {},
        isFormValid: false,
        filter: undefined,
        animals: props.location.state ? mapAnimals(props.groups[props.location.state.indexGroup] ? props.groups[props.location.state.indexGroup].animals : []) : [],
      };
      this.handleFormChange = this.handleFormChange.bind(this);
      this.onFormSubmit = this.onFormSubmit.bind(this);
    }


    componentDidUpdate(prevProps) {
      if (this.props !== prevProps) {
        this.setState({
          group: this.props.location.state ? this.props.groups[this.props.location.state.indexGroup] : {},
          animals: this.props.location.state ? mapAnimals(this.props.groups[this.props.location.state.indexGroup] ? this.props.groups[this.props.location.state.indexGroup].animals : []) : [],
        });
      }
    }

    handleFormChange(e) {
      const copyGroupData = { ...this.state.group, };
      if (e.target.name === 'groupTypeID') {
        copyGroupData[e.target.name] = e.target.value.id;
      } else {
        copyGroupData[e.target.name] = e.target.value;
      }

      const isFormValid = checkIsFormForGroupDataValid(copyGroupData);
      this.setState({
        group: copyGroupData,
        isFormValid,
      });
    }

    onFormSubmit(e, mutationTrigger) {
      e.preventDefault();
      const data = deepCopy(this.state.group);
      delete data.animals;
      delete data.whenCreate;
      delete data.__typename;
      mutationTrigger({
        variables:
            {
              dataItem: data,
            },
      });
    }

    filterChange = (event) => {
      this.setState({
        animals: filterBy(mapAnimals(this.state.group.animals), event.filter),
        filter: event.filter,
      });
    };

    export = () => {
      this._export.save();
    };

    render() {
      return (
        <div>
          {this.props.indexGroup !== undefined
                && (
                <Link
                  className="button-icon"
                  to={{
                    pathname: '/groups/groups-managment/edit-group',
                    state: {
                      indexGroup: this.props.indexGroup,
                    },
                  }}
                >
                  <FontAwesomeIcon icon="pen" />
                </Link>
                )}
          {this.props.indexGroup === undefined
                    && (
                    <div className="container">
                      <h3 className="mb-4">РЕДАКТИРАЙ МЕНИДЖМЪНТ ГРУПА</h3>
                      <h4 className="mb-2">Данни за групата</h4>
                      <Mutation
                        mutation={EDIT_GROUP_DATA}
                        awaitRefetchQueries
                        refetchQueries={[{ query: GET_ALL_ANIMAL_GROUPS, variables: {}, }]}
                      >
                        {(mutationTrigger) => (
                          <form onSubmit={(e) => this.onFormSubmit(e, mutationTrigger)}>
                            <Row>
                              <Col lg="4">
                                <Row>
                                  <Col lg="12">
                                    <div className="form-group">
                                      <label>Тип на групата</label>
                                      <DropDownList
                                        data={GROUP_TYPES}
                                        dataItemKey="id"
                                        textField="text"
                                        name="groupTypeID"
                                        onChange={this.handleFormChange}
                                        value={this.state.group.groupTypeID ? GROUP_TYPES.find(type => type.id === this.state.group.groupTypeID) : ''}
                                      />
                                    </div>
                                  </Col>
                                  <Col lg="12">
                                    <div className="form-group">
                                      <label>Дата</label>
                                      <DatePicker
                                        value={this.state.group.groupDate ? this.state.group.groupDate : ''}
                                        name="groupDate"
                                        onChange={this.handleFormChange}
                                      />
                                    </div>
                                  </Col>
                                  <Col lg="12">
                                    <div className="form-group">
                                      <label>Име на групата</label>
                                      <Input type="text" name="managementGroupsName" onChange={this.handleFormChange} value={this.state.group.managementGroupsName ? this.state.group.managementGroupsName : ''} />
                                    </div>
                                  </Col>
                                  <Col lg="12">
                                    <div className="form-group">
                                      <label>Кратко име на групата</label>
                                      <Input type="text" name="managementGroupsShortName" onChange={this.handleFormChange} value={this.state.group.managementGroupsShortName ? this.state.group.managementGroupsShortName : ''} />
                                    </div>
                                  </Col>
                                </Row>
                              </Col>
                              <Col lg="5">
                                <div className="form-group">
                                  <label>Коментар</label>
                                  <textarea className="k-textarea" name="managementGroupsComment" onChange={this.handleFormChange} value={this.state.group.managementGroupsComment ? this.state.group.managementGroupsComment : ''} rows={9} />
                                </div>
                              </Col>
                            </Row>
                            <button type="submit" disabled={!this.state.isFormValid} className="btn btn-primary">Запази</button>
                          </form>
                        )}
                      </Mutation>
                      <hr className="color-primary mb-5 mt-5" />
                      <h4 className="mb-3">
                        Животни в групата (
                        {this.state.group.animals.length}
                        )
                      </h4>
                      <AddAnimalsInGroupModal {...this.props} managementGroupsID={this.state.group.managementGroupsID} />
                      <ExcelExport
                        data={this.state.animals}
                        ref={exporter => this._export = exporter}
                      >
                        <Grid
                          className="table table-advanced"
                          filterable
                          data={this.state.animals}
                          filter={this.state.filter}
                          onFilterChange={this.filterChange}
                        >
                          <GridToolbar>
                            <div className="d-flex justify-content-end">
                              <button
                                title="Export excel"
                                className="btn btn-secondary"
                                onClick={this.export}
                              >
                                Експорт в Excel
                              </button>
                            </div>
                          </GridToolbar>
                          <Column field="text" title="Животно" />
                          <Column field="animalBreed" title="Порода" width="200px" />
                          <Column field="dateOfBirth" title="Дата на раждане" width="200px" />


                          <Column field="" filterable={false} title="Действия" width="112px" cell={(props) => <td className="d-flex justify-content-center"><DeleteAnimalsInGroupModal {...props} managementGroupsID={this.state.group.managementGroupsID} /></td>} />
                        </Grid>
                      </ExcelExport>
                    </div>
                    )}
        </div>
      );
    }
}

export default EditGroup;
