/* eslint-disable no-restricted-globals */
/* eslint react/no-array-index-key:0 */
/* eslint react/prop-types:0 */
/* eslint react/destructuring-assignment:0 */
import * as React from 'react';

import DatesHelper from '../../../../../../tools/DatesHelper';
import { generateLocationString } from '../../../animal-utilities';
import DeleteMovementModal from './delete-movement-model';
import { mapAnimalLocationType } from '../../../animal-mapping-functions';

const MovementsTable = props => {
  const generateTableRows = () => {
    return props.animal.animalLocation.map((location, i) => {
      return (
        <tr key={i}>
          <td>{DatesHelper.parseAndFormatDate(location.moveDate)}</td>
          <td>{generateLocationString({ animalLocation: [location], }) }</td>
          <td>{mapAnimalLocationType(location.animalLocationType) }</td>
          <td className="d-flex justify-content-center">
            <DeleteMovementModal animalID={location.animalID} animalLocationId={location.animalLocationId} />
          </td>
        </tr>
      );
    });
  };

  return (
    <table className="table">
      <thead>
        <tr>
          <th>Дата на преместване</th>
          <th>Локация</th>
          <th>Събитие</th>
          <th>Инструменти</th>
        </tr>
      </thead>
      <tbody>
        {generateTableRows()}
      </tbody>
    </table>
  );
};

export default MovementsTable;
