/* eslint react/no-access-state-in-setstate:0 */
/* eslint react/destructuring-assignment:0 */
import * as React from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { ToastContainer } from 'react-toastify';
import { withLocalize } from 'react-localize-redux';

import Header from './header';
import Footer from './footer';

class Index extends React.Component<any, any> {
  constructor(props) {
    super(props);

    this.props.initialize({
      languages: [
        { name: 'Bulgarian', code: 'bg', },
        { name: 'English', code: 'en', }
      ],
      options: { renderToStaticMarkup, },
    });
    this.state = {
      isMenuOpen: false,
    };

    this.toggleMenu = this.toggleMenu.bind(this);
  }

  toggleMenu() {
    this.setState({
      isMenuOpen: !this.state.isMenuOpen,
    });
  }

    render = () => {
      return (

        <div>
          <Header auth={this.props.auth} />
          <div className="content-wrapper">
            <div className="container">{this.props.children}</div>
          </div>
          <ToastContainer />
          <Footer />
        </div>
      );
    }
}

export default withLocalize(Index);
