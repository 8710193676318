/* eslint react/no-unescaped-entities:0 */
/* eslint react/prop-types:0 */
import * as React from 'react';

import Query from '../../../../data/query';
import { DATA_TAB_ENUMS } from '../../../../data/animal/tabs-queries';
import { GET_SIRES_AND_DAMS } from '../../../../data/animal/animal-query';
import VetisForm from './data/vetis-form';
import AnimalDataForm from './data/animal-data-form';
import AssistantTable from './data/assistant-table';
import AddAssisModal from './data/add-assistant-modal';


const DataTab = props => (
  <Query {...props} query={DATA_TAB_ENUMS}>
    {({ aClasses, breeds, color, }) => {
      return (
        <Query query={GET_SIRES_AND_DAMS}>
          {({ sires, dams, }) => (
            <div className="tab">
              <h3 className="mb-4">ДАННИ</h3>
              <h4>ДАННИ НА ФЕРМАТА</h4>
              <p className="mb-3">Данни за животното , това са верните ви данни!</p>
              <AnimalDataForm
                currentAnimal={props.currentAnimal}
                aClasses={aClasses}
                breeds={breeds}
                color={color}
                sires={sires}
                dams={dams}
              />
              <h4>ВЕТИС ДАННИ</h4>
              <p className="mb-3">
                Данни от системата на БАБХ. Оцветените
                <span className="text-success">полета означават "несъответствие"</span>
              </p>
              <VetisForm currentAnimal={props.currentAnimal} color={color} dams={dams} breeds={breeds} />
              <div className="table-inner mb-5">
                <h4>ПОДПОМАГАНЕ</h4>
                <h4>Данни за подпомагане на животното</h4>
                <p className="mb-3">
                  {' '}
                  <small>Тук се посочват данните при заявяване на животното за подпомагане!</small>
                </p>
                <AddAssisModal animalID={props.currentAnimal.animalId} />
                <AssistantTable assisEvents={props.currentAnimal.animalAssistance} animalID={props.currentAnimal.animalId} />
              </div>
            </div>
          )}
        </Query>
      );
    }}
  </Query>
);

export default DataTab;
