import * as React from 'react';
import RemoveAnimals from './remove-animals';
import Query from '../../../../../data/query';
import { GET_ANIMALS_FOR_GROUPS } from '../../../../../data/groups/groups-query';
import { DATA_TAB_ENUMS } from '../../../../../data/animal/tabs-queries';


const RemoveAnimalsWrapper = props => (
  <Query query={DATA_TAB_ENUMS}>
    {({ aClasses, breeds, color, }) => (
      <Query query={GET_ANIMALS_FOR_GROUPS}>
        {({ animals, }) => (
          <RemoveAnimals
            {...props}
            animals={animals.items}
            aClasses={aClasses.items}
            breeds={breeds.items}
            colors={color.items}
          />
        )}
      </Query>
    )}
  </Query>
);

export default RemoveAnimalsWrapper;
