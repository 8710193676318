/* eslint react/no-array-index-key:0 */
/* eslint react/prop-types:0 */
import * as React from 'react';
import DatesHelper from '../../../../../../tools/DatesHelper';
import { getTranslation } from '../../../../../../tools/function-helpers';
import { EID_TYPES } from '../../../../../../tools/translation-constants';

const RetagTable = props => {
  const generateTableRows = () => {
    return props.animal.reTag.map((retagObj, i) => {
      return (
        <tr key={i}>
          <td>{DatesHelper.dateToAppFormat(retagObj.dateReTag)}</td>
          <td>{retagObj.lID ? retagObj.lID : ''}</td>
          <td>{retagObj.vID ? retagObj.vID : ''}</td>
          <td>{retagObj.electronicId ? retagObj.electronicId : ''}</td>
          <td>{retagObj.eIDType ? getTranslation(retagObj.eIDType, EID_TYPES) : ''}</td>
        </tr>
      );
    });
  };

  return (
    <table className="table">
      <thead>
        <tr>
          <th>Дата</th>
          <th>Ветис №</th>
          <th>Вин №</th>
          <th>EID №</th>
          <th>Вид EID</th>
        </tr>
      </thead>
      <tbody>
        {generateTableRows()}
      </tbody>
    </table>
  );
};

export default RetagTable;
