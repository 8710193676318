/* eslint no-underscore-dangle:0 */
/* eslint import/no-extraneous-dependencies:0 */

import * as React from 'react';
import ReactDOM from 'react-dom';
import { createStore } from 'redux';
import { Provider } from 'react-redux';
import { LocalizeProvider } from 'react-localize-redux';
import { Route, BrowserRouter, Switch } from 'react-router-dom';
import { ApolloProvider } from '@apollo/react-hooks';
import { ApolloClient } from 'apollo-client';
import { createHttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import { InMemoryCache } from 'apollo-cache-inmemory';

import Home from './components/views/home/home';
import Animals from './components/views/animals/animals';
import Auth from './components/auth/auth';
import Callback from './components/auth/callback';
import Index from './components/layout/index';
import Defaults from './data/local/defaults';
import Events from './components/views/events/events';
import Reports from './components/views/reports/reports';
import Settings from './components/views/settings/settings';
import Groups from './components/views/groups/groups';
import Movements from './components/views/movements/movements';

import '@progress/kendo-theme-default/dist/all.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './fontawesome';
import { GRAPHQL_URL } from './config';
import reducers from './data/redux/reducers/main-reducer';
import 'react-toastify/dist/ReactToastify.css';

import './assets/uxp-styles/main.scss';
import '../node_modules/react-grid-layout/css/styles.css';
import '../node_modules/react-resizable/css/styles.css';
import DatesHelper from './tools/DatesHelper';

const auth = new Auth();

const httpLink = createHttpLink({
  uri: GRAPHQL_URL,
});

const authLink = setContext(({ variables, }, { headers, }) => {
  const token = localStorage.getItem('access_token');
  DatesHelper.setDateToCorrectISOString(variables);
  return {
    headers: {
      ...headers,
      authorization: `Bearer ${token || ''}`,
    },
  };
});

const store = createStore(reducers);

const cache = new InMemoryCache({
  dataIdFromObject: (o: any) => (o._id ? `${o.__typename}:${o._id}` : null),
});

const client = new ApolloClient({
  cache,
  link: authLink.concat(httpLink),
});
cache.writeData({
  data: Defaults,
});

const handleAuthentication = async ({ location, history, }) => {
  if (/access_token|id_token|error/.test(location.hash)) {
    auth.handleAuthentication(history);
  }
};

const IndexContainer = () => {
  return (
    <Provider store={store}>
      <LocalizeProvider store={store}>
        <ApolloProvider client={client}>
          <BrowserRouter>
            <Index auth={auth}>
              <Switch>
                <Route path="/" exact render={props => <Home auth={auth} {...props} />} />
                <Route path="/animals" render={props => <Animals {...props} auth={auth} />} />
                <Route path="/movements" render={props => <Movements {...props} />} />
                <Route path="/groups" render={props => <Groups {...props} />} />
                <Route path="/events" component={Events} />
                <Route path="/reports" render={props => <Reports {...props} />} />
                <Route path="/settings" component={Settings} />
                <Route
                  path="/callback"
                  render={props => {
                    handleAuthentication(props);
                    return <Callback auth={auth} {...props} />;
                  }}
                />
              </Switch>
            </Index>
          </BrowserRouter>
        </ApolloProvider>
      </LocalizeProvider>
    </Provider>
  );
};

const rootElement = document.getElementById('root');
ReactDOM.render(<IndexContainer />, rootElement);
