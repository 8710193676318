/* eslint react/button-has-type:0 */
/* eslint no-prototype-builtins:0 */
/* eslint react/no-access-state-in-setstate:0 */
/* eslint react/destructuring-assignment:0 */
/* eslint react/no-unused-state:0 */
import * as React from 'react';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Row, Col } from 'reactstrap';
import { Input } from '@progress/kendo-react-inputs';
import Mutation from '../../../../../../data/mutation';
import { PREGNANCY_TYPES } from '../../../../../../tools/translation-constants';
import { ADD_ANIMAL_PREGNANCY, GET_ANIMAL_EVENTS } from '../../../../../../data/animal/animal-query';
import { GET_PREGNANCY_DATA } from '../../../../../../data/reports/reports-query';
import { DatePicker } from '../../../../../utils/date-picker';

class AddPregnancyModal extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      pregnancyObj: props,
      submitDisabled: true,
      tableType: 'pregnancy',
    };
    this.toggleDialog = this.toggleDialog.bind(this);
    this.handleFormChange = this.handleFormChange.bind(this);
  }

  toggleDialog() {
    this.setState({
      visible: !this.state.visible,
    });

    if (!this.state.visible) {
      this.resetState();
    }
  }

  handleFormChange(e) {
    const objCopy = { ...this.state.pregnancyObj, };

    if (e.target.name === 'pregnancyCheck') {
      objCopy[e.target.name] = e.target.value.id;
    } else {
      objCopy[e.target.name] = e.target.value;
    }

    const isFormSubmitable = !(Object.keys(objCopy).length > 1);
    this.setState({
      pregnancyObj: objCopy,
      submitDisabled: isFormSubmitable,
    });
  }

  handleSubmit(e, addAnimalPregnancyTrigger) {
    e.preventDefault();
    const stateCopy = { ...this.state.pregnancyObj, };
    let dataToSave;

    if (!stateCopy.hasOwnProperty('pregnancyDate')) {
      stateCopy.pregnancyDate = new Date();
      dataToSave = { ...stateCopy, };
    } else {
      dataToSave = { ...this.state.pregnancyObj, };
    }

    if (Object.keys(dataToSave).length > 1) {
      addAnimalPregnancyTrigger({
        variables:
                {
                  animalPregnancyAdd: dataToSave,
                },
      });
    }
  }

  resetState() {
    const { animalID, } = this.state.pregnancyObj;
    this.setState({
      pregnancyObj: {
        animalID,
      },
      submitDisabled: true,
    });
  }

  render() {
    return (
      <Mutation
        mutation={ADD_ANIMAL_PREGNANCY}
        awaitRefetchQueries
        refetchQueries={[{ query: GET_ANIMAL_EVENTS, variables: { id: this.state.pregnancyObj.animalID, }, }, { query: GET_PREGNANCY_DATA, }]}
        onCompleted={() => this.toggleDialog()}
      >
        {(addAnimalPregnancyTrigger) => (
          <div>
            <button className="btn btn-primary" onClick={this.toggleDialog}>ДОБАВИ СЪБИТИЕ</button>
            {this.state.visible && (
            <form onSubmit={e => this.handleSubmit(e, addAnimalPregnancyTrigger)}>
              <Dialog title="Добави събитие" onClose={this.toggleDialog} minWidth="500px" height="80%">
                <Row>
                  <Col lg={{ size: 8, offset: 2, }}>

                    <div className="form-group">
                      <label>Събитие</label>
                      <DropDownList
                        style={{ width: '100%', }}
                        data={PREGNANCY_TYPES}
                        dataItemKey="id"
                        textField="text"
                        name="pregnancyCheck"
                        required
                        onChange={this.handleFormChange}
                      />
                    </div>

                    <div className="form-group">
                      <label>Стадиий в дни</label>
                      <Input type="text" name="pregnancyDays" onChange={this.handleFormChange} />
                    </div>

                    <div className="form-group">
                      <label>Дата</label>
                      <DatePicker
                        value={this.state.pregnancyObj?.pregnancyDate}
                        name="pregnancyDate"
                        onChange={this.handleFormChange}
                        min={new Date(this.props.animaldateOfBirth)}
                        max={new Date()}
                      />
                    </div>

                    <div className="form-group">
                      <label>Коментар</label>
                      <textarea className="k-textarea" name="comment" onChange={this.handleFormChange} />
                    </div>
                  </Col>
                </Row>
                <DialogActionsBar>
                  <div className="d-flex">
                    <button className="btn btn-secondary" onClick={this.toggleDialog}>Отказ</button>
                    <button type="submit" disabled={this.state.submitDisabled} className="btn btn-primary ml-3">Добави</button>
                  </div>
                </DialogActionsBar>
              </Dialog>
            </form>
            )}
          </div>
        )}
      </Mutation>
    );
  }
}

export default AddPregnancyModal;
