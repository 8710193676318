import {
  HORN_STATUSES,
  BREEDING_STATUSES,
  WEIGHT_TYPES,
  TYPE_INPUTS, ANIMAL_OTS_TYPES,
  PREGNANCY_TYPES,
  ANIMAL_TUBERCULOSIS_BRUCELLOSIS_TYPES,
  STO_TYPES,
  SEXES,
  YES_NO,
  FATE_REASON,
  ANIMAL_WEANING_TYPES,
  ANIMAL_EVENTS_TYPES,
  ANIMAL_LOCATION_TYPES
} from '../../../tools/translation-constants';

const extractTranslation = (collection, enumValue, errorMessage) => {
  const translation = collection.find(o => o.id === enumValue);
  if (!translation) {
    throw new Error(errorMessage);
  }
  return translation.text;
};
export const mapBreedingStatus = ({ breedingStatus, }) => {
  return extractTranslation(
    BREEDING_STATUSES, breedingStatus,
    `Unsupported breeding status: ${breedingStatus}`
  );
};


export const mapWeightType = weightEnumVal => {
  return extractTranslation(
    WEIGHT_TYPES, weightEnumVal,
    `Unsupported weight type: ${weightEnumVal}`
  );
};

export const mapHornStatus = hornStatus => {
  return extractTranslation(
    HORN_STATUSES, hornStatus,
    `Unsupported horn status: ${hornStatus}`
  );
};

export const mapTypeInput = ({ typeInput, }) => {
  return extractTranslation(
    TYPE_INPUTS, typeInput,
    `Unsupported type input: ${typeInput}`
  );
};

export const mapAnimalTuberBrucelType = typeInput => {
  return extractTranslation(
    ANIMAL_TUBERCULOSIS_BRUCELLOSIS_TYPES, typeInput,
    `Unsupported OTS type: ${typeInput}`
  );
};

export const mapAnimalOTSType = typeInput => {
  return extractTranslation(
    ANIMAL_OTS_TYPES, typeInput,
    `Unsupported OTS type: ${typeInput}`
  );
};

export const mapPregnancyType = typeInput => {
  return extractTranslation(
    PREGNANCY_TYPES, typeInput,
    `Unsupported pregnancy type: ${typeInput}`
  );
};

export const mapSTOType = typeInput => {
  return extractTranslation(
    STO_TYPES, typeInput,
    `Unsupported STO type: ${typeInput}`
  );
};

export const mapSexType = typeInput => {
  return extractTranslation(
    SEXES, typeInput,
    `Unsupported sex type: ${typeInput}`
  );
};

export const mapYesNo = typeInput => {
  return extractTranslation(
    YES_NO, typeInput,
    `Unsupported type: ${typeInput}`
  );
};

export const mapAnimalFateReason = typeInput => {
  return extractTranslation(
    FATE_REASON, typeInput,
    `Unsupported fate reason type: ${typeInput}`
  );
};

export const mapAnimalWeaningSatus = typeInput => {
  return extractTranslation(
    ANIMAL_WEANING_TYPES, typeInput,
    `Unsupported weaning status type: ${typeInput}`
  );
};

export const mapAnimalEvent = typeInput => {
  return extractTranslation(
    ANIMAL_EVENTS_TYPES, typeInput,
    `Unsupported event type: ${typeInput}`
  );
};

export const mapAnimalLocationType = typeInput => {
  return extractTranslation(
    ANIMAL_LOCATION_TYPES, typeInput,
    `Unsupported location type: ${typeInput}`
  );
};
