/* eslint max-len:0 */
import * as React from 'react';
import AssistanceTable from './assistance-report-table';
import Query from '../../../../data/query';
import { GET_ANIMALS_ASSISTANCE_DATA } from '../../../../data/reports/reports-query';
import {
  animalSexTranslation, getTranslation, reportAnimalSummary, dateFormatForTableFilters
} from '../../../../tools/function-helpers';
import { ASSIS_YEAR } from '../../../../tools/translation-constants';

const AssistanceReport = props => {
  return (
    <Query query={GET_ANIMALS_ASSISTANCE_DATA}>
      {({ animalAssistance, }) => {
        const mappedAnimals = animalAssistance.items.map((animal) => {
          const sexTranslation = animalSexTranslation(animal.animals.sex);
          return {
            ...animal,
            animalSummary: reportAnimalSummary(animal.animals, sexTranslation),
            assisYearTr: getTranslation(animal.assisYear, ASSIS_YEAR),
            dateIn: dateFormatForTableFilters(animal.assistanceDateIn),
            dateOut: dateFormatForTableFilters(animal.assistanceDateTo),
          };
        });
        const assisYears = ASSIS_YEAR.map(bcs => bcs.text);
        return (
          <AssistanceTable {...props} animals={mappedAnimals} assisYears={assisYears} />
        );
      }}
    </Query>
  );
};

export default AssistanceReport;
