/* eslint max-len:0 */
/* eslint react/no-access-state-in-setstate:0 */
/* eslint react/destructuring-assignment:0 */
import * as React from 'react';
import { Row, Col } from 'reactstrap';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Input } from '@progress/kendo-react-inputs';
import { GROUP_TYPES } from '../../../../../tools/translation-constants';
import TabNavButtons from './tab-nav-buttons';
import { checkIsFormForGroupDataValid } from '../../../../../tools/function-helpers';
import { DatePicker } from '../../../../utils/date-picker';


class GroupData extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      groupData: props.getGroupData(),
      isFormValid: checkIsFormForGroupDataValid(props.getGroupData()),
    };
    this.handleFormChange = this.handleFormChange.bind(this);
  }

  handleFormChange(e) {
    const copyGroupData = { ...this.state.groupData, };
    if (e.target.name === 'groupTypeID') {
      copyGroupData[e.target.name] = e.target.value.id;
      copyGroupData.groupTypeTranslation = e.target.value;
    } else {
      copyGroupData[e.target.name] = e.target.value;
    }

    const isFormValid = checkIsFormForGroupDataValid(copyGroupData);

    this.setState({
      groupData: copyGroupData,
      isFormValid,
    });
    this.props.updateGroupData(copyGroupData);
  }

  render() {
    return (
      <div>
        <h3>Данни за групата</h3>
        <form>
          <Row>
            <Col lg="4">
              <Row>
                <Col lg="12">
                  <div className="form-group">
                    <label>Тип на групата</label>
                    <DropDownList
                      data={GROUP_TYPES}
                      dataItemKey="id"
                      textField="text"
                      name="groupTypeID"
                      onChange={this.handleFormChange}
                      value={this.state.groupData.groupTypeTranslation ? this.state.groupData.groupTypeTranslation : ''}
                    />
                  </div>

                </Col>
                <Col lg="12">
                  <div className="form-group">
                    <label>Име на групата</label>
                    <Input type="text" name="managementGroupsName" onChange={this.handleFormChange} value={this.state.groupData.managementGroupsName ? this.state.groupData.managementGroupsName : ''} />
                  </div>
                </Col>
                <Col lg="12">
                  <div className="form-group">
                    <label>Дата</label>
                    <DatePicker
                      value={this.state.groupData.groupDate ? this.state.groupData.groupDate : ''}
                      name="groupDate"
                      onChange={this.handleFormChange}
                    />
                  </div>
                </Col>
                <Col lg="12">
                  <div className="form-group">
                    <label>Кратко име на групата</label>
                    <Input type="text" name="managementGroupsShortName" onChange={this.handleFormChange} value={this.state.groupData.managementGroupsShortName ? this.state.groupData.managementGroupsShortName : ''} />
                  </div>
                </Col>
              </Row>
            </Col>
            <Col lg="5">
              <div className="form-group">
                <label>Коментар</label>
                <textarea className="k-textarea" name="managementGroupsComment" onChange={this.handleFormChange} value={this.state.groupData.managementGroupsComment ? this.state.groupData.managementGroupsComment : ''} rows={9} />
              </div>
            </Col>
          </Row>
        </form>
        <TabNavButtons parentProps={this.props} isFormValid={this.state.isFormValid} />
      </div>

    );
  }
}

export default GroupData;
