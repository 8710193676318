import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import DropAnimals from './drop-animals/drop-animals';
import ChangeLocationAnimals from './change-location-animals/change-location-animals';

const Movements = props => (
  <Switch>
    <Route path="/movements/animals-change-location" render={() => <ChangeLocationAnimals {...props} />} />
    <Route path="/movements/drop-animals" render={() => <DropAnimals {...props} />} />
  </Switch>
);

export default Movements;
