/* eslint max-len:0 */
/* eslint react/button-has-type:0 */
/* eslint jsx-a11y/control-has-associated-label:0 */
/* eslint react/no-access-state-in-setstate:0 */
/* eslint react/destructuring-assignment:0 */
/* eslint react/sort-comp:0 */
/* eslint import/order:0 */
import * as React from 'react';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Mutation from '../../../../../data/mutation';
import {
  DELETE_ANIMAL_IN_GROUP,
  DELETE_ANIMAL_IN_SERVICE,
  GET_ALL_ANIMAL_GROUPS, GET_ALL_SERVICE_GROUPS
} from '../../../../../data/groups/groups-query';
import '../../groups-service/add-service-group/add-group-service.css';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import DatesHelper from '../../../../../tools/DatesHelper';

class DeleteAnimalsInGroupModal extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      isService: props.isService,
      group: props.dataItem,
      reasonToDelete: {
        comment: '',
        dateOut: new Date(),
      },
      isValid: true,
    };
    this.toggleDialog = this.toggleDialog.bind(this);
    this.onReasonToChange = this.onReasonToChange.bind(this);
  }

  toggleDialog() {
    this.setState({
      visible: !this.state.visible,
      isValid: !this.state.visible,
    });
  }

    onReasonToChange = ({ target: { value, }, }, prop) => {
      const { reasonToDelete, } = this.state;

      reasonToDelete[prop] = value;

      this.setState({
        reasonToDelete: { ...reasonToDelete, },
      });
    };

    handleSubmit(e, mutationTrigger) {
      const { isService, group, reasonToDelete, } = this.state;

      e.preventDefault();
      if (!!reasonToDelete.comment || !!reasonToDelete.dateOut) {
        const data = !isService ? {
          managementGroupsID: this.props.managementGroupsID,
          animalID: this.props.dataItem.animalID,
        } : {
          animalInGroupID: group.animalInGroupID,
          animalID: group.animalID,
          comment: reasonToDelete.comment,
          dateIn: group.dateIn,
          dateOut: DatesHelper.dateToApiFormat(reasonToDelete.dateOut),
        };
        this.toggleDialog();
        mutationTrigger({
          variables: {
            dataItem: data,
          },
        });
      } else {
        this.setState({ isValid: false, });
      }
    }

    render() {
      const { isService, isValid, reasonToDelete: { comment, dateOut, }, } = this.state;
      return (
        <Mutation
          mutation={isService ? DELETE_ANIMAL_IN_SERVICE : DELETE_ANIMAL_IN_GROUP}
          awaitRefetchQueries
          refetchQueries={[{ query: isService ? GET_ALL_SERVICE_GROUPS : GET_ALL_ANIMAL_GROUPS, }]}
        >
          { mutationTrigger => (
            <div>
              <button className="button-icon" onClick={this.toggleDialog}><FontAwesomeIcon icon="trash" /></button>
              {this.state.visible && (
              <form onSubmit={e => this.handleSubmit(e, mutationTrigger)} className="health-form">
                <Dialog className="delete" title={`${isService ? 'Извади' : 'Изтрии'} животно`} onClose={this.toggleDialog} minWidth="500px" height={isService ? 465 : 180}>
                  <h4>Сигурни ли сте, че искате да изтриете животно?</h4>
                  { isService
                                    && (
                                    <>
                                      <div className="form-group mt-2">
                                        <label>Коментар</label>
                                        <textarea className="k-textarea" onChange={e => this.onReasonToChange(e, 'comment')} name="Note" value={comment} />
                                      </div>
                                      <div className="form-group mt-2">
                                        <label>Дата на излизане от сървис</label>
                                        <DatePicker format="dd.MM.yyyy" value={dateOut} onChange={e => this.onReasonToChange(e, 'dateOut')} />
                                      </div>
                                    </>
                                    )}
                  {
                                   !isValid && <div className="error"><span className="text-center"><p>Моля, попълнете причини за отпадането и дата на изваждане от сървис</p></span></div>
                                }
                  <DialogActionsBar>
                    <div className="d-flex">
                      <button className="btn btn-secondary" onClick={this.toggleDialog}>Отказ</button>
                      <button type="submit" className="btn btn-primary ml-3">{isService ? 'Премахни' : 'Изтрий'}</button>
                    </div>
                  </DialogActionsBar>
                </Dialog>
              </form>
              )}
            </div>
          )}
        </Mutation>
      );
    }
}

export default DeleteAnimalsInGroupModal;
