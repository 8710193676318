/* eslint react/button-has-type:0 */
/* eslint react/no-access-state-in-setstate:0 */
/* eslint react/destructuring-assignment:0 */
import * as React from 'react';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import Mutation from '../../../../../data/mutation';
import { ADD_ANIMALS_IN_GROUP, GET_ALL_ANIMAL_GROUPS } from '../../../../../data/groups/groups-query';
import ListBoxComponent from '../../../../list-box/list-box';
import ListBoxFiltersComponent from '../../../../list-box-filters/list-box-filters';
import { mapAnimalsForListBox } from '../../../../../tools/function-helpers';


class AddAnimalsInGroupModal extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      animalsToMap: mapAnimalsForListBox(props.animals),
      animalGroup: [],
    };
    this.toggleDialog = this.toggleDialog.bind(this);
    this.updateAnimalsToMap = this.updateAnimalsToMap.bind(this);
    this.updateGroup = this.updateGroup.bind(this);
  }

  toggleDialog() {
    this.setState({
      visible: !this.state.visible,
    });

    if (!this.state.visible) {
      this.resetState();
    }
  }

  handleSubmit(e, mutationTrigger) {
    e.preventDefault();
    const data = this.state.animalGroup.map((animal) => {
      return {
        managementGroupsID: this.props.managementGroupsID,
        animalID: animal.id,
      };
    });
    mutationTrigger({
      variables:
            {
              dataItem: data,
            },
    });
  }

  resetState() {
    this.setState({
      animalGroup: [],
    });
  }

  updateAnimalsToMap(animalsToMap) {
    this.setState({
      animalsToMap,
    });
  }

  updateGroup(updatedGroup) {
    this.setState({
      animalGroup: updatedGroup,
    });
  }

  render() {
    return (
      <Mutation
        mutation={ADD_ANIMALS_IN_GROUP}
        awaitRefetchQueries
        refetchQueries={[{ query: GET_ALL_ANIMAL_GROUPS, }]}
        onCompleted={() => this.toggleDialog()}
      >
        {(mutationTrigger) => (
          <div>
            <button className="btn btn-primary mb-3" onClick={this.toggleDialog}>Добави животно</button>
            {this.state.visible && (
            <form onSubmit={e => this.handleSubmit(e, mutationTrigger)} className="health-form">
              <Dialog title="Добави животно" onClose={this.toggleDialog} width="75%" height="70%">
                <ListBoxFiltersComponent
                  animals={this.props.animals}
                  aClasses={this.props.aClasses}
                  breeds={this.props.breeds}
                  colors={this.props.colors}
                  groups={this.props.groups}
                  updateAnimalsToMap={this.updateAnimalsToMap}
                  mapAnimals={mapAnimalsForListBox}
                  hideLocationSelect
                />

                <ListBoxComponent
                  items={this.state.animalsToMap}
                  listTitle="Изберете животни:"
                  groupTitle="Брой животни:"
                  getGroup={this.props.getGroup}
                  updateGroup={this.updateGroup}
                />

                <DialogActionsBar>
                  <div className="d-flex">
                    <button className="btn btn-secondary" onClick={this.toggleDialog}>Отказ</button>
                    <button type="submit" disabled={this.state.animalGroup.length === 0} className="btn btn-primary ml-3">Добави</button>
                  </div>
                </DialogActionsBar>
              </Dialog>
            </form>
            )}
          </div>
        )}
      </Mutation>
    );
  }
}

export default AddAnimalsInGroupModal;
