/* eslint react/no-array-index-key:0 */
/* eslint react/prop-types:0 */
import * as React from 'react';
import DatesHelper from '../../../../../../tools/DatesHelper';


const AITable = props => {
  const generateTableRows = () => {
    return props.aIEvent.map((ai, i) => {
      return (
        <tr key={i}>
          <td>{DatesHelper.parseAndFormatDate(ai.dateAI)}</td>
          <td>
            {ai.semen.animal[0].lID}
            -
            {ai.semen.animal[0].breed.breedShort }
            -
            {ai.semen.semenCode ? ai.semen.semenCode : '-'}
          </td>
          <td>
            {ai.vet ? ai.vet.farmClientName : '-' }
          </td>
          <td>
            {ai.commentAI }
          </td>
        </tr>
      );
    });
  };

  return (
    <table className="table">
      <thead>
        <tr>
          <th>Дата ИО</th>
          <th>Бик | Код</th>
          <th>Отговорник</th>
          <th>Коментар</th>
        </tr>
      </thead>
      <tbody>
        {generateTableRows()}
      </tbody>
    </table>
  );
};

export default AITable;
