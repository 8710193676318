/* eslint react/destructuring-assignment:0 */
import * as React from 'react';
import _ from 'lodash';
import ListBoxComponent from '../../../../list-box/list-box';
import ListBoxFiltersComponent from '../../../../list-box-filters/list-box-filters';
import TabNavButtons from './tab-nav-buttons';
import { mapAnimalsForListBox } from '../../../../../tools/function-helpers';

class RemoveAnimals extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      animals: props.animals,
      animalsToMap: mapAnimalsForListBox(props.animals),
    };
    this.updateAnimalsToMap = this.updateAnimalsToMap.bind(this);
  }

  updateAnimalsToMap(animalsToMap) {
    const { getGroup, } = this.props;
    const selectedAnimalIds = getGroup()?.map(a => a.id);
    this.setState({
      animalsToMap: animalsToMap.filter(a => !selectedAnimalIds.includes(a.id)),
    });
  }

  render() {
    return (
      <div>
        <h3>ИЗБОР НА ЖИВОТНИ</h3>
        <ListBoxFiltersComponent
          animals={this.state.animals}
          aClasses={this.props.aClasses}
          breeds={this.props.breeds}
          colors={this.props.colors}
          groups={this.props.groups}
          updateAnimalsToMap={this.updateAnimalsToMap}
          mapAnimals={mapAnimalsForListBox}
          hideGroupSelect
          hideLocationSelect
        />

        <ListBoxComponent
          items={this.state.animalsToMap}
          listTitle="Изберете животни:"
          groupTitle="Брой животни:"
          getGroup={this.props.getGroup}
          updateGroup={this.props.updateGroup}
        />
        <TabNavButtons parentProps={this.props} />
      </div>
    );
  }
}

export default RemoveAnimals;
