import { gql } from 'apollo-boost';

export const UPDATE_ANIMALS_DEFAULT = gql`
mutation UpdateListAnimal($dataItem: [animalInput]){
  updateListAnimal(animals:$dataItem){
   animalId
   active
   fateReason
  }
}
`;
