/* eslint max-len:0 */
/* eslint react/button-has-type:0 */
/* eslint jsx-a11y/control-has-associated-label:0 */
/* eslint react/no-access-state-in-setstate:0 */
/* eslint react/destructuring-assignment:0 */
import * as React from 'react';
import { Row, Col } from 'reactstrap';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Input } from '@progress/kendo-react-inputs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Mutation from '../../../../../data/mutation';
import { EDIT_NOTE, GET_ANIMAL_NOTES } from '../../../../../data/animal/animal-query';
import DatesHelper from '../../../../../tools/DatesHelper';
import { DatePicker } from '../../../../utils/date-picker';

class UpdateNoteModal extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = this.initialState();

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  openModal() {
    this.setState({
      visible: true,
    });
  }

  closeModal() {
    this.setState(this.initialState());
  }

  initialState() {
    return {
      visible: false,
      noteToUpdate: {
        animalID: this.props.animalId,
        noteID: this.props.currentNote.noteID,
        dateNote: DatesHelper.parseDate(this.props.currentNote.dateNote).toDate(),
        noteTopic: this.props.currentNote.noteTopic,
        note: this.props.currentNote.note,
      },
    };
  }

  handleChange(e) {
    const { value, } = e.target;
    const { name, } = e.target;
    const currentNoteToUpdate = { ...this.state.noteToUpdate, };
    currentNoteToUpdate[name] = value;
    this.setState({ noteToUpdate: currentNoteToUpdate, });
  }

  handleSubmit(e, updateNote) {
    e.preventDefault();
    updateNote({
      variables:
        { noteToUpdate: this.state.noteToUpdate, },
    });
  }

  submitDisabled() {
    return this.state.noteToUpdate.dateNote === null || this.state.noteToUpdate.noteTopic.length === 0
      || this.state.noteToUpdate.note.length === 0;
  }

  noteEditedResolver(cache, updateNoteAnimal) {
    const currentIdString = this.props.animalId;
    const animalResult = cache.readQuery({ query: GET_ANIMAL_NOTES, variables: { id: currentIdString, }, });
    const targetIndex = animalResult.animal.notes
      .findIndex(n => n.noteID === updateNoteAnimal.noteID);

    if (targetIndex >= 0) {
      animalResult.animal.notes[targetIndex] = updateNoteAnimal;
      cache.writeQuery({
        query: GET_ANIMAL_NOTES,
        variables: {
          id: currentIdString,
        },
        data: { animal: animalResult.animal, },
      });
    }
  }

  render() {
    return (
      <Mutation
        mutation={EDIT_NOTE}
        update={(cache, { data: { updateNoteAnimal, }, }) => this.noteEditedResolver(cache, updateNoteAnimal)}
        onCompleted={this.closeModal}
      >
        {(updateNoteTrigger) => (
          <div>
            <button className="button-icon" onClick={this.openModal}><FontAwesomeIcon icon="pen" /></button>
            {!this.state.visible ? ''
              : (
                <Dialog title="Редакция на бележка" minWidth="500px" onClose={this.closeModal}>
                  <form>
                    <Row>
                      <Col lg={{ size: 8, offset: 2, }}>
                        <div className="form-group">
                          <label>Дата</label>
                          <DatePicker
                            value={this.state.noteToUpdate.dateNote}
                            name="dateNote"
                            onChange={e => this.handleChange(e)}
                            min={new Date(this.props.animaldateOfBirth)}
                          />
                        </div>
                        <div className="form-group">
                          <label>Тема</label>
                          <Input type="text" name="noteTopic" onChange={e => this.handleChange(e)} value={this.state.noteToUpdate.noteTopic || ''} />
                        </div>
                        <div className="form-group">
                          <label>Бележка</label>
                          <textarea className="k-textarea" name="note" onChange={e => this.handleChange(e)} value={this.state.noteToUpdate.note} />
                        </div>
                      </Col>
                    </Row>
                  </form>
                  <DialogActionsBar>
                    <div className="d-flex">
                      <button type="button" className="btn btn-secondary" onClick={this.closeModal}>Отказ</button>
                      <button type="submit" onClick={e => this.handleSubmit(e, updateNoteTrigger)} disabled={this.submitDisabled()} className="btn btn-primary ml-3">Редактирай</button>
                    </div>
                  </DialogActionsBar>
                </Dialog>
              )}
          </div>
        )}
      </Mutation>
    );
  }
}

export default UpdateNoteModal;
