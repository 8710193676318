/* eslint consistent-return:0 */
/* eslint react/destructuring-assignment:0 */
/* eslint react/no-did-update-set-state:0 */
import * as React from 'react';
import { NavLink, Redirect } from 'react-router-dom';
import Mutation from '../../../../../data/mutation';
import { UPDATE_ANIMALS_DEFAULT } from '../../../../../data/drop-animals/drop-animals-queries';
import { ANIMALS_GRID } from '../../../../../data/animal/animal-query';

import { deepCopy } from '../../../../../tools/function-helpers';


class TabNavButtons extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      group: props.parentProps.getGroup ? props.parentProps.getGroup() : [],
      groupData: props.parentProps.getAnimalsData && props.parentProps.getAnimalsData() ? props.parentProps.getAnimalsData() : {},
      isDisabled: props.parentProps.getGroup ? !(props.parentProps.getGroup().length > 0) : true,
      isSubmited: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.renderRedirect = this.renderRedirect.bind(this);
  }


  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      this.setState({
        group: this.props.parentProps.getGroup ? this.props.parentProps.getGroup() : [],
        groupData: this.props.parentProps.getAnimalsData && this.props.parentProps.getAnimalsData() ? this.props.parentProps.getAnimalsData() : {},
        isDisabled: this.props.parentProps.getGroup ? !(this.props.parentProps.getGroup().length > 0) : true,
      });
    }
  }

  handleSubmit = (e, mutationTrigger) => {
    e.preventDefault();
    const groupData = deepCopy(this.state.groupData);
    const data = this.state.group.map((animal) => {
      return {
        animalId: animal.id,
        active: true,
        animalAI: [{
          animalID: animal.id,
          semenID: groupData.semen.semenID,
          commentAI: groupData.commentAI,
          farmClientID: groupData.farmClientID,
          dateAI: groupData.dateAI ? groupData.dateAI : new Date(),
        }],
      };
    });

    mutationTrigger({
      variables:
      {
        dataItem: data,
      },
    });

    this.setState({
      isSubmited: true,
    });
  }

    renderRedirect = () => {
      if (this.state.isSubmited) {
        return <Redirect to={{ pathname: '/animals/list', }} />;
      }
    }

    render() {
      const links = this.props.parentProps.tabsLinks;
      const index = links.findIndex((p) => p === this.props.parentProps.location.pathname);
      const next = index !== links.length - 1 ? links[index + 1] : links[links.length - 1];
      const prev = index !== 0 ? links[index - 1] : links[0];
      return (
        <Mutation
          mutation={UPDATE_ANIMALS_DEFAULT}
          awaitRefetchQueries
          refetchQueries={[{ query: ANIMALS_GRID, variables: {}, }]}
        >
          {(mutationTrigger) => (
            <div className="steps-action-btns d-flex justify-content-end mt-5 jus">
              {this.renderRedirect()}
              <div>
                {index > 0 && <NavLink exact className="btn btn-secondary mr-2" to={prev}>НАЗАД</NavLink>}
                {index < (links.length - 1) && <NavLink exact className="btn btn-primary" to={next}>НАПРЕД</NavLink>}
                {index === (links.length - 1) && (
                <button
                  disabled={this.state.isDisabled ? true : !this.props.isFormValid}
                  onClick={e => this.handleSubmit(e, mutationTrigger)}
                  type="submit"
                  className="btn btn-primary"
                >
                  ЗАПИШИ
                </button>
                )}
              </div>
            </div>
          )}
        </Mutation>
      );
    }
}

export default TabNavButtons;
