/* eslint react/button-has-type:0 */
/* eslint react/no-array-index-key:0 */
/* eslint max-len:0 */
/* eslint no-plusplus:0 */
/* eslint react/destructuring-assignment:0 */
/* eslint react/no-access-state-in-setstate:0 */
import * as React from 'react';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Row, Col } from 'reactstrap';
import { Input } from '@progress/kendo-react-inputs';
import Mutation from '../../../../../../data/mutation';
import {
  STO_TYPES, STO_TYPE_TYPES, STO_TYPE_TYPES_CONDITIONS, VETIS_PREFIX_TYPES, SEXES
} from '../../../../../../tools/translation-constants';
import {
  GET_ANIMAL_EVENTS, CREATE_BIRTH_EVENT, ANIMALS_GRID, GET_ANIMAL_DETAILS
} from '../../../../../../data/animal/animal-query';
import { GET_DEAD_BORN_ANIMALS } from '../../../../../../data/reports/reports-query';
import {
  deepCopy, generateCustomString, generateLIDString, selectedValue
} from '../../../../../../tools/function-helpers';
import CustomComboBox from '../../data/custom-combo-box';
import { DatePicker } from '../../../../../utils/date-picker';

const defaultData = {
  sTO: 'UNKNOWN',
  vetisPrefix: 'BG',
};

const defaultChildrenData = {
  active: true,
  discriminator: 'Animal',
  animalFunctionality: 'unknown',
  animalMarbling: 'unknown',
  animalMusculature: 'unknown',
  animalSkeleton: 'unknown',
  animalTemperament: 'unknown',
  breedingStatus: 'unknown',
  dNAType: 'unknown',
  dryWetStatus: 'unknown',
  fromEmbryoTransfer: false,
  registerSection: 'unknown',
  underSelection: false,
  forScrap: 'unknown',
  countryFrom: 'UNKNOWN',
  breedingFor: 'UNKNOWN',
  eIDType: 'UNKNOWN',
  hornStatusID: 'UNKNOWN',
  typeInput: 'SELF',
  breedsID: 30,
  colorID: 11,
  weaningStatus: 'sucking',
  registrationDate: new Date(),
};

const fieldsToResetTable = [
  'vetisPrefix', 'lID', 'vID', 'name', 'birthCount', 'sireID', 'classID', 'sex', 'dateBirth'
];

class AddBirthModal extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      dataItem: {
        animalID: props.animalID,
        ...defaultData,
      },
      bornАnimals: [],
      submitDisabled: true,
      generateTable: false,
      disabledIndexesDeadBorn: [],
    };
    this.toggleDialog = this.toggleDialog.bind(this);
    this.handleFormChange = this.handleFormChange.bind(this);
    this.generateAnimalsGroup = this.generateAnimalsGroup.bind(this);
    this.generateAnimalsTable = this.generateAnimalsTable.bind(this);
    this.isTableGenBtnDisabled = this.isTableGenBtnDisabled.bind(this);
    this.handleTableFormChange = this.handleTableFormChange.bind(this);
  }

  toggleDialog() {
    this.setState({
      visible: !this.state.visible,
    });

    if (!this.state.visible) {
      this.resetState();
    }
  }

  handleFormChange(e) {
    const objCopy = { ...this.state.dataItem, };
    const generateTable: any = { };
    if (e.target.name === 'placenta') {
      objCopy[e.target.name] = e.target.checked;
    } else if (typeof e.target.value === 'object' && e.target.name !== 'dateBirth') {
      if (e.target.name === 'sireID') {
        objCopy[e.target.name] = e.target.value ? e.target.value.animalId : null;
      } else if (e.target.name === 'classID') {
        objCopy[e.target.name] = e.target.value.classID;
      } else {
        objCopy[e.target.name] = e.target.value.id;
      }
    } else {
      objCopy[e.target.name] = e.target.value;
    }

    if (fieldsToResetTable.find(f => f === e.target.name)) {
      generateTable.generateTable = false;
    }

    this.setState({
      dataItem: objCopy,
      submitDisabled: !objCopy.birthCount,
      ...generateTable,
    });
  }

  handleTableFormChange(e) {
    const arrayCopy = deepCopy(this.state.bornАnimals);
    let copydisabledIndexesDeadBorn = deepCopy(this.state.disabledIndexesDeadBorn);
    let index;
    const generateTableObj: any = {};

    if (e.target.name === 'bornDead') {
      [, index] = e.target.id.split('-');
    } else {
      index = e.target.props['data-index'] === 0
        ? e.target.props['data-index']
        : e.target.props['data-index']
            || e.target.props.index
            || e.target.props.tabIndex;
    }

    if (e.target.name === 'sex') {
      arrayCopy[index][e.target.name] = e.target.value.id;
    } else if (e.target.name === 'colorID') {
      arrayCopy[index][e.target.name] = e.target.value.colorID;
    } else if (e.target.name === 'classID') {
      arrayCopy[index][e.target.name] = e.target.value.classID;
    } else if (e.target.name === 'breedsID') {
      arrayCopy[index][e.target.name] = e.target.value.breedsId;
    } else if (e.target.name === 'bornDead') {
      arrayCopy[index][e.target.name] = e.target.checked;
      if (e.target.checked) {
        arrayCopy[index].lID = '';
        arrayCopy[index].name = '';
        arrayCopy[index].vID = '';
        arrayCopy[index].active = false;
        copydisabledIndexesDeadBorn[index] = true;
      } else {
        copydisabledIndexesDeadBorn = [];
        arrayCopy[index].active = true;
        generateTableObj.generateTable = false;
      }
    } else {
      arrayCopy[index][e.target.name] = e.target.value;
    }

    this.setState({
      bornАnimals: arrayCopy,
      disabledIndexesDeadBorn: copydisabledIndexesDeadBorn,
      ...generateTableObj,
    });
  }

  handleSubmit(e, mutationTrigger) {
    e.preventDefault();
    const stateCopy = deepCopy(this.state.dataItem);
    stateCopy.dateBirth = stateCopy.dateBirth ? stateCopy.dateBirth : new Date();
    stateCopy.date = stateCopy.dateBirth ? stateCopy.dateBirth : new Date();
    if (!selectedValue(this.state.dataItem.sTO, STO_TYPE_TYPES_CONDITIONS, 'id')) {
      delete stateCopy.sTOTypeID;
    }
    delete stateCopy.vetisPrefix;
    delete stateCopy.lID;
    delete stateCopy.vID;
    delete stateCopy.name;
    delete stateCopy.sireID;
    delete stateCopy.sex;
    delete stateCopy.classID;

    const dataItem = {
      mother: {
        ...stateCopy,
      },
      children: [
        ...deepCopy(this.state.bornАnimals)
      ],
    };

    mutationTrigger({
      variables:
            {
              dataItem,
            },
    });
  }

  resetState() {
    const { animalID, } = this.state.dataItem;
    this.setState({
      dataItem: {
        animalID,
        ...defaultData,
      },
      bornАnimals: [],
      submitDisabled: true,
      generateTable: false,
    });
  }

  generateAnimalsGroup() {
    const bornAnimalsCopy = [];
    const len = this.state.dataItem.birthCount ? this.state.dataItem.birthCount : 0;

    for (let i = 0; i < len; i++) {
      const animalDamLocationLen = this.props.animalDamLocation.length;
      const animalLocation = animalDamLocationLen > 0 ? [{
        moveDate: this.state.dataItem.dateBirth ? this.state.dataItem.dateBirth : new Date(),
        locationId: this.props.animalDamLocation[0].locationId,
      }] : [];
      const currAnimal = {
        ...defaultChildrenData,
        ...deepCopy(this.state.dataItem),
        firmID: this.props.firmID,
        bornDead: false,
        animalLocation,
      };
      const nameLid = this.state.dataItem.lID ? generateLIDString(currAnimal, i, true) : '';
      currAnimal.name = `${this.state.dataItem.name ? this.state.dataItem.name : ''} ${this.state.dataItem.lID ? `${nameLid}`.slice(`${nameLid}`.length - 3) : ''}`.trim();
      currAnimal.vID = this.state.dataItem.vID ? generateCustomString(this.state.dataItem.vID, i) : '';
      currAnimal.lID = this.state.dataItem.lID ? generateLIDString(currAnimal, i) : '';
      currAnimal.damID = this.state.dataItem.animalID;
      currAnimal.dateOfBirth = this.state.dataItem.dateBirth ? this.state.dataItem.dateBirth : new Date();

      if (this.state.dataItem.sireID) {
        currAnimal.sireID = this.state.dataItem.sireID;
      }

      delete currAnimal.birthCount;
      delete currAnimal.dateBirth;
      delete currAnimal.sTO;
      delete currAnimal.sTOTypeID;
      delete currAnimal.placenta;
      delete currAnimal.comment;
      delete currAnimal.vetisPrefix;
      delete currAnimal.animalID;

      bornAnimalsCopy.push(currAnimal);
    }

    this.setState({
      bornАnimals: bornAnimalsCopy,
      generateTable: true,
      disabledIndexesDeadBorn: [],
    });
  }


  generateAnimalsTable() {
    return this.state.bornАnimals.map((animal, i) => {
      return (
        <tr key={i}>
          <td><Input disabled={this.state.disabledIndexesDeadBorn[i]} name="lID" data-index={i} onChange={this.handleTableFormChange} value={animal.lID ? animal.lID : ''} /></td>
          <td><Input disabled={this.state.disabledIndexesDeadBorn[i]} name="name" data-index={i} onChange={this.handleTableFormChange} value={animal.name ? animal.name : ''} /></td>
          <td><Input disabled={this.state.disabledIndexesDeadBorn[i]} name="vID" data-index={i} onChange={this.handleTableFormChange} value={animal.vID ? animal.vID : ''} /></td>
          <td>
            <DropDownList
              data={this.props.breeds}
              dataItemKey="breedsId"
              textField="breedName"
              name="breedsID"
              onChange={this.handleTableFormChange}
              value={animal.breedsID ? selectedValue(animal.breedsID, this.props.breeds, 'breedsId') : ''}
              tabIndex={i}
            />
          </td>
          <td>
            <DropDownList
              data={this.props.aClasses}
              dataItemKey="classID"
              textField="className"
              name="classID"
              onChange={this.handleTableFormChange}
              value={animal.classID ? selectedValue(animal.classID, this.props.aClasses, 'classID') : ''}
              tabIndex={i}
            />
          </td>
          <td>
            <DropDownList
              data={SEXES}
              dataItemKey="id"
              textField="text"
              name="sex"
              onChange={this.handleTableFormChange}
              value={animal.sex ? selectedValue(animal.sex, SEXES, 'id') : ''}
              tabIndex={i}
            />
          </td>
          <td>
            <DropDownList
              data={this.props.color}
              dataItemKey="colorID"
              textField="colorName"
              name="colorID"
              onChange={this.handleTableFormChange}
              value={animal.colorID ? selectedValue(animal.colorID, this.props.color, 'colorID') : ''}
              tabIndex={i}
            />
          </td>
          <td style={{ verticalAlign: 'middle', textAlign: 'center', }}>
            <div className="form-group m-0">
              <input id={`bornDead-${i}`} type="checkbox" className="k-checkbox" checked={animal.bornDead ? animal.bornDead : false} name="bornDead" onChange={this.handleTableFormChange} />
              <label className="k-checkbox-label" htmlFor={`bornDead-${i}`} />
            </div>
          </td>
        </tr>
      );
    });
  }

  isTableGenBtnDisabled() {
    return !(this.state.dataItem.birthCount && this.state.dataItem.lID && this.state.dataItem.vID && this.state.dataItem.name && this.state.dataItem.vetisPrefix && this.state.dataItem.classID && this.state.dataItem.sex);
  }

  isSubmitDisabled() {
    return !(this.state.generateTable && !this.state.submitDisabled);
  }

  render() {
    let REFETCH_DEAD_BORN_ANIMALS = [];
    if (this.state.disabledIndexesDeadBorn.find(value => value === true)) {
      REFETCH_DEAD_BORN_ANIMALS = [{
        query: GET_DEAD_BORN_ANIMALS,
      }];
    }
    return (
      <Mutation
        mutation={CREATE_BIRTH_EVENT}
        awaitRefetchQueries
        refetchQueries={[{ query: GET_ANIMAL_EVENTS, variables: { id: this.state.dataItem.animalID, }, }, { query: ANIMALS_GRID, }, { query: GET_ANIMAL_DETAILS, variables: { id: this.state.dataItem.animalID, }, }, ...REFETCH_DEAD_BORN_ANIMALS]}
        onCompleted={() => this.toggleDialog()}
      >
        {(mutationTrigger) => (
          <div>
            <button className="btn btn-primary" onClick={this.toggleDialog}>ДОБАВИ СЪБИТИЕ</button>
            {this.state.visible && (
            <form onSubmit={e => this.handleSubmit(e, mutationTrigger)}>
              <Dialog title="Добави събитие" onClose={this.toggleDialog} width="1000px" height="80%">
                <h4 className="mb-3">Данни отелване</h4>
                <Row>
                  <Col lg="8">
                    <Row>
                      <Col lg="6">
                        <div className="form-group">
                          <label>Брой телета</label>
                          <Input type="number" name="birthCount" onChange={this.handleFormChange} />
                        </div>
                      </Col>
                      <Col lg="6">
                        <div className="form-group">
                          <label>Избери баща</label>
                          <CustomComboBox
                            name="sireID"
                            onChange={this.handleFormChange}
                            data={this.props.sires}
                            textField="lID"
                            dataItemKey="animalId"
                          />
                        </div>
                      </Col>
                      <Col lg="6">
                        <div className="form-group">
                          <label>Дата</label>
                          <DatePicker
                            value={this.state.dataItem.dateBirth}
                            name="dateBirth"
                            onChange={this.handleFormChange}
                            min={new Date(this.props.animaldateOfBirth)}
                            max={new Date()}
                          />
                        </div>
                      </Col>
                      <Col lg="6">
                        <div className="form-group">
                          <label>СТО:</label>
                          <DropDownList
                            data={STO_TYPES}
                            dataItemKey="id"
                            textField="text"
                            name="sTO"
                            value={this.state.dataItem.sTO ? selectedValue(this.state.dataItem.sTO, STO_TYPES, 'id') : null}
                            onChange={this.handleFormChange}
                          />
                        </div>
                      </Col>
                      {selectedValue(this.state.dataItem.sTO, STO_TYPE_TYPES_CONDITIONS, 'id') && (
                      <Col lg="6">
                        {' '}
                        <div className="form-group">
                          <label>Причини за ТО</label>
                          <DropDownList
                            data={STO_TYPE_TYPES}
                            dataItemKey="id"
                            textField="text"
                            name="sTOTypeID"
                            onChange={this.handleFormChange}
                          />
                        </div>
                      </Col>
                      )}
                      <Col lg="12">
                        <div className="form-group">
                          <input id="placenta" type="checkbox" className="k-checkbox" checked={this.state.dataItem.placenta ? this.state.dataItem.placenta : false} name="placenta" onChange={this.handleFormChange} />
                          <label className="k-checkbox-label" htmlFor="placenta">Изхвърлена плацента</label>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  <Col lg="4">
                    <div className="form-group">
                      <label>Бележка</label>
                      <textarea className="k-textarea" rows={5} name="comment" onChange={this.handleFormChange} />
                    </div>
                  </Col>
                </Row>
                <h4 className="mb-3">Генератор имена и номера на животни</h4>
                <Row>
                  <Col lg="4">
                    <div className="form-group">
                      <label>ВЕТИС №</label>
                      <Row>
                        <Col lg="4">
                          <div>
                            <DropDownList
                              data={VETIS_PREFIX_TYPES}
                              dataItemKey="id"
                              textField="text"
                              name="vetisPrefix"
                              onChange={this.handleFormChange}
                              value={this.state.dataItem.vetisPrefix ? selectedValue(this.state.dataItem.vetisPrefix, VETIS_PREFIX_TYPES, 'id') : null}
                            />
                          </div>
                        </Col>
                        <Col lg="8">
                          <div>
                            <Input name="lID" onChange={this.handleFormChange} />
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                  <Col lg="4">
                    <div className="form-group">
                      <label>ВИН №</label>
                      <Input name="vID" onChange={this.handleFormChange} />
                    </div>
                  </Col>
                  <Col lg="4">
                    <div className="form-group">
                      <label>Име</label>
                      <Input name="name" onChange={this.handleFormChange} />
                    </div>
                  </Col>
                  <Col lg="4">
                    <div className="form-group">
                      <label>Категория</label>
                      <DropDownList
                        data={this.props.aClasses}
                        dataItemKey="classID"
                        textField="className"
                        name="classID"
                        onChange={this.handleFormChange}
                      />
                    </div>
                  </Col>
                  <Col lg="4">
                    <label>Пол</label>
                    <DropDownList
                      data={SEXES}
                      dataItemKey="id"
                      textField="text"
                      name="sex"
                      onChange={this.handleFormChange}
                    />
                  </Col>
                </Row>
                <div className="mt-3">
                  <button
                    type="button"
                    disabled={this.isTableGenBtnDisabled()}
                    onClick={this.generateAnimalsGroup}
                    className="btn btn-primary mb-3"
                  >
                    Генерирай Животни
                  </button>
                  {this.state.generateTable && (
                  <table className="table">
                    <thead>
                      <tr>
                        <th>LID</th>
                        <th>Име</th>
                        <th>VID</th>
                        <th>Порода</th>
                        <th>Категория</th>
                        <th>Пол</th>
                        <th>Цвят</th>
                        <th>Мъртво родено</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.generateTable && this.generateAnimalsTable()}
                    </tbody>
                  </table>
                  )}
                </div>
                <DialogActionsBar>
                  <div className="d-flex">
                    <button className="btn btn-secondary" onClick={this.toggleDialog}>Отказ</button>
                    <button type="submit" disabled={this.isSubmitDisabled()} className="btn btn-primary ml-3">Добави</button>
                  </div>
                </DialogActionsBar>
              </Dialog>
            </form>
            )}
          </div>
        )}
      </Mutation>
    );
  }
}

export default AddBirthModal;
