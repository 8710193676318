import { gql } from 'apollo-boost';


export const GET_LOCATIONS_BY_TYPE = (type) => gql(`
{
  locations(locationType:${type})
  {
    items
    {
      locationID
      locationName
      locationType
      locationSpace
      locationSpaceHec
      groundType
      comment
      longitude
      latitude
      animalObject
      {
        animalObjectID
        animalObjectNumber
      }
      children
      {
        locationID
        locationName
        parentId
        locationSpace
        locationSpaceHec
        comment
        locationType
         children
      {
        locationID
        locationName
        parentId
        locationSpace
        locationSpaceHec
        comment
        locationType
      }
      }
    }
  }
}
`);


export const GET_ANIMAL_OBJECTS = gql`
{
  animalsObjects{
    items{
      animalObjectNumber
      animalObjectID
      parentFirm
      {
        firmName
      }
    }
  }
}
`;

export const ADD_LOCATION = gql`
  mutation CreateLocation($dataItem: locationInput) {
    createLocation(location: $dataItem){
      locationID
    }
  }
`;


export const EDIT_LOCATION = gql`
  mutation EditLocation($dataItem: locationInput) {
    editLocation(location: $dataItem){
      locationID
    }
  }
`;
