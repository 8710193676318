/* eslint no-use-before-define:0 */
/* eslint no-param-reassign:0 */
/* eslint no-shadow:0 */
/* eslint react/destructuring-assignment:0 */
/* eslint no-unused-vars:0 */
import * as React from 'react';
import {
  groupBy, uniqBy, partition, sumBy
} from 'lodash';
import DashboardCard from '../../../lib/dashboard-card/dashboard-card';
import { deepCopy } from '../../../../tools/function-helpers';
import { Animal, Child } from '../../../../data/domains/Animal';

type CowHeiferAggregateProps = {
    animals: Animal[]
}

const CowHeiferAggregate = (props: CowHeiferAggregateProps) => {
  const groupedAnimals = groupBy(props.animals, (a: Animal) => a.aClass.classID);
  const cowsHeifer: Animal[] = [...(groupedAnimals['5'] || []), ...(groupedAnimals['4'] || [])]; // Cows and Heifers

  const bornThisYear = (child: Child) => {
    const yearOfBornChild = new Date(child?.dateOfBirth).getFullYear();
    const thisYear = new Date().getFullYear();
    return yearOfBornChild === thisYear;
  };
  const calving = cowsHeifer.filter(animal => animal.children.filter(bornThisYear).length > 0);

  const partitioned = partition(deepCopy(calving), a => {
    const born = a.children.filter(bornThisYear);
    const calvingAnimals = uniqBy(born, (a: Child) => a.dateOfBirth);
    a.children = calvingAnimals;
    return a.aClass.classID === 5;
  });

  const [cows, heifers] = partitioned;

    type BreadingStatusType = {
        PREGNANT: string,
        IN_SERVICE: string,
        UNKNOWN: string
    };

    const BREADING_STATUS: BreadingStatusType = {
      IN_SERVICE: 'IN_SERVICE',
      PREGNANT: 'PREGNANT',
      UNKNOWN: 'UNKNOWN',
    };

    const grouped = groupBy(cowsHeifer, 'breedingStatus');
    const groupedPregnant = groupBy(grouped[BREADING_STATUS.PREGNANT], a => a.aClass.classID);
    const groupedInService = groupBy(grouped[BREADING_STATUS.IN_SERVICE], a => a.aClass.classID);
    const groupedUnknown = groupBy(grouped[BREADING_STATUS.UNKNOWN], a => a.aClass.classID);

    return (
      <DashboardCard className="mb-5">

        <Card
          style={style}
          label="Отелени"
          textTop={`${sumBy(cows, a => a.children.length) + sumBy(heifers, a => a.children.length)}`}
          textBottom={`${
            cows.length} Крави / ${
            heifers.length} Юници
            `}
        />
        <Card
          style={style}
          label="Бременни"
          textTop={grouped[BREADING_STATUS.PREGNANT]?.length}
          textCenter={`(${(
            ((grouped[BREADING_STATUS.PREGNANT] || []).length / cowsHeifer.length) * 100).toFixed(1)}
                %)`}
          textBottom={`${
            (groupedPregnant['5'] || []).length} Крави / ${
            (groupedPregnant['4'] || []).length} Юници
            `}
        />
        <Card
          style={style}
          label="В сървис"
          textTop={grouped[BREADING_STATUS.IN_SERVICE]?.length}
          textCenter={`(${(
            ((grouped[BREADING_STATUS.IN_SERVICE] || []).length / cowsHeifer.length) * 100).toFixed(1)}
                %)`}
          textBottom={`${
            (groupedInService['5'] || []).length} Крави / ${
            (groupedInService['4'] || []).length} Юници
            `}
        />
        <Card
          label="Осеменени"
          textTop="30%"
          style={style}
        />
        <Card
          style={style}
          label="Без статус"
          textTop={grouped[BREADING_STATUS.UNKNOWN]?.length}
          textCenter={`(${(
            ((grouped[BREADING_STATUS.UNKNOWN] || []).length / cowsHeifer.length) * 100).toFixed(1)}
                %)`}
          textBottom={`${
            (groupedUnknown['5'] || []).length} Крави / ${
            (groupedUnknown['4'] || []).length} Юници
            `}
        />
      </DashboardCard>
    );
};

export const Card = props => (
  <div className="card" style={props?.style?.container}>
    <div className="card-body" style={props?.style}>
      <h5 className="mb-3 text-center" style={props?.style?.text}>{props?.label}</h5>
      <h2 style={props?.style?.text} className="text-center">
        {props?.textTop}
        <span className="small ml-1">{props?.textCenter}</span>
      </h2>
      <h5 style={props?.style?.text} className="text-center">{props?.textBottom}</h5>
    </div>
  </div>
);

const style = {
  text: {
    color: '#fff',
  },
  container: {
    backgroundColor: '#3743ff',
    margin: '5px',
    borderRadius: '4.5px',
  },
};
export default CowHeiferAggregate;
