import { ANIMAL_GET } from '../../types/animal-types';

export function animal(state = { }, { payload, type, }) {
  switch (type) {
    case ANIMAL_GET:
      return { ...payload, };
    default:
      return state;
  }
}
