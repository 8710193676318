/* eslint react/prefer-stateless-function:0 */
import * as React from 'react';

export default class Footer extends React.Component {
    render = () => {
      return (
        <footer>
          <div className="container text-center">
            <p>
              &copy;
              {new Date().getFullYear()}
              {' '}
              - Network Agro Solutions
            </p>
          </div>
        </footer>
      );
    }
}
