/* eslint max-len:0 */
/* eslint consistent-return:0 */
/* eslint no-prototype-builtins:0 */
/* eslint react/destructuring-assignment:0 */
/* eslint react/no-did-update-set-state:0 */
import * as React from 'react';
import { NavLink, Redirect } from 'react-router-dom';
import Mutation from '../../../../../data/mutation';
import { UPDATE_ANIMALS_DEFAULT } from '../../../../../data/drop-animals/drop-animals-queries';
import { ANIMALS_GRID } from '../../../../../data/animal/animal-query';
import { GET_DROPPED_ANIMALS_DATA } from '../../../../../data/reports/reports-query';

import { deepCopy } from '../../../../../tools/function-helpers';


class TabNavButtons extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      group: props.parentProps.getGroup ? props.parentProps.getGroup() : [],
      groupData: props.dropAnimalsData ? props.dropAnimalsData : {},
      isDisabled: props.parentProps.getGroup ? !(props.parentProps.getGroup().length > 0) : true,
      isSubmited: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.renderRedirect = this.renderRedirect.bind(this);
  }


  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      this.setState({
        group: this.props.parentProps.getGroup ? this.props.parentProps.getGroup() : [],
        groupData: this.props.dropAnimalsData ? this.props.dropAnimalsData : {},
        isDisabled: this.props.parentProps.getGroup ? !(this.props.parentProps.getGroup().length > 0) : true,
      });
    }
  }

  handleSubmit = (e, mutationTrigger) => {
    e.preventDefault();

    const groupData = deepCopy(this.state.groupData);
    if (!groupData.hasOwnProperty('fateDate')) {
      groupData.fateDate = new Date();
    }

    const data = this.state.group.map((animal) => {
      const defaultData = {
        animalId: animal.id,
        active: false,
        fateDate: groupData.fateDate,
        fateReason: groupData.fateReason,
        fateDoc: groupData.fateDoc,
        ...(groupData.fateCode ? { fateCode: groupData.fateCode, } : {}),
        ...(groupData.fateDoc ? { fateDoc: groupData.fateDoc, } : {}),

      };

      if (groupData.fateReason === 'DIE') {
        return {
          ...defaultData,
          animalDie: {
            animalID: defaultData.animalId,
            ...(groupData.farmClientID ? { farmClientID: groupData.farmClientID, } : {}),
            ...(groupData.fateCode ? { animalDieComent: groupData.fateCode, } : {}),
            ...(groupData.animalDieTypeID ? { animalDieTypeID: groupData.animalDieTypeID, } : {}),
            ...(groupData.animalDieDiagnosticID ? { animalDieDiagnosticID: groupData.animalDieDiagnosticID, } : {}),
            ...(groupData.skeletonDoc ? { skeletonDoc: groupData.skeletonDoc, } : {}),
            ...(groupData.animalDieDriver ? { animalDieDriver: groupData.animalDieDriver, } : {}),
            ...(groupData.animalDiePriceDiagnostic ? { animalDiePriceDiagnostic: groupData.animalDiePriceDiagnostic, } : {}),

          },
        };
      } if (groupData.fateReason === 'SALE') {
        const animalSellData = deepCopy(groupData);
        delete animalSellData.animalId;
        delete animalSellData.active;
        delete animalSellData.fateDate;
        delete animalSellData.fateReason;
        delete animalSellData.fateCode;
        return {
          ...defaultData,
          animalSell: {
            animalID: defaultData.animalId,
            ...(groupData.fateCode ? { animalSellComent: groupData.fateCode, } : {}),
            ...(groupData.fateDoc ? { fateDoc: groupData.fateDoc, } : {}),
            ...animalSellData,
          },
        };
      }
      return {
        ...defaultData,
      };
    });

    mutationTrigger({
      variables:
      {
        dataItem: data,
      },
    });

    this.setState({
      isSubmited: true,
    });
  }

    renderRedirect = () => {
      if (this.state.isSubmited) {
        return <Redirect to={{ pathname: '/animals/list', }} />;
      }
    }

    render() {
      const links = this.props.parentProps.tabsLinks;
      const index = links.findIndex((p) => p === this.props.parentProps.location.pathname);
      const next = index !== links.length - 1 ? links[index + 1] : links[links.length - 1];
      const prev = index !== 0 ? links[index - 1] : links[0];
      return (
        <Mutation
          mutation={UPDATE_ANIMALS_DEFAULT}
          awaitRefetchQueries
          refetchQueries={[{ query: ANIMALS_GRID, variables: {}, }, { query: GET_DROPPED_ANIMALS_DATA, }]}
        >
          {(mutationTrigger) => (
            <div className="steps-action-btns d-flex justify-content-end mt-5 jus">
              {this.renderRedirect()}
              <div>
                {index > 0 && <NavLink exact className="btn btn-secondary mr-2" to={prev}>НАЗАД</NavLink>}
                {index < (links.length - 1) && <NavLink exact className="btn btn-primary" to={next}>НАПРЕД</NavLink>}
                {index === (links.length - 1) && <button disabled={this.state.isDisabled ? true : !this.props.isFormValid} onClick={e => this.handleSubmit(e, mutationTrigger)} type="submit" className="btn btn-primary">ЗАПИШИ</button>}
              </div>
            </div>
          )}
        </Mutation>
      );
    }
}

export default TabNavButtons;
