/* eslint no-param-reassign:0 */
/* eslint react/prop-types:0 */
import * as React from 'react';
import { adopt } from 'react-adopt';
import Query from '../../../data/query';
import { ANIMALS_GRID, GET_ANIMAL_LOCATIONS } from '../../../data/animal/animal-query';
import { TABLE_FILTERS_ENUMS } from '../../../data/enums/enums-query';
import AnimalsList from './animals-list';
import { generateLocationString, getAnimalAgeInMonths } from './animal-utilities';
import { animalSexTranslation, dateFormatForTableFilters, selectedValue } from '../../../tools/function-helpers';
import {
  SEXES, BREEDING_FOR, ANIMAL_FOR_SCRAP, BOOLEANS
} from '../../../tools/translation-constants';

const Composed = adopt({
  firstQ: ({ render, }) => <Query query={TABLE_FILTERS_ENUMS}>{ render }</Query>,
  secondQ: ({ render, }) => <Query query={ANIMALS_GRID}>{ render }</Query>,
  thirdQ: ({ render, }) => <Query query={GET_ANIMAL_LOCATIONS}>{ render }</Query>,
});

export default class AnimalsListWrapper extends React.Component<any, any> {
  setCustomCells = animals => {
    return animals.map(animal => {
      animal.location = generateLocationString({ animalLocation: animal.animalLocation.reverse(), });
      animal.sexTranslation = animalSexTranslation(animal.sex);
      animal.ageInMonths = animal.dateOfBirth ? getAnimalAgeInMonths(animal.dateOfBirth) : null;
      animal.dateOfBirthFormat = animal.dateOfBirth ? dateFormatForTableFilters(animal.dateOfBirth) : null;
      animal.breedingForTranslation = selectedValue(animal.breedingFor, BREEDING_FOR, 'id').text;
      animal.forScrapTranslation = animal.forScrap ? selectedValue(animal.forScrap, ANIMAL_FOR_SCRAP, 'id').text : '';
      animal.underSelectionTranslation = selectedValue(animal.underSelection, BOOLEANS, 'id').text;
      animal.registrationDateFormat = animal.registrationDate ? dateFormatForTableFilters(animal.registrationDate) : null;

      return animal;
    });
  };

  render() {
    return (
      <>
        <Composed>
          {({ firstQ, secondQ, thirdQ, }) => {
            const { breeds, aClasses, color, } = firstQ;
            const { animals, } = secondQ;
            const { locations, } = thirdQ;

            animals.items = this.setCustomCells(animals.items);

            return (
              <AnimalsList
                {...this.props}
                animals={animals}
                breeds={breeds.items.map((breed) => breed.breedName)}
                aClasses={aClasses.items.map((aClass) => aClass.className)}
                sexes={SEXES.map(sex => sex.text)}
                breedingFor={BREEDING_FOR.map(bf => bf.text)}
                forScrap={ANIMAL_FOR_SCRAP.map(sc => sc.text)}
                locations={locations.items}
                underSelection={BOOLEANS.map(us => us.text)}
                colors={color.items.map((colors) => colors.colorName)}
              />
            );
          }}
        </Composed>
      </>
    );
  }
}
