/* eslint max-len:0 */
/* eslint no-prototype-builtins:0 */
/* eslint react/destructuring-assignment:0 */
/* eslint react/no-access-state-in-setstate:0 */
import * as React from 'react';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Row, Col } from 'reactstrap';
import { Input } from '@progress/kendo-react-inputs';
import {
  FATE_REASON, DROP_DIRECTION_TYPES, DIE_DIAGNOSTIC_TYPE, ANIMAL_DIE_TYPE
} from '../../../../../tools/translation-constants';
import TabNavButtons from './tab-nav-buttons';
import FarmClientDropdown from '../../../animals/tabs/health/farm-client-dropdown';
import { selectedValue } from '../../../../../tools/function-helpers';
import { DatePicker } from '../../../../utils/date-picker';

const checkDropReason = (value, reason) => {
  return value === reason;
};

class DropReason extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      dropAnimalsData: {
        ...props.getDropAnimalsData(),
        animalSellPriceOther: parseFloat(props.getDropAnimalsData().animalSellPricePerAnimal) * props.getGroup().length,
      },
      dropReasonSell: props.getDropAnimalsData().fateReason ? checkDropReason(props.getDropAnimalsData().fateReason, 'SALE') : false,
      dropReasonDead: props.getDropAnimalsData().fateReason ? checkDropReason(props.getDropAnimalsData().fateReason, 'DIE') : false,
      lastDropReason: props.getDropAnimalsData().fateReason ? props.getDropAnimalsData().fateReason : '',
    };
    this.handleFormChange = this.handleFormChange.bind(this);
  }

  handleFormChange(event) {
    let isReasonSell = this.state.dropReasonSell;
    let isReasonDead = this.state.dropReasonDead;
    let { lastDropReason, } = this.state;
    let objCopy = { ...this.state.dropAnimalsData, };

    if (event.target.name === 'fateReason' && lastDropReason !== event.target.value.id) {
      objCopy = {};
      lastDropReason = event.target.value.id;
    }

    if (event.target.name === 'fateReason') {
      isReasonSell = checkDropReason(event.target.value.id, 'SALE');
      isReasonDead = checkDropReason(event.target.value.id, 'DIE');
      objCopy[event.target.name] = event.target.value.id;
    } else if (typeof event.target.value === 'object' && event.target.value.hasOwnProperty('id') && event.target.value.hasOwnProperty('text')) {
      objCopy[event.target.name] = event.target.value.id;
    } else if (typeof event.target.value === 'object' && event.target.value.hasOwnProperty('farmClientID')) {
      objCopy[event.target.name] = event.target.value.farmClientID;
    } else if (event.target.name === 'animalSellPricePerAnimal') {
      objCopy.animalSellPriceOther = parseFloat(event.target.value) * this.props.getGroup().length;
      objCopy[event.target.name] = event.target.value;
    } else {
      objCopy[event.target.name] = event.target.value;
    }

    this.setState({
      dropReasonSell: isReasonSell,
      dropReasonDead: isReasonDead,
      dropAnimalsData: objCopy,
      lastDropReason,
    });
    this.props.updateDropAnimalsData(objCopy);
  }

  render() {
    return (
      <div>
        <h3 className="mb-2">ПРИЧИНА ЗА ОТПАДАНЕ</h3>
        <form>
          <Row className="mb-2">
            <Col lg="3">
              <div className="form-group">
                <label>Изберете причина за отпадане</label>
                <DropDownList
                  data={FATE_REASON}
                  dataItemKey="id"
                  textField="text"
                  name="fateReason"
                  onChange={this.handleFormChange}
                  required
                  value={this.state.dropAnimalsData.fateReason ? selectedValue(this.state.dropAnimalsData.fateReason, FATE_REASON, 'id') : ''}
                />
              </div>
            </Col>
          </Row>
          {this.state.dropReasonSell && (
          <div>
            <h4>Данни отпадане:</h4>
            <Row className="mb-2">
              <Col lg="3">
                <div className="form-group">
                  <label>Клиент</label>
                  <FarmClientDropdown
                    tableType="dropAnimals"
                    handleFormChange={this.handleFormChange}
                    farmClientID={this.state.dropAnimalsData.farmClientID ? this.state.dropAnimalsData.farmClientID : ''}
                  />
                </div>
              </Col>
              <Col lg="3">
                <div className="form-group">
                  <label>Направление</label>
                  <DropDownList
                    data={DROP_DIRECTION_TYPES}
                    dataItemKey="id"
                    textField="text"
                    name="animalSellDirection"
                    onChange={this.handleFormChange}
                    required
                    value={this.state.dropAnimalsData.animalSellDirection ? selectedValue(this.state.dropAnimalsData.animalSellDirection, DROP_DIRECTION_TYPES, 'id') : ''}
                  />
                </div>
              </Col>
            </Row>
            <h4>Транспорт:</h4>
            <Row className="mb-2">
              <Col lg="3" className="d-flex flex-column justify-content-end">
                <div className="form-group">
                  <label>Шофьор</label>
                  <FarmClientDropdown
                    tableType="transClients"
                    name="driverID"
                    handleFormChange={this.handleFormChange}
                    farmClientID={this.state.dropAnimalsData.driverID ? this.state.dropAnimalsData.driverID : ''}
                  />
                </div>
              </Col>
              <Col lg="3" className="d-flex flex-column justify-content-end">
                <div className="form-group">
                  <label>Номер транспортно средство</label>
                  <Input
                    name="animalSellCarPlate"
                    onChange={this.handleFormChange}
                    value={this.state.dropAnimalsData.animalSellCarPlate ? this.state.dropAnimalsData.animalSellCarPlate : ''}
                  />
                </div>
              </Col>
              <Col lg="3" className="d-flex flex-column justify-content-end">
                <div className="form-group">
                  <label>Пътен лист ВЕТИС</label>
                  <Input
                    name="animalSellBABHTrasportDoc"
                    onChange={this.handleFormChange}
                    value={this.state.dropAnimalsData.animalSellBABHTrasportDoc ? this.state.dropAnimalsData.animalSellBABHTrasportDoc : ''}
                  />
                </div>
              </Col>
            </Row>
            <h4>Икономика:</h4>
            <Row className="mb-2">
              <Col lg="3">
                <div className="form-group">
                  <label>Бруто тегло във фермата</label>
                  <Input
                    type="number"
                    name="animalSellKgInFarm"
                    onChange={this.handleFormChange}
                    value={this.state.dropAnimalsData.animalSellKgInFarm ? this.state.dropAnimalsData.animalSellKgInFarm : ''}
                  />
                </div>
              </Col>
              <Col lg="3">
                <div className="form-group">
                  <label>Бруто тегло при клиента</label>
                  <Input
                    type="number"
                    name="animalSellKgInClient"
                    onChange={this.handleFormChange}
                    value={this.state.dropAnimalsData.animalSellKgInClient ? this.state.dropAnimalsData.animalSellKgInClient : ''}
                  />
                </div>
              </Col>
              <div className="w-100" />
              <Col lg="3" className="d-flex flex-column justify-content-end">
                <div className="form-group">
                  <label>Цена кг/жт</label>
                  <Input
                    type="number"
                    name="animalSellPricePerKG"
                    onChange={this.handleFormChange}
                    value={this.state.dropAnimalsData.animalSellPricePerKG ? this.state.dropAnimalsData.animalSellPricePerKG : ''}
                  />
                </div>
              </Col>
              <Col lg="3" className="d-flex flex-column justify-content-end">
                <div className="form-group">
                  <label>Цена на глава</label>
                  <Input
                    type="number"
                    name="animalSellPricePerAnimal"
                    onChange={this.handleFormChange}
                    value={this.state.dropAnimalsData.animalSellPricePerAnimal ? this.state.dropAnimalsData.animalSellPricePerAnimal : ''}
                  />
                </div>
              </Col>
              <Col lg="3" className="d-flex flex-column justify-content-end">
                <div className="form-group">
                  <label>Стойност на покупката</label>
                  <Input
                    readOnly
                    name="animalSellPriceOther"
                    onChange={this.handleFormChange}
                    value={this.state.dropAnimalsData.animalSellPriceOther ? this.state.dropAnimalsData.animalSellPriceOther : ''}
                  />
                </div>
              </Col>
              <Col lg="3" className="d-flex flex-column justify-content-end">
                <div className="form-group">
                  <label>Други разходи(пътни листи, комисионни)</label>
                  <Input
                    type="number"
                    name="animalSellTransportPrice"
                    onChange={this.handleFormChange}
                    value={this.state.dropAnimalsData.animalSellTransportPrice ? this.state.dropAnimalsData.animalSellTransportPrice : ''}
                  />
                </div>
              </Col>
            </Row>
          </div>
          )}

          {this.state.dropReasonDead && (
          <Row>
            <Col lg="3">
              <div className="form-group">
                <label>Причина</label>
                <DropDownList
                  data={ANIMAL_DIE_TYPE}
                  dataItemKey="id"
                  textField="text"
                  name="animalDieTypeID"
                  onChange={this.handleFormChange}
                  required
                  value={this.state.dropAnimalsData.animalDieTypeID ? selectedValue(this.state.dropAnimalsData.animalDieTypeID, ANIMAL_DIE_TYPE, 'id') : ''}
                />
              </div>
              <div className="form-group">
                <label>Екарисажна Бележка</label>
                <Input
                  name="skeletonDoc"
                  onChange={this.handleFormChange}
                  value={this.state.dropAnimalsData.skeletonDoc ? this.state.dropAnimalsData.skeletonDoc : ''}
                />
              </div>
            </Col>
            <Col lg="3">
              <div className="form-group">
                <label>Диагноза</label>
                <DropDownList
                  data={DIE_DIAGNOSTIC_TYPE}
                  dataItemKey="id"
                  textField="text"
                  name="animalDieDiagnosticID"
                  onChange={this.handleFormChange}
                  required
                  value={this.state.dropAnimalsData.animalDieDiagnosticID ? selectedValue(this.state.dropAnimalsData.animalDieDiagnosticID, DIE_DIAGNOSTIC_TYPE, 'id') : ''}
                />
              </div>
              <div className="form-group">
                <label>Номер на транспортно средство</label>
                <Input
                  name="animalDieDriver"
                  onChange={this.handleFormChange}
                  value={this.state.dropAnimalsData.animalDieDriver ? this.state.dropAnimalsData.animalDieDriver : ''}
                />
              </div>
            </Col>
            <Col lg="3">
              <div className="form-group">
                <label>Ветеринар</label>
                <FarmClientDropdown
                  tableType="dropAnimalsVet"
                  handleFormChange={this.handleFormChange}
                  farmClientID={this.state.dropAnimalsData.farmClientID ? this.state.dropAnimalsData.farmClientID : ''}
                />
              </div>
              <div className="form-group">
                <label>Цена на екарисажната услуга лв/глава</label>
                <Input
                  type="number"
                  name="animalDiePriceDiagnostic"
                  onChange={this.handleFormChange}
                  value={this.state.dropAnimalsData.animalDiePriceDiagnostic ? this.state.dropAnimalsData.animalDiePriceDiagnostic : ''}
                />
              </div>


            </Col>

          </Row>
          )}
          {this.state.lastDropReason !== '' && (
          <Row>
            <Col lg="3">
              <div className="form-group">
                <label>Дата на събитие</label>
                <DatePicker
                  name="fateDate"
                  onChange={this.handleFormChange}
                  value={this.state.dropAnimalsData.fateDate}
                />
              </div>
            </Col>

            <Col lg="3">
              <div className="form-group">
                <label>Документ:</label>
                <Input
                  type="text"
                  value={this.state.dropAnimalsData.fateDoc ? this.state.dropAnimalsData.Doc : ''}
                  name="fateDoc"
                  onChange={this.handleFormChange}
                />
              </div>
            </Col>

            <div className="w-100" />
            <Col lg="4">
              <div className="form-group">
                <label>Бележка</label>
                <textarea
                  className="k-textarea"
                  name="fateCode"
                  onChange={this.handleFormChange}
                  value={this.state.dropAnimalsData.fateCode ? this.state.dropAnimalsData.fateCode : ''}
                />
              </div>
            </Col>
          </Row>
          )}
        </form>
        <TabNavButtons parentProps={this.props} isFormValid={!!this.state.lastDropReason} dropAnimalsData={this.state.dropAnimalsData} />
      </div>
    );
  }
}

export default DropReason;
