/* eslint react/no-array-index-key:0 */
/* eslint react/destructuring-assignment:0 */
import * as React from 'react';
import { Link } from 'react-router-dom';
import { GROUP_TYPES } from '../../../../tools/translation-constants';
import AlertEntriesComponent from '../../../alert-entries/alert-entries';
import EditGroup from './edit-group';
import DatesHelper from '../../../../tools/DatesHelper';

class GroupsManagment extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      groups: this.props.groups,
    };
    this.generateTableRows = this.generateTableRows.bind(this);
  }

    generateTableRows = () => {
      return this.state.groups.map((group, i) => {
        return (
          <tr key={i}>
            <td>
              {group.managementGroupsName}
            </td>
            <td>
              {GROUP_TYPES.find(type => type.id === group.groupTypeID).text}
            </td>
            <td style={{ width: '1px', }}>
              {group.animals.length}
            </td>
            <td>
              {group.managementGroupsShortName}
            </td>
            <td>
              {DatesHelper.dateToAppFormat(group.groupDate)}
            </td>
            <td>
              {group.managementGroupsComment}
            </td>
            <td style={{ width: '1px', }}>
              <div className="d-flex justify-content-center">
                <EditGroup
                  indexGroup={i}
                  {...this.props}
                />
              </div>
            </td>
          </tr>
        );
      });
    }

    render() {
      return (
        <div>
          <h3 className="mb-4">Списък групи животни</h3>
          <AlertEntriesComponent text="Успешно създадохте група с" {...this.props} />
          <Link className="btn btn-primary" to="/groups/groups-managment/add-group">Добави група</Link>
          <table className="table">
            <thead>
              <tr>
                <th>Име</th>
                <th>Тип на групата</th>
                <th>Животни</th>
                <th>Кратко Име</th>
                <th>Дата</th>
                <th>Коментар</th>
                <th>Действия</th>
              </tr>
            </thead>
            <tbody>
              {this.generateTableRows()}
            </tbody>
          </table>

        </div>
      );
    }
}

export default GroupsManagment;
