/* eslint react/button-has-type:0 */
/* eslint max-len:0 */
/* eslint no-prototype-builtins:0 */
/* eslint react/destructuring-assignment:0 */
/* eslint react/no-access-state-in-setstate:0 */
import * as React from 'react';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Row, Col } from 'reactstrap';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import Mutation from '../../../../../../data/mutation';
import {
  ADD_ANIMAL_LOCATION, GET_ANIMAL_MOVEMENTS_LOCATION, GET_ANIMAL_LOCATIONS, GET_ANIMAL_DETAILS
} from '../../../../../../data/animal/animal-query';
import { GET_ANIMALS_FOR_GROUPS, GET_ANIMALS_FOR_SERVICE_GROUP } from '../../../../../../data/groups/groups-query';
import Query from '../../../../../../data/query';
import { generateLocationString } from '../../../animal-utilities';
import { DatePicker } from '../../../../../utils/date-picker';

class AddMovementModal extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      animalID: props.animalID,
      visible: false,
      locationObj: {
        animalID: props.animalID,
      },
      submitDisabled: true,
    };
    this.toggleDialog = this.toggleDialog.bind(this);
    this.handleFormChange = this.handleFormChange.bind(this);
  }

  toggleDialog() {
    this.setState({
      visible: !this.state.visible,
    });

    if (!this.state.visible) {
      this.resetState();
    }
  }


  handleFormChange(e) {
    const objCopy = { ...this.state.locationObj, };

    if (e.target.name === 'locationId') {
      objCopy[e.target.name] = e.target.value.locationID;
    } else {
      objCopy[e.target.name] = e.target.value;
    }
    const isFormSubmitable = !(Object.keys(objCopy).length > 1);
    this.setState({
      locationObj: objCopy,
      submitDisabled: isFormSubmitable,
    });
  }

  handleSubmit(e, addAnimalLocationtrigger) {
    e.preventDefault();
    const stateCopy = { ...this.state.locationObj, };

    if (!stateCopy.hasOwnProperty('moveDate')) {
      stateCopy.moveDate = new Date();
    }
    this.toggleDialog();
    if (Object.keys(stateCopy).length > 1) {
      addAnimalLocationtrigger({
        variables:
          {
            animalAddLocation: stateCopy,
          },
      });
    }
  }

  resetState() {
    const { animalID, } = this.state;
    this.setState({
      locationObj: {
        animalID,
      },
      submitDisabled: true,
    });
  }

  render() {
    return (
      <Mutation
        mutation={ADD_ANIMAL_LOCATION}
        awaitRefetchQueries
        refetchQueries={[{ query: GET_ANIMAL_DETAILS, variables: { id: this.state.animalID, }, }, { query: GET_ANIMAL_MOVEMENTS_LOCATION, variables: { id: this.state.animalID, }, }, { query: GET_ANIMALS_FOR_GROUPS, }, { query: GET_ANIMALS_FOR_SERVICE_GROUP, }]}
      >
        {(addAnimalLocationtrigger) => (
          <div>
            <button className="btn btn-primary" onClick={this.toggleDialog} style={{ width: 'unset', }}>ПРОМЕНИ ЛОКАЦИЯ</button>
            {this.state.visible && (
            <form onSubmit={e => this.handleSubmit(e, addAnimalLocationtrigger)} className="health-form" style={{ marginTop: '10px', }}>
              <Dialog className="health-modal" title="Добави събитие" onClose={this.toggleDialog} minWidth="500px" height="70%">
                <Row>
                  <Col lg={{ size: 8, offset: 2, }}>
                    <Query query={GET_ANIMAL_LOCATIONS}>
                      {({ locations, }) => {
                        const result = locations.items.map((l) => {
                          return { animalLocation: [{ location: { ...l, }, }], };
                        });

                        const locationData = result.map((l) => {
                          return {
                            locationID: l.animalLocation[0].location.locationID,
                            locationText: generateLocationString(l),
                          };
                        });
                        return (
                          <div className="form-group">
                            <label>Локация</label>
                            <DropDownList
                              style={{ width: '100%', }}
                              data={locationData}
                              dataItemKey="locationID"
                              textField="locationText"
                              name="locationId"
                              required
                              onChange={this.handleFormChange}
                            />
                          </div>
                        );
                      }}
                    </Query>
                    <div className="form-group">
                      <label>Дата на преместване</label>
                      <DatePicker
                        value={this.state.locationObj?.moveDate}
                        name="moveDate"
                        onChange={this.handleFormChange}
                        min={new Date(this.props.animaldateOfBirth)}
                        max={new Date()}
                      />
                    </div>
                  </Col>
                </Row>
                <DialogActionsBar>
                  <div className="d-flex">
                    <button className="btn btn-secondary" onClick={this.toggleDialog}>Отказ</button>
                    <button type="submit" disabled={this.state.submitDisabled} className="btn btn-primary ml-3">Добави</button>
                  </div>
                </DialogActionsBar>
              </Dialog>
            </form>
            )}
          </div>
        )}
      </Mutation>
    );
  }
}

export default AddMovementModal;
