/* eslint react/prefer-stateless-function:0 */
import * as React from 'react';
// @ts-ignore
import loading from '../../assets/img/loader.svg';

class Callback extends React.Component {
  render() {
    const style = {
      display: 'flex',
      justifyContent: 'center',
      height: '100vh',
      width: '100vw',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      backgroundColor: 'white',
    };

    return (
      <div
        style={{
          ...style,
          position: 'fixed',
        }}
      >
        <img src={loading} alt="loading" />
      </div>
    );
  }
}

export default Callback;
