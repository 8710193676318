/* eslint react/no-unused-state:0 */
import * as React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import GroupDataTab from './tabs/group-data-tab';
import GroupAndLocationTab from './tabs/group-and-location-tab';
import EconomyTab from './tabs/economy-tab';
import TableTab from './tabs/table-tab';
import EntriesNavLinks from '../entries-nav-links';
import Query from '../../../../../../data/query';
import { DATA_TAB_ENUMS } from '../../../../../../data/animal/tabs-queries';
import { GET_FIRMS_IN_FARMS_NAMES_AND_ID } from '../../../../../../data/companies/companies-queries';


class GroupEntries extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      groupAnimal: {
        active: true,
        discriminator: 'Animal',
        animalFunctionality: 'unknown',
        animalMarbling: 'unknown',
        animalMusculature: 'unknown',
        animalSkeleton: 'unknown',
        animalTemperament: 'unknown',
        breedingStatus: 'unknown',
        dNAType: 'unknown',
        dryWetStatus: 'unknown',
        fromEmbryoTransfer: false,
        registerSection: 'unknown',
        underSelection: false,
        forScrap: 'unknown',
        countryFrom: 'UNKNOWN',
        eIDType: 'UNKNOWN',
        hornStatusID: 'UNKNOWN',
        typeInput: 'UNKNOWN',
        breedingFor: 'UNKNOWN',
        breedsID: 30,
        colorID: 11,
        weaningStatus: 'unknown',
        registrationDate: new Date(),
        animalPurchase: [{
          purchaseDate: new Date(),
        }],
        animalLocation: [{
          moveDate: new Date(),
        }],

      },
      updateGroupObject: this.updateGroupObject,
    };
    this.updateGroupObject = this.updateGroupObject.bind(this);
    this.getGroupObject = this.getGroupObject.bind(this);
  }

  getGroupObject() {
    const { groupAnimal, } = this.state;
    return groupAnimal;
  }

  updateGroupObject(animal) {
    this.setState({
      groupAnimal: animal,
    });
  }

  render() {
    const { groupAnimal, } = this.state;

    let tabs = {
      links: [
        '/animals/movement/group-entries',
        '/animals/movement/group-entries/group-and-location',
        '/animals/movement/group-entries/table'
      ],
      titles: [
        'Групови данни',
        'Локация',
        'Таблица'
      ],
    };
    if (groupAnimal.typeInput && groupAnimal.typeInput === 'PURCHASE') {
      tabs = {
        links: [
          '/animals/movement/group-entries',
          '/animals/movement/group-entries/group-and-location',
          '/animals/movement/group-entries/economy',
          '/animals/movement/group-entries/table'
        ],
        titles: [
          'Групови данни',
          'Локация',
          'Икономика',
          'Таблица'
        ],
      };
    }

    return (
      <Query query={DATA_TAB_ENUMS}>
        {({ aClasses, breeds, color, }) => (
          <Query query={GET_FIRMS_IN_FARMS_NAMES_AND_ID}>
            {({ firmsInFarms, }) => (
              <div>
                <div className="steps container-fluid">
                  <EntriesNavLinks tabs={tabs} />
                </div>

                <div className="container-fluid">
                  <Switch>
                    <Route
                      path={tabs.links[1]}
                      render={props => {
                        return (
                          <GroupAndLocationTab
                            props={props}
                            {...this.props}
                            getGroupObject={this.getGroupObject}
                            updateGroupObject={this.updateGroupObject}
                            tabsLinks={tabs.links}
                          />
                        );
                      }}
                    />
                    <Route
                      path={tabs.links[3] ? tabs.links[3] : tabs.links[2]}
                      render={props => {
                        return (
                          <TableTab
                            props={props}
                            getGroupObject={this.getGroupObject}
                            tabsLinks={tabs.links}
                            color={color.items}
                            aClasses={aClasses.items}
                            breeds={breeds.items}
                          />
                        );
                      }}
                    />
                    <Route
                      path={tabs.links[3] ? tabs.links[2] : '/animals/movement/group-entries/economy'}
                      render={props => {
                        if (groupAnimal.typeInput && groupAnimal.typeInput === 'PURCHASE') {
                          return (
                            <EconomyTab
                              props={props}
                              getGroupObject={this.getGroupObject}
                              updateGroupObject={this.updateGroupObject}
                              tabsLinks={tabs.links}
                            />
                          );
                        }
                        return <Redirect to={tabs.links[0]} />;
                      }}
                    />
                    <Route
                      path={tabs.links[0]}
                      render={props => {
                        return (
                          <GroupDataTab
                            props={props}
                            getGroupObject={this.getGroupObject}
                            updateGroupObject={this.updateGroupObject}
                            aClasses={aClasses.items}
                            breeds={breeds.items}
                            color={color.items}
                            tabsLinks={tabs.links}
                            firmsInFarms={firmsInFarms}
                          />
                        );
                      }}
                    />
                  </Switch>
                </div>
              </div>
            )}
          </Query>
        )}
      </Query>
    );
  }
}

export default GroupEntries;
