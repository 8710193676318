/* eslint no-shadow:0 */
/* eslint react/no-array-index-key:0 */
/* eslint react/prop-types:0 */
/* eslint react/destructuring-assignment:0 */
import * as React from 'react';
import DatesHelper from '../../../../../../tools/DatesHelper';


const mappedBullsData = (bulls) => {
  return bulls.map(bull => {
    return (
      `${bull.animals.lID}(${bull.animals.breed ? bull.animals.breed.breedShort : '-'})`
    );
  });
};

const ServiceTable = props => {
  const sortedAnimalGroup = props.animal.animalInService.length > 0 ? props.animal.animalInService.sort((a, b) => {
    return new Date(a.group.dateCreate).getTime() - new Date(b.group.dateCreate).getTime();
  }) : [];

  const mappedAnimalsGroups = sortedAnimalGroup.map((animalGroup) => {
    const bulls = props.serviceGroups.find(group => group.groupID === animalGroup.groupID)
      .animalServiceProp
      .filter(animalInGroup => animalInGroup.animals.classID === 7);
    return {
      ...animalGroup,
      bulls: mappedBullsData(bulls),
    };
  });

  const generateTableRows = () => {
    return mappedAnimalsGroups.map((animal, i) => {
      return (
        <tr key={i}>
          <td>{DatesHelper.parseAndFormatDate(animal.group.dateCreate)}</td>
          <td>{animal.group.groupName}</td>
          <td>{animal.bulls.map((bull, i) => <p key={i}>{bull}</p>)}</td>
          <td>
            <p>
              Дата на влизане в сървис:
              {animal.dateIn ? DatesHelper.dateToAppFormat(animal.dateIn) : '-'}
            </p>
            <p>
              Дата на излизане от сървис:
              {animal.dateOut ? DatesHelper.dateToAppFormat(animal.dateOut) : '-'}
            </p>
            <p>
              Причина:
              {animal.comment ? animal.comment : '-'}
            </p>
            <p>
              Дата на приключване:
              {DatesHelper.parseAndFormatDate(animal.group.dateExpires)}
            </p>
          </td>
          <td>{animal.group.comment}</td>
        </tr>
      );
    });
  };

  return (

    <table className="table">
      <thead>
        <tr>
          <th>Дата</th>
          <th>Вид сървис</th>
          <th>Бик</th>
          <th>Информация</th>
          <th>Коментар</th>
        </tr>
      </thead>
      <tbody>
        {generateTableRows()}
      </tbody>
    </table>
  );
};

export default ServiceTable;
