/* eslint react/destructuring-assignment:0 */
/* eslint react/button-has-type:0 */
/* eslint react/no-array-index-key:0 */
/* eslint react/prop-types:0 */
import * as React from 'react';
import { NavLink } from 'react-router-dom';

const EntriesNavLinks = (props) => {
  const generateNavLinks = () => {
    return props.tabs.links.map((l, index) => {
      return (
        <li className="nav-item" key={index}>
          <NavLink className="nav-link" exact to={l}>
            <span className="step">{index + 1}</span>
            {' '}
            <span className="title">{props.tabs.titles[index]}</span>
          </NavLink>
        </li>
      );
    });
  };

  const generateNavButtons = () => {
    return props.tabs.map((tab, index) => {
      return (
        <li className="nav-item" key={index}>
          <button
            onClick={e => {
              e.preventDefault();
              tab.onClick(e, tab, index);
            }}
            className={`nav-link ${tab.active ? 'active' : ''}`}
          >
            <span className="step">{index + 1}</span>
            {' '}
            <span
              className="title"
            >
              {tab.title}
            </span>
          </button>
        </li>
      );
    });
  };

  return (
    <ul className="nav nav-pills nav-fill">
      { !props.renderButtons ? generateNavLinks() : generateNavButtons()}
    </ul>
  );
};


export default EntriesNavLinks;
