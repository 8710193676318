/* eslint react/button-has-type:0 */
/* eslint jsx-a11y/control-has-associated-label:0 */
/* eslint react/no-access-state-in-setstate:0 */
/* eslint react/destructuring-assignment:0 */
/* eslint react/no-unused-state:0 */
import * as React from 'react';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Row, Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


class DeleteBcsModal extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      bCSObj: props.bCSObj,
    };
    this.toggleDialog = this.toggleDialog.bind(this);
  }

  toggleDialog() {
    this.setState({
      visible: !this.state.visible,
    });
  }


  handleSubmit(e) {
    e.preventDefault();
    this.toggleDialog();
  }

  render() {
    return (
      <div className="d-inline-block">
        <button className="button-icon mr-4" onClick={this.toggleDialog}><FontAwesomeIcon icon="trash" /></button>
        {this.state.visible && (
        <Dialog className="health-modal" title="Изтрий събитие" onClose={this.toggleDialog} minWidth="500px">
          <form className="health-form">
            <Row>
              <Col lg={{ size: 12, }}>
                <div className="mb-4 text-center">
                  Сигурни ли сте,че искате да изтриете събитието?
                </div>
              </Col>
            </Row>
          </form>
          <DialogActionsBar>
            <div className="d-flex justify-content-center">
              <button className="btn btn-secondary" onClick={this.toggleDialog}>Отказ</button>
              <button type="submit" onClick={e => this.handleSubmit(e)} className="btn btn-primary ml-3">Изтрий</button>
            </div>
          </DialogActionsBar>
        </Dialog>
        )}
      </div>
    );
  }
}

export default DeleteBcsModal;
