/* eslint-disable no-nested-ternary */
/* eslint max-len:0 */
import * as React from 'react';
import BCSReportTable from './bcs-report-table';
import Query from '../../../../data/query';
import { GET_ANIMALS_BCS_DATA } from '../../../../data/reports/reports-query';
import {
  animalSexTranslation, dateFormatForTableFilters, getTranslation, reportAnimalSummary
} from '../../../../tools/function-helpers';
import { ANIMAL_OTS_TYPES } from '../../../../tools/translation-constants';
import DatesHelper from '../../../../tools/DatesHelper';

const BCSReport = props => {
  return (
    <Query query={GET_ANIMALS_BCS_DATA}>
      {({ animals, }) => {
        const mappedAnimals = animals.items.map((animal) => {
          const sexTranslation = animalSexTranslation(animal.sex);
          const bCSLen = animal.bCS.length;
          const lastBCS = animal.bCS[bCSLen - 1];
          const prevBCS = animal.bCS[bCSLen - 2];
          return {
            ...animal,
            animalSummary: reportAnimalSummary(animal, sexTranslation),
            lastBCSDate: bCSLen > 0 ? dateFormatForTableFilters(lastBCS.bCSDate) : '',
            lastBCSValue: bCSLen > 0 && lastBCS.animalOTS ? getTranslation(lastBCS.animalOTS, ANIMAL_OTS_TYPES) : '',
            lastPersonInCharge: bCSLen > 0 && lastBCS.personInCharge ? lastBCS.personInCharge.farmClientName : '',
            lastComment: bCSLen > 0 && lastBCS.comment ? lastBCS.comment : '',
            prevBCSVal: bCSLen > 1 ? prevBCS.animalOTS ? getTranslation(prevBCS.animalOTS, ANIMAL_OTS_TYPES) : '' : '',
            prevBCSDate: bCSLen > 1 ? prevBCS.bCSDate ? DatesHelper.dateToAppFormat(prevBCS.bCSDate) : '' : '',

            // prevBCSValue: bCSLen > 1 ? `${prevBCS.animalOTS ? getTranslation(prevBCS.animalOTS, ANIMAL_OTS_TYPES) : '-'} | ${DatesHelper.dateToAppFormat(prevBCS.bCSDate)} | ${prevBCS.personInCharge ? prevBCS.personInCharge.farmClientName : '-'}` : '',
          };
        });
        const bCSTypes = ANIMAL_OTS_TYPES.map(bcs => bcs.text);
        return (
          <BCSReportTable {...props} animals={mappedAnimals} bCSTypes={bCSTypes} />
        );
      }}
    </Query>
  );
};

export default BCSReport;
