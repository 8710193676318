/* eslint react/prop-types:0 */
/* eslint react/destructuring-assignment:0 */
import * as React from 'react';
import { Row, Col } from 'reactstrap';

import Query from '../../../../data/query';
import { GET_ANIMAL_ADG } from '../../../../data/animal/animal-query';
import DatesHelper from '../../../../tools/DatesHelper';
import DashboardCard from '../../../lib/dashboard-card/dashboard-card';
import AddWeightModal from './weights/add-weight-modal';
import AdgChart from './weights/adg-chart';
import AdjustedWeightsTable from './weights/adjusted-weights-table';
import WeightsChart from './weights/weights-chart';
import WeightsTable from './weights/weights-table';

const WeightsTab = props => (
  <Query {...props} query={GET_ANIMAL_ADG} variables={{ id: props.currentAnimal.animalId, }}>
    {({ animal, }) => (
      <div className="tab">
        <Row className="mb-4">
          <Col lg="6">
            <div className="tab-head">
              <div className="d-flex align-items-baseline mb-4">
                <h3 className="mb-0">ТЕГЛА</h3>
                <div className="d-flex align-items-baseline ml-3">
                  <div className="d-flex flex-column ml-3">
                    <AddWeightModal animalID={props.currentAnimal.animalId} animaldateOfBirth={props.currentAnimal.dateOfBirth} />
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col lg="3">
            <DashboardCard className="align-items-end">
              <h5 className="mr-2">Последно тегло</h5>
              {props.currentAnimal.lastWeight.dateWeight && props.currentAnimal.lastWeight.weight ? (
                <div className="d-flex justify-content-between align-items-end">
                  <p className="mb-0 mr-2">{DatesHelper.parseAndFormatDate(props.currentAnimal.lastWeight.dateWeight)}</p>
                  <h2 className="mb-0">
                    {props.currentAnimal.lastWeight.weight}
                    <small>кг</small>
                  </h2>
                </div>
              )
                : ''}
            </DashboardCard>
          </Col>
          <Col lg="3">
            <DashboardCard>
              <div className="d-flex justify-content-between">
                <div>
                  <h5 className="mr-2">Общо СДП</h5>
                  {props.currentAnimal.adgOverall
                    ? (
                      <h2 className="mb-0">
                        {props.currentAnimal.adgOverall.toFixed(2)}
                        <small>кг/д</small>
                      </h2>
                    )
                    : ''}
                </div>
                <div>
                  <h5>Актуално СДП</h5>
                  {animal.animalADG !== null && animal.animalADG.length > 0
                    ? (
                      <h2 className="mb-0">
                        {animal.animalADG[animal.animalADG.length - 1].animalANG.toFixed(2)}
                        <small>кг/д</small>
                      </h2>
                    )
                    : ''}
                </div>
              </div>
            </DashboardCard>
          </Col>
        </Row>
        <Row className="mb-4">
          <Col lg="12">
            <h4 className="mb-2">Контролни тегла | Развъдни тегла</h4>
          </Col>
          <Col lg="6">
            <WeightsTable animal={props.currentAnimal} animaldateOfBirth={props.currentAnimal.dateOfBirth} />
          </Col>
          <Col lg="6">
            <WeightsChart animal={props.currentAnimal} />
          </Col>
        </Row>
        <Row className="mb-4">
          <Col lg="6">
            <AdjustedWeightsTable animal={props.currentAnimal} />
          </Col>
          <Col lg="6">
            <AdgChart animal={props.currentAnimal} animalADG={animal.animalADG} />
          </Col>
        </Row>
      </div>
    )}
  </Query>
);

export default WeightsTab;
