/* eslint max-len:0 */
/* eslint react/button-has-type:0 */
/* eslint jsx-a11y/control-has-associated-label:0 */
/* eslint react/destructuring-assignment:0 */
/* eslint react/no-access-state-in-setstate:0 */
/* eslint react/sort-comp:0 */
import * as React from 'react';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Row, Col } from 'reactstrap';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Mutation from '../../../../../../data/mutation';
import { UPDATE_ANIMAL_BCS, GET_ANIMAL_BCS } from '../../../../../../data/animal/animal-query';
import FarmClientDropdown from '../../health/farm-client-dropdown';
import { ANIMAL_OTS_TYPES } from '../../../../../../tools/translation-constants';
import { GET_ANIMALS_BCS_DATA } from '../../../../../../data/reports/reports-query';
import { DatePicker } from '../../../../../utils/date-picker';


class EditBcsModal extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      bCSObj: props.bCSObj,
      changes: {
        animalID: props.bCSObj.animalID,
        bCSID: props.bCSObj.bCSID,
        bCSDate: props.bCSObj.bCSDate,
      },
      initialState: props.bCSObj,
      submitDisabled: true,
      tableType: 'bCS',
    };
    this.toggleDialog = this.toggleDialog.bind(this);
    this.handleFormChange = this.handleFormChange.bind(this);
  }

  toggleDialog() {
    this.setState({
      visible: !this.state.visible,
    });

    if (!this.state.visible) {
      this.resetChanges();
    }
  }


  handleFormChange(e) {
    const objCopy = { ...this.state.bCSObj, };
    const changes = { ...this.state.changes, };

    if (e.target.name === 'animalOTS') {
      objCopy[e.target.name] = e.target.value.id;
      changes[e.target.name] = e.target.value.id;
    } else if (e.target.name === 'farmClientID') {
      objCopy[e.target.name] = e.target.value.farmClientID;
      changes[e.target.name] = e.target.value.farmClientID;
    } else {
      objCopy[e.target.name] = e.target.value;
      changes[e.target.name] = e.target.value;
    }

    const isFormSubmitable = !(Object.keys(changes).length > 1);
    this.setState({
      bCSObj: objCopy,
      changes,
      submitDisabled: isFormSubmitable,
    });
  }

  handleSubmit(e, updateAnimalBCSTrigger) {
    e.preventDefault();

    updateAnimalBCSTrigger({
      variables:
            {
              animalUpdateBCS: this.state.changes,
            },
    });
    this.setState({
      initialState: this.state.bCSObj,
    });
    this.resetChanges();
  }

  resetChanges() {
    this.setState({
      changes: {
        animalID: this.state.bCSObj.animalID,
        bCSID: this.state.bCSObj.bCSID,
        bCSDate: this.state.bCSObj.bCSDate,
      },
      submitDisabled: true,
      bCSObj: this.state.initialState,
    });
  }

  setFarmClientIdDropdownValue() {
    let selectedFarmClientId;
    if (this.state.bCSObj.farmClientID) {
      selectedFarmClientId = this.state.bCSObj.farmClientID;
    } else if (this.state.bCSObj.personInCharge) {
      selectedFarmClientId = this.state.bCSObj.personInCharge.farmClientID;
    } else {
      selectedFarmClientId = '';
    }
    return selectedFarmClientId;
  }

  render() {
    const selectedOTC = ANIMAL_OTS_TYPES.find((el) => {
      return el.id === this.state.bCSObj.animalOTS;
    });

    return (
      <Mutation
        mutation={UPDATE_ANIMAL_BCS}
        awaitRefetchQueries
        refetchQueries={[{ query: GET_ANIMAL_BCS, variables: { id: this.state.bCSObj.animalID, }, }, { query: GET_ANIMALS_BCS_DATA, }]}
        onCompleted={() => this.toggleDialog()}
      >
        {(updateAnimalBCSTrigger) => (
          <div className="d-inline-block">
            <button className="button-icon" onClick={this.toggleDialog}><FontAwesomeIcon icon="pen" /></button>
            {this.state.visible && (
            <Dialog className="health-modal" title="Редактирай събитие" onClose={this.toggleDialog} minWidth="500px" height="70%">
              <form className="health-form">
                <Row>
                  <Col lg={{ size: 8, offset: 2, }}>
                    <div className="form-group">
                      <label>ОТС</label>
                      <DropDownList
                        style={{ width: '100%', }}
                        data={ANIMAL_OTS_TYPES}
                        dataItemKey="id"
                        textField="text"
                        name="animalOTS"
                        required
                        value={selectedOTC}
                        onChange={this.handleFormChange}
                      />
                    </div>
                    <div className="form-group">
                      <label>Отговорник</label>
                      <FarmClientDropdown tableType={this.state.tableType} farmClientID={this.setFarmClientIdDropdownValue()} handleFormChange={this.handleFormChange} />
                    </div>
                    <div className="form-group">
                      <label>Дата</label>
                      <DatePicker
                        value={new Date(this.state.bCSObj.bCSDate)}
                        onChange={this.handleFormChange}
                        name="bCSDate"
                        min={new Date(this.props.animaldateOfBirth)}
                        max={new Date()}
                      />
                    </div>
                    <div className="form-group">
                      <label>Бележка</label>
                      <textarea className="k-textarea" name="comment" value={this.state.bCSObj.comment ? this.state.bCSObj.comment : ''} onChange={this.handleFormChange} />
                    </div>
                  </Col>
                </Row>
              </form>
              <DialogActionsBar>
                <div className="d-flex">
                  <button className="btn btn-secondary" onClick={this.toggleDialog}>Отказ</button>
                  <button type="submit" onClick={e => this.handleSubmit(e, updateAnimalBCSTrigger)} disabled={this.state.submitDisabled} className="btn btn-primary ml-3">Добави</button>
                </div>
              </DialogActionsBar>
            </Dialog>
            )}
          </div>
        )}
      </Mutation>
    );
  }
}

export default EditBcsModal;
