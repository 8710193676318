/* eslint-disable */
import * as React from 'react';
import Query from '../../../../data/query';
import DppEditTable from './dpp-edit-table';
import { SINGLE_DPP_GROUP } from '../../../../data/dpp-group/dpp-group-mutations';
// import { animalSexTranslation, dateFormatForTableFilters, reportAnimalSummary } from '../../../../tools/function-helpers';
// import DatesHelper from '../../../../tools/DatesHelper';


const DPPEdit = props => { 
  const id = props.match.params.id;
  return (
    <Query query={SINGLE_DPP_GROUP} variables={{ id, }}>
      {({ groupDPP, }) => {
  //     const mappedAnimals = [];

  //   for (let i = 0; i < groupDPP.animalTuberculosis.length; i++) {
  //    mappedAnimals.push({
  //    ...groupDPP.animalTuberculosis[i],
  //    ...(groupDPP.animalBrucellosis.find((itmInner) => itmInner.animalID === groupDPP.animalTuberculosis[i].animalID)),
  //   });
  // }
  const mappedAnimals = groupDPP.animalTuberculosis.map(tub => {
    const bruc = groupDPP.animalBrucellosis.find(itmInner => itmInner.animalID === tub.animalID);
    const animal = tub.animal;
    return {
        ...animal,
        animalTuberculosis: {
            ...tub
        },
        animalBrucellosis: {
            ...bruc
        },
        changes: false
    }
  });

        return (
          <DppEditTable {...props} animals={mappedAnimals} />
        );
      }}
    </Query>
  );
};

export default DPPEdit;
