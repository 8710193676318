export const BOOLEANS = [
  { id: true, text: 'Да', },
  { id: false, text: 'Не', }
];

export const SEXES = [
  { id: 'MALE', text: 'Мъжки', },
  { id: 'FEMALE', text: 'Женски', }
];

export const MALEACLASSES = [
  { id: '1', text: 'Теле М', },
  { id: '3', text: 'Вол-Кастрат', },
  { id: '6', text: 'Бик', },
  { id: '7', text: 'Бик-Разплодник', }
];

export const FEMALEACLASSES = [
  { id: '2', text: 'Теле Ж', },
  { id: '4', text: 'Юница', },
  { id: '5', text: 'Крава', }
];

export const USER_ROLES = [
  { id: 'OPERATOR', text: 'Оператор', },
  { id: 'VET', text: 'Ветеринарен лекар', },
  { id: 'NCONSULTANT', text: 'Консултант по хранене', },
  { id: 'ADMIN', text: 'Администратор', }
];

export const COUNTRIES = [
  { id: 'BULGARIA', text: 'България', },
  { id: 'UNKNOWN', text: 'Неопределен', },
  { id: 'IRELAND', text: 'Ирландия', },
  { id: 'FRANCE', text: 'Франция', },
  { id: 'GERMANY', text: 'Германия', },
  { id: 'ITALY', text: 'Италия', },
  { id: 'UK', text: 'Великобритания', },
  { id: 'DENMARK', text: 'Дания', },
  { id: 'USA', text: 'САЩ', }
];

export const DNA_TYPES = [
  { id: 'UNKNOWN', text: 'Неопределен', },
  { id: 'SNP', text: 'SNP', },
  { id: 'MICROSATELLITE', text: 'Microsatelite', }
];

export const EID_TYPES = [
  { id: 'UNKNOWN', text: 'Неопределен', },
  { id: 'EAR_TAG', text: 'Ушна марка', },
  { id: 'BOLUS', text: 'Болус', }
];

export const REGISTERS = [
  { id: 'NONE', text: 'Няма', },
  { id: 'UNKNOWN', text: 'Неопределен', },
  { id: 'ARMPGB', text: 'АРМПГБ', },
  { id: 'NAMGB', text: 'НАМГБ', }
];

export const HORN_STATUSES = [
  { id: 'UNKNOWN', text: 'Неопределен', },
  { id: 'HUMMEL', text: 'Безрог', },
  { id: 'HORNED', text: 'Рогат', },
  { id: 'DEHORN', text: 'Обезроговен', }
];

export const BREEDING_STATUSES = [
  { id: 'UNKNOWN', text: 'Неопределен', },
  { id: 'EMPTY', text: 'Празна', },
  { id: 'PREGNANT', text: 'Бременна', },
  { id: 'ABORTION', text: 'Абортирала', },
  { id: 'IN_SERVICE', text: 'В сървис', }
];

export const DRY_WET_STATUSES = [
  { id: 'UNKNOWN', text: 'Неопределен', },
  { id: 'WET', text: 'Лактираща', },
  { id: 'DRY', text: 'Сухостойна', }
];

export const WEIGHT_TYPES = [
  { id: 'CONTROL_WEIGHT', text: 'Контролно тегло', },
  { id: 'BIRTH_WEIGHT', text: 'Тегло при раждане', },
  { id: 'WEANING_WEIGHT', text: 'Тегло при отбиване', },
  { id: 'YEARLING_WEIGHT', text: 'Тегло на една година', },
  { id: 'MATURE_WEIGHT', text: 'Тегло на възрастно животно', },
  { id: 'TECH_WEIGHT', text: 'Тегло при клане', }
];

export const TYPE_INPUTS = [
  { id: 'UNKNOWN', text: 'Неопределен', },
  { id: 'PURCHASE', text: 'Покупка', },
  { id: 'TEMPORARY_IN', text: 'Временно в обекта', },
  { id: 'SELF', text: 'Собствено производство', }
];

export const ANIMAL_TUBERCULOSIS_BRUCELLOSIS_TYPES = [
  { id: 'UNKNOWN', text: 'Неопределен', },
  { id: 'POSITIVE', text: 'Позитивен', },
  { id: 'NEGATIVE', text: 'Отрицателен', },
  { id: 'SUSPICIOUS', text: 'Подозрителен', }
];

export const ANIMAL_OTS_TYPES = [
  { id: 'UNKNOWN', text: 'Неопределено', },
  { id: 'ONE', text: '1-Изтощенa', },
  { id: 'TWO', text: '2-Много Слаба', },
  { id: 'THREE', text: '3-Слаба', },
  { id: 'FOUR', text: '4-Умерено Слаба', },
  { id: 'FIVE', text: '5-Оптимално', },
  { id: 'SIX', text: '6-Оптимално замускулено', },
  { id: 'SEVEN', text: '7-Замускулена', },
  { id: 'EIGHT', text: '8-Средно Затлъстели', },
  { id: 'NINE', text: '9-Затлъстели', }
];
export const ONE_TO_NINE = [
  { id: 'UNKNOWN', text: 'Неопределен', },
  { id: 'ONE', text: '1', },
  { id: 'TWO', text: '2', },
  { id: 'THREE', text: '3', },
  { id: 'FOUR', text: '4', },
  { id: 'FIVE', text: '5', },
  { id: 'SIX', text: '6', },
  { id: 'SEVEN', text: '7', },
  { id: 'EIGHT', text: '8', },
  { id: 'NINE', text: '9', }
];

export const MARBLINGS = [
  { id: 'UNKNOWN', text: 'Неопределен', },
  { id: 'PRIME_1', text: 'Prime 1', },
  { id: 'PRIME_2', text: 'Prime 2', },
  { id: 'CHOICE_1', text: 'Choice 1', },
  { id: 'CHOICE_2', text: 'Choice 2', },
  { id: 'SELECT_1', text: 'Select 1', },
  { id: 'SELECT_2', text: 'Select 2', }
];


export const LOCATION_TYPES = {
  BARN: {
    id: 0,
    text: 'BARN',
  },
  PASTURE: {
    id: 1,
    text: 'PASTURE',
  },
  SECTOR: {
    id: 2,
    text: 'SECTOR',
  },
  BOX: {
    id: 3,
    text: 'BOX',
  },
  PADDOCK: {
    id: 0,
    text: 'PADDOCK',
  },
};

export const LOCATIONS_TYPES_TRANSLATIONS = [
  { id: 'BARN', text: 'Обор', },
  { id: 'PASTURE', text: 'Пасище', },
  { id: 'SECTOR', text: 'Сектор', },
  { id: 'BOX', text: 'Бокс', },
  { id: 'PADDOCK', text: 'Падок', }
];

export const VETIS_PREFIX_TYPES = [
  { id: 'BG', text: 'BG', },
  { id: 'AT', text: 'AT', },
  { id: 'BE', text: 'BE', },
  { id: 'CY', text: 'CY', },
  { id: 'CZ', text: 'CZ', },
  { id: 'DE', text: 'DE', },
  { id: 'DK', text: 'DK', },
  { id: 'EE', text: 'EE', },
  { id: 'EL', text: 'EL', },
  { id: 'ES', text: 'ES', },
  { id: 'FI', text: 'FI', },
  { id: 'FR', text: 'FR', },
  { id: 'HR', text: 'HR', },
  { id: 'HU', text: 'HU', },
  { id: 'IE', text: 'IE', },
  { id: 'IT', text: 'IT', },
  { id: 'LT', text: 'LT', },
  { id: 'LU', text: 'LU', },
  { id: 'LV', text: 'LV', },
  { id: 'MT', text: 'MT', },
  { id: 'NL', text: 'NL', },
  { id: 'PL', text: 'PL', },
  { id: 'PT', text: 'PT', },
  { id: 'RO', text: 'RO', },
  { id: 'SE', text: 'SE', },
  { id: 'SI', text: 'SI', },
  { id: 'SK', text: 'SK', },
  { id: 'UK', text: 'UK', },
  { id: 'US', text: 'US', },
  { id: 'TR', text: 'TR', }
];

export const REGISTER_SECTION = [
  { id: 'UNKNOWN', text: 'Неопределен', },
  { id: 'A', text: 'A', },
  { id: 'B', text: 'B', },
  { id: 'C', text: 'C', },
  { id: 'D', text: 'D', }
];

export const PREGNANCY_TYPES = [
  { id: 'POSITIVE', text: 'Положителен', },
  { id: 'NEGATIVE', text: 'Отрицателен', },
  { id: 'UNKNOWN', text: 'Неопределен', },
  { id: 'ABORT', text: 'Аборт', }
];

export const STO_TYPES = [
  { id: 'UNKNOWN', text: 'Неопределена', },
  { id: 'EASY', text: 'Лесно', },
  { id: 'NORMAL', text: 'Нормално', },
  { id: 'HARD', text: 'Трудно', },
  { id: 'CAESAREAN_SECTION', text: 'Цезарово Сечение', },
  { id: 'DISLOCATION', text: 'Изкълчване', }
];

export const STO_TYPE_TYPES_CONDITIONS = [
  { id: 'HARD', text: 'Трудно', },
  { id: 'CAESAREAN_SECTION', text: 'Цезарово Сечение', },
  { id: 'DISLOCATION', text: 'Изкълчване', }
];

export const STO_TYPE_TYPES = [
  { id: 'UNDEFINED', text: 'Неопределена', },
  { id: 'LACK_OF_CONTRACTIONS', text: 'Липса на контракции', },
  { id: 'VIGOROUS_CONTRACTIONS', text: 'Енергични контракции', },
  { id: 'NARROW_VULVA', text: 'Тясна вулва', },
  { id: 'NARROW_VAGINA', text: 'Тясна вагина', },
  { id: 'NARROW_CERVIX', text: 'Тесен цервикс', },
  { id: 'UTERINE_TWIST', text: 'Усукване на утера', },
  { id: 'FETAL_RESISTANT', text: 'Резистентни фетални', },
  { id: 'PLACENTAL_MEMBRANE_RUPTURE', text: 'Мембрани разкъсване на плацентата', },
  { id: 'MALFORMATION_ON_THE_FETUS', text: 'Молоформации на фетуса', },
  { id: 'MALFORMATION_ON_THE_FETUS', text: 'Анормална позиция', },
  { id: 'BIG_OFFSPRING', text: 'Голям приплод', }

];

export const YES_NO = [
  { id: 'YES', text: 'ДА', },
  { id: 'NO', text: 'НЕ', }
];

export const FATE_REASON = [
  { id: 'SALE', text: 'Продажба', },
  { id: 'DIE', text: 'Смърт', },
  { id: 'FARM', text: 'Временно напускане', },
  { id: 'SLAUGHTER', text: 'Клане по необходимост', },
  { id: 'OTHER', text: 'Друго', }
];

export const FATE_REASON_SHORT = [
  { id: 'SLAUGHTER', text: 'Клане', },
  { id: 'OTHER', text: 'Друго', }
];

export const BREEDING_FOR = [
  { id: 'UNKNOWN', text: 'Неустановено', },
  { id: 'BREEDING', text: 'Разплод', },
  { id: 'FATTENING', text: 'Угояване', }
];

export const FARM_CONTRAGENTS_TYPES = [
  { id: 'FARM', text: 'Ферма', },
  { id: 'BUTCHER', text: 'Кланица', },
  { id: 'VET', text: 'Ветеринарен лекар', },
  { id: 'ANIMAL_DEALER', text: 'Търговец животни', },
  { id: 'ANIMAL_TRANS', text: 'Транспорт животни', },
  { id: 'ANIMAL_PROVIDER', text: 'Доставчик', },
  { id: 'ANIMAL_EMPLOYEE', text: 'Служител', },
  { id: 'SKELETON', text: 'Екарисаж', },
  { id: 'OTHER', text: 'Друго', }
];

export const TYPE_EXIST = [
  { id: 'FROM_PEDIGREE', text: 'От Родословие', },
  { id: 'FROM_SEMEN', text: 'От Семенен материал', }
];

export const GROUP_TYPES = [
  { id: 'MANAGEMENT', text: 'Мениджмънт', },
  { id: 'VETERINARY', text: 'Ветеринарна', },
  { id: 'HERD', text: 'Стадо', }
];

export const DROP_DIRECTION_TYPES = [
  { id: 'SLAUGHTER', text: 'Клане', },
  { id: 'FARM', text: 'Ферма', },
  { id: 'OTHER', text: 'Друго', }
];

export const DIE_DIAGNOSTIC_TYPE = [
  { id: 'UNKNOWN', text: 'Неупределено', },
  { id: 'LEUKEMIA', text: 'Левкемия', },
  { id: 'ACTINOMYCOSIS', text: 'Актиномикоза', },
  { id: 'ANAPLASMOSIS', text: 'Анаплазмоза', },
  { id: 'ANTHRAX', text: 'Антракс', },
  { id: 'ANOTHER_INFECTIOUS_DISEASE', text: 'Друго инфекциозно заболяване', },
  { id: 'ANOTHER_INFECTIOUS_DISEASE_BABESIA', text: 'Друго инфекциозно заболяване(Babesia)', },
  { id: 'ANOTHER_INFECTIOUS_DISEASE_BABESIELLA', text: 'Друго инфекциозно заболяване(Babesiella)', },
  { id: 'EPHEMERAL_FEVER', text: 'Ефемерна треска', },
  { id: 'LEPTOSPIROSIS', text: 'Лептоспироза', }
];

export const ANIMAL_DIE_TYPE = [
  { id: 'UNKNOWN', text: 'Неупределено', },
  { id: 'OTHER', text: 'Други', },
  { id: 'ILLNESS_CALF', text: 'Заболяване теле', },
  { id: 'INFECTIOUS', text: 'Инфекциозно', },
  { id: 'CARELESSNESS', text: 'Немарливост', },
  { id: 'COMMON', text: 'Общи', },
  { id: 'BIRTH', text: 'Раждане/Отелване', },
  { id: 'INJURY', text: 'Травма', },
  { id: 'PREDATORS', text: 'Хищници', }
];

export const ANIMAL_WEANING_TYPES = [
  { id: 'UNKNOWN', text: 'Неупределено', },
  { id: 'SUCKING', text: 'Бозаещо', },
  { id: 'WEANED', text: 'Отбито', }

];

export const ANIMAL_LOCATION_TYPES = [
  { id: 'MOVING', text: 'Преместване', },
  { id: 'SERVICE', text: 'От сървис', }

];
// {
//   UNKNOWN:{
//     text: "UNKNOWN"
//   },
//   SUCKING:{
//     text:"SUCKING"
//   },
//   WEANED:{
//     text:"WEANED"
//   }
// };

export const ANIMAL_FOR_SCRAP = [
  { id: 'UNKNOWN', text: 'Неупределено', },
  { id: 'FORSCRAP', text: 'За Брак', }
];

export const ANIMAL_EVENTS_TYPES = [
  { id: 'BIRTH', text: 'Раждане', },
  { id: 'CALVING', text: 'Отелване', },
  { id: 'SYSTEM_ENTER', text: 'Вход в системата', },
  { id: 'SERVICE_GROUP', text: 'Сървис', },
  { id: 'VACCINATION', text: 'Ваксинация', },
  { id: 'PREGNANCY_REPORTING', text: 'Бременност', },
  { id: 'WEANING', text: 'Отбиване', },
  { id: 'HEAT', text: 'Разгонване', },
  { id: 'DRYING', text: 'Пресушаване', }
];

export const ASSIS_YEAR = [
  { id: 'ASSIS_17', text: '2017', },
  { id: 'ASSIS_18', text: '2018', },
  { id: 'ASSIS_19', text: '2019', },
  { id: 'ASSIS_20', text: '2020', },
  { id: 'ASSIS_21', text: '2021', },
  { id: 'ASSIS_22', text: '2022', }
];


export const TABLE_BG_MESSAGES = {
  calendar: {
    today: 'Днес',
  },
  grid: {
    groupPanelEmpty: 'Провлачете колона и я пуснете тук, за да групирате по тази колона',
    pagerInfo: '{0} - {1} от {2} записи',
    pagerItemsPerPage: 'записa на страница',
    noRecords: 'Няма намерени записи.',
    filterEqOperator: 'Е равно на',
    filterNotEqOperator: 'Не е равно на',
    filterGtOperator: 'Е по голямо от',
    filterGteOperator: 'Е по голямо или равно',
    filterLteOperator: 'Е по малко или равно',
    filterLtOperator: 'Е по малко',
    filterAndLogic: 'и',
    filterOrLogic: 'или',
    filterClearButton: 'Изчисти',
    filterSubmitButton: 'Филтрирай',
    filterTitle: 'Филтрирай',
    filterAfterOrEqualOperator: 'Е след или равно на',
    filterAfterOperator: 'E след',
    filterBeforeOperator: 'Е преди',
    filterBeforeOrEqualOperator: 'Е преди или равно на',
  },
};


export const bgDateFields = {
  main: {
    bg: {
      identity: {
        version: {
          _cldrVersion: '36',
        },
        language: 'bg',
      },
      dates: {
        fields: {
          era: {
            displayName: 'ера',
          },
          'era-short': {
            displayName: 'ера',
          },
          'era-narrow': {
            displayName: 'ера',
          },
          year: {
            displayName: 'година',
            'relative-type--1': 'миналата година',
            'relative-type-0': 'тази година',
            'relative-type-1': 'следващата година',
            'relativeTime-type-future': {
              'relativeTimePattern-count-one': 'след {0} година',
              'relativeTimePattern-count-other': 'след {0} години',
            },
            'relativeTime-type-past': {
              'relativeTimePattern-count-one': 'преди {0} година',
              'relativeTimePattern-count-other': 'преди {0} години',
            },
          },
          'year-short': {
            displayName: 'г.',
            'relative-type--1': 'мин. г.',
            'relative-type-0': 'т. г.',
            'relative-type-1': 'следв. г.',
            'relativeTime-type-future': {
              'relativeTimePattern-count-one': 'след {0} г.',
              'relativeTimePattern-count-other': 'след {0} г.',
            },
            'relativeTime-type-past': {
              'relativeTimePattern-count-one': 'преди {0} г.',
              'relativeTimePattern-count-other': 'преди {0} г.',
            },
          },
          'year-narrow': {
            displayName: 'г.',
            'relative-type--1': 'мин. г.',
            'relative-type-0': 'т. г.',
            'relative-type-1': 'сл. г.',
            'relativeTime-type-future': {
              'relativeTimePattern-count-one': 'сл. {0} г.',
              'relativeTimePattern-count-other': 'сл. {0} г.',
            },
            'relativeTime-type-past': {
              'relativeTimePattern-count-one': 'пр. {0} г.',
              'relativeTimePattern-count-other': 'пр. {0} г.',
            },
          },
          quarter: {
            displayName: 'тримесечие',
            'relative-type--1': 'предходно тримесечие',
            'relative-type-0': 'това тримесечие',
            'relative-type-1': 'следващо тримесечие',
            'relativeTime-type-future': {
              'relativeTimePattern-count-one': 'след {0} тримесечие',
              'relativeTimePattern-count-other': 'след {0} тримесечия',
            },
            'relativeTime-type-past': {
              'relativeTimePattern-count-one': 'преди {0} тримесечие',
              'relativeTimePattern-count-other': 'преди {0} тримесечия',
            },
          },
          'quarter-short': {
            displayName: 'трим.',
            'relative-type--1': 'мин. трим.',
            'relative-type-0': 'това трим.',
            'relative-type-1': 'следв. трим.',
            'relativeTime-type-future': {
              'relativeTimePattern-count-one': 'след {0} трим.',
              'relativeTimePattern-count-other': 'след {0} трим.',
            },
            'relativeTime-type-past': {
              'relativeTimePattern-count-one': 'преди {0} трим.',
              'relativeTimePattern-count-other': 'преди {0} трим.',
            },
          },
          'quarter-narrow': {
            displayName: 'трим.',
            'relative-type--1': 'мин. трим.',
            'relative-type-0': 'това трим.',
            'relative-type-1': 'следв. трим.',
            'relativeTime-type-future': {
              'relativeTimePattern-count-one': 'сл. {0} трим.',
              'relativeTimePattern-count-other': 'сл. {0} трим.',
            },
            'relativeTime-type-past': {
              'relativeTimePattern-count-one': 'пр. {0} трим.',
              'relativeTimePattern-count-other': 'пр. {0} трим.',
            },
          },
          month: {
            displayName: 'месец',
            'relative-type--1': 'предходен месец',
            'relative-type-0': 'този месец',
            'relative-type-1': 'следващ месец',
            'relativeTime-type-future': {
              'relativeTimePattern-count-one': 'след {0} месец',
              'relativeTimePattern-count-other': 'след {0} месеца',
            },
            'relativeTime-type-past': {
              'relativeTimePattern-count-one': 'преди {0} месец',
              'relativeTimePattern-count-other': 'преди {0} месеца',
            },
          },
          'month-short': {
            displayName: 'мес.',
            'relative-type--1': 'мин. мес.',
            'relative-type-0': 'този мес.',
            'relative-type-1': 'следв. мес.',
            'relativeTime-type-future': {
              'relativeTimePattern-count-one': 'след {0} м.',
              'relativeTimePattern-count-other': 'след {0} м.',
            },
            'relativeTime-type-past': {
              'relativeTimePattern-count-one': 'преди {0} м.',
              'relativeTimePattern-count-other': 'преди {0} м.',
            },
          },
          'month-narrow': {
            displayName: 'мес.',
            'relative-type--1': 'мин. м.',
            'relative-type-0': 'т. м.',
            'relative-type-1': 'сл. м.',
            'relativeTime-type-future': {
              'relativeTimePattern-count-one': 'сл. {0} м.',
              'relativeTimePattern-count-other': 'сл. {0} м.',
            },
            'relativeTime-type-past': {
              'relativeTimePattern-count-one': 'пр. {0} м.',
              'relativeTimePattern-count-other': 'пр. {0} м.',
            },
          },
          week: {
            displayName: 'седмица',
            'relative-type--1': 'предходната седмица',
            'relative-type-0': 'тази седмица',
            'relative-type-1': 'следващата седмица',
            'relativeTime-type-future': {
              'relativeTimePattern-count-one': 'след {0} седмица',
              'relativeTimePattern-count-other': 'след {0} седмици',
            },
            'relativeTime-type-past': {
              'relativeTimePattern-count-one': 'преди {0} седмица',
              'relativeTimePattern-count-other': 'преди {0} седмици',
            },
            relativePeriod: 'седмицата от {0}',
          },
          'week-short': {
            displayName: 'седм.',
            'relative-type--1': 'мин. седм.',
            'relative-type-0': 'тази седм.',
            'relative-type-1': 'следв. седм.',
            'relativeTime-type-future': {
              'relativeTimePattern-count-one': 'след {0} седм.',
              'relativeTimePattern-count-other': 'след {0} седм.',
            },
            'relativeTime-type-past': {
              'relativeTimePattern-count-one': 'преди {0} седм.',
              'relativeTimePattern-count-other': 'преди {0} седм.',
            },
            relativePeriod: 'седмицата от {0}',
          },
          'week-narrow': {
            displayName: 'седм.',
            'relative-type--1': 'мин. седм.',
            'relative-type-0': 'тази седм.',
            'relative-type-1': 'сл. седм.',
            'relativeTime-type-future': {
              'relativeTimePattern-count-one': 'сл. {0} седм.',
              'relativeTimePattern-count-other': 'сл. {0} седм.',
            },
            'relativeTime-type-past': {
              'relativeTimePattern-count-one': 'пр. {0} седм.',
              'relativeTimePattern-count-other': 'пр. {0} седм.',
            },
            relativePeriod: 'седмицата от {0}',
          },
          weekOfMonth: {
            displayName: 'седмица от месеца',
          },
          'weekOfMonth-short': {
            displayName: 'седмица от месеца',
          },
          'weekOfMonth-narrow': {
            displayName: 'седмица от месеца',
          },
          day: {
            displayName: 'ден',
            'relative-type--2': 'онзи ден',
            'relative-type--1': 'вчера',
            'relative-type-0': 'днес',
            'relative-type-1': 'утре',
            'relative-type-2': 'вдругиден',
            'relativeTime-type-future': {
              'relativeTimePattern-count-one': 'след {0} ден',
              'relativeTimePattern-count-other': 'след {0} дни',
            },
            'relativeTime-type-past': {
              'relativeTimePattern-count-one': 'преди {0} ден',
              'relativeTimePattern-count-other': 'преди {0} дни',
            },
          },
          'day-short': {
            displayName: 'д',
            'relative-type--2': 'онзи ден',
            'relative-type--1': 'вчера',
            'relative-type-0': 'днес',
            'relative-type-1': 'утре',
            'relative-type-2': 'вдругиден',
            'relativeTime-type-future': {
              'relativeTimePattern-count-one': 'след {0} ден',
              'relativeTimePattern-count-other': 'след {0} дни',
            },
            'relativeTime-type-past': {
              'relativeTimePattern-count-one': 'преди {0} ден',
              'relativeTimePattern-count-other': 'преди {0} дни',
            },
          },
          'day-narrow': {
            displayName: 'д',
            'relative-type--2': 'онзи ден',
            'relative-type--1': 'вчера',
            'relative-type-0': 'днес',
            'relative-type-1': 'утре',
            'relative-type-2': 'вдругиден',
            'relativeTime-type-future': {
              'relativeTimePattern-count-one': 'сл. {0} д',
              'relativeTimePattern-count-other': 'сл. {0} д',
            },
            'relativeTime-type-past': {
              'relativeTimePattern-count-one': 'пр. {0} д',
              'relativeTimePattern-count-other': 'пр. {0} д',
            },
          },
          dayOfYear: {
            displayName: 'ден от годината',
          },
          'dayOfYear-short': {
            displayName: 'ден от г.',
          },
          'dayOfYear-narrow': {
            displayName: 'ден от г.',
          },
          weekday: {
            displayName: 'ден от седмицата',
          },
          'weekday-short': {
            displayName: 'ден от седм.',
          },
          'weekday-narrow': {
            displayName: 'ден от седм.',
          },
          weekdayOfMonth: {
            displayName: 'работен ден от месеца',
          },
          'weekdayOfMonth-short': {
            displayName: 'раб. ден от мес.',
          },
          'weekdayOfMonth-narrow': {
            displayName: 'раб. ден от мес.',
          },
          sun: {
            'relative-type--1': 'предходната неделя',
            'relative-type-0': 'тази неделя',
            'relative-type-1': 'следващата неделя',
            'relativeTime-type-future': {
              'relativeTimePattern-count-one': 'след {0} неделя',
              'relativeTimePattern-count-other': 'след {0} недели',
            },
            'relativeTime-type-past': {
              'relativeTimePattern-count-one': 'преди {0} неделя',
              'relativeTimePattern-count-other': 'преди {0} недели',
            },
          },
          'sun-short': {
            'relative-type--1': 'предходната нд',
            'relative-type-0': 'тази нд',
            'relative-type-1': 'следващата нд',
            'relativeTime-type-future': {
              'relativeTimePattern-count-one': 'след {0} нд',
              'relativeTimePattern-count-other': 'след {0} нд',
            },
            'relativeTime-type-past': {
              'relativeTimePattern-count-one': 'преди {0} нд',
              'relativeTimePattern-count-other': 'преди {0} нд',
            },
          },
          'sun-narrow': {
            'relative-type--1': 'предх. нд',
            'relative-type-0': 'тази нд',
            'relative-type-1': 'следв. нд',
            'relativeTime-type-future': {
              'relativeTimePattern-count-one': 'сл. {0} нд',
              'relativeTimePattern-count-other': 'сл. {0} нд',
            },
            'relativeTime-type-past': {
              'relativeTimePattern-count-one': 'пр. {0} нд',
              'relativeTimePattern-count-other': 'пр. {0} нд',
            },
          },
          mon: {
            'relative-type--1': 'предходният понеделник',
            'relative-type-0': 'този понеделник',
            'relative-type-1': 'следващият понеделник',
            'relativeTime-type-future': {
              'relativeTimePattern-count-one': 'след {0} понеделник',
              'relativeTimePattern-count-other': 'след {0} понеделника',
            },
            'relativeTime-type-past': {
              'relativeTimePattern-count-one': 'преди {0} понеделник',
              'relativeTimePattern-count-other': 'преди {0} понеделника',
            },
          },
          'mon-short': {
            'relative-type--1': 'предходният пн',
            'relative-type-0': 'този пн',
            'relative-type-1': 'следващият пн',
            'relativeTime-type-future': {
              'relativeTimePattern-count-one': 'след {0} пн',
              'relativeTimePattern-count-other': 'след {0} пн',
            },
            'relativeTime-type-past': {
              'relativeTimePattern-count-one': 'преди {0} пн',
              'relativeTimePattern-count-other': 'преди {0} пн',
            },
          },
          'mon-narrow': {
            'relative-type--1': 'предх. пн',
            'relative-type-0': 'този пн',
            'relative-type-1': 'следв. пн',
            'relativeTime-type-future': {
              'relativeTimePattern-count-one': 'сл. {0} пн',
              'relativeTimePattern-count-other': 'сл. {0} пн',
            },
            'relativeTime-type-past': {
              'relativeTimePattern-count-one': 'пр. {0} пн',
              'relativeTimePattern-count-other': 'пр. {0} пн',
            },
          },
          tue: {
            'relative-type--1': 'предходният вторник',
            'relative-type-0': 'този вторник',
            'relative-type-1': 'следващият вторник',
            'relativeTime-type-future': {
              'relativeTimePattern-count-one': 'след {0} вторник',
              'relativeTimePattern-count-other': 'след {0} вторника',
            },
            'relativeTime-type-past': {
              'relativeTimePattern-count-one': 'преди {0} вторник',
              'relativeTimePattern-count-other': 'преди {0} вторника',
            },
          },
          'tue-short': {
            'relative-type--1': 'предходният вт',
            'relative-type-0': 'този вт',
            'relative-type-1': 'следващият вт',
            'relativeTime-type-future': {
              'relativeTimePattern-count-one': 'след {0} вт',
              'relativeTimePattern-count-other': 'след {0} вт',
            },
            'relativeTime-type-past': {
              'relativeTimePattern-count-one': 'преди {0} вт',
              'relativeTimePattern-count-other': 'преди {0} вт',
            },
          },
          'tue-narrow': {
            'relative-type--1': 'предх. вт',
            'relative-type-0': 'този вт',
            'relative-type-1': 'следв. вт',
            'relativeTime-type-future': {
              'relativeTimePattern-count-one': 'сл. {0} вт.',
              'relativeTimePattern-count-other': 'сл. {0} вт.',
            },
            'relativeTime-type-past': {
              'relativeTimePattern-count-one': 'пр. {0} вт.',
              'relativeTimePattern-count-other': 'пр. {0} вт.',
            },
          },
          wed: {
            'relative-type--1': 'предходната сряда',
            'relative-type-0': 'тази сряда',
            'relative-type-1': 'следващата сряда',
            'relativeTime-type-future': {
              'relativeTimePattern-count-one': 'след {0} сряда',
              'relativeTimePattern-count-other': 'след {0} среди',
            },
            'relativeTime-type-past': {
              'relativeTimePattern-count-one': 'преди {0} сряда',
              'relativeTimePattern-count-other': 'преди {0} среди',
            },
          },
          'wed-short': {
            'relative-type--1': 'предходната ср',
            'relative-type-0': 'тази ср',
            'relative-type-1': 'следващата ср',
            'relativeTime-type-future': {
              'relativeTimePattern-count-one': 'след {0} ср',
              'relativeTimePattern-count-other': 'след {0} ср',
            },
            'relativeTime-type-past': {
              'relativeTimePattern-count-one': 'преди {0} ср',
              'relativeTimePattern-count-other': 'преди {0} ср',
            },
          },
          'wed-narrow': {
            'relative-type--1': 'предх. ср',
            'relative-type-0': 'тази ср',
            'relative-type-1': 'следв. ср',
            'relativeTime-type-future': {
              'relativeTimePattern-count-one': 'сл. {0} ср',
              'relativeTimePattern-count-other': 'сл. {0} ср',
            },
            'relativeTime-type-past': {
              'relativeTimePattern-count-one': 'пр. {0} ср',
              'relativeTimePattern-count-other': 'пр. {0} ср',
            },
          },
          thu: {
            'relative-type--1': 'предходният четвъртък',
            'relative-type-0': 'този четвъртък',
            'relative-type-1': 'следващият четвъртък',
            'relativeTime-type-future': {
              'relativeTimePattern-count-one': 'след {0} четвъртък',
              'relativeTimePattern-count-other': 'след {0} четвъртъка',
            },
            'relativeTime-type-past': {
              'relativeTimePattern-count-one': 'преди {0} четвъртък',
              'relativeTimePattern-count-other': 'преди {0} четвъртъка',
            },
          },
          'thu-short': {
            'relative-type--1': 'предходният чт',
            'relative-type-0': 'този чт',
            'relative-type-1': 'следващият чт',
            'relativeTime-type-future': {
              'relativeTimePattern-count-one': 'след {0} чт',
              'relativeTimePattern-count-other': 'след {0} чт',
            },
            'relativeTime-type-past': {
              'relativeTimePattern-count-one': 'преди {0} чт',
              'relativeTimePattern-count-other': 'преди {0} чт',
            },
          },
          'thu-narrow': {
            'relative-type--1': 'предх. чт',
            'relative-type-0': 'този чт',
            'relative-type-1': 'следв. чт',
            'relativeTime-type-future': {
              'relativeTimePattern-count-one': 'след {0} четвъртък',
              'relativeTimePattern-count-other': 'след {0} четвъртъка',
            },
            'relativeTime-type-past': {
              'relativeTimePattern-count-one': 'пр. {0} чт',
              'relativeTimePattern-count-other': 'пр. {0} чт',
            },
          },
          fri: {
            'relative-type--1': 'предходният петък',
            'relative-type-0': 'този петък',
            'relative-type-1': 'следващият петък',
            'relativeTime-type-future': {
              'relativeTimePattern-count-one': 'след {0} петък',
              'relativeTimePattern-count-other': 'след {0} петъка',
            },
            'relativeTime-type-past': {
              'relativeTimePattern-count-one': 'преди {0} петък',
              'relativeTimePattern-count-other': 'преди {0} петъка',
            },
          },
          'fri-short': {
            'relative-type--1': 'предходният пт',
            'relative-type-0': 'този пт',
            'relative-type-1': 'следващият пт',
            'relativeTime-type-future': {
              'relativeTimePattern-count-one': 'след {0} пт',
              'relativeTimePattern-count-other': 'след {0} пт',
            },
            'relativeTime-type-past': {
              'relativeTimePattern-count-one': 'преди {0} пт',
              'relativeTimePattern-count-other': 'преди {0} пт',
            },
          },
          'fri-narrow': {
            'relative-type--1': 'предх. пт',
            'relative-type-0': 'този пт',
            'relative-type-1': 'следв. пт',
            'relativeTime-type-future': {
              'relativeTimePattern-count-one': 'сл. {0} пт',
              'relativeTimePattern-count-other': 'сл. {0} пт',
            },
            'relativeTime-type-past': {
              'relativeTimePattern-count-one': 'пр. {0} пт',
              'relativeTimePattern-count-other': 'пр. {0} пт',
            },
          },
          sat: {
            'relative-type--1': 'предходната събота',
            'relative-type-0': 'тази събота',
            'relative-type-1': 'следващата събота',
            'relativeTime-type-future': {
              'relativeTimePattern-count-one': 'след {0} събота',
              'relativeTimePattern-count-other': 'след {0} съботи',
            },
            'relativeTime-type-past': {
              'relativeTimePattern-count-one': 'преди {0} събота',
              'relativeTimePattern-count-other': 'преди {0} съботи',
            },
          },
          'sat-short': {
            'relative-type--1': 'предходната сб',
            'relative-type-0': 'тази сб',
            'relative-type-1': 'следващата сб',
            'relativeTime-type-future': {
              'relativeTimePattern-count-one': 'след {0} сб',
              'relativeTimePattern-count-other': 'след {0} сб',
            },
            'relativeTime-type-past': {
              'relativeTimePattern-count-one': 'преди {0} сб',
              'relativeTimePattern-count-other': 'преди {0} сб',
            },
          },
          'sat-narrow': {
            'relative-type--1': 'предх. сб',
            'relative-type-0': 'тази сб',
            'relative-type-1': 'следв. сб',
            'relativeTime-type-future': {
              'relativeTimePattern-count-one': 'сл. {0} сб',
              'relativeTimePattern-count-other': 'сл. {0} сб',
            },
            'relativeTime-type-past': {
              'relativeTimePattern-count-one': 'пр. {0} сб',
              'relativeTimePattern-count-other': 'пр. {0} сб',
            },
          },
          'dayperiod-short': {
            displayName: 'пр.об./сл.об.',
          },
          dayperiod: {
            displayName: 'пр.об./сл.об.',
          },
          'dayperiod-narrow': {
            displayName: 'пр.об./сл.об.',
          },
          hour: {
            displayName: 'час',
            'relative-type-0': 'в този час',
            'relativeTime-type-future': {
              'relativeTimePattern-count-one': 'след {0} час',
              'relativeTimePattern-count-other': 'след {0} часа',
            },
            'relativeTime-type-past': {
              'relativeTimePattern-count-one': 'преди {0} час',
              'relativeTimePattern-count-other': 'преди {0} часа',
            },
          },
          'hour-short': {
            displayName: 'ч',
            'relative-type-0': 'в този час',
            'relativeTime-type-future': {
              'relativeTimePattern-count-one': 'след {0} ч',
              'relativeTimePattern-count-other': 'след {0} ч',
            },
            'relativeTime-type-past': {
              'relativeTimePattern-count-one': 'преди {0} ч',
              'relativeTimePattern-count-other': 'преди {0} ч',
            },
          },
          'hour-narrow': {
            displayName: 'ч',
            'relative-type-0': 'в този час',
            'relativeTime-type-future': {
              'relativeTimePattern-count-one': 'сл. {0} ч',
              'relativeTimePattern-count-other': 'сл. {0} ч',
            },
            'relativeTime-type-past': {
              'relativeTimePattern-count-one': 'пр. {0} ч',
              'relativeTimePattern-count-other': 'пр. {0} ч',
            },
          },
          minute: {
            displayName: 'минута',
            'relative-type-0': 'в тази минута',
            'relativeTime-type-future': {
              'relativeTimePattern-count-one': 'след {0} минута',
              'relativeTimePattern-count-other': 'след {0} минути',
            },
            'relativeTime-type-past': {
              'relativeTimePattern-count-one': 'преди {0} минута',
              'relativeTimePattern-count-other': 'преди {0} минути',
            },
          },
          'minute-short': {
            displayName: 'мин',
            'relative-type-0': 'в тази минута',
            'relativeTime-type-future': {
              'relativeTimePattern-count-one': 'след {0} мин',
              'relativeTimePattern-count-other': 'след {0} мин',
            },
            'relativeTime-type-past': {
              'relativeTimePattern-count-one': 'преди {0} мин',
              'relativeTimePattern-count-other': 'преди {0} мин',
            },
          },
          'minute-narrow': {
            displayName: 'мин',
            'relative-type-0': 'в тази минута',
            'relativeTime-type-future': {
              'relativeTimePattern-count-one': 'сл. {0} мин',
              'relativeTimePattern-count-other': 'сл. {0} мин',
            },
            'relativeTime-type-past': {
              'relativeTimePattern-count-one': 'пр. {0} мин',
              'relativeTimePattern-count-other': 'пр. {0} мин',
            },
          },
          second: {
            displayName: 'секунда',
            'relative-type-0': 'сега',
            'relativeTime-type-future': {
              'relativeTimePattern-count-one': 'след {0} секунда',
              'relativeTimePattern-count-other': 'след {0} секунди',
            },
            'relativeTime-type-past': {
              'relativeTimePattern-count-one': 'преди {0} секунда',
              'relativeTimePattern-count-other': 'преди {0} секунди',
            },
          },
          'second-short': {
            displayName: 'сек',
            'relative-type-0': 'сега',
            'relativeTime-type-future': {
              'relativeTimePattern-count-one': 'след {0} сек',
              'relativeTimePattern-count-other': 'след {0} сек',
            },
            'relativeTime-type-past': {
              'relativeTimePattern-count-one': 'преди {0} сек',
              'relativeTimePattern-count-other': 'преди {0} сек',
            },
          },
          'second-narrow': {
            displayName: 'с',
            'relative-type-0': 'сега',
            'relativeTime-type-future': {
              'relativeTimePattern-count-one': 'сл. {0} сек',
              'relativeTimePattern-count-other': 'сл. {0} сек',
            },
            'relativeTime-type-past': {
              'relativeTimePattern-count-one': 'пр. {0} сек',
              'relativeTimePattern-count-other': 'пр. {0} сек',
            },
          },
          zone: {
            displayName: 'часова зона',
          },
          'zone-short': {
            displayName: 'час. зона',
          },
          'zone-narrow': {
            displayName: 'час. зона',
          },
        },
      },
    },
  },
};

export const bgGregorian = {
  main: {
    bg: {
      identity: {
        version: {
          _cldrVersion: '36',
        },
        language: 'bg',
      },
      dates: {
        calendars: {
          gregorian: {
            months: {
              format: {
                abbreviated: {
                  1: 'яну',
                  2: 'фев',
                  3: 'март',
                  4: 'апр',
                  5: 'май',
                  6: 'юни',
                  7: 'юли',
                  8: 'авг',
                  9: 'сеп',
                  10: 'окт',
                  11: 'ное',
                  12: 'дек',
                },
                narrow: {
                  1: 'я',
                  2: 'ф',
                  3: 'м',
                  4: 'а',
                  5: 'м',
                  6: 'ю',
                  7: 'ю',
                  8: 'а',
                  9: 'с',
                  10: 'о',
                  11: 'н',
                  12: 'д',
                },
                wide: {
                  1: 'януари',
                  2: 'февруари',
                  3: 'март',
                  4: 'април',
                  5: 'май',
                  6: 'юни',
                  7: 'юли',
                  8: 'август',
                  9: 'септември',
                  10: 'октомври',
                  11: 'ноември',
                  12: 'декември',
                },
              },
              'stand-alone': {
                abbreviated: {
                  1: 'яну',
                  2: 'фев',
                  3: 'март',
                  4: 'апр',
                  5: 'май',
                  6: 'юни',
                  7: 'юли',
                  8: 'авг',
                  9: 'сеп',
                  10: 'окт',
                  11: 'ное',
                  12: 'дек',
                },
                narrow: {
                  1: 'я',
                  2: 'ф',
                  3: 'м',
                  4: 'а',
                  5: 'м',
                  6: 'ю',
                  7: 'ю',
                  8: 'а',
                  9: 'с',
                  10: 'о',
                  11: 'н',
                  12: 'д',
                },
                wide: {
                  1: 'януари',
                  2: 'февруари',
                  3: 'март',
                  4: 'април',
                  5: 'май',
                  6: 'юни',
                  7: 'юли',
                  8: 'август',
                  9: 'септември',
                  10: 'октомври',
                  11: 'ноември',
                  12: 'декември',
                },
              },
            },
            days: {
              format: {
                abbreviated: {
                  sun: 'нд',
                  mon: 'пн',
                  tue: 'вт',
                  wed: 'ср',
                  thu: 'чт',
                  fri: 'пт',
                  sat: 'сб',
                },
                narrow: {
                  sun: 'н',
                  mon: 'п',
                  tue: 'в',
                  wed: 'с',
                  thu: 'ч',
                  fri: 'п',
                  sat: 'с',
                },
                short: {
                  sun: 'нд',
                  mon: 'пн',
                  tue: 'вт',
                  wed: 'ср',
                  thu: 'чт',
                  fri: 'пт',
                  sat: 'сб',
                },
                wide: {
                  sun: 'неделя',
                  mon: 'понеделник',
                  tue: 'вторник',
                  wed: 'сряда',
                  thu: 'четвъртък',
                  fri: 'петък',
                  sat: 'събота',
                },
              },
              'stand-alone': {
                abbreviated: {
                  sun: 'нд',
                  mon: 'пн',
                  tue: 'вт',
                  wed: 'ср',
                  thu: 'чт',
                  fri: 'пт',
                  sat: 'сб',
                },
                narrow: {
                  sun: 'н',
                  mon: 'п',
                  tue: 'в',
                  wed: 'с',
                  thu: 'ч',
                  fri: 'п',
                  sat: 'с',
                },
                short: {
                  sun: 'нд',
                  mon: 'пн',
                  tue: 'вт',
                  wed: 'ср',
                  thu: 'чт',
                  fri: 'пт',
                  sat: 'сб',
                },
                wide: {
                  sun: 'неделя',
                  mon: 'понеделник',
                  tue: 'вторник',
                  wed: 'сряда',
                  thu: 'четвъртък',
                  fri: 'петък',
                  sat: 'събота',
                },
              },
            },
            quarters: {
              format: {
                abbreviated: {
                  1: '1. трим.',
                  2: '2. трим.',
                  3: '3. трим.',
                  4: '4. трим.',
                },
                narrow: {
                  1: '1',
                  2: '2',
                  3: '3',
                  4: '4',
                },
                wide: {
                  1: '1. тримесечие',
                  2: '2. тримесечие',
                  3: '3. тримесечие',
                  4: '4. тримесечие',
                },
              },
              'stand-alone': {
                abbreviated: {
                  1: '1. трим.',
                  2: '2. трим.',
                  3: '3. трим.',
                  4: '4. трим.',
                },
                narrow: {
                  1: '1',
                  2: '2',
                  3: '3',
                  4: '4',
                },
                wide: {
                  1: '1. тримесечие',
                  2: '2. тримесечие',
                  3: '3. тримесечие',
                  4: '4. тримесечие',
                },
              },
            },
            dayPeriods: {
              format: {
                abbreviated: {
                  midnight: 'полунощ',
                  am: 'am',
                  pm: 'pm',
                  morning1: 'сутринта',
                  morning2: 'на обяд',
                  afternoon1: 'следобед',
                  evening1: 'вечерта',
                  night1: 'през нощта',
                },
                narrow: {
                  midnight: 'полунощ',
                  am: 'am',
                  pm: 'pm',
                  morning1: 'сутринта',
                  morning2: 'на обяд',
                  afternoon1: 'следобед',
                  evening1: 'вечерта',
                  night1: 'през нощта',
                },
                wide: {
                  midnight: 'полунощ',
                  am: 'пр.об.',
                  pm: 'сл.об.',
                  morning1: 'сутринта',
                  morning2: 'на обяд',
                  afternoon1: 'следобед',
                  evening1: 'вечерта',
                  night1: 'през нощта',
                },
              },
              'stand-alone': {
                abbreviated: {
                  midnight: 'полунощ',
                  am: 'am',
                  pm: 'pm',
                  morning1: 'сутринта',
                  morning2: 'на обяд',
                  afternoon1: 'следобед',
                  evening1: 'вечерта',
                  night1: 'през нощта',
                },
                narrow: {
                  midnight: 'полунощ',
                  am: 'am',
                  pm: 'pm',
                  morning1: 'сутринта',
                  morning2: 'на обяд',
                  afternoon1: 'следобед',
                  evening1: 'вечерта',
                  night1: 'през нощта',
                },
                wide: {
                  midnight: 'полунощ',
                  am: 'am',
                  pm: 'pm',
                  morning1: 'сутринта',
                  morning2: 'на обяд',
                  afternoon1: 'следобед',
                  evening1: 'вечерта',
                  night1: 'през нощта',
                },
              },
            },
            eras: {
              eraNames: {
                0: 'преди Христа',
                '0-alt-variant': 'преди новата ера',
                1: 'след Христа',
                '1-alt-variant': 'след новата ера',
              },
              eraAbbr: {
                0: 'пр.Хр.',
                '0-alt-variant': 'пр.н.е.',
                1: 'сл.Хр.',
                '1-alt-variant': 'сл.н.е.',
              },
              eraNarrow: {
                0: 'пр.Хр.',
                '0-alt-variant': 'пр.н.е.',
                1: 'сл.Хр.',
                '1-alt-variant': 'сл.н.е.',
              },
            },
            dateFormats: {
              full: "EEEE, d MMMM y 'г'.",
              long: "d MMMM y 'г'.",
              medium: "d.MM.y 'г'.",
              short: "d.MM.yy 'г'.",
            },
            timeFormats: {
              full: 'H:mm:ss zzzz',
              long: 'H:mm:ss z',
              medium: 'H:mm:ss',
              short: 'H:mm',
            },
            dateTimeFormats: {
              full: '{1}, {0}',
              long: '{1}, {0}',
              medium: '{1}, {0}',
              short: '{1}, {0}',
              availableFormats: {
                Bh: 'h B',
                Bhm: 'h:mm B',
                Bhms: 'h:mm:ss B',
                d: 'd',
                E: 'ccc',
                EBhm: 'E, h:mm B',
                EBhms: 'E, h:mm:ss B',
                Ed: 'E, d',
                Ehm: 'E, h:mm a',
                EHm: 'E, HH:mm',
                Ehms: 'E, h:mm:ss a',
                EHms: 'E, H:mm:ss',
                Gy: "y 'г'. G",
                GyMMM: "MM.y 'г'. G",
                GyMMMd: "d.MM.y 'г'. G",
                GyMMMEd: "E, d.MM.y 'г'. G",
                GyMMMM: "MMMM y 'г'. G",
                GyMMMMd: "d MMMM y 'г'. G",
                GyMMMMEd: "E, d MMMM y 'г'. G",
                h: 'h a',
                H: 'H',
                hm: 'h:mm a',
                Hm: 'H:mm',
                hms: 'h:mm:ss a',
                Hms: 'H:mm:ss',
                hmsv: 'h:mm:ss a v',
                Hmsv: 'HH:mm:ss v',
                hmv: 'h:mm a v',
                Hmv: 'HH:mm v',
                M: 'L',
                Md: 'd.MM',
                MEd: 'E, d.MM',
                MMM: 'MM',
                MMMd: 'd.MM',
                MMMEd: 'E, d.MM',
                MMMM: 'LLLL',
                MMMMd: 'd MMMM',
                MMMMdd: 'd MMMM',
                MMMMEd: 'E, d MMMM',
                'MMMMW-count-one': "'седмица' W 'от' MMMM",
                'MMMMW-count-other': "'седмица' W 'от' MMMM",
                ms: 'm:ss',
                y: "y 'г'.",
                yM: "MM.y 'г'.",
                yMd: "d.MM.y 'г'.",
                yMEd: "E, d.MM.y 'г'.",
                yMMM: "MM.y 'г'.",
                yMMMd: "d.MM.y 'г'.",
                yMMMEd: "E, d.MM.y 'г'.",
                yMMMM: "MMMM y 'г'.",
                yMMMMd: "d MMMM y 'г'.",
                yMMMMEd: "E, d MMMM y 'г'.",
                yQQQ: "QQQ y 'г'.",
                yQQQQ: "QQQQ y 'г'.",
                'yw-count-one': "'седмица' w 'от' Y 'г'.",
                'yw-count-other': "'седмица' w 'от' Y 'г'.",
              },
              appendItems: {
                Day: '{0} ({2}: {1})',
                'Day-Of-Week': '{0} {1}',
                Era: '{1} {0}',
                Hour: '{0} ({2}: {1})',
                Minute: '{0} ({2}: {1})',
                Month: '{0} ({2}: {1})',
                Quarter: '{0} ({2}: {1})',
                Second: '{0} ({2}: {1})',
                Timezone: '{0} {1}',
                Week: '{0} ({2}: {1})',
                Year: '{1} {0}',
              },
              intervalFormats: {
                intervalFormatFallback: '{0} – {1}',
                Bh: {
                  B: 'h B – h B',
                  h: 'h – h B',
                },
                Bhm: {
                  B: 'h:mm B – h:mm B',
                  h: 'h:mm – h:mm B',
                  m: 'h:mm – h:mm B',
                },
                d: {
                  d: 'd – d',
                },
                Gy: {
                  G: 'y G – y G',
                  y: 'y – y G',
                },
                GyM: {
                  G: 'MM.y GGGGG – MM.y GGGGG',
                  M: 'MM.y – MM.y GGGGG',
                  y: 'MM.y – MM.y GGGGG',
                },
                GyMd: {
                  d: 'dd.MM.y – dd.MM.y GGGGG',
                  G: 'dd.MM.y GGGGG – dd.MM.y GGGGG',
                  M: 'dd.MM.y – dd.MM.y GGGGG',
                  y: 'dd.MM.y – dd.MM.y GGGGG',
                },
                GyMEd: {
                  d: 'E, dd.MM.y – E, dd.MM.y GGGGG',
                  G: 'E, dd.MM.y GGGGG – E, dd.MM.y GGGGG',
                  M: 'E, dd.MM.y – E, dd.MM.y GGGGG',
                  y: 'E, dd.MM.y – E, dd.MM.y GGGGG',
                },
                GyMMM: {
                  G: 'MMM y G – MMM y G',
                  M: 'MMM – MMM y G',
                  y: 'MMM y – MMM y G',
                },
                GyMMMd: {
                  d: 'd – d MMM y G',
                  G: 'd MMM y G – d MMM y G',
                  M: 'd MMM – d MMM y G',
                  y: 'd MMM y – d MMM y G',
                },
                GyMMMEd: {
                  d: 'E, d MMM – E, d MMM y G',
                  G: 'E, d MMM y G – E, d MMM y G',
                  M: 'E, d MMM – E, d MMM y G',
                  y: 'E, d MMM y – E, d MMM y G',
                },
                h: {
                  a: 'h a – h a',
                  h: 'h – h a',
                },
                H: {
                  H: 'H – H',
                },
                hm: {
                  a: 'h:mm a – h:mm a',
                  h: 'h:mm – h:mm a',
                  m: 'h:mm – h:mm a',
                },
                Hm: {
                  H: 'H:mm – H:mm',
                  m: 'H:mm – H:mm',
                },
                hmv: {
                  a: 'h:mm a – h:mm a v',
                  h: 'h:mm a – h:mm a v',
                  m: 'h:mm a – h:mm a v',
                },
                Hmv: {
                  H: 'H:mm – H:mm v',
                  m: 'H:mm – H:mm v',
                },
                hv: {
                  a: 'h a – h a v',
                  h: 'h – h a v',
                },
                Hv: {
                  H: 'H – H v',
                },
                M: {
                  M: 'M – M',
                },
                Md: {
                  d: 'd.MM – d.MM',
                  M: 'd.MM – d.MM',
                },
                MEd: {
                  d: 'E, d.MM – E, d.MM',
                  M: 'E, d.MM – E, d.MM',
                },
                MMM: {
                  M: 'MM – MM',
                },
                MMMd: {
                  d: 'd.MM – d.MM',
                  M: 'd.MM – d.MM',
                },
                MMMEd: {
                  d: 'E, d.MM – E, d.MM',
                  M: 'E, d.MM – E, d.MM',
                },
                MMMM: {
                  M: 'LLLL – LLLL',
                },
                MMMMd: {
                  d: 'd – d MMMM',
                  M: 'd MMMM – d MMMM',
                },
                MMMMEd: {
                  d: 'E, d MMMM – E, d MMMM',
                  M: 'E, d MMMM – E, d MMMM',
                },
                y: {
                  y: "y – y 'г'.",
                },
                yM: {
                  M: "MM.y 'г'. – MM.y 'г'.",
                  y: "MM.y 'г'. – MM.y 'г'.",
                },
                yMd: {
                  d: "d.MM – d.MM.y 'г'.",
                  M: "d.MM – d.MM.y 'г'.",
                  y: "d.MM.y 'г'. – d.MM.y 'г'.",
                },
                yMEd: {
                  d: "E, d.MM – E, d.MM.y 'г'.",
                  M: "E, d.MM – E, d.MM.y 'г'.",
                  y: "E, d.MM.y 'г'. – E, d.MM.y 'г'.",
                },
                yMMM: {
                  M: "MM.y 'г'. – MM.y 'г'.",
                  y: "MM.y 'г'. – MM.y 'г'.",
                },
                yMMMd: {
                  d: "d.MM – d.MM.y 'г'.",
                  M: "d.MM – d.MM.y 'г'.",
                  y: "d.MM.y 'г'. – d.MM.y 'г'.",
                },
                yMMMEd: {
                  d: "E, d.MM – E, d.MM.y 'г'.",
                  M: "E, d.MM – E, d.MM.y 'г'.",
                  y: "E, d.MM.y 'г'. – E, d.MM.y 'г'.",
                },
                yMMMM: {
                  M: "MMMM – MMMM y 'г'.",
                  y: "MMMM y 'г'. – MMMM y 'г'.",
                },
                yMMMMd: {
                  d: "d – d MMMM y 'г'.",
                  M: "d MMMM – d MMMM y 'г'.",
                  y: "d MMMM y 'г'. – d MMMM y 'г'.",
                },
                yMMMMEd: {
                  d: "E, d MMMM – E, d MMMM y 'г'.",
                  M: "E, d MMMM – E, d MMMM y 'г'.",
                  y: "E, d MMMM y 'г'. – E, d MMMM y 'г'.",
                },
              },
            },
          },
        },
      },
    },
  },
};

export const weekData = {
  supplemental: {
    version: {
      _unicodeVersion: '12.1.0',
      _cldrVersion: '36',
    },
    weekData: {
      minDays: {
        '001': '1',
        AD: '4',
        AN: '4',
        AT: '4',
        AX: '4',
        BE: '4',
        BG: '4',
        CH: '4',
        CZ: '4',
        DE: '4',
        DK: '4',
        EE: '4',
        ES: '4',
        FI: '4',
        FJ: '4',
        FO: '4',
        FR: '4',
        GB: '4',
        GF: '4',
        GG: '4',
        GI: '4',
        GP: '4',
        GR: '4',
        GU: '1',
        HU: '4',
        IE: '4',
        IM: '4',
        IS: '4',
        IT: '4',
        JE: '4',
        LI: '4',
        LT: '4',
        LU: '4',
        MC: '4',
        MQ: '4',
        NL: '4',
        NO: '4',
        PL: '4',
        PT: '4',
        RE: '4',
        RU: '4',
        SE: '4',
        SJ: '4',
        SK: '4',
        SM: '4',
        UM: '1',
        US: '1',
        VA: '4',
        VI: '1',
      },
      firstDay: {
        '001': 'mon',
        AD: 'mon',
        AE: 'sat',
        AF: 'sat',
        AG: 'sun',
        AI: 'mon',
        AL: 'mon',
        AM: 'mon',
        AN: 'mon',
        AR: 'mon',
        AS: 'sun',
        AT: 'mon',
        AU: 'sun',
        AX: 'mon',
        AZ: 'mon',
        BA: 'mon',
        BD: 'sun',
        BE: 'mon',
        BG: 'mon',
        BH: 'sat',
        BM: 'mon',
        BN: 'mon',
        BR: 'sun',
        BS: 'sun',
        BT: 'sun',
        BW: 'sun',
        BY: 'mon',
        BZ: 'sun',
        CA: 'sun',
        CH: 'mon',
        CL: 'mon',
        CM: 'mon',
        CN: 'sun',
        CO: 'sun',
        CR: 'mon',
        CY: 'mon',
        CZ: 'mon',
        DE: 'mon',
        DJ: 'sat',
        DK: 'mon',
        DM: 'sun',
        DO: 'sun',
        DZ: 'sat',
        EC: 'mon',
        EE: 'mon',
        EG: 'sat',
        ES: 'mon',
        ET: 'sun',
        FI: 'mon',
        FJ: 'mon',
        FO: 'mon',
        FR: 'mon',
        GB: 'mon',
        'GB-alt-variant': 'sun',
        GE: 'mon',
        GF: 'mon',
        GP: 'mon',
        GR: 'mon',
        GT: 'sun',
        GU: 'sun',
        HK: 'sun',
        HN: 'sun',
        HR: 'mon',
        HU: 'mon',
        ID: 'sun',
        IE: 'mon',
        IL: 'sun',
        IN: 'sun',
        IQ: 'sat',
        IR: 'sat',
        IS: 'mon',
        IT: 'mon',
        JM: 'sun',
        JO: 'sat',
        JP: 'sun',
        KE: 'sun',
        KG: 'mon',
        KH: 'sun',
        KR: 'sun',
        KW: 'sat',
        KZ: 'mon',
        LA: 'sun',
        LB: 'mon',
        LI: 'mon',
        LK: 'mon',
        LT: 'mon',
        LU: 'mon',
        LV: 'mon',
        LY: 'sat',
        MC: 'mon',
        MD: 'mon',
        ME: 'mon',
        MH: 'sun',
        MK: 'mon',
        MM: 'sun',
        MN: 'mon',
        MO: 'sun',
        MQ: 'mon',
        MT: 'sun',
        MV: 'fri',
        MX: 'sun',
        MY: 'mon',
        MZ: 'sun',
        NI: 'sun',
        NL: 'mon',
        NO: 'mon',
        NP: 'sun',
        NZ: 'mon',
        OM: 'sat',
        PA: 'sun',
        PE: 'sun',
        PH: 'sun',
        PK: 'sun',
        PL: 'mon',
        PR: 'sun',
        PT: 'sun',
        PY: 'sun',
        QA: 'sat',
        RE: 'mon',
        RO: 'mon',
        RS: 'mon',
        RU: 'mon',
        SA: 'sun',
        SD: 'sat',
        SE: 'mon',
        SG: 'sun',
        SI: 'mon',
        SK: 'mon',
        SM: 'mon',
        SV: 'sun',
        SY: 'sat',
        TH: 'sun',
        TJ: 'mon',
        TM: 'mon',
        TR: 'mon',
        TT: 'sun',
        TW: 'sun',
        UA: 'mon',
        UM: 'sun',
        US: 'sun',
        UY: 'mon',
        UZ: 'mon',
        VA: 'mon',
        VE: 'sun',
        VI: 'sun',
        VN: 'mon',
        WS: 'sun',
        XK: 'mon',
        YE: 'sun',
        ZA: 'sun',
        ZW: 'sun',
      },
      weekendStart: {
        '001': 'sat',
        AE: 'fri',
        AF: 'thu',
        BH: 'fri',
        DZ: 'fri',
        EG: 'fri',
        IL: 'fri',
        IN: 'sun',
        IQ: 'fri',
        IR: 'fri',
        JO: 'fri',
        KW: 'fri',
        LY: 'fri',
        OM: 'fri',
        QA: 'fri',
        SA: 'fri',
        SD: 'fri',
        SY: 'fri',
        UG: 'sun',
        YE: 'fri',
      },
      weekendEnd: {
        '001': 'sun',
        AE: 'sat',
        AF: 'fri',
        BH: 'sat',
        DZ: 'sat',
        EG: 'sat',
        IL: 'sat',
        IQ: 'sat',
        IR: 'fri',
        JO: 'sat',
        KW: 'sat',
        LY: 'sat',
        OM: 'sat',
        QA: 'sat',
        SA: 'sat',
        SD: 'sat',
        SY: 'sat',
        YE: 'sat',
      },
      af: {
        _ordering: 'weekOfDate weekOfInterval weekOfMonth',
      },
      'am az bs cs cy da el et hi ky lt mk sk ta th': {
        _ordering: 'weekOfYear weekOfMonth',
      },
      'ar fil gu hu hy id kk ko': {
        _ordering: 'weekOfMonth',
      },
      'be ro ru': {
        _ordering: 'weekOfInterval weekOfMonth',
      },
      'bg de iw pt ur zh': {
        _ordering: 'weekOfDate weekOfMonth weekOfInterval',
      },
      'ca es fr gl': {
        _ordering: 'weekOfDate',
      },
      'en bn ja ka': {
        _ordering: 'weekOfDate weekOfMonth',
      },
      eu: {
        _ordering: 'weekOfMonth weekOfDate',
      },
      'fa hr it lv pl si sr uk uz': {
        _ordering: 'weekOfMonth weekOfInterval',
      },
      'fi zh-TW': {
        _ordering: 'weekOfYear weekOfDate weekOfMonth',
      },
      'is mn no sv vi': {
        _ordering: 'weekOfYear weekOfMonth weekOfInterval',
      },
      'km mr': {
        _ordering: 'weekOfMonth weekOfYear',
      },
      'kn ml pa': {
        _ordering: 'weekOfMonth weekOfDate weekOfYear',
      },
      'lo sq': {
        _ordering: 'weekOfMonth weekOfInterval weekOfDate weekOfYear',
      },
      'ms tr': {
        _ordering: 'weekOfMonth weekOfYear weekOfInterval weekOfDate',
      },
      nl: {
        _ordering: 'weekOfDate weekOfYear weekOfMonth',
      },
      sl: {
        _ordering: 'weekOfInterval',
      },
      'sw te': {
        _ordering: 'weekOfMonth weekOfInterval weekOfYear',
      },
      und: {
        _ordering: 'weekOfYear',
      },
      zu: {
        _ordering: 'weekOfYear weekOfInterval',
      },
    },
  },
};

export const numbers = {
  main: {
    bg: {
      identity: {
        version: {
          _cldrVersion: '36',
        },
        language: 'bg',
      },
      numbers: {
        defaultNumberingSystem: 'latn',
        otherNumberingSystems: {
          native: 'latn',
        },
        minimumGroupingDigits: '2',
        'symbols-numberSystem-latn': {
          decimal: ',',
          group: ' ',
          list: ';',
          percentSign: '%',
          plusSign: '+',
          minusSign: '-',
          exponential: 'E',
          superscriptingExponent: '×',
          perMille: '‰',
          infinity: '∞',
          nan: 'NaN',
          timeSeparator: ':',
        },
        'decimalFormats-numberSystem-latn': {
          standard: '#,##0.###',
          long: {
            decimalFormat: {
              '1000-count-one': "0 хил'.'",
              '1000-count-other': '0 хиляди',
              '10000-count-one': '00 хиляди',
              '10000-count-other': '00 хиляди',
              '100000-count-one': '000 хиляди',
              '100000-count-other': '000 хиляди',
              '1000000-count-one': '0 милион',
              '1000000-count-other': '0 милиона',
              '10000000-count-one': '00 милиона',
              '10000000-count-other': '00 милиона',
              '100000000-count-one': '000 милиона',
              '100000000-count-other': '000 милиона',
              '1000000000-count-one': '0 милиард',
              '1000000000-count-other': '0 милиарда',
              '10000000000-count-one': '00 милиарда',
              '10000000000-count-other': '00 милиарда',
              '100000000000-count-one': '000 милиарда',
              '100000000000-count-other': '000 милиарда',
              '1000000000000-count-one': '0 трилион',
              '1000000000000-count-other': '0 трилиона',
              '10000000000000-count-one': '00 трилиона',
              '10000000000000-count-other': '00 трилиона',
              '100000000000000-count-one': '000 трилиона',
              '100000000000000-count-other': '000 трилиона',
            },
          },
          short: {
            decimalFormat: {
              '1000-count-one': "0 хил'.'",
              '1000-count-other': "0 хил'.'",
              '10000-count-one': "00 хил'.'",
              '10000-count-other': "00 хил'.'",
              '100000-count-one': "000 хил'.'",
              '100000-count-other': "000 хил'.'",
              '1000000-count-one': "0 млн'.'",
              '1000000-count-other': "0 млн'.'",
              '10000000-count-one': "00 млн'.'",
              '10000000-count-other': "00 млн'.'",
              '100000000-count-one': "000 млн'.'",
              '100000000-count-other': "000 млн'.'",
              '1000000000-count-one': "0 млрд'.'",
              '1000000000-count-other': "0 млрд'.'",
              '10000000000-count-one': "00 млрд'.'",
              '10000000000-count-other': "00 млрд'.'",
              '100000000000-count-one': "000 млрд'.'",
              '100000000000-count-other': "000 млрд'.'",
              '1000000000000-count-one': "0 трлн'.'",
              '1000000000000-count-other': "0 трлн'.'",
              '10000000000000-count-one': "00 трлн'.'",
              '10000000000000-count-other': "00 трлн'.'",
              '100000000000000-count-one': "000 трлн'.'",
              '100000000000000-count-other': "000 трлн'.'",
            },
          },
        },
        'scientificFormats-numberSystem-latn': {
          standard: '#E0',
        },
        'percentFormats-numberSystem-latn': {
          standard: '#,##0%',
        },
        'currencyFormats-numberSystem-latn': {
          currencySpacing: {
            beforeCurrency: {
              currencyMatch: '[:^S:]',
              surroundingMatch: '[:digit:]',
              insertBetween: ' ',
            },
            afterCurrency: {
              currencyMatch: '[:^S:]',
              surroundingMatch: '[:digit:]',
              insertBetween: ' ',
            },
          },
          standard: '0.00 ¤',
          accounting: '0.00 ¤;(0.00 ¤)',
          short: {
            standard: {
              '1000-count-one': "0 хил'.' ¤",
              '1000-count-other': "0 хил'.' ¤",
              '10000-count-one': "00 хил'.' ¤",
              '10000-count-other': "00 хил'.' ¤",
              '100000-count-one': "000 хил'.' ¤",
              '100000-count-other': "000 хил'.' ¤",
              '1000000-count-one': "0 млн'.' ¤",
              '1000000-count-other': "0 млн'.' ¤",
              '10000000-count-one': "00 млн'.' ¤",
              '10000000-count-other': "00 млн'.' ¤",
              '100000000-count-one': "000 млн'.' ¤",
              '100000000-count-other': "000 млн'.' ¤",
              '1000000000-count-one': "0 млрд'.' ¤",
              '1000000000-count-other': "0 млрд'.' ¤",
              '10000000000-count-one': "00 млрд'.' ¤",
              '10000000000-count-other': "00 млрд'.' ¤",
              '100000000000-count-one': "000 млрд'.' ¤",
              '100000000000-count-other': "000 млрд'.' ¤",
              '1000000000000-count-one': "0 трлн'.' ¤",
              '1000000000000-count-other': "0 трлн'.' ¤",
              '10000000000000-count-one': "00 трлн'.' ¤",
              '10000000000000-count-other': "00 трлн'.' ¤",
              '100000000000000-count-one': "000 трлн'.' ¤",
              '100000000000000-count-other': "000 трлн'.' ¤",
            },
          },
          'unitPattern-count-one': '{0} {1}',
          'unitPattern-count-other': '{0} {1}',
        },
        'miscPatterns-numberSystem-latn': {
          approximately: '~{0}',
          atLeast: '≥ {0}',
          atMost: '≤ {0}',
          range: '{0} – {1}',
        },
        minimalPairs: {
          'pluralMinimalPairs-count-one': '{0} ден',
          'pluralMinimalPairs-count-other': '{0} дена',
          other: 'Завийте надясно по {0}-ата пресечка.',
        },
      },
    },
  },
};
