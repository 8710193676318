/* eslint no-restricted-syntax:0 */
/* eslint no-param-reassign:0 */
/* eslint react/no-access-state-in-setstate:0 */
/* eslint class-methods-use-this:0 */
/* eslint react/no-unused-state:0 */
/* eslint react/no-did-update-set-state:0 */
/* eslint react/destructuring-assignment:0 */
/* eslint react/sort-comp:0 */
/* eslint no-underscore-dangle:0 */
import * as React from 'react';

import DatesHelper from '../../../../../tools/DatesHelper';
import { BOOLEANS, SEXES } from '../../../../../tools/translation-constants';
import vetisFormTemplate from './vetis-form-template';
import { GET_ANIMAL_DETAILS } from '../../../../../data/animal/animal-query';
import { deepCopy } from '../../../../../tools/function-helpers';

class VetisForm extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = this._initializeState(props.currentAnimal);
    this.toggleEditing = this.toggleEditing.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  _initializeState(currentAnimal) {
    const vetisObj = currentAnimal.vetis[0] || {};
    const vetisColor = vetisObj.color || {};
    const vetisBreed = vetisObj.breed || {};

    return {
      editing: false,
      changes: {
        animalID: currentAnimal.animalId,
        animalVetisID: vetisObj.animalVetisID,
      },
      animalData: {
        animalVetisLID: vetisObj.animalVetisLID || '',
        animalVetisEID: vetisObj.animalVetisEID || '',
        animalVetisDamLID: vetisObj.animalVetisDamLID || '',
        underSelection: BOOLEANS.find(b => b.id === vetisObj.underSelection) || {},
        animalVetisSex: SEXES.find(s => s.id === vetisObj.animalVetisSex) || {},
        animalVetisDOB: vetisObj.animalVetisDOB ? DatesHelper.parseDate(vetisObj.animalVetisDOB).toDate() : null,
        animalVetisColorID: { colorID: vetisColor.colorID, colorName: vetisColor.colorName, },
        animalVetisBreedID: { breedsId: vetisBreed.breedsId, breedName: vetisBreed.breedName, },

      },
      vetisComparison: this._getVetisComparisonState(currentAnimal),
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.currentAnimal !== prevProps.currentAnimal) {
      this.setState({
        vetisComparison: this._getVetisComparisonState(this.props.currentAnimal),
      });
    }
  }

  _getVetisComparisonState(currentAnimal) {
    return {
      lID: currentAnimal.lID || '',
      dateOfBirth: currentAnimal.dateOfBirth ? DatesHelper.parseDate(currentAnimal.dateOfBirth).toDate() : null,
      eID: currentAnimal.electronicId || '',
      sex: currentAnimal.sex,
      colorID: currentAnimal.color ? currentAnimal.color.colorID : '',
      underSelection: BOOLEANS.find(b => b.id === currentAnimal.underSelection) || {},
      damlID: currentAnimal.dam ? currentAnimal.dam.lID : '',
      breedsId: currentAnimal.breed ? currentAnimal.breed.breedsId : '',
    };
  }

  toggleEditing() {
    const isEditing = !this.state.editing;
    this.setState({ editing: isEditing, });
    if (!isEditing) {
      this.setState(this._initializeState(this.props.currentAnimal));
    }
  }

  handleChange(event) {
    const targetName = event.target.name;
    const targetVal = event.target.value;
    this.setState(prevState => {
      const updatedChanges = this._registerChanges(prevState.changes, targetName, targetVal);
      return {
        animalData: {
          ...prevState.animalData,
          [targetName]: targetVal,
        },
        changes: updatedChanges,
      };
    });
  }

  _registerChanges(allChanges, prop, val) {
    if (prop === 'animalVetisColorID') {
      allChanges.animalVetisColorID = val.colorID;
    } else if (prop === 'animalVetisBreedID') {
      allChanges.animalVetisBreedID = val.breedsId;
    } else if (prop === 'animalVetisSex' || prop === 'underSelection') {
      allChanges[prop] = val.id;
    } else {
      allChanges[prop] = val;
    }
    return allChanges;
  }

  handleSubmit(event, addVetis, updateVetis) {
    event.preventDefault();
    const objCopy = deepCopy(this.state.changes);
    for (const key in objCopy) {
      if (key === 'animalVetisDOB') {
        objCopy[key] = DatesHelper.dateToApiFormat(objCopy[key]);
      }
    }

    if (Object.keys(this.state.changes).length > 2) {
      if (this.props.currentAnimal.vetis.length > 0) {
        updateVetis({
          variables:
            { vetisUpdates: objCopy, },
        });
      } else {
        addVetis({
          variables:
            { vetisUpdates: objCopy, },
        });
      }
    }
  }

  vetisUpdateResolver(cache, updateAnimalVetis) {
    const currentIdString = this.props.currentAnimal.animalId;
    const animalResult = cache.readQuery({ query: GET_ANIMAL_DETAILS, variables: { id: currentIdString, }, });

    animalResult.animal.vetis = [updateAnimalVetis];
    cache.writeQuery({
      query: GET_ANIMAL_DETAILS,
      variables: {
        id: currentIdString,
      },
      data: { animal: animalResult.animal, },
    });
    this.toggleEditing();
  }

  render = vetisFormTemplate;
}

export default VetisForm;
