/* eslint max-len:0 */
/* eslint react/destructuring-assignment:0 */
/* eslint react/sort-comp:0 */
/* eslint react/no-unused-state:0 */
import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import EntriesNavLinks from '../../animals/movements/entries/entries-nav-links';
import SelectAnimals from './change-location-tabs/select-animals';
import SelectLocation from './change-location-tabs/select-location';


const tabs = {
  links: [
    '/movements/animals-change-location',
    '/movements/animals-change-location/location-select'
  ],
  titles: [
    'Избор на животни',
    'Избор на локация'
  ],
};


class ChangeLocationAnimals extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      group: [],
      updateGroup: this.updateGroup,
      animalsData: {},
    };
    this.updateGroup = this.updateGroup.bind(this);
    this.getGroup = this.getGroup.bind(this);
    this.updateAnimalsData = this.updateAnimalsData.bind(this);
    this.getAnimalsData = this.getAnimalsData.bind(this);
  }

  updateGroup(animals) {
    this.setState({
      group: animals,
    });
  }

  getGroup() {
    return this.state.group;
  }

  updateAnimalsData(data) {
    this.setState({
      animalsData: data,
    });
  }

  getAnimalsData() {
    return this.state.animalsData;
  }

  render() {
    return (
      <div className="container-fluid">
        <div className="steps">
          <EntriesNavLinks tabs={tabs} />
        </div>
        <Switch>
          <Route
            path={tabs.links[1]}
            render={props => {
              return <SelectLocation {...props} tabsLinks={tabs.links} getGroup={this.getGroup} updateAnimalsData={this.updateAnimalsData} getAnimalsData={this.getAnimalsData} />;
            }}
          />
          <Route
            path={tabs.links[0]}
            render={props => {
              const parentProps = this.props;
              const curProps = {
                ...props,
                ...parentProps,
              };
              return <SelectAnimals {...curProps} tabsLinks={tabs.links} updateGroup={this.updateGroup} getGroup={this.getGroup} />;
            }}
          />
        </Switch>
      </div>
    );
  }
}

export default ChangeLocationAnimals;
