/* eslint react/no-array-index-key:0 */
/* eslint react/prop-types:0 */
import * as React from 'react';
import DeleteWeightModal from './delete-assistant-modal';
import DatesHelper from '../../../../../tools/DatesHelper';

const AssistantTable = props => {
  const generateTableRows = () => {
    return props.assisEvents.map((assis, i) => {
      return (
        <tr key={i}>
          {/* <td>{assis.assisYear}</td> */}
          <td>{DatesHelper.dateToAppFormat(assis.assistanceDateIn)}</td>
          <td>{assis.assistanceName}</td>
          <td>{assis.assistanceDays}</td>
          <td>{DatesHelper.dateToAppFormat(assis.assistanceDateTo)}</td>
          <td><DeleteWeightModal {...props} assistanceID={assis.assistanceID} /></td>
        </tr>
      );
    });
  };

  return (
    <table className="table">
      <thead>
        <tr>
          {/* <th>Кампания</th> */}
          <th>Дата на заявяване</th>
          <th>Мярка</th>
          <th>Задържане</th>
          <th>Задържане до</th>
          <th>Действия</th>
        </tr>
      </thead>
      <tbody>
        {generateTableRows()}
      </tbody>
    </table>
  );
};

export default AssistantTable;
