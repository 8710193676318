/* eslint react/button-has-type:0 */
/* eslint react/no-access-state-in-setstate:0 */
/* eslint react/destructuring-assignment:0 */
/* eslint no-plusplus:0 */
/* eslint no-prototype-builtins:0 */
/* eslint no-restricted-syntax:0 */
import * as React from 'react';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Row, Col } from 'reactstrap';
import { deepCopy } from '../../tools/function-helpers';

const calcVisibleCols = (columns) => {
  let countVisible = 0;
  for (const key in columns) {
    if (columns.hasOwnProperty(key) && columns[key].visible) {
      countVisible++;
    }
  }
  return countVisible;
};

class CustomColumnModal extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      columns: props.columns,
      submitDisabled: true,
      counterVisible: calcVisibleCols(props.columns),
    };
    this.toggleDialog = this.toggleDialog.bind(this);
    this.handleFormChange = this.handleFormChange.bind(this);
    this.generateCheckBoxes = this.generateCheckBoxes.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.resetState = this.resetState.bind(this);
  }

  toggleDialog() {
    this.setState({
      visible: !this.state.visible,
    });

    if (!this.state.visible) {
      this.resetState();
    }
  }

  handleFormChange(event) {
    const colsCopy = deepCopy(this.state.columns);
    colsCopy[event.target.value].visible = event.target.checked;

    this.setState({
      columns: colsCopy,
      submitDisabled: false,
      counterVisible: calcVisibleCols(colsCopy),
    });
  }

  handleSubmit(e) {
    e.preventDefault();
    this.props.updateTableVisibleCols(this.state.columns);
    this.toggleDialog();
  }

  generateCheckBoxes() {
    const checkboxes = [];
    for (const key in this.state.columns) {
      if (this.state.columns.hasOwnProperty(key)) {
        const checkbox = (
          <div key={key}>
            <label className="k-form-field">
              <input
                type="checkbox"
                checked={this.state.columns[key] ? this.state.columns[key].visible : false}
                className="k-checkbox"
                id={key}
                name="customColumn"
                value={key}
                onChange={this.handleFormChange}
                disabled={!!(this.state.counterVisible === 1 && this.state.columns[key].visible)}
              />
              <label
                className="k-checkbox-label"
                htmlFor={key}
              >
                {this.state.columns[key].title}
              </label>
            </label>
          </div>
        );
        checkboxes.push(checkbox);
      }
    }
    return checkboxes;
  }

  resetState() {
    this.setState({
      columns: this.props.columns,
      submitDisabled: true,
    });
  }

  render() {
    return (
      <div>
        <button className="btn btn-secondary" onClick={this.toggleDialog}>Промени колони</button>
        {this.state.visible && (
        <form onSubmit={e => this.handleSubmit(e)} className="health-form">
          <Dialog className="health-modal" title="Промени колони" onClose={this.toggleDialog} minWidth="500px" height="70%">
            <Row>
              <Col lg={{ size: 8, offset: 2, }}>
                {this.generateCheckBoxes()}
              </Col>
            </Row>
            <DialogActionsBar>
              <div className="d-flex">
                <button className="btn btn-secondary" onClick={this.toggleDialog}>Отказ</button>
                <button type="submit" disabled={this.state.submitDisabled} className="btn btn-primary ml-3">Запази</button>
              </div>
            </DialogActionsBar>
          </Dialog>
        </form>
        )}
      </div>
    );
  }
}

export default CustomColumnModal;
