/* eslint jsx-a11y/control-has-associated-label:0 */
/* eslint react/no-access-state-in-setstate:0 */
/* eslint react/destructuring-assignment:0 */
import * as React from 'react';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Row, Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Mutation from '../../../../../../data/mutation';
import {
  DELETE_MOVEMENT, GET_ANIMAL_MOVEMENTS_LOCATION, GET_ANIMAL_DETAILS
} from '../../../../../../data/animal/animal-query';


class DeleteMovementModal extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
    };
    this.toggleDialog = this.toggleDialog.bind(this);
  }

  toggleDialog() {
    this.setState({
      visible: !this.state.visible,
    });
  }

  handleSubmit(e, deleteMovement) {
    e.preventDefault();
    this.toggleDialog();
    deleteMovement({
      variables:
      {
        animalLocationId: this.props.animalLocationId,
      },
    });
  }

  render() {
    return (
      <Mutation
        mutation={DELETE_MOVEMENT}
        awaitRefetchQueries
        refetchQueries={[{ query: GET_ANIMAL_DETAILS, variables: { id: this.props.animalID, }, },
          { query: GET_ANIMAL_MOVEMENTS_LOCATION, variables: { id: this.props.animalID, }, }]}
      >
        {(deleteMovementTrigger) => (
          <div className="d-inline-block">
            <button type="button" className="button-icon mr-4" onClick={this.toggleDialog}><FontAwesomeIcon icon="trash" /></button>
            {this.state.visible && (
            <Dialog className="health-modal" title="Изтрий тегло" onClose={this.toggleDialog} minWidth="500px">
              <form className="health-form">
                <Row>
                  <Col lg={{ size: 12, }}>
                    <div className="mb-4 text-center">
                      Сигурни ли сте,че искате да изтриете този запис?
                    </div>
                  </Col>
                </Row>
              </form>
              <DialogActionsBar>
                <div className="d-flex justify-content-center">
                  <button type="button" className="btn btn-secondary" onClick={this.toggleDialog}>Отказ</button>
                  <button type="submit" onClick={e => this.handleSubmit(e, deleteMovementTrigger)} className="btn btn-primary ml-3">Изтрий</button>
                </div>
              </DialogActionsBar>
            </Dialog>
            )}
          </div>
        )}
      </Mutation>
    );
  }
}

export default DeleteMovementModal;
