/* eslint react/prop-types:0 */
/* eslint react/destructuring-assignment:0 */
import * as React from 'react';

import DashboardCard from '../../../../lib/dashboard-card/dashboard-card';
import DatesHelper from '../../../../../tools/DatesHelper';
import { getAnimalWeightPerType } from '../../animal-utilities';
import { AnimalWeightEnum } from '../../animal-enums';
import Query from '../../../../../data/query';
import { GET_ADJUSTED_WEIGHTS } from '../../../../../data/animal/animal-query';

const AdjustedWeightsTable = props => {
  const birthWeight = getAnimalWeightPerType(props.animal, AnimalWeightEnum.birthWeight()) || {};
  const weaningWeight = getAnimalWeightPerType(props.animal, AnimalWeightEnum.weaningWeight()) || {};
  const yearlingWeight = getAnimalWeightPerType(props.animal, AnimalWeightEnum.yearlingWeight()) || {};
  const matureWeight = getAnimalWeightPerType(props.animal, AnimalWeightEnum.matureWeight()) || {};
  const techWeight = getAnimalWeightPerType(props.animal, AnimalWeightEnum.techWeight()) || {};

  return (
    <Query query={GET_ADJUSTED_WEIGHTS} variables={{ id: props.animal.animalId, }}>
      {({ animal, }) => {
        return (
          <DashboardCard className="overflow-auto" isLight>
            <div className="card-heading">
              <h5>
                <span className="text-success">РАЗВЪДНИ ТЕГЛА</span>
                {' '}
                |
                {' '}
                {props.animal.lID}
                {' '}
                |
                {' '}
                {props.animal.vID}
                {' '}
                |
                {' '}
                {props.animal.breed.breedShort}
                {' '}
                |
                {' '}
                {props.animal.ageInMonths}
                {' '}
                |
                {' '}
                {props.animal.lastWeight.weight}
              </h5>
              <hr />
            </div>
            <table className="table">
              <thead>
                <tr>
                  <th>Дата</th>
                  <th>Тегло</th>
                  <th>Приравнено</th>
                  <th>Вид</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{birthWeight.dateWeight ? DatesHelper.parseAndFormatDate(birthWeight.dateWeight) : '-'}</td>
                  <td>{birthWeight.weight || '-'}</td>
                  <td>{animal.adjustedBirthWeight ? Math.round(animal.adjustedBirthWeight) : '-'}</td>
                  <td>@0 дни раждане</td>
                </tr>
                <tr>
                  <td>{weaningWeight.dateWeight ? DatesHelper.parseAndFormatDate(weaningWeight.dateWeight) : '-'}</td>
                  <td>{weaningWeight.weight || '-'}</td>
                  <td>{animal.adjustedWeaningWeight ? Math.round(animal.adjustedWeaningWeight) : '-'}</td>
                  <td>@200 дни раждане</td>
                </tr>
                <tr>
                  <td>{yearlingWeight.dateWeight ? DatesHelper.parseAndFormatDate(yearlingWeight.dateWeight) : '-'}</td>
                  <td>{yearlingWeight.weight || '-'}</td>
                  <td>{animal.adjustedYearlingWeight ? Math.round(animal.adjustedYearlingWeight) : '-'}</td>
                  <td>@365 дни раждане</td>
                </tr>
                <tr>
                  <td>{matureWeight.dateWeight ? DatesHelper.parseAndFormatDate(matureWeight.dateWeight) : '-'}</td>
                  <td>{matureWeight.weight || '-'}</td>
                  <td>{animal.adjustedMatureWeight ? Math.round(animal.adjustedMatureWeight) : '-'}</td>
                  <td>@550 дни раждане</td>
                </tr>
                <tr>
                  <td>{techWeight.dateWeight ? DatesHelper.parseAndFormatDate(techWeight.dateWeight) : '-'}</td>
                  <td>{techWeight.weight || '-'}</td>
                  <td>{animal.adjustedTechWeight ? Math.round(animal.adjustedTechWeight) : '-'}</td>
                  <td>Клане</td>
                </tr>
              </tbody>
            </table>
          </DashboardCard>
        );
      }}
    </Query>
  );
};

export default AdjustedWeightsTable;
