/* eslint no-param-reassign:0 */
/* eslint max-len:0 */
/* eslint react/button-has-type:0 */
/* eslint react/prop-types:0 */
/* eslint react/destructuring-assignment:0 */
/* eslint no-shadow:0 */
import * as React from 'react';
import { Redirect } from 'react-router';
import { differenceBy } from 'lodash';
import EntriesNavLinks from '../../../animals/movements/entries/entries-nav-links';
import ServiceAnimalChoice from './add-service-animal-choice';
import ServiceBullChoice from './add-service-bull-choice';
import ServiceGroupData from './add-service-group-data';
import ServiceFinalConfirmation from './add-service-final-confirmation';
import './add-group-service.css';
import {
  ADD_SERVICE_GROUP,
  GET_ANIMALS_FOR_SERVICE_GROUP,
  GET_BULLS_FOR_SERVICE_GROUP,
  GET_ALL_SERVICE_GROUPS
} from '../../../../../data/groups/groups-query';
import { GET_SERVICE_GROUPS, GET_ANIMAL_LOCATIONS } from '../../../../../data/animal/animal-query';
import Mutation from '../../../../../data/mutation';
import { deepCopy } from '../../../../../tools/function-helpers';
import Query from '../../../../../data/query';

import DatesHelper from '../../../../../tools/DatesHelper';
import { generateLocationString } from '../../../animals/animal-utilities';

const GroupsServiceAdd = props => {
  const [step, setStep] = React.useState(1);
  const [shouldReturn, setShouldReturn] = React.useState(false);
  const [fetched, setFetched] = React.useState(false);
  const [location, setLocation] = React.useState(null);
  const [allLocations, setAllLocations] = React.useState(null);
  const [bullsState, setBullsState] = React.useState({
    allBulls: [],
    selectedBulls: [],
    availableBulls: [],
  });

  const [animalsState, setAnimalState] = React.useState({
    allAnimals: [],
    selectedAnimals: [],
    availableAnimals: [],
  });

  const [group, setGroupData] = React.useState({
    groupName: `Сървис Група - ${DatesHelper.dateToAppFormat(new Date())}`,
    daysLong: 90,
    dateCreate: new Date(),
    comment: '',
  });

  const onChangeStep = (e, tab, step) => {
    setStep(step + 1);
  };
  const getTabs = inModalMode => {
    const fixedTabs = [
      {
        title: 'Избор на животни',
        onClick: onChangeStep,
        active: step === 1,
      }, {
        title: 'Избор на бикове',
        onClick: onChangeStep,
        active: step === 2,
      }
    ];
    const rest = [
      {
        title: 'Данни сървис',
        onClick: onChangeStep,
        active: step === 3,
      },
      {
        title: 'Смяна на локация/Финал',
        onClick: onChangeStep,
        active: step === 4,
      }];

    return inModalMode ? fixedTabs : [...fixedTabs, ...rest];
  };

  const tabs = getTabs(props.inModalMode);

  const handleSubmit = (e, mutationTrigger) => {
    e.preventDefault();
    const copied = deepCopy(group);
    copied.animalInGroup = [...animalsState.selectedAnimals, ...bullsState.selectedBulls].map(a => {
      const { locationId, } = location;
      return {
        animalID: a.id,
        animalLocation: {
          animalID: a.id,
          locationId,
          moveDate: group.dateCreate,
          animalLocationType: 'SERVICE',
        },
      };
    });

    mutationTrigger({
      variables: {
        serviceGroup: copied,
      },
    });

    setShouldReturn(true);
  };

  const onLocationChange = location => setLocation(location);

  const shouldBeAbleToSubmitServiceGroup = () => {
    return animalsState.selectedAnimals.length === 0 || bullsState.selectedBulls.length === 0 || !location;
  };

  const renderComponent = mutationTrigger => {
    if (typeof props.onAnimalChange === 'function') {
      props.onAnimalChange(animalsState, bullsState);
    }
    return shouldReturn ? <Redirect to={{ pathname: '/groups/groups-service', }} /> : (
      <>
        <div className="steps container-fluid">
          <EntriesNavLinks tabs={tabs} renderButtons />
          { step === 1 && (
          <ServiceAnimalChoice
            allLocations={allLocations}
            {...animalsState}
            setAnimalState={setAnimalState}
            otherProps={props.otherProps}
            inModal={props.inModalMode}
            dateInAnimal={props.dateInAnimal}
          />
          )}
          { step === 2 && (
          <ServiceBullChoice
            allLocations={allLocations}
            {...bullsState}
            setBullState={setBullsState}
            otherProps={props.otherProps}
            inModal={props.inModalMode}
            dateInBulls={props.dateInBulls}
          />
          )}
          { step === 3 && <ServiceGroupData setGroupData={setGroupData} group={group} /> }
          { step === 4 && (
          <ServiceFinalConfirmation
            location={location}
            onLocationChange={onLocationChange}
            selectedAnimals={animalsState.selectedAnimals}
            selectedBulls={bullsState.selectedBulls}
          />
          )}
        </div>
        {!props.inModalMode && (
        <div className="steps-action-btns d-flex justify-content-between mt-5">
          <div>
            <button onClick={() => setShouldReturn(true)} className="btn btn-secondary mr-4">ОБРАТНО КЪМ ВСИЧКИ ГРУПИ</button>
          </div>
          <div>
            { step !== 1 && <button onClick={() => setStep(step - 1)} className="btn btn-secondary mr-2">НАЗАД</button> }
            { step !== 4 && <button onClick={() => setStep(step + 1)} className="btn btn-primary">НАПРЕД</button> }
            { step === 4 && <button disabled={shouldBeAbleToSubmitServiceGroup()} onClick={e => handleSubmit(e, mutationTrigger)} className="btn btn-primary">СЪЗДАЙ ГРУПА</button> }
          </div>
        </div>
        ) }
      </>
    );
  };

  const generateAnimalsForInModalMode = (animals, group, bulls) => {
    const res = differenceBy(animals, group.animalServiceProp, 'id');
    const animalsLeftFromGroup = group.animalServiceProp.filter(asp => !!asp.dateOut && asp.animals.sex === (bulls ? 'MALE' : 'FEMALE'));
    const mappedLeftFromGroup = animalsLeftFromGroup.map(a => {
      a.location = generateLocationString({ animalLocation: a.animals.animalLocation.reverse(), });
      return a.animals;
    });
    return {
      [bulls ? 'allBulls' : 'allAnimals']: [...res, ...mappedLeftFromGroup],
      [bulls ? 'availableBulls' : 'availableAnimals']: [...res, ...mappedLeftFromGroup],
      [bulls ? 'selectedBulls' : 'selectedAnimals']: [],
    };
  };

  const mapAnimalsWithCurrentLocation = animals => animals.map(a => {
    a.location = generateLocationString({ animalLocation: a.animalLocation.reverse(), });
    return a;
  });

  const calculateInitialAnimalState = (inModalMode, serviceAnimals) => {
    const animals = mapAnimalsWithCurrentLocation(serviceAnimals.items);
    if (!inModalMode) {
      return {
        allAnimals: [...animals],
        availableAnimals: [...animals],
        selectedAnimals: [],
      };
    }
    return generateAnimalsForInModalMode(animals, props.group, false);
  };

  const calculateInitialBullState = (inModalMode, serviceBulls) => {
    const bulls = mapAnimalsWithCurrentLocation(serviceBulls.items);
    if (!inModalMode) {
      return {
        allBulls: [...bulls],
        availableBulls: [...bulls],
        selectedBulls: [],
      };
    }
    return generateAnimalsForInModalMode(bulls, props.group, true);
  };


  return (
    <>
      { !fetched ? (
        <Mutation
          mutation={ADD_SERVICE_GROUP}
          awaitRefetchQueries
          refetchQueries={[{ query: GET_ALL_SERVICE_GROUPS, variables: {}, }, { query: GET_SERVICE_GROUPS, }]}
        >
          { mutationTrigger => (

            <Query query={GET_ANIMALS_FOR_SERVICE_GROUP} {...props}>
              {({ serviceAnimals, }) => (
                <Query query={GET_BULLS_FOR_SERVICE_GROUP} {...props}>
                  {({ serviceBulls, }) => {
                    return (
                      <Query {...props} query={GET_ANIMAL_LOCATIONS}>
                        {({ locations, }) => {
                          const locationsMapped = locations.items.map((location) => {
                            return {
                              ...location,
                              locationName: location.locationName ? location.locationName : '-',
                            };
                          });
                          setFetched(true);
                          setAllLocations(locationsMapped);
                          // @ts-ignore
                          setAnimalState(calculateInitialAnimalState(props.inModalMode, serviceAnimals));

                          // @ts-ignore
                          setBullsState(calculateInitialBullState(props.inModalMode, serviceBulls));
                          return renderComponent(mutationTrigger);
                        }}
                      </Query>
                    );
                  }}
                </Query>
              )}
            </Query>
          )}
        </Mutation>
      ) : (
        <Mutation
          mutation={ADD_SERVICE_GROUP}
          awaitRefetchQueries
          refetchQueries={[{ query: GET_ALL_SERVICE_GROUPS, variables: {}, }]}
        >
          { mutationTrigger => renderComponent(mutationTrigger)}
        </Mutation>
      )}
    </>
  );
};

export default GroupsServiceAdd;
