/* eslint no-unused-vars:0 */
/* eslint react/button-has-type:0 */
/* eslint max-len:0 */
/* eslint react/prop-types:0 */
import * as React from 'react';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import {
  ADD_ANIMAL_IN_SERVICE_GROUP,
  GET_ALL_SERVICE_GROUPS
} from '../../../../../data/groups/groups-query';
import Mutation from '../../../../../data/mutation';
import GroupsServiceAdd from '../add-service-group/add-service-group';
import { DATA_TAB_ENUMS } from '../../../../../data/animal/tabs-queries';
import Query from '../../../../../data/query';
import DateHelter from '../../../../../tools/DatesHelper';


const AddAnimalInServiceGroupModal = props => {
  const [visible, setVisible] = React.useState(false);
  let animalsToAdd = {
    animalState: { selectedAnimals: [], allAnimals: [], },
    bullState: { selectedBulls: [], },
  };
  const [dateInAnimal, setDateInAnimal] = React.useState(new Date());
  const [dateInBulls, setDateInBulls] = React.useState(new Date());

  const handleSubmit = (e, mutationTrigger) => {
    e.preventDefault();
    e.stopPropagation();

    const animals = animalsToAdd.animalState.selectedAnimals.map(a => {
      const match = props.group.animalServiceProp.find(aa => a.id === aa.id) || animalsToAdd.animalState.allAnimals.find(aa => a.id === aa.id) || { };
      return {
        animalID: match.id,
        animalInGroupID: match.animalInGroupID,
        groupID: props.group.groupID,
        dateIn: DateHelter.dateToApiFormat(dateInAnimal),
        dateOut: match.dateOut,

      };
    });

    const bulls = animalsToAdd.bullState.selectedBulls.map(a => {
      const match = props.group.animalServiceProp.find(aa => a.id === aa.id) || animalsToAdd.bullState.selectedBulls.find(aa => a.id === aa.id) || { };
      return {
        animalID: match.id,
        groupID: props.group.groupID,
        dateIn: DateHelter.dateToApiFormat(dateInBulls),
        dateOut: match.dateOut,

      };
    });

    const res = [...animals, ...bulls];

    mutationTrigger({
      variables: {
        animalInServiceGroup: res,
      },
    });

    setVisible(false);
  };

  const onAnimalChange = (animalState, bullState) => {
    animalsToAdd = {
      animalState,
      bullState,
    };
  };
  return (
    <Mutation
      mutation={ADD_ANIMAL_IN_SERVICE_GROUP}
      awaitRefetchQueries
      refetchQueries={[{ query: GET_ALL_SERVICE_GROUPS, }]}
    >
      { mutationTrigger => (
        <>
          { visible
                    && (
                    <form onSubmit={e => handleSubmit(e, mutationTrigger)} className="health-form">
                      <Dialog title="Добави животно" onClose={() => setVisible(false)} width="75%" height="100%">
                        <Query query={DATA_TAB_ENUMS}>
                          {({ aClasses, breeds, color, }) => {
                            return (
                              <GroupsServiceAdd
                                group={props.group}
                                onAnimalChange={onAnimalChange}
                                inModalMode
                                dateInAnimal={{ setDateIn: setDateInAnimal, dateIn: dateInAnimal, }}
                                dateInBulls={{ setDateIn: setDateInBulls, dateIn: dateInBulls, }}
                                otherProps={{
                                  aClasses: aClasses.items,
                                  breeds: breeds.items,
                                  color: color.items,
                                  groups: [],
                                }}
                              />
                            );
                          }}
                        </Query>

                        <DialogActionsBar>
                          <div className="d-flex">
                            <button className="btn btn-secondary" onClick={() => setVisible(false)}>Отказ</button>
                            <button type="submit" className="btn btn-primary ml-3">Добави</button>
                          </div>
                        </DialogActionsBar>
                      </Dialog>

                    </form>
                    )}
          {!visible && <button className="btn btn-primary ml-2 mb-2" onClick={_ => setVisible(true)}>Добави животно</button>}
        </>
      )}
    </Mutation>
  );
};

export default AddAnimalInServiceGroupModal;
