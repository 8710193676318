/* eslint max-len:0 */
/* eslint react/destructuring-assignment:0 */
/* eslint react/sort-comp:0 */
/* eslint react/no-unused-state:0 */
import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import AddAnimals from './add-group-tabs/add-animals';
import GroupData from './add-group-tabs/group-data';
import EntriesNavLinks from '../../animals/movements/entries/entries-nav-links';


const tabs = {
  links: [
    '/groups/groups-managment/add-group',
    '/groups/groups-managment/add-group/group-data'
  ],
  titles: [
    'Избор на животни',
    'Данни за групата'
  ],
};


class AddGroup extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      group: [],
      groupData: {},
      updateGroup: this.updateGroup,
    };
    this.updateGroup = this.updateGroup.bind(this);
    this.getGroup = this.getGroup.bind(this);
    this.getGroupData = this.getGroupData.bind(this);
    this.updateGroupData = this.updateGroupData.bind(this);
  }

  updateGroup(animals) {
    this.setState({
      group: animals,
    });
  }

  getGroup() {
    return this.state.group;
  }

  getGroupData() {
    return this.state.groupData;
  }

  updateGroupData(groupData) {
    this.setState({
      groupData,
    });
  }

  render() {
    return (
      <div className="container-fluid">
        <h3 className="mb-4">ДОБАВИ МЕНИДЖМЪНТ ГРУПА</h3>
        <div className="steps">
          <EntriesNavLinks tabs={tabs} />
        </div>

        <Switch>
          <Route
            path={tabs.links[1]}
            render={props => {
              return <GroupData {...props} tabsLinks={tabs.links} getGroup={this.getGroup} getGroupData={this.getGroupData} updateGroupData={this.updateGroupData} />;
            }}
          />
          <Route
            path={tabs.links[0]}
            render={props => {
              const parentProps = this.props;
              const curProps = {
                ...props,
                ...parentProps,
              };
              return <AddAnimals {...curProps} tabsLinks={tabs.links} updateGroup={this.updateGroup} getGroup={this.getGroup} />;
            }}
          />
        </Switch>
      </div>
    );
  }
}

export default AddGroup;
