/* eslint react/prop-types:0 */
/* eslint react/destructuring-assignment:0 */
/* eslint max-len:0 */
import * as React from 'react';
import { Row, Col } from 'reactstrap';

import DatesHelper from '../../../../../../tools/DatesHelper';
import { mapAnimalFateReason } from '../../../animal-mapping-functions';
import { selectedValue } from '../../../../../../tools/function-helpers';
import { DIE_DIAGNOSTIC_TYPE, ANIMAL_DIE_TYPE, DROP_DIRECTION_TYPES } from '../../../../../../tools/translation-constants';

const animalDieInformation = (animal) => {
  const index = animal.animalDie.length - 1;
  return (
    <div>
      <div>
        Причина |
        {' '}
        {animal.animalDie[index].animalDieTypeID ? selectedValue(animal.animalDie[index].animalDieTypeID, ANIMAL_DIE_TYPE, 'id').text : '-'}
      </div>
      <div>
        Диагноза |
        {' '}
        {animal.animalDie[index].animalDieDiagnosticID ? selectedValue(animal.animalDie[index].animalDieDiagnosticID, DIE_DIAGNOSTIC_TYPE, 'id').text : '-'}
      </div>
      <div>
        Ветеринар |
        {' '}
        {animal.animalDie[index].personInCharge ? animal.animalDie[index].personInCharge.farmClientName : '-'}
      </div>
      <div>
        Екарисажна Бележка |
        {' '}
        {animal.animalDie[index].skeletonDoc ? animal.animalDie[index].skeletonDoc : '-'}
      </div>
      <div>
        Номер на транспортно средство |
        {' '}
        {animal.animalDie[index].animalDieDriver ? animal.animalDie[index].animalDieDriver : '-'}
      </div>
      <div>
        Цена на екарисажната услуга лв/глава |
        {' '}
        {animal.animalDie[index].animalDiePriceDiagnostic ? animal.animalDie[index].animalDiePriceDiagnostic : '-'}
      </div>

    </div>
  );
};

const animalSellInformation = (animal) => {
  const index = animal.animalSell.length - 1;
  return (
    <div>
      <div>
        Клиент |
        {' '}
        {animal.animalSell[index].client ? animal.animalSell[index].client.farmClientName : '-'}
      </div>
      <div>
        Направление |
        {' '}
        {animal.animalSell[index].animalSellDirection ? selectedValue(animal.animalSell[index].animalSellDirection, DROP_DIRECTION_TYPES, 'id').text : '-'}
      </div>
      <div>
        Шофьор |
        {' '}
        {animal.animalSell[index].driver ? animal.animalSell[index].driver.farmClientName : '-'}
      </div>
      <div>
        Номер транспортно средство |
        {' '}
        {animal.animalSell[index].animalSellCarPlate ? animal.animalSell[index].animalSellCarPlate : '-'}
      </div>
      <div>
        Пътен лист ВЕТИС |
        {' '}
        {animal.animalSell[index].animalSellBABHTrasportDoc ? animal.animalSell[index].animalSellBABHTrasportDoc : '-'}
      </div>
      <div>
        Бруто тегло във фермата |
        {' '}
        {animal.animalSell[index].animalSellKgInFarm ? animal.animalSell[index].animalSellKgInFarm : '-'}
      </div>
      <div>
        Бруто тегло при клиента |
        {' '}
        {animal.animalSell[index].animalSellKgInClient ? animal.animalSell[index].animalSellKgInClient : '-'}
      </div>
      <div>
        Цена кг/жт |
        {' '}
        {animal.animalSell[index].animalSellPricePerKG ? animal.animalSell[index].animalSellPricePerKG : '-'}
      </div>
      <div>
        Цена на глава |
        {' '}
        {animal.animalSell[index].animalSellPricePerAnimal ? animal.animalSell[index].animalSellPricePerAnimal : '-'}
      </div>
      <div>
        Стойност на покупката |
        {' '}
        {animal.animalSell[index].animalSellPriceOther ? animal.animalSell[index].animalSellPriceOther : '-'}
      </div>
      <div>
        Други разходи(пътни листи, комисионни) |
        {' '}
        {animal.animalSell[index].animalSellTransportPrice ? animal.animalSell[index].animalSellTransportPrice : '-'}
      </div>

    </div>
  );
};

const FateTable = props => {
  const generateTableBody = () => {
    return (
      <tbody>
        <tr>
          <td>{props.animal.fateDate ? DatesHelper.parseAndFormatDate(props.animal.fateDate) : '-'}</td>
          <td>{props.animal.fateReason ? mapAnimalFateReason(props.animal.fateReason) : '-'}</td>
          <td>
            {props.animal.animalSell.length > 0 && animalSellInformation(props.animal)}
            {props.animal.animalDie.length > 0 && animalDieInformation(props.animal)}
            <div>
              Документ: |
              {' '}
              {props.animal.fateDoc ? props.animal.fateDoc : '-'}
            </div>
            <div>
              Бележка |
              {' '}
              {props.animal.fateCode ? props.animal.fateCode : '-'}
            </div>
          </td>
        </tr>
      </tbody>
    );
  };

  return (

    <Row>
      <Col>
        <table className="table">
          <thead>
            <tr>
              <th>Дата</th>
              <th>Събитие</th>
              <th>Информация</th>
            </tr>
          </thead>
          {generateTableBody()}
        </table>
      </Col>
    </Row>
  );
};

export default FateTable;
