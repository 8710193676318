/* eslint react/button-has-type:0 */
/* eslint max-len:0 */
/* eslint no-prototype-builtins:0 */
/* eslint no-restricted-syntax:0 */
/* eslint guard-for-in:0 */
/* eslint react/no-unused-state:0 */
/* eslint react/no-access-state-in-setstate:0 */
/* eslint react/destructuring-assignment:0 */
/* eslint no-underscore-dangle:0 */
/* eslint react/sort-comp:0 */
import * as React from 'react';
import {
  Grid, GridColumn as Column, GridToolbar, GridColumnMenuFilter
} from '@progress/kendo-react-grid';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { filterBy, orderBy } from '@progress/kendo-data-query';

import {
  LocalizationProvider, loadMessages, load, IntlProvider
} from '@progress/kendo-react-intl';
import CustomColumnModal from '../../../custom-column/custom-column';

import {
  TABLE_BG_MESSAGES, bgDateFields, bgGregorian, weekData, numbers
} from '../../../../tools/translation-constants';
import { dropdownFilterCell } from '../../../../tools/dropdown-filter-cell';

const PAGE_TAKE = 10;

load(weekData, numbers, bgDateFields, bgGregorian);

loadMessages(TABLE_BG_MESSAGES, 'bg-BG');

class DeadBornReportTable extends React.Component<any, any> {
  _export;

  export = () => {
    this._export.save();
  };

  constructor(props) {
    super(props);
    this.state = {
      animals: props.animals,
      stoTypes: props.stoTypes,
      stoTypeTypes: props.stoTypeTypes,
      filter: undefined,
      sort: [],
      sortable: {
        allowUnsort: true,
        mode: 'single',
      },
      skip: 0,
      take: PAGE_TAKE,
      pageSize: PAGE_TAKE,
      pageable: {
        buttonCount: 5,
        info: true,
        type: 'numeric',
        previousNext: true,
        pageSizes: [5, 10, 20, props.animals.length],
      },
      columns: {
        lID: {
          title: 'Ветис №',
          visible: true,
          field: 'lID',
          width: '180px',
        },
        dateOfBirth: {
          title: 'Дата на раждане',
          visible: true,
          field: 'dateOfBirth',
          width: '210px',
        },
        sTO: {
          title: 'СТО',
          visible: true,
          field: 'sTO',
          width: '150px',
        },
        sTOTypeID: {
          title: 'Причини за ТО',
          visible: true,
          field: 'sTOTypeID',
          width: '200px',
        },
        damSummary: {
          title: 'Майка',
          visible: true,
          field: 'damSummary',
          width: '250px',
        },
        sireSummary: {
          title: 'Баща',
          visible: true,
          field: 'sireSummary',
          width: '250px',
        },
        comment: {
          title: 'Бележка',
          visible: true,
          field: 'comment',
          width: '250px',
        },
      },
    };
    this.pageChange = this.pageChange.bind(this);
    this.updateTableVisibleCols = this.updateTableVisibleCols.bind(this);
    this.printCols = this.printCols.bind(this);
  }

  pageChange = (event) => {
    this.setState({
      skip: event.page.skip,
      take: event.page.take,
      pageSize: event.page.take,
    });
  };

  filterChange = (event) => {
    this.setState({
      animals: filterBy(orderBy(this.props.animals, this.state.sort), event.filter),
      filter: event.filter,
      skip: 0,
      tate: PAGE_TAKE,
    });
  };

  sortChange = (event) => {
    this.setState({
      animals: orderBy(this.state.animals, event.sort),
      sort: event.sort,
    });
  };

  updateTableVisibleCols = (columns) => {
    this.setState({
      columns,
    });
  };

  printCols() {
    const dropCols = [];
    for (const key in this.state.columns) {
      const curCol = this.state.columns[key];
      if (this.state.columns.hasOwnProperty(key) && curCol.visible) {
        if (key === 'dateOfBirth') {
          dropCols.push(
            <Column
              key={key}
              field={curCol.field}
              title={curCol.title}
              width={curCol.width}
              filter="date"
              format="{0:dd.MM.yyy}"
              columnMenu={(props) => <GridColumnMenuFilter {...props} />}
            />
          );
        } else if (key === 'sTO') {
          dropCols.push(
            <Column
              key={key}
              field={curCol.field}
              title={curCol.title}
              width={curCol.width}
              filterCell={(props) => dropdownFilterCell(props, this.state.stoTypes)}
            />
          );
        } else if (key === 'sTOTypeID') {
          dropCols.push(
            <Column
              key={key}
              field={curCol.field}
              title={curCol.title}
              width={curCol.width}
              filterCell={(props) => dropdownFilterCell(props, this.state.stoTypeTypes)}
            />
          );
        } else {
          dropCols.push(<Column key={key} field={curCol.field} title={curCol.title} width={curCol.width} />);
        }
      }
    }
    return dropCols;
  }

  render() {
    return (
      <div className="container-fluid">
        <h3 className="mb-4">Справка МЪРТВО РОДЕНИ</h3>
        <LocalizationProvider language="bg-BG">
          <IntlProvider locale="bg">
            <ExcelExport
              data={this.state.animals}
              fileName="report-dead-born-animals.xlsx"
              ref={(exporter) => {
                this._export = exporter;
              }}
            >
              <Grid
                data={this.state.animals.slice(this.state.skip, this.state.take + this.state.skip)}
                className="table table-advanced"
                skip={this.state.skip}
                total={this.state.animals.length}
                onPageChange={this.pageChange}
                onFilterChange={this.filterChange}
                filter={this.state.filter}
                filterable
                pageable={this.state.pageable}
                pageSize={this.state.pageSize}
                onSortChange={this.sortChange}
                sort={this.state.sort}
                sortable={this.state.sortable}
                resizable
                reorderable
              >
                <GridToolbar>
                  <div className="d-flex justify-content-between align-items-center">
                    <span className="list-counter">
                      Брой Животни:
                      {this.state.animals.length}
                    </span>
                    <div className="d-flex d-inline-block">
                      <CustomColumnModal columns={this.state.columns} updateTableVisibleCols={this.updateTableVisibleCols} />
                      <button title="Export Excel" className="btn btn-secondary ml-2" onClick={this.export}>
                        Експорт в Excel
                      </button>
                    </div>
                  </div>
                </GridToolbar>
                {this.printCols()}
              </Grid>
            </ExcelExport>
          </IntlProvider>
        </LocalizationProvider>
      </div>
    );
  }
}

export default DeadBornReportTable;
