/* eslint react/no-access-state-in-setstate:0 */
/* eslint react/destructuring-assignment:0 */
import * as React from 'react';
import { Row, Col } from 'reactstrap';
import { Input } from '@progress/kendo-react-inputs';
import TabNavButtons from '../../tab-nav-buttons';
import FarmClientDropdown from '../../../../tabs/health/farm-client-dropdown';
import { DatePicker } from '../../../../../../utils/date-picker';

class EconomyTab extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      animal: {
        ...props.getGroupObject(),
      },
      updateGroupObject: props.updateGroupObject,
      farmClientTableType: 'entries',
    };
    this.handleFormChange = this.handleFormChange.bind(this);
  }

  handleFormChange(e) {
    const objCopy = { ...this.state.animal, };

    const [first, _] = objCopy?.animalPurchase;
    if (e.target.name === 'pricePerHead') {
      first[e.target.name] = e.target.value;
      first.priceTotal = e.target.value * (this.state.animal.numberAnimals ? this.state.animal.numberAnimals : 1);
    } if (e.target.name === 'farmClientID') {
      first[e.target.name] = e.target.value.farmClientID;
    } else {
      first[e.target.name] = e.target.value;
    }
    this.setState({
      animal: objCopy,
    });
    this.state.updateGroupObject(objCopy);
  }

  render() {
    const [first, _] = this.state.animal?.animalPurchase;
    return (
      <div className="tab">
        <form>
          <h3>ЦЕНА</h3>
          <p className="mb-2">Показатели с отношение към цената!</p>
          <Row>
            <Col lg="3">
              <div className="form-group">
                <label>Цена кг/жт</label>
                <Input type="number" name="pricePerKG" onChange={this.handleFormChange} value={first?.pricePerKG} />
              </div>
            </Col>
            <Col lg="3">
              <div className="form-group">
                <label>Цена на глава</label>
                <Input type="number" name="pricePerHead" onChange={this.handleFormChange} value={first?.pricePerHead} />
              </div>
            </Col>
            <Col lg="3">
              <div className="form-group">
                <label>Стойност на покупката</label>
                <Input type="number" readOnly name="priceTotal" onChange={this.handleFormChange} value={first?.priceTotal} />
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg="3">
              <div className="form-group">
                <label>Разходи за транспорт</label>
                <Input type="number" name="expenseTransport" onChange={this.handleFormChange} value={first?.expenseTransport} />
              </div>
            </Col>
            <Col lg="3">
              <div className="form-group">
                <label>Ветиринарни разходи</label>
                <Input type="number" name="expenseVet" onChange={this.handleFormChange} value={first?.expenseVet} />
              </div>
            </Col>
            <Col lg="3">
              <div className="form-group">
                <label>Други разходи</label>
                <Input type="number" name="expenseOther" onChange={this.handleFormChange} value={first?.expenseOther} />
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="d-flex flex-column justify-content-end" lg="3">
              <div className="form-group">
                <label>Развъдчик - Ферма</label>
                <FarmClientDropdown
                  farmClientID={first?.farmClientID}
                  tableType={this.state.farmClientTableType}
                  handleFormChange={this.handleFormChange}
                />
              </div>
            </Col>
            <Col className="d-flex flex-column justify-content-end" lg="3">
              <div className="form-group">
                <label>Дата на попупка</label>
                <DatePicker
                  name="purchaseDate"
                  onChange={this.handleFormChange}
                  value={first?.purchaseDate}
                />
              </div>
            </Col>
            <Col className="d-flex flex-column justify-content-end" lg="3">
              <div className="form-group">
                <label>Пътен лист номер</label>
                <Input type="text" name="documents" onChange={this.handleFormChange} value={first?.documents} />
              </div>
            </Col>
          </Row>
          {this.props.tabLinks && <TabNavButtons parentProps={this.props} /> }
        </form>
      </div>
    );
  }
}

export default EconomyTab;
