/* eslint no-unused-vars:0 */
import * as React from 'react';
import { GridFilterCellProps } from '@progress/kendo-react-grid';
import { DropDownListChangeEvent } from '@progress/kendo-react-dropdowns/dist/npm/DropDownList/DropDownListProps';
import { dropdownFilterCell, DropdownFilterCellProps } from './dropdown-filter-cell';
import { transformLocations } from '../components/views/animals/animal-utilities';

const dropDownProps = {
  itemRender: (li, itemProps) => {
    const { fullLocationName, } = itemProps.dataItem;

    const getStyles = it => {
      if (it.thirdLevelLocation) {
        return { color: 'orange', marginLeft: '30px', };
      }
      if (it.secondLevelLocation) {
        return { color: 'red', marginLeft: '20px', };
      }
      return { color: '#00F', };
    };

    const itemChildren = (
      <>
        <span style={getStyles(itemProps.dataItem)}>{fullLocationName}</span>
      </>
    );

    return React.cloneElement(li, li.props, itemChildren);
  },
  getValue: (e, defaultValue) => {
    return e.target.value !== defaultValue ? e.target.value.fullLocationName : '';
  },
};

export default props => {
  const p : DropdownFilterCellProps<GridFilterCellProps, string, DropDownListChangeEvent> = {
    ...props,
    ...dropDownProps,
  };

  return dropdownFilterCell(p, transformLocations(props.locations));
};
