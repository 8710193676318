import { gql } from 'apollo-boost';

export const GET_ALL_TASKS = gql`
{
    task
    {
      items
      {
        taskID
        title
        description
        end
        start
        isAllDay
        recurrenceRule
        endTimezone
        evenType
        recurrenceID
        recurrenceException
        startTimezone 
      }
    }
}
`;
