export class AnimalWeightEnum {
  static controlWeight() { return 'CONTROL_WEIGHT'; }

  static birthWeight() { return 'BIRTH_WEIGHT'; }

  static weaningWeight() { return 'WEANING_WEIGHT'; }

  static yearlingWeight() { return 'YEARLING_WEIGHT'; }

  static matureWeight() { return 'MATURE_WEIGHT'; }

  static techWeight() { return 'TECH_WEIGHT'; }
}
