/* eslint react/prop-types:0 */
/* eslint react/destructuring-assignment:0 */
import * as React from 'react';
import Query from '../../../../data/query';
import {
  GET_ANIMAL_BCS, GET_ANIMAL_MOVEMENTS_LOCATION, GET_ANIMAL_FATE, GET_ANIMAL_RETAGS
} from '../../../../data/animal/animal-query';
import BcsTable from './events/bcs-table/bcs-table';
import AddBcsModal from './events/bcs-table/add-bcs-modal';
import MovementsTable from './events/movements-table/movements-table';
import AddMovementModal from './events/movements-table/add-movement-modal';
import FateTable from './events/fate-table/fate-table';
import RetagTable from './events/retag-table/retag-table';
import RetagModal from './events/retag-table/retag-modal';

import NotesTable from './events/notes-table';

const EventsTab = props => {
  const id = props.currentAnimalId;
  return (
    <div className="tab">
      <div className="tab-head">
        <div className="d-flex align-items-baseline mb-4">
          <h3 className="mb-0">СЪБИТИЯ</h3>
        </div>
      </div>
      <Query query={GET_ANIMAL_FATE} variables={{ id, }}>
        {({ animal, }) => {
          return (
            !animal.active && (
            <div className="mb-5">
              <h4>ОТПАДАНЕ</h4>
              <p className="mb-3"><small>Събития за отпадането на животното!</small></p>
              {/* { animal.active ? <AddFateModal animal={animal} animalID={animal.animalId} /> : <FateTable animal={animal} />} */}
              <FateTable animal={animal} />
            </div>
            )
          );
        }}
      </Query>
      {/* <div className="table-inner mb-5">
        <h4 className="mb-3">ВЕТЕРИНАРНИ | РЕПРОДУКЦИЯ | ДАННИ | ЗДРАВЕ | РАЗВЪДНИ | ТЕЛЕТА | ТЕГЛА</h4>
        <button className="btn btn-primary">ДОБАВИ СЪБИТИЕ</button>
        <form className="filters m-0">
          <small className="filters-title d-block text-accent my-3">ФИЛТРИ</small>
          <div className="d-flex">
            <div className="form-group mr-4">
              <label htmlFor="exampleInputEmail1">От дата</label>
              <DatePicker defaultValue={new Date()} format="dd.MM.yyyy"  />
            </div>
            <div className="form-group">
              <label htmlFor="exampleInputEmail1">До дата</label>
              <DatePicker defaultValue={new Date()} format="dd.MM.yyyy" />
            </div>
          </div>
          <div>
            <label className="k-form-field mr-4">
              <input type="checkbox" className="k-checkbox" id="test1" />
              <label className="k-checkbox-label" htmlFor="test1">Общи</label>
            </label>
            <label className="k-form-field mr-4">
              <input type="checkbox" className="k-checkbox" id="test2" />
              <label className="k-checkbox-label" htmlFor="test2">Репродуктивни</label>
            </label>
            <label className="k-form-field mr-4">
              <input type="checkbox" className="k-checkbox" id="test3" />
              <label className="k-checkbox-label" htmlFor="test3">Ветеринарни</label>
            </label>
            <label className="k-form-field mr-4">
              <input type="checkbox" className="k-checkbox" id="test4" />
              <label className="k-checkbox-label" htmlFor="test4">Отелвания</label>
            </label>
            <label className="k-form-field mr-4">
              <input type="checkbox" className="k-checkbox" id="test5" />
              <label className="k-checkbox-label" htmlFor="test5">Движения</label>
            </label>
            <label className="k-form-field mr-4">
              <input type="checkbox" className="k-checkbox" id="test6" />
              <label className="k-checkbox-label" htmlFor="test6">Сървиси</label>
            </label>
            <label className="k-form-field mr-4">
              <input type="checkbox" className="k-checkbox" id="test7" />
              <label className="k-checkbox-label" htmlFor="test7">Телета</label>
            </label>
          </div>
          <small className="mb-1">История на събитията за FR4433554050</small>
        </form>
        <table className="table">
          <thead>
            <tr>
              <th>Дата</th>
              <th>Събитие</th>
              <th>Информация за събитието</th>
              <th>Ветеринарен лекар /Отговорник</th>
              <th>Инструменти</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>07/09/17</td>
              <td>Бременност</td>
              <td></td>
              <td>Георги Великов</td>
              <td className="text-center">
                <button className="button-icon mr-4"><FontAwesomeIcon icon={'trash'} /></button>
                <button className="button-icon"><FontAwesomeIcon icon={'pen'} /></button>
              </td>
            </tr>
            <tr>
              <td>09/10/18</td>
              <td>Бременност</td>
              <td></td>
              <td>Георги Великов</td>
              <td className="text-center">
                <button className="button-icon mr-4"><FontAwesomeIcon icon={'trash'} /></button>
                <button className="button-icon"><FontAwesomeIcon icon={'pen'} /></button>
              </td>
            </tr>
            <tr>
              <td>04/06/19</td>
              <td>Бременност</td>
              <td></td>
              <td>Георги Великов</td>
              <td className="text-center">
                <button className="button-icon mr-4"><FontAwesomeIcon icon={'trash'} /></button>
                <button className="button-icon"><FontAwesomeIcon icon={'pen'} /></button>
              </td>
            </tr>
            <tr>
              <td>15/06/19</td>
              <td>Аборт</td>
              <td></td>
              <td>Кирил Богданов</td>
              <td className="text-center">
                <button className="button-icon mr-4"><FontAwesomeIcon icon={'trash'} /></button>
                <button className="button-icon"><FontAwesomeIcon icon={'pen'} /></button>
              </td>
            </tr>
          </tbody>
        </table>
      </div> */}

      <Query {...props} query={GET_ANIMAL_MOVEMENTS_LOCATION} variables={{ id, }} fetchPolicy="network-only">
        {({ animal, }) => {
          return (
            <div className="table-inner mb-5">
              <h4>ДВИЖЕНИЯ</h4>
              <p className="mb-3"><small>Cъбития за движения на животното!</small></p>
              <AddMovementModal animalID={id} animaldateOfBirth={props.animaldateOfBirth} />
              <MovementsTable animal={animal} />
            </div>
          );
        }}
      </Query>

      <NotesTable animalId={id} animaldateOfBirth={props.animaldateOfBirth} />
      <Query {...props} query={GET_ANIMAL_BCS} variables={{ id, }}>
        {({ animal, }) => {
          return (
            <div className="table-inner mb-5">
              <h4>OTC</h4>
              <p className="mb-3"><small>Cъбития за oценка на телесно състояние!</small></p>
              <AddBcsModal animalID={id} animaldateOfBirth={props.animaldateOfBirth} />
              <BcsTable bCS={animal.bCS} animaldateOfBirth={props.animaldateOfBirth} />
            </div>
          );
        }}
      </Query>

      <Query {...props} query={GET_ANIMAL_RETAGS} variables={{ id, }}>
        {({ animal, }) => {
          return (
            <div className="table-inner mb-5">
              <h4>ПРЕМАРКИРАНЕ</h4>
              <p className="mb-3"><small>Cъбития за премаркирането на животното!</small></p>
              <RetagModal animal={animal} animaldateOfBirth={props.animaldateOfBirth} />
              <RetagTable animal={animal} />
            </div>
          );
        }}
      </Query>
    </div>
  );
};

export default EventsTab;
