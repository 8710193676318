/* eslint react/no-array-index-key:0 */
/* eslint react/prop-types:0 */
/* eslint react/destructuring-assignment:0 */
import * as React from 'react';
import {
  Chart,
  ChartValueAxis,
  ChartValueAxisItem,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartSeries,
  ChartSeriesItem,
  ChartArea,
  ChartTooltip
} from '@progress/kendo-react-charts';

import DashboardCard from '../../../../lib/dashboard-card/dashboard-card';
import DatesHelper from '../../../../../tools/DatesHelper';

const AdgChart = props => {
  const adgChart = props.animalADG ? props.animalADG.map(a => a.animalANG) : [];
  const adgChartDates = props.animalADG ? props.animalADG
    .map(a => DatesHelper.parseAndFormatDate(a.aNGdate)) : [];

  const adgOverallChart = Array(adgChart.length).fill(props.animal.adgOverall);

  const series = [
    { data: adgChart, },
    { data: adgOverallChart, }
  ];

  return (
    <DashboardCard>
      <div className="card-heading">
        <h5>
          <span className="text-success">СДП</span>
          {' '}
          |
          {' '}
          {props.animal.lID}
          {' '}
          |
          {' '}
          {props.animal.vID}
          {' '}
          |
          {' '}
          {props.animal.breed.breedShort}
          {' '}
          |
          {' '}
          {props.animal.ageInMonths}
          {' '}
          |
          {' '}
          {props.animal.lastWeight.weight}
        </h5>
        <hr />
      </div>
      <Chart>
        <ChartArea background="#ffffff" height={240} />
        <ChartTooltip />
        <ChartValueAxis>
          <ChartValueAxisItem />
        </ChartValueAxis>
        <ChartCategoryAxis>
          <ChartCategoryAxisItem categories={adgChartDates} />
        </ChartCategoryAxis>
        <ChartSeries>
          {series.map((item, idx) => (
            <ChartSeriesItem
              key={idx}
              type="line"
              data={item.data}
              color={['#2bd97d', '#3743ff'][idx]}
            />
          ))}
        </ChartSeries>
      </Chart>
    </DashboardCard>
  );
};

export default AdgChart;
