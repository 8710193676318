import { gql } from 'apollo-boost';


export const GET_RODEO_VETIS_DATA = gql`
{
  animals{
    items{
      lID
      vID
      breed{
        breedName
        breedShort
      }
      dateOfBirth
      color{
        colorName
      }
      sex
      electronicId
      dam{
        lID
      }
      vetis{
        animalVetisLID
        animalVetisDOB
        breed{
          breedName
        }
        color{
          colorName
        }
        animalVetisSex
        animalVetisEID
        animalVetisDamLID
      } 
    }
  }
}
`;


export const GET_REVACCINATION_DATA = gql`
{
  animals{
    items{
      lID
      vID
      dateOfBirth
      sex
      breed{
         breedShort
      }
      vaccine{
        animalVaccineDose
        animalVaccineDrug
        animalVaccineDate
        animalVaccineEndMonths
      }
    }
  }
}
`;


export const GET_WEIGHTS_DATA = gql`
{
  animals{
    items{
      lID
      vID
      dateOfBirth
      sex
      breed{
         breedShort
      }
      weights{
        dateWeight
        weight
        weightType
      }
      animalADG{
        animalANG
      }
      adgOverall
    }
  }
}
`;


export const GET_PREGNANCY_DATA = gql`
{
  animals(classType: DAM)
  {
    items
    {
      lID
      vID
      dateOfBirth
      sex
      breed{
         breedShort
      }
      children
      {
        dateOfBirth
      }
      events
      {
        pregnancyEvents
        {
          pregnancyDate
          pregnancyCheck
         pregnancyDays
        }
      }
      animalInService
      {
        groupID
        group
        {
          groupName
        }
      }
      curentLocation {
        moveDate
        locationId
        location{
          locationName
          locationParent{
            locationName
            locationParent{
              locationName
              locationType
            }
          }
        }
      }
    }
  }
}
`;


export const GET_ANIMALS_IN_LOCATION = gql`
  query locationReport($id: ID!) {
    locationReport(id:$id){
      items
      {
          animalID
          moveDate
          animals
          {
            animalId
            active
            lID
            vID
            dateOfBirth
            sex
            breed{
            breedShort
            }
            adgOverall
            weights{
              weight
              dateWeight
            }
            animalLocation {
              location{
                locationName
                locationParent{
                  locationName
                  locationParent{
                    locationName
                  }
                }
              }
            }
          }
      }
    }
  }
`;


export const GET_DROPPED_ANIMALS_DATA = gql`
{
  animalsExit{
  items{
    animalId
    lID
    vID
    sex
    bornDead
    dateOfBirth
     breed{
        breedName
        breedShort
    }
    fateReason
    fateCode
    fateDate
    fateDoc
    animalDie{
      animalDieTypeID
      animalDieComent
      skeletonDoc
    }
    animalSell{
      client{
        farmClientName
      }
      animalSellDirection
      animalSellBABHTrasportDoc
    }
    active
  }
}
}
`;


export const GET_ANIMALS_BCS_DATA = gql`
{
  animals{
    items{
      animalId
      lID
      vID
      dateOfBirth
      sex
       breed{
         breedShort
      }
      bCS{
      bCSID
      bCSDate
      animalOTS
      comment
      personInCharge{
        farmClientName
      }
      }
    }
  }
}
`;

export const GET_DEAD_BORN_ANIMALS = gql`
{
  animalsBornDead
  {
    items
    {
      lID
      animalId
      dateOfBirth
      events
      {
        birthEvents
        {
          sTO
          sTOTypeID
          comment
        }
      }
      dam
      {
        animalId
        lID
        vID
        sex
        dateOfBirth
        breed{
          breedShort
        }
      }
      sire
      {
        animalId
        lID
        vID
        sex
        dateOfBirth
         breed{
          breedShort
        }
      }
    }
  }
}
`;

export const GET_ALL_WEIGHT_SESSIONS = gql`
{
  weightSessions
  {
    items
    {
      sessionID
      gallagherSessionID
      whenSync
      isSync
      sessionName
      sessionDate
      weights
      {
        weightID
        weight
      }
    }
  }
}
`;

export const SINGLE_WEIGHT_SESSION = gql`
query SingleWeightSession($id: ID!){
    weightSession(id: $id)
      {
        sessionID
        gallagherSessionID
        whenSync
        isSync
        sessionName
        weights
        {
          weight
          weightType
          dateWeight
          comment
          animal
          {
            animalId
            lID
            vID
            sex
            dateOfBirth
            breed{
              breedShort
            }
            animalADG{
              animalANG
            }
            adgOverall
          }
        }
      }
    }
 `;

export const GET_ANIMALS_ASSISTANCE_DATA = gql`
{
  animalAssistance
  {
    items
    {
      assistanceID
      assistanceDateIn
      assistanceDateTo
      assistanceDays
      assistanceName
      assisYear
      animals
      {
      animalId
      lID
      vID
      dateOfBirth
      sex
      breed{
    breedShort
      }
      }
    }
  }
}
`;

export const GET_ANIMALS_RETAG_DATA = gql`
{
  animals
  {
    items
    {
      animalId
      lID
      vID
      dateOfBirth
      sex
      breed{
         breedShort
      }
      reTag
      {
        lID
        vID
        electronicId
        dateReTag
      }
    }
  }
}
`;
