/* eslint no-unused-vars:0 */
/* eslint react/no-access-state-in-setstate:0 */
/* eslint react/no-did-update-set-state:0 */
/* eslint react/destructuring-assignment:0 */
/* eslint react/no-unused-state:0 */
/* eslint max-len:0 */
/* eslint max-classes-per-file:0 */
import * as React from 'react';

import {
  TreeList, mapTree, extendDataItem, TreeListCell
} from '@progress/kendo-react-treelist';
import UsersTableModal from './users-table-modal';
import { deepCopy } from '../../../../tools/function-helpers';
import { GET_ROLES } from '../../../../data/users/users-queries';
import { USER_ROLES } from '../../../../tools/translation-constants';

import Query from '../../../../data/query';

const getTranslation = (value, translations) => { const result = translations.find((item) => item.id === value); return result ? result.text : 'празно'; };

const subItemsField = 'childrenObject';
const expandField = 'expanded';

class Users extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      data: props.users,
      itemInEdit: undefined,
      expanded: [0],
    };

    this.onExpandChange = this.onExpandChange.bind(this);
    this.processData = this.processData.bind(this);
    this.actionsCell = this.actionsCell.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.users !== prevProps.users) {
      this.setState({
        data: this.props.users,
      });
    }
  }

    actionsCell = (data) => {
      return class extends TreeListCell {
        render() {
          const { id, } = this.props.dataItem;
          const copyData = deepCopy(data);
          let dataItem = {};
          dataItem = copyData.find(userItem => userItem.id === id);

          return (
            <td>
              <Query query={GET_ROLES}>
                {({ userRole, }) => (
                  <div className="d-flex justify-content-center">
                    <UsersTableModal dataItem={dataItem} users={userRole} type="user" action="edit" />
                  </div>
                )}
              </Query>
            </td>
          );
        }
      };
    }

    onExpandChange = (e) => {
      this.setState({
        expanded: e.value
          ? this.state.expanded.filter(id => id !== e.dataItem.id)
          : [...this.state.expanded, e.dataItem.id],
      });
    }

    processData = () => {
      const { expanded, data, } = this.state;
      const mappedData = data.map((item) => {
        return {
          id: item.id,
          name: item.userName ? item.userName : '(празно)',
          role: getTranslation(item.role, USER_ROLES),
          email: item.email ? item.email : '(празно)',
        };
      });

      return mapTree(mappedData, subItemsField, item => extendDataItem(item, subItemsField, {
        [expandField]: expanded.includes(item.id),
      }));
    }

    render() {
      const columns = [
        {
          field: 'name', title: 'Име', expandable: true, width: '30%',
        },
        { field: 'email', title: 'Email', },
        { field: 'role', title: 'Роля', },
        { title: 'Действия', cell: this.actionsCell(this.state.data), width: '1px', }
      ];

      const copyData = deepCopy(this.state.data);

      return (
        <div>

          <div className="d-flex justify-content-start">
            <UsersTableModal dataItem={{}} type="user" action="add" />
          </div>

          <TreeList
            className="table"
            data={this.processData()}
            style={{ maxHeight: '100%', overflow: 'auto', }}
            expandField={expandField}
            subItemsField={subItemsField}
            onExpandChange={this.onExpandChange}
            columns={columns}
          />
        </div>
      );
    }
}

export default Users;
