/* eslint react/sort-comp:0 */
/* eslint react/destructuring-assignment:0 */
import * as React from 'react';


class AlertEntriesComponent extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      visible: this.isSavedEntries(),
      count: this.isSavedEntries() ? this.props.location.state.savedEntries : 0,
      isHistoryClear: false,
    };
    this.isSavedEntries = this.isSavedEntries.bind(this);
    this.renderAlert = this.renderAlert.bind(this);
  }

  isSavedEntries() {
    return this.props.location.state && this.props.location.state.savedEntries;
  }

  renderAlert() {
    return this.state.visible ? (
      <div className="alert alert-success" role="alert">
        {this.props.text}
        {' '}
        {this.state.count}
        {' '}
        {this.state.count > 1 ? 'животни' : 'животно'}
        !
        {this.removeSavedEntries()}
      </div>
    ) : '';
  }

  removeSavedEntries() {
    if (!this.state.isHistoryClear) {
      setTimeout(() => {
        const stateCopy = { ...this.props.location.state, };
        delete stateCopy.savedEntries;
        this.props.history.replace({ state: stateCopy, });
        this.setState({
          isHistoryClear: true,
        });
      }, 1000);
    }
  }

  render() {
    return (
      this.renderAlert()
    );
  }
}


export default AlertEntriesComponent;
