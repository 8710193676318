import { gql } from 'apollo-boost';


export const GET_CONTRAGENTS = gql`
{
  farmClients{
   items{
    farmClientID
    farmClientName
    farmClientEIK
    farmClientMOL
    farmClientTel
    farmClientEmail
    farmClientFirmName
    farmClientsInType
    farmClientAddress
    comment
    }
  }
}
`;


export const ADD_CONTRAGENT = gql`
mutation AddClient($dataItem: clientsInput){
      addClient(farmClient:$dataItem){
        farmClientID
        farmClientsInType
   }
}
`;

export const EDIT_CONTRAGENT = gql`
mutation UpdateClient($dataItem: clientsInput){
    updateClient(farmClient: $dataItem){
        farmClientID
        farmClientsInType
    }
}
`;
