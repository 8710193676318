/* eslint-disable react/prop-types */
/* eslint react/button-has-type:0 */
/* eslint max-len:0 */
/* eslint no-prototype-builtins:0 */
/* eslint react/no-access-state-in-setstate:0 */
/* eslint react/destructuring-assignment:0 */
import React, { useState } from 'react';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { withApollo } from '@apollo/react-hoc';
import { Row, Col } from 'reactstrap';
import Mutation from '../../../../data/mutation';
import { ADD_ANIMAL_GROUP_WEIGHTS } from '../../../../data/groups/groups-query';

// @ts-ignore
import cowIcon from '../../../../assets/img/icons/cow.svg';
// @ts-ignore
import tableIcon from '../../../../assets/img/icons/table.svg';

const GroupWeightingModal = (props) => {
  const [visible, setVisible] = useState(false);


  const toggleDialog = () => {
    setVisible(!visible);
  };

  const handleSubmit = (e, mutationTrigger) => {
    e.preventDefault();

    const { uniqueAnimals, } = props.filterUniqueAndDuplicate();

    if (uniqueAnimals.length > 0) {
      const animals = uniqueAnimals.map((animal) => {
        return {
          animalLID: animal.lID,
          dateWeight: animal.dateWeight,
          weight: animal.weight,
          comment: animal.comment,
          weightType: animal.weightType,
        };
      });

      props.client.clearStore();
      mutationTrigger({
        variables:
        {
          animalWeights: animals,
        },
      });
    }
  };

  const printDuplicate = [];
  Object.entries(props.filterUniqueAndDuplicate().duplicateAnimals).forEach((el, i) => {
    const [unique, curDuplicate] = el as any;
    const index = i;
    const animalInfo = curDuplicate.shift();
    if (curDuplicate.length > 0) {
      const duplicateToPrint = [
        unique,
        ...curDuplicate
      ];

      printDuplicate.push(
        <div key={index} className="spreadsheet-duplicate-animal">
          <p className="d-flex align-items-start mb-2">
            <img src={cowIcon} alt="cow" className="mr-1" />
            {animalInfo}
          </p>
          <p className="spreadsheet-duplicate-count d-flex align-items-center mb-4">
            <img src={tableIcon} alt="table" className="mr-1" />
            <span className="mr-1">На редове: </span>
            {duplicateToPrint.join(' ,')}
          </p>
        </div>
      );
    }
  });

  return (
    <Mutation
      mutation={ADD_ANIMAL_GROUP_WEIGHTS}
      onCompleted={() => toggleDialog()}
    >
      {(mutationTrigger) => (
        <div>
          <button className="btn btn-primary" style={{ display: 'none', }} onClick={toggleDialog} id="spreadSheetButton">ГРУПОВО ПРЕТЕГЛЯНЕ</button>
          {visible && (
            <Dialog title="Добави тегла" onClose={toggleDialog} minWidth="500px" height="80%">
              <Row>
                <Col lg={{ size: 10, offset: 1, }}>
                  <div>
                    <h4 className="mb-3">Данни за записите:</h4>
                    <p className="mb-1">
                      Редове със записи:
                      {' '}
                      {props.filteredData().length + props.invalidRows().length}
                    </p>
                    <p className="mb-1">
                      Валидни редове:
                      {' '}
                      {props.filteredData().length}
                      {' '}
                      (
                      {props.filterUniqueAndDuplicate().uniqueAnimals.length}
                      {' '}
                      {props.filterUniqueAndDuplicate().uniqueAnimals.length !== 1 ? 'животни' : 'животно'}
                      )
                    </p>
                    <p className="mb-1">
                      Невалидни редове:
                      {' '}
                      {props.invalidRows().length}
                    </p>
                  </div>

                  {printDuplicate.length > 0 && (
                  <div className="mt-3">
                    <h4 className="mb-3 spreadsheet-warning-msg">
                      Внимание ! Дублирани животни !
                    </h4>
                    <p className="font-weight-bold mb-4">Ha следните редове има идентификатор за едно и също животно:</p>
                    <div className="spreadsheet-duplicate-animals-container">
                      {printDuplicate}
                    </div>
                  </div>
                  )}
                </Col>
              </Row>
              <DialogActionsBar>
                <div className="d-flex">
                  <button className="btn btn-secondary" onClick={toggleDialog}>Отказ</button>
                  <button
                    type="submit"
                    disabled={!(props.filterUniqueAndDuplicate().uniqueAnimals.length > 0)}
                    className="btn btn-primary ml-3"
                    onClick={e => handleSubmit(e, mutationTrigger)}
                  >
                    Запиши
                  </button>
                </div>
              </DialogActionsBar>
            </Dialog>
          )}
        </div>
      )}
    </Mutation>
  );
};

export default withApollo<any, any>(GroupWeightingModal);
