/* eslint react/button-has-type:0 */
/* eslint max-len:0 */
/* eslint no-underscore-dangle:0 */
/* eslint react/destructuring-assignment:0 */
/* eslint react/no-access-state-in-setstate:0 */
/* eslint no-param-reassign:0 */
import * as React from 'react';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Row, Col } from 'reactstrap';
import { Input } from '@progress/kendo-react-inputs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import Mutation from '../../../../data/mutation';
import { GET_USERS, EDIT_USER, ADD_USER } from '../../../../data/users/users-queries';
import { USER_ROLES } from '../../../../tools/translation-constants';
import { deepCopy, isActionTypeAndGetQuery } from '../../../../tools/function-helpers';

const getQuery = (type, action) => {
  if (action === 'add') {
    return ADD_USER;
  }
  return EDIT_USER;
};

const keysToRemove = ['roles', 'farmId'];

const removeObjKeys = (obj, arrayKeys) => {
  arrayKeys.forEach(element => {
    delete obj[element];
  });
};

const selectedValue = (val, array, prop) => {
  const selected = array.find((element) => {
    return element[prop]?.toLowerCase() === val?.toLowerCase();
  });
  return selected;
};


class UsersTableModal extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      dataItem: {
        ...props.dataItem,
      },
      submitDisabled: true,
    };
    this.toggleDialog = this.toggleDialog.bind(this);
    this.handleFormChange = this.handleFormChange.bind(this);
  }

  toggleDialog() {
    this.setState({
      visible: !this.state.visible,
    });

    if (!this.state.visible) {
      this.resetState();
    }
  }


  handleFormChange(e) {
    const objCopy = deepCopy(this.state.dataItem);
    let isFormSubmitable = false;
    delete objCopy.__typename;

    delete objCopy.childrenObject;

    if (e.target.name === 'userName') {
      objCopy[e.target.name] = e.target.value;
    } else if (e.target.name === 'email') {
      objCopy[e.target.name] = e.target.value;
    } else if (e.target.name === 'roles') {
      objCopy[e.target.name] = e.target.value.value;
      objCopy.role = e.target.value.id;
    }

    if (!objCopy.userName || !objCopy.email || !objCopy.role) {
      isFormSubmitable = true;
    } else {
      isFormSubmitable = false;
    }

    this.setState({
      dataItem: objCopy,
      submitDisabled: isFormSubmitable,
    });
  }

  handleSubmit(e, mutationTrigger) {
    e.preventDefault();

    const objCopy = { ...this.state.dataItem, };
    this.toggleDialog();
    removeObjKeys(objCopy, keysToRemove);
    mutationTrigger({
      variables:
            {
              dataItem: objCopy,
            },
    });
  }

  resetState() {
    const copyData = { ...this.props.dataItem, };
    this.setState({
      dataItem: copyData,
      submitDisabled: true,
    });
  }

  render() {
    const isAddActionType = isActionTypeAndGetQuery(this.props.action, 'add', '', '');
    return (
      <Mutation
        mutation={getQuery(this.props.type, this.props.action)}
        awaitRefetchQueries
        refetchQueries={[{ query: GET_USERS, variables: {}, }]}
      >
        {(mutationTrigger) => (
          <div className="d-inline-block">
            <button className={isAddActionType.isActionType ? 'btn btn-primary mr-2' : 'button-icon'} onClick={this.toggleDialog}>{isAddActionType.isActionType ? 'Добави потребител' : <FontAwesomeIcon icon="pen" />}</button>
            {this.state.visible && (
            <form onSubmit={e => this.handleSubmit(e, mutationTrigger)}>
              <Dialog title={`${isAddActionType.isActionType ? 'Добави' : 'Редактирай'} ${'потребител'}`} onClose={this.toggleDialog} minWidth="500px" height="70%">
                <Row>
                  <Col lg={{ size: 8, offset: 2, }}>
                    <div className="form-group">
                      <label>Име</label>
                      <Input type="text" name="userName" value={this.state.dataItem.userName ? this.state.dataItem.userName : ''} onChange={this.handleFormChange} />
                    </div>
                    {
                                            isAddActionType.isActionType
                                            && (
                                            <div className="form-group">
                                              <label>Email</label>
                                              <Input type="text" name="email" value={this.state.dataItem.email ? this.state.dataItem.email : ''} onChange={this.handleFormChange} />
                                            </div>
                                            )
                                        }
                    <div className="form-group">
                      <label>Роля:</label>
                      <DropDownList
                        data={USER_ROLES}
                        dataItemKey="id"
                        textField="text"
                        name="roles"
                        value={this.state.dataItem.role ? selectedValue(this.state.dataItem.role, USER_ROLES, 'id') : ''}
                        onChange={this.handleFormChange}
                        required
                      />
                    </div>
                  </Col>
                </Row>
                <DialogActionsBar>
                  <div className="d-flex">
                    <button className="btn btn-secondary" onClick={this.toggleDialog}>Отказ</button>
                    <button type="submit" disabled={this.state.submitDisabled} className="btn btn-primary ml-3">{isAddActionType.isActionType ? 'Добави' : 'Редактирай'}</button>
                  </div>
                </DialogActionsBar>
              </Dialog>
            </form>
            )}
          </div>
        )}
      </Mutation>
    );
  }
}

export default UsersTableModal;
