/* eslint react/no-unescaped-entities:0 */
/* eslint react/button-has-type:0 */
/* eslint jsx-a11y/control-has-associated-label:0 */
/* eslint max-len:0 */
/* eslint react/no-this-in-sfc:0 */
/* eslint react/destructuring-assignment:0 */
import * as React from 'react';
import { Row, Col } from 'reactstrap';
import { Input } from '@progress/kendo-react-inputs';
import { DropDownList } from '@progress/kendo-react-dropdowns';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FarmClientDropdown from '../health/farm-client-dropdown';

import Mutation from '../../../../../data/mutation';
import CustomComboBox from './custom-combo-box';
import {
  UPDATE_ANIMAL, GET_ANIMAL_DETAILS, GET_ANIMAL_BREEDS, ANIMALS_GRID
} from '../../../../../data/animal/animal-query';
import { GET_RODEO_VETIS_DATA } from '../../../../../data/reports/reports-query';
import { GET_FIRMS_IN_FARMS_NAMES_AND_ID } from '../../../../../data/companies/companies-queries';
import PurchaseOwnerModal from './purchase-price-modal';
import {
  SEXES, BOOLEANS, COUNTRIES, DNA_TYPES,
  EID_TYPES, REGISTERS, HORN_STATUSES, BREEDING_FOR, ANIMAL_FOR_SCRAP
} from '../../../../../tools/translation-constants';
import Query from '../../../../../data/query';
import BreedCompositModal from './breed-composit-modal';
import { DatePicker } from '../../../../utils/date-picker';

export default function (this: any) {
  const getPurchase = () => {
    const [first] = this.props.currentAnimal.animalPurchase;
    return first;
  };
  return (
    <Mutation
      mutation={UPDATE_ANIMAL}
      awaitRefetchQueries
      refetchQueries={[{ query: GET_ANIMAL_DETAILS, variables: { id: this.props.currentAnimal.animalId, }, }, { query: GET_RODEO_VETIS_DATA, }, { query: ANIMALS_GRID, }]}
      onCompleted={() => this.toggleEditing()}
    >
      {(updateAnimalTrigger) => (
        <form onSubmit={e => this.handleSubmit(e, updateAnimalTrigger)}>
          <div className="mb-2">
            <button type="button" className={this.state.editing ? 'btn btn-secondary' : 'btn btn-primary'} onClick={this.toggleEditing}>{this.state.editing ? 'ОТКАЖИ' : 'РЕДАКТИРАЙ'}</button>
            {this.state.editing ? <button disabled={Object.keys(this.state.changes).length <= 1} type="submit" className="btn btn-primary ml-2">ЗАПИШИ</button> : ''}
            {/* <button type="button" className="btn btn-dark">ПРЕМАРКИРАЙ</button> */}
          </div>
          <fieldset disabled={!this.state.editing}>
            <Row>
              <Col lg="2">
                <div className="form-group">
                  <label>ВЕТИС №</label>
                  <Input name="lID" onChange={this.handleChange} value={this.state.animalData.lID} />
                </div>
              </Col>
              <Col lg="2">
                <div className="form-group">
                  <label>ВИН №</label>
                  <Input name="vID" onChange={this.handleChange} value={this.state.animalData.vID} />
                </div>
              </Col>
              <Col lg="2">
                <div className="form-group">
                  <label>EID №</label>
                  <Input name="electronicId" onChange={this.handleChange} value={this.state.animalData.electronicId} />
                </div>
              </Col>
              <Col lg="2">
                <div className="form-group">
                  <label>Вид EID</label>
                  <DropDownList
                    name="eIDType"
                    onChange={this.handleChange}
                    data={EID_TYPES}
                    textField="text"
                    dataItemKey="id"
                    value={this.state.animalData.eIDType}
                    disabled={!this.state.editing}
                  />
                </div>
              </Col>
              <Col lg="2">
                <div className="form-group">
                  <label>Направление</label>
                  <DropDownList
                    name="breedingFor"
                    onChange={this.handleChange}
                    data={BREEDING_FOR}
                    textField="text"
                    dataItemKey="id"
                    value={this.state.animalData.breedingFor}
                    disabled={!this.state.editing}
                  />
                </div>
              </Col>
              <Col lg="2">
                <div className="form-group">
                  <label>Име</label>
                  <Input name="name" onChange={this.handleChange} value={this.state.animalData.name} />
                </div>
              </Col>
              <Col lg="2">
                <div className="form-group">
                  <label>Паспорт №</label>
                  <Input name="passportNumber" onChange={this.handleChange} value={this.state.animalData.passportNumber} />
                </div>
              </Col>
              <Col lg="2">
                <div className="form-group">
                  <label>Дата на раждане</label>
                  <DatePicker
                    name="dateOfBirth"
                    onChange={this.handleChange}
                    value={this.state.animalData?.dateOfBirth}
                    disabled={!this.state.editing}
                  />
                </div>
              </Col>
              <Col lg="2">
                <div className="form-group">
                  <label>Пол</label>
                  <DropDownList
                    name="sex"
                    onChange={this.handleChange}
                    data={SEXES}
                    textField="text"
                    dataItemKey="id"
                    value={this.state.animalData.sex}
                    disabled={!this.state.editing}
                  />
                </div>
              </Col>
              <Col lg="2">
                <div className="form-group">
                  <label>Категория</label>
                  <DropDownList
                    name="aClass"
                    onChange={this.handleChange}
                    data={this.props.aClasses.items}
                    textField="className"
                    dataItemKey="classID"
                    value={this.state.animalData.aClass}
                    disabled={!this.state.editing}
                  />
                </div>
              </Col>
              <Col lg="2">
                <div className="form-group">
                  <label>Порода</label>
                  <DropDownList
                    name="breed"
                    onChange={this.handleChange}
                    data={this.props.breeds.items}
                    textField="breedName"
                    dataItemKey="breedsId"
                    value={this.state.animalData.breed}
                    disabled={!this.state.editing}
                  />
                </div>
              </Col>
              <Col lg="2">
                <Query query={GET_ANIMAL_BREEDS}>
                  {(breeds) => {
                    return (
                      <div className="form-group">
                        <label>Породен композит</label>
                        <span>{this.state.animalData.formattedBreedComposition}</span>
                        {this.state.editing && (
                        <BreedCompositModal
                          refreshText={this.refreshBreedComposition}
                          animal={this.state.animalData}
                          breeds={breeds.breeds.items}
                        />
                        )}
                      </div>
                    );
                  }}
                </Query>
              </Col>
              <Col lg="2">
                <div className="form-group">
                  <label>Рога</label>
                  <DropDownList
                    name="hornStatusID"
                    onChange={this.handleChange}
                    data={HORN_STATUSES}
                    textField="text"
                    dataItemKey="id"
                    value={this.state.animalData.hornStatusID}
                    disabled={!this.state.editing}
                  />
                </div>
              </Col>
              <Col lg="2">
                <div className="form-group">
                  <label>Цвят</label>
                  <DropDownList
                    name="color"
                    onChange={this.handleChange}
                    data={this.props.color.items}
                    textField="colorName"
                    dataItemKey="colorID"
                    value={this.state.animalData.color}
                    disabled={!this.state.editing}
                  />
                </div>
              </Col>
              <Col lg="2">
                <div className="form-group">
                  <label>Майка</label>
                  <CustomComboBox
                    name="damID"
                    onChange={this.handleChange}
                    value={this.state.animalData.damID}
                    data={this.props.dams.items}
                    textField="lID"
                    dataItemKey="animalId"
                    disabled={!this.state.editing}
                  />
                </div>
              </Col>
              <Col lg="2">
                <div className="form-group">
                  <label>Баща</label>
                  <CustomComboBox
                    name="sireID"
                    onChange={this.handleChange}
                    value={this.state.animalData.sireID}
                    data={this.props.sires.items}
                    textField="lID"
                    dataItemKey="animalId"
                    disabled={!this.state.editing}
                  />
                </div>
              </Col>
              <Col lg="2">
                <div className="form-group">
                  <label>Произход държава</label>
                  <DropDownList
                    name="countryFrom"
                    onChange={this.handleChange}
                    data={COUNTRIES}
                    textField="text"
                    dataItemKey="id"
                    value={this.state.animalData.countryFrom}
                    disabled={!this.state.editing}
                  />
                </div>
              </Col>
              <Col lg="2">
                <div className="form-group">
                  <label>Цена на придобиване</label>
                  <div style={{ display: 'flex', }}>
                    <Input disabled value={getPurchase()?.priceTotal} />
                    {this.state.editing && <button className="button-icon" onClick={() => this.setState({ purchaseEditing: true, })}><FontAwesomeIcon icon="pen" /></button> }
                    {this.state.purchaseEditing && <PurchaseOwnerModal onClose={() => this.setState({ purchaseEditing: false, })} animal={this.props.currentAnimal} />}
                  </div>
                </div>
              </Col>
              <Col lg="2">
                <div className="form-group">
                  <label>Дата на придобиване</label>
                  <DatePicker
                    name="registrationDate"
                    onChange={this.handleChange}
                    value={this.state.animalData?.registrationDate}
                    disabled={!this.state.editing}
                  />
                </div>
              </Col>
              <Col lg="4">
                <div className="form-group">
                  <label className="d-flex flex-column">Развъдчик</label>
                  <FarmClientDropdown name="farmClient" farmClientID={getPurchase()?.client?.farmClientID} tableType={this.state.farmClientTableType} handleFormChange={this.handleChange} disabled={!this.state.editing} />
                </div>
              </Col>
              <Col lg="2">
                <div className="form-group">
                  <label>Развъден регистър ІBGІ</label>
                  <DropDownList
                    name="register"
                    onChange={this.handleChange}
                    data={REGISTERS}
                    textField="text"
                    dataItemKey="id"
                    value={this.state.animalData.register}
                    disabled={!this.state.editing}
                  />
                </div>
              </Col>
              <Col lg="2">
                <div className="form-group">
                  <label>Племенен регистър 2*</label>
                  <Input name="herdBookOut" onChange={this.handleChange} value={this.state.animalData.herdBookOut} />
                </div>
              </Col>
              <Col lg="2">
                <div className="form-group">
                  <label>ДНК №</label>
                  <Input name="dNAnumber" onChange={this.handleChange} value={this.state.animalData.dNAnumber} />
                </div>
              </Col>
              <Col lg="2">
                <div className="form-group">
                  <label>ДНК Тип</label>
                  <DropDownList
                    name="dNAType"
                    onChange={this.handleChange}
                    data={DNA_TYPES}
                    textField="text"
                    dataItemKey="id"
                    value={this.state.animalData.dNAType}
                    disabled={!this.state.editing}
                  />
                </div>
              </Col>
              <Col lg="2">
                <div className="form-group">
                  <label>От ембрио-трансфер</label>
                  <DropDownList
                    name="fromEmbryoTransfer"
                    onChange={this.handleChange}
                    data={BOOLEANS}
                    textField="text"
                    dataItemKey="id"
                    value={this.state.animalData.fromEmbryoTransfer}
                    disabled={!this.state.editing}
                  />
                </div>
              </Col>
              <Col lg="2">
                <div className="form-group">
                  <label>Сурогатна майка</label>
                  <Input disabled />
                </div>
              </Col>
              <Col lg="2">
                <div className="form-group">
                  <label>"Под СК"</label>
                  <DropDownList
                    name="underSelection"
                    onChange={this.handleChange}
                    data={BOOLEANS}
                    textField="text"
                    dataItemKey="id"
                    value={this.state.animalData.underSelection}
                    disabled={!this.state.editing}
                  />
                </div>
              </Col>
              {/* <Col lg="2">
                <div className="form-group">
                  <label>Задържане до</label>
                  <DatePicker
                    name="holdTo"
                    onChange={this.handleChange}
                    value={this.state.animalData?.holdTo}
                    disabled={!this.state.editing}
                  />
                </div>
              </Col> */}
              <Col lg="2">
                <div className="form-group">
                  <label>За Брак</label>
                  <DropDownList
                    name="forScrap"
                    onChange={this.handleChange}
                    data={ANIMAL_FOR_SCRAP}
                    textField="text"
                    dataItemKey="id"
                    value={this.state.animalData.forScrap}
                    disabled={!this.state.editing}
                  />
                </div>
              </Col>
              <Col lg="2">
                <Query query={GET_FIRMS_IN_FARMS_NAMES_AND_ID}>
                  {(firmsInFarms) => (
                    <div className="form-group">
                      <label>Собственик</label>
                      <DropDownList
                        name="firmID"
                        onChange={this.handleChange}
                        data={firmsInFarms.firmsInFarms.items}
                        textField="firmName"
                        dataItemKey="firmID"
                        value={this.state.animalData.firmID}
                        disabled={!this.state.editing}
                      />
                    </div>
                  )}
                </Query>
              </Col>
              <Col lg="2">
                <div className="form-group">
                  <label>Бележка</label>
                  <textarea className="k-textarea" name="comment" onChange={this.handleChange} value={this.state.animalData.comment} />
                </div>
              </Col>
            </Row>
          </fieldset>
        </form>
      )}
    </Mutation>
  );
}
