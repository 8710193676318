import { combineReducers } from 'redux';
import { localizeReducer } from 'react-localize-redux';
import { auth } from './auth-reducer';
import { animal } from './animal/animal-reducer';


export default combineReducers({
  localize: localizeReducer,
  auth,
  animal,
});
