/* eslint react/button-has-type:0 */
/* eslint no-shadow:0 */
/* eslint no-underscore-dangle:0 */
/* eslint react/destructuring-assignment:0 */
import * as React from 'react';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Row, Col } from 'reactstrap';
import { useRodeoMutation } from '../../../../../data/mutation';
import { ADD_ANIMAL_PURCHASE, GET_ANIMAL_DETAILS, EDIT_ANIMAL_PURCHASE } from '../../../../../data/animal/animal-query';
import EconomyTab from '../../movements/entries/group-entries/tabs/economy-tab';

type AnimalPurchase = {
    animalID: number,
    priceTotal: number,
    pricePerHead: number,
    __typename: string,
    client: {
        farmClientName: string
    }
}

type Animal = {
    animalId: number,
    animalPurchase: [AnimalPurchase]
}
type PurchasePriceModalProps = {
    animal: Animal,
    onClose: () => void
}

type AddOrEdit = 'add' | 'edit';

const PurchaseOwnerModal = (props: PurchasePriceModalProps) => {
  const addOrEdit: AddOrEdit = props.animal.animalPurchase.length ? 'edit' : 'add';
  if (addOrEdit === 'add') {
    const purchase: AnimalPurchase = {
      pricePerHead: 0,
      client: {
        farmClientName: '',
      },
      __typename: '',
      animalID: null,
      priceTotal: 0,
    };
    props.animal.animalPurchase.push(purchase);
  }

  const [animal, setAnimal] = React.useState<Animal>(props.animal);

  const submitDisabled = (): boolean => false;

  const handleSubmit = (e, mut) => {
    const [purchase] = animal.animalPurchase;
    purchase.animalID = animal.animalId;
    delete purchase.client;
    delete purchase.__typename;

    mut({
      variables: {
        purchase,
      },
    });

    props.onClose();
  };

  const getGroupObject = () => animal;
  const updateGroupObject = animal => setAnimal(animal);

  const mutation = useRodeoMutation({
    mutation: addOrEdit === 'add' ? ADD_ANIMAL_PURCHASE : EDIT_ANIMAL_PURCHASE,
    awaitRefetchQueries: true,
    refetchQueries: [{ query: GET_ANIMAL_DETAILS, variables: { id: animal.animalId, }, }],
  });

  return (
    <Dialog className="health-modal" title="Данни за покупка" onClose={props.onClose}>
      <Row>
        <Col lg={{ size: 12, }}>
          <EconomyTab getGroupObject={getGroupObject} updateGroupObject={updateGroupObject} />
        </Col>
      </Row>
      <DialogActionsBar>
        <div className="d-flex">
          <button className="btn btn-secondary" onClick={props.onClose}>Отказ</button>
          <button type="submit" disabled={submitDisabled()} onClick={e => handleSubmit(e, mutation)} className="btn btn-primary ml-3">Запази</button>
        </div>
      </DialogActionsBar>
    </Dialog>
  );
};

export default PurchaseOwnerModal;
