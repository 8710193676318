/* eslint react/prop-types:0 */
/* eslint react/destructuring-assignment:0 */
import * as React from 'react';
import { Row, Col } from 'reactstrap';
import { Input, NumericTextBox } from '@progress/kendo-react-inputs';

import { DatePicker } from '../../../../utils/date-picker';

const ServiceGroupData = props => {
  const [group, setGroup] = React.useState(props.group);

  const onChange = (e, prop) => {
    group[prop] = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    const copy = { ...group, };
    setGroup(copy);
    props.setGroupData(copy);
  };

  return (
    <fieldset>
      <h3 className="mb-4">РЕДАКТИРАЙ СЪРВИС ГРУПА</h3>
      <h4 className="mb-2">Данни за групата</h4>
      <Row>
        <Col lg="4">
          <Row>
            <Col>
              <div className="form-group">
                <label>Име на групата</label>
                <Input name="Name" onChange={e => onChange(e, 'groupName')} value={group.groupName} />
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="form-group">
                <label>Продължителност в дни</label>
                <NumericTextBox name="Duration" onChange={e => onChange(e, 'daysLong')} value={group.daysLong} />
              </div>
            </Col>
          </Row>

          <Row>
            <Col>
              <div className="form-group">
                <label>Начало на сървиса</label>
                <DatePicker
                  value={typeof group.dateCreate === 'string' ? new Date(Date.parse(group.dateCreate)) : group.dateCreate}
                  onChange={e => onChange(e, 'dateCreate')}
                />
              </div>
            </Col>
          </Row>
          { group.groupID
                    && (
                    <div className="form-group">
                      <input id="active" type="checkbox" className="k-checkbox" checked={group.active} onChange={e => onChange(e, 'active')} />
                      <label className="k-checkbox-label" htmlFor="active">Активна</label>
                    </div>
                    )}
        </Col>
        <Col lg="5">
          <Col>
            <div className="form-group">
              <label>Коментар</label>
              <textarea className="k-textarea" name="Note" onChange={e => onChange(e, 'comment')} value={group.comment || ''} />
            </div>
          </Col>
        </Col>
      </Row>

    </fieldset>
  );
};


export default ServiceGroupData;
