/* eslint react/no-access-state-in-setstate:0 */
/* eslint react/destructuring-assignment:0 */
import * as React from 'react';
import { Row, Col } from 'reactstrap';
import Query from '../../../../../data/query';
import { GET_ANIMAL_LOCATIONS } from '../../../../../data/animal/animal-query';
import LocationsSelect from '../../../../locations-select/locations-select';
import TabNavButtons from './tab-nav-buttons';
import { deepCopy } from '../../../../../tools/function-helpers';
import { DatePicker } from '../../../../utils/date-picker';

class SelectLocation extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      animalsData: props.getAnimalsData ? props.getAnimalsData() : {},
    };
    this.handleFormChange = this.handleFormChange.bind(this);
    this.updateLocationIDandSelected = this.updateLocationIDandSelected.bind(this);
  }

  handleFormChange(event) {
    const animalsDataCopy = deepCopy(this.state.animalsData);
    animalsDataCopy[event.target.name] = event.target.value;
    this.setState({
      animalsData: animalsDataCopy,
    });
    this.props.updateAnimalsData(animalsDataCopy);
  }

  updateLocationIDandSelected(location) {
    const animalsDataCopy = deepCopy(this.state.animalsData);
    const data = {
      ...animalsDataCopy,
      ...location,
    };
    this.setState({
      animalsData: data,
    });
    this.props.updateAnimalsData(data);
  }

  render() {
    return (
      <div>
        <h3>ИЗБОР НА ЛОКАЦИЯ</h3>
        <Query query={GET_ANIMAL_LOCATIONS}>
          {({ locations, }) => {
            return (
              <LocationsSelect
                locations={locations.items}
                updateLocationIDandSelected={this.updateLocationIDandSelected}
                selectedLocations={this.state.animalsData.selected ? this.state.animalsData.selected : null}
              />
            );
          }}
        </Query>
        <form>
          <Row>
            <Col lg="3">
              <div className="form-group">
                <label>Дата на преместване</label>
                <DatePicker
                  name="moveDate"
                  onChange={this.handleFormChange}
                  value={this.state.animalsData?.moveDate}
                />
              </div>
            </Col>
          </Row>

        </form>
        <TabNavButtons parentProps={this.props} isFormValid={!!this.state.animalsData.locationId} />
      </div>
    );
  }
}

export default SelectLocation;
