/* eslint react/prop-types:0 */
/* eslint react/destructuring-assignment:0 */
import * as React from 'react';

import Query from '../../../../data/query';
import Pedigree from '../../../lib/pedigree/pedigree';
import BreedingForm from './breeding/breeding-form';
import { GET_ANIMAL_BREEDING_DATA } from '../../../../data/animal/animal-query';

const BreedingTab = props => {
  return (
    <div className="tab">
      <div className="tab-head">
        <div className="d-flex align-items-baseline mb-4">
          <h3 className="mb-0">РАЗВЪДНИ</h3>
          {/* <div className="ml-3">
            <strong className="d-block">А | PED | НАМГБ | ДНК 134/567 | SNP</strong>
            <strong className="d-block">7/7/7 | @0 | 29 кг | @200 | 185 кг | @ 365 | 388 кг</strong>
          </div> */}
        </div>
        <h4 className="mb-3">Развъдни данни | Родословие</h4>
      </div>
      <div>
        <Query query={GET_ANIMAL_BREEDING_DATA} variables={{ id: props.currentAnimal.animalId, }}>
          {(data) => {
            return <BreedingForm animalId={props.currentAnimal.animalId} breedingData={data} />;
          }}
        </Query>
        <Pedigree currentAnimalId={props.currentAnimal.animalId} />
      </div>
    </div>
  );
};

export default BreedingTab;
