/* eslint-disable */
import * as React from 'react';
import LocationsTable from './locations-table';
import LocationsSelect from '../../../locations-select/locations-select';
import Query from '../../../../data/query';
import { GET_ANIMAL_LOCATIONS } from '../../../../data/animal/animal-query';
import { GET_ANIMALS_IN_LOCATION } from '../../../../data/reports/reports-query';
import { animalSexTranslation, reportAnimalSummary } from '../../../../tools/function-helpers';
import DatesHelper from '../../../../tools/DatesHelper';
import {
  generateLocationString,
} from '../../animals/animal-utilities';


class LocationsReport extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      locationID: -1,
      lastLocationId: null,
      updateGroupObject: props.updateGroupObject,
      isSubmited: false,
    };
    this.updateLocationID = this.updateLocationID.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  updateLocationID(location) {
    this.setState({
      locationID: location.locationId,
      isSubmited: false,
    });
  }

  handleSubmit() {
    this.setState({
      isSubmited: true,
      lastLocationId: this.state.locationID,
    });
  }


  render() {
    return (
      <div className="container-fluid">
        <h3 className="mb-4">Справка ЖИВОТНИ ПО ЛОКАЦИЯ</h3>
        <h4 className="mb-2">Моля изберете локация</h4>
        <Query query={GET_ANIMAL_LOCATIONS}>
          {({ locations, }) => {
            return <LocationsSelect locations={locations.items} updateLocationIDandSelected={this.updateLocationID} />;
          }}
        </Query>
        <button className="btn btn-primary" onClick={this.handleSubmit} disabled={this.state.lastLocationId === this.state.locationID || this.state.locationID === -1}>Генерирай таблица</button>
        <hr className="color-primary mb-5" />
        {this.state.isSubmited && (
        <Query query={GET_ANIMALS_IN_LOCATION} variables={{ id: this.state.locationID, }} fetchPolicy="network-only">
          {({ locationReport, }) => {
            const mappedAnimals = locationReport ? locationReport.items.filter(a => a.animals.active).map((item) => {
              const sexTranslation = animalSexTranslation(item.animals.sex);
              const weightsLen = item.animals.weights.length;
              const locLen = item.animals.animalLocation.length;
              return {
                moveDate: DatesHelper.dateToAppFormat(item.moveDate),
                curentLocation: generateLocationString({ animalLocation: item.animals.animalLocation.reverse(), }),
                adgOverall: parseFloat(item.animals.adgOverall.toFixed(2)),
                lastWeight: weightsLen > 0 ? item.animals.weights[weightsLen - 1].weight : null,
                dateWeight: weightsLen > 0 ? DatesHelper.dateToAppFormat(item.animals.weights[weightsLen - 1].dateWeight) : null,
                animalSummary: reportAnimalSummary(item.animals, sexTranslation),
                prevLocation:  locLen > 0 ? generateLocationString({ animalLocation: item.animals.animalLocation.slice(0,-1), }) : null,
              };
            }) : [];
            return <LocationsTable {...this.props} animals={mappedAnimals} />;
          }}
        </Query>
        )}
      </div>
    );
  }
}

export default LocationsReport;
