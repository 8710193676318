/* eslint no-unused-vars:0 */
import * as React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import GroupEntries from './entries/group-entries/group-entries';


const Movements = _ => (
  <Switch>
    <Route path="/animals/movement/group-entries" component={GroupEntries} />
    <Route path="/animals/movement">
      <Redirect to="/animals/movement/group-entries" />
    </Route>
  </Switch>
);

export default Movements;
