/* eslint react/button-has-type:0 */
/* eslint max-len:0 */
/* eslint no-prototype-builtins:0 */
/* eslint react/no-access-state-in-setstate:0 */
/* eslint react/destructuring-assignment:0 */
import * as React from 'react';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Row, Col } from 'reactstrap';
import { Input } from '@progress/kendo-react-inputs';
import Mutation from '../../../../../../data/mutation';
import { GET_ANIMAL_CHILDREN_WEANING_DATA, CREATE_WEANING_EVENT, GET_ANIMAL_DETAILS } from '../../../../../../data/animal/animal-query';
import { animalSexTranslation, deepCopy } from '../../../../../../tools/function-helpers';
import { getAnimalAgeInMonths } from '../../../animal-utilities';
import { DatePicker } from '../../../../../utils/date-picker';

const mappedChildrenSucking = (children, status) => {
  return children.filter(child => child.weaningStatus === status && child.bornDead !== true && child.active === true).map((filtered) => {
    return {
      id: filtered.animalId,
      value: `${filtered.lID}(${filtered.vID} | ${animalSexTranslation(filtered.sex)} | ${getAnimalAgeInMonths(filtered.dateOfBirth)}м.)`,
    };
  });
};


class WeaningModal extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      dataItem: {
        animalID: this.props.animalID,
      },
      submitDisabled: true,
    };
    this.toggleDialog = this.toggleDialog.bind(this);
    this.handleFormChange = this.handleFormChange.bind(this);
  }

  toggleDialog() {
    this.setState({
      visible: !this.state.visible,
    });

    if (!this.state.visible) {
      this.resetState();
    }
  }

  handleFormChange(e) {
    const objCopy = { ...this.state.dataItem, };
    objCopy[e.target.name] = e.target.value;

    const isFormSubmitable = !objCopy.calfID;
    this.setState({
      dataItem: objCopy,
      submitDisabled: isFormSubmitable,
    });
  }

  handleSubmit(e, mutationTrigger) {
    e.preventDefault();
    const stateCopy = deepCopy(this.state.dataItem);
    stateCopy.calfID = stateCopy.calfID.id;
    if (!stateCopy.hasOwnProperty('weaningDate')) {
      stateCopy.weaningDate = new Date();
    }

    mutationTrigger({
      variables:
            {
              dataItem: stateCopy,
            },
    });
  }

  resetState() {
    const { animalID, } = this.state.dataItem;
    this.setState({
      dataItem: {
        animalID,
      },
      submitDisabled: true,
    });
  }

  render() {
    return (
      <Mutation
        mutation={CREATE_WEANING_EVENT}
        awaitRefetchQueries
        refetchQueries={[{ query: GET_ANIMAL_CHILDREN_WEANING_DATA, variables: { id: this.state.dataItem.animalID, }, }, { query: GET_ANIMAL_DETAILS, variables: { id: this.state.dataItem.calfID ? this.state.dataItem.calfID.id : null, }, }]}
        onCompleted={() => this.toggleDialog()}
      >
        {(mutationTrigger) => (
          <div>
            <button className="btn btn-primary" onClick={this.toggleDialog}>ДОБАВИ СЪБИТИЕ</button>
            {this.state.visible && (
            <form onSubmit={e => this.handleSubmit(e, mutationTrigger)}>
              <Dialog title="Добави събитие" onClose={this.toggleDialog} minWidth="500px" height="80%">
                <Row>
                  <Col lg={{ size: 8, offset: 2, }}>

                    <div className="form-group">
                      <label>Изберете теле</label>
                      <DropDownList
                        style={{ width: '100%', }}
                        data={mappedChildrenSucking(this.props.animalChildren, 'SUCKING')}
                        dataItemKey="id"
                        textField="value"
                        name="calfID"
                        required
                        onChange={this.handleFormChange}
                      />
                    </div>
                    <div className="form-group">
                      <label>Килограми при отбиване</label>
                      <Input type="number" name="weaningWeight" onChange={this.handleFormChange} />
                    </div>
                    <div className="form-group">
                      <label>Дата</label>
                      <DatePicker
                        value={this.state.dataItem?.weaningDate}
                        name="weaningDate"
                        onChange={this.handleFormChange}
                        min={new Date(this.props.animaldateOfBirth)}
                        max={new Date()}
                      />
                    </div>
                  </Col>
                </Row>
                <DialogActionsBar>
                  <div className="d-flex">
                    <button className="btn btn-secondary" onClick={this.toggleDialog}>Отказ</button>
                    <button type="submit" disabled={this.state.submitDisabled} className="btn btn-primary ml-3">Добави</button>
                  </div>
                </DialogActionsBar>
              </Dialog>
            </form>
            )}
          </div>
        )}
      </Mutation>
    );
  }
}

export default WeaningModal;
