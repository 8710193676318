/* eslint react/no-access-state-in-setstate:0 */
/* eslint react/no-did-update-set-state:0 */
/* eslint react/destructuring-assignment:0 */
/* eslint no-unused-vars:0 */
import * as React from 'react';
import { Row, Col } from 'reactstrap';
import { Input } from '@progress/kendo-react-inputs';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { filterBy } from '@progress/kendo-data-query';
import { intersectionWith } from 'lodash';
import { DropDownListChangeEvent } from '@progress/kendo-react-dropdowns/dist/npm/DropDownList/DropDownListProps';
import { deepCopy } from '../../tools/function-helpers';
import { DEFAULT_VALUE_DROPDOWN, DropdownFilterCellProps } from '../../tools/dropdown-filter-cell';
import LocationDropDownList from '../../tools/locations-dropdown-filter-cell';

const locationDefault = DEFAULT_VALUE_DROPDOWN;
const breedDefault = { breedName: DEFAULT_VALUE_DROPDOWN, breedsId: -1, breedShort: '', };
const colorDefault = { colorName: DEFAULT_VALUE_DROPDOWN, colorID: -1, };
const classNameDefault = { className: DEFAULT_VALUE_DROPDOWN, classID: -1, };
const groupDefault = { managementGroupsName: DEFAULT_VALUE_DROPDOWN, managementGroupsID: -1, };

class ListBoxFiltersComponent extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      animals: props.animals,
      searchValue: '',
      breedValue: breedDefault,
      colorValue: colorDefault,
      className: classNameDefault,
      groupValue: groupDefault,
      locationValue: locationDefault,
      breeds: [breedDefault, ...(props.breeds ? props.breeds : [])],
      colors: [colorDefault, ...(props.colors ? props.colors : [])],
      aClasses: [classNameDefault, ...(props.aClasses ? props.aClasses : [])],
      locations: [...(props.locations ? props.locations : [])],
      animalGroups: [groupDefault, ...(props.groups ? props.groups : [])],
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.animals !== prevProps.animals) {
      this.setState({
        animals: this.props.animals,
      });
    }
  }

  handleChange(event) {
    let data = deepCopy(this.state.animals);
    const searchInputValue = event.target.name === 'search' ? event.target.value : this.state.searchValue;
    const breedSelectValue = event.target.name === 'breed' ? event.target.value : this.state.breedValue;
    const colorSelectValue = event.target.name === 'color' ? event.target.value : this.state.colorValue;
    const classNameSelectValue = event.target.name === 'className' ? event.target.value : this.state.className;
    const groupSelectValue = event.target.name === 'groups' ? event.target.value : this.state.groupValue;
    const locationSelectValue = event.target.name === 'locations' ? event.target.value : this.state.locationValue;

    if (groupSelectValue.managementGroupsID !== -1) {
      data = this.props.intersectionOfAnimals
        ? intersectionWith(data, groupSelectValue.animals, (animal, animlaFromGroup) => animal.id === animlaFromGroup.animalId)
        : groupSelectValue.animals;
    }

    let logic = 'or';
    const filters: any = [
      {
        logic: 'or',
        filters: [
          { field: 'lID', operator: 'contains', value: searchInputValue, },
          { field: 'vID', operator: 'contains', value: searchInputValue, }
        ],
      }
    ];

    if (breedSelectValue.breedShort !== '') {
      logic = 'and';
      filters.push({
        field: 'breed.breedShort',
        operator: 'contains',
        value: breedSelectValue.breedShort,
      });
    }

    if (colorSelectValue.colorID !== -1) {
      logic = 'and';
      filters.push({
        field: 'color.colorName',
        operator: 'contains',
        value: colorSelectValue.colorName,
      });
    }

    if (locationSelectValue !== DEFAULT_VALUE_DROPDOWN) {
      logic = 'and';
      filters.push({
        field: 'location',
        operator: 'contains',
        value: locationSelectValue.fullLocationName,
      });
    }

    if (classNameSelectValue.classID !== -1) {
      logic = 'and';
      filters.push({
        field: 'aClass.className',
        operator: 'contains',
        value: classNameSelectValue.className,
      });
    }

    const filter: any = {
      logic,
      filters,
    };

    const newData = searchInputValue !== ''
      || breedSelectValue.breedsId !== -1
      || colorSelectValue.colorID !== -1
      || classNameSelectValue.classID !== -1
      || locationSelectValue !== DEFAULT_VALUE_DROPDOWN
      ? filterBy(data, filter)
      : this.props.animals;

    this.setState({
      searchValue: searchInputValue,
      breedValue: breedSelectValue,
      colorValue: colorSelectValue,
      className: classNameSelectValue,
      groupValue: groupSelectValue,
      locationValue: locationSelectValue,
    });

    this.props.updateAnimalsToMap(this.props.mapAnimals ? this.props.mapAnimals(newData) : newData);
  }

  renderLocationDropDown = (): JSX.Element => {
    const props: DropdownFilterCellProps<any, string, DropDownListChangeEvent> = {
      value: this.state.locationValue.fullLocationName,
      onChange: this.handleChange.bind(this),
    };
    return <LocationDropDownList name="locations" {...props} locations={this.state.locations} />;
  };

  render() {
    return (
      <div>
        <Row>
          {!this.props.hideGroupSelect && (
            <Col lg="3">
              <div className="form-group">
                <label>{!this.props.intersectionOfAnimals ? 'Групи животни' : 'Стада'}</label>
                <DropDownList
                  data={this.state.animalGroups}
                  dataItemKey="managementGroupsID"
                  textField="managementGroupsName"
                  name="groups"
                  onChange={this.handleChange}
                  value={this.state.groupValue ? this.state.groupValue : ''}
                />
              </div>
            </Col>
          )}
          {!this.props.hideLocationSelect && (
            <Col lg="3">
              <div className="form-group">
                <label>Локации</label>

                {this.renderLocationDropDown()}
              </div>
            </Col>
          )}
        </Row>
        <Row>
          {!this.props.hideSearchInput && (
            <Col lg="3">
              <div className="form-group">
                <label>Търси по: Ветис</label>
                <Input type="text" name="search" onChange={this.handleChange} />
              </div>
            </Col>
          )}
          {!this.props.hideBreedSelect && (
            <Col lg="3">
              <div className="form-group">
                <label>Порода</label>
                <DropDownList
                  data={this.state.breeds}
                  dataItemKey="breedsId"
                  textField="breedName"
                  name="breed"
                  onChange={this.handleChange}
                  value={this.state.breedValue ? this.state.breedValue : ''}
                />
              </div>
            </Col>
          )}
          {!this.props.hideColorSelect && (
            <Col lg="3">
              <div className="form-group">
                <label>Цвят</label>
                <DropDownList
                  data={this.state.colors}
                  dataItemKey="colorID"
                  textField="colorName"
                  name="color"
                  onChange={this.handleChange}
                  value={this.state.colorValue ? this.state.colorValue : ''}
                />
              </div>
            </Col>
          )}
          {!this.props.hideClassNameSelect && (
            <Col lg="3">
              <div className="form-group">
                <label>Категория</label>
                <DropDownList
                  data={this.state.aClasses}
                  dataItemKey="classID"
                  textField="className"
                  name="className"
                  onChange={this.handleChange}
                  value={this.state.className ? this.state.className : ''}
                />
              </div>
            </Col>
          )}
        </Row>
      </div>
    );
  }
}

export default ListBoxFiltersComponent;
