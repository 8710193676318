/* eslint react/no-array-index-key:0 */
/* eslint react/prop-types:0 */
import * as React from 'react';
import DatesHelper from '../../../../../../tools/DatesHelper';
import { mapAnimalEvent } from '../../../animal-mapping-functions';

const WeaningTable = props => {
  const generateTableRows = () => {
    return props.weaningEvents.map((weaning, i) => {
      return (
        <tr key={i}>
          <td>{DatesHelper.parseAndFormatDate(weaning.weaningDate)}</td>
          <td>
            {mapAnimalEvent(weaning.animalEventTypeID)}
          </td>
          <td>
            {weaning.weaningWeight }
            кг.
          </td>
        </tr>
      );
    });
  };

  return (
    <table className="table">
      <thead>
        <tr>
          <th>Дата</th>
          <th>Събитие</th>
          <th>Кг. отбиване</th>
        </tr>
      </thead>
      <tbody>
        {generateTableRows()}
      </tbody>
    </table>
  );
};

export default WeaningTable;
