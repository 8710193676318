/* eslint react/button-has-type:0 */
/* eslint max-len:0 */
/* eslint react/no-array-index-key:0 */
/* eslint no-underscore-dangle:0 */
/* eslint react/no-access-state-in-setstate:0 */
/* eslint react/destructuring-assignment:0 */

import * as React from 'react';
import {
  Scheduler, DayView, WeekView, MonthView, AgendaView
} from '@progress/kendo-react-scheduler';
import {
  IntlProvider, LocalizationProvider
} from '@progress/kendo-react-intl';
import '@progress/kendo-date-math/tz/Europe/Sofia';

class SchedulerView extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      tasks: props.tasks,
    };
  }

  render() {
    const displayDate = new Date();
    return (
      <div>
        <LocalizationProvider language="bg-BG">
          <IntlProvider locale="bg">
            <Scheduler
              data={this.state.tasks}
          // eslint-disable-next-line react/jsx-boolean-value
              editable={true}
              timezone="Europe/Sofia"
              defaultDate={displayDate}
            >
              <AgendaView />
              <DayView />
              <WeekView />
              <MonthView />
            </Scheduler>
          </IntlProvider>
        </LocalizationProvider>
      </div>
    );
  }
}

export default SchedulerView;
