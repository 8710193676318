/* eslint max-len:0 */
/* eslint consistent-return:0 */
/* eslint react/destructuring-assignment:0 */
/* eslint react/no-did-update-set-state:0 */
import * as React from 'react';
import { NavLink, Redirect } from 'react-router-dom';
import Mutation from '../../../../../data/mutation';
import { GET_ALL_ANIMAL_GROUPS, ADD_GROUP } from '../../../../../data/groups/groups-query';

import { deepCopy } from '../../../../../tools/function-helpers';


class TabNavButtons extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      group: props.parentProps.getGroup ? props.parentProps.getGroup() : [],
      groupData: props.parentProps.getGroupData ? props.parentProps.getGroupData() : {},
      isDisabled: props.parentProps.getGroup ? !(props.parentProps.getGroup().length > 0) : true,
      isSubmited: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.renderRedirect = this.renderRedirect.bind(this);
  }


  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      this.setState({
        group: this.props.parentProps.getGroup ? this.props.parentProps.getGroup() : [],
        groupData: this.props.parentProps.getGroupData ? this.props.parentProps.getGroupData() : {},
        isDisabled: this.props.parentProps.getGroup ? !(this.props.parentProps.getGroup().length > 0) : true,
      });
    }
  }

  handleSubmit = (e, mutationTrigger) => {
    e.preventDefault();

    const data = deepCopy(this.state.groupData);
    data.animalsInGroup = this.state.group.map(animal => { return { animalID: animal.id, }; });
    delete data.groupTypeTranslation;
    mutationTrigger({
      variables:
      {
        dataItem: data,
      },
    });

    this.setState({
      isSubmited: true,
    });
  }

  renderRedirect = () => {
    if (this.state.isSubmited) {
      return <Redirect to={{ pathname: '/groups/groups-managment', state: { savedEntries: this.state.group.length, }, }} />;
    }
  }

  render() {
    const links = this.props.parentProps.tabsLinks;
    const index = links.findIndex((p) => p === this.props.parentProps.location.pathname);
    const next = index !== links.length - 1 ? links[index + 1] : links[links.length - 1];
    const prev = index !== 0 ? links[index - 1] : links[0];
    return (
      <Mutation
        mutation={ADD_GROUP}
        awaitRefetchQueries
        refetchQueries={[{ query: GET_ALL_ANIMAL_GROUPS, variables: {}, }]}
      >
        {(mutationTrigger) => (
          <div className="steps-action-btns d-flex justify-content-between mt-5">
            {this.renderRedirect()}
            <div>
              <NavLink className="btn btn-secondary mr-4" to="/groups/groups-managment">ОБРАТНО КЪМ ВСИЧКИ ГРУПИ</NavLink>
            </div>
            <div>
              {index > 0 && <NavLink exact className="btn btn-secondary mr-2" to={prev}>НАЗАД</NavLink>}
              {index < (links.length - 1) && <NavLink exact className="btn btn-primary" to={next}>НАПРЕД</NavLink>}
              {index === (links.length - 1) && <button disabled={this.state.isDisabled ? true : !this.props.isFormValid} onClick={e => this.handleSubmit(e, mutationTrigger)} type="submit" className="btn btn-primary">СЪЗДАЙ ГРУПА</button>}
            </div>
          </div>
        )}
      </Mutation>
    );
  }
}

export default TabNavButtons;
