/* eslint-disable import/prefer-default-export */
import { gql } from 'apollo-boost';

export const GROUP_INSERT_ANIMAL = gql`
  mutation GroupInsertAnimal($dataItem: [animalInput]) {
    groupInsertAnimal(animals:$dataItem){
      animalId
    }
}
`;
