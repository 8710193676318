import * as React from 'react';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Row, Col } from 'reactstrap';
import { Input } from '@progress/kendo-react-inputs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import CustomComboBox from '../tabs/data/custom-combo-box';
import Mutation from '../../../../data/mutation';
import { GET_SIRES_AND_DAMS } from '../../../../data/animal/animal-query';
import { GET_EXTERNAL_ANIMALS, ADD_EXTERNAL_ANIMAL, UPDATE_EXTERNAL_ANIMAL } from '../../../../data/external-animals/external-animals-queries';
import { TYPE_EXIST, SEXES, COUNTRIES } from '../../../../tools/translation-constants';
import { deepCopy, isActionTypeAndGetQuery } from '../../../../tools/function-helpers';
import { DatePicker } from '../../../utils/date-picker';

const keysToRemove = ['aClass', 'breed', 'color', 'dam', 'sire', 'sexTranslation',
  'typeExistTranslation', '__typename', 'dateOfBirthFormated', 'countryTranslation'];

const removeObjKeys = (obj, arrayKeys) => {
  const object = obj;
  arrayKeys.forEach(element => {
    delete object[element];
  });
};

const animalDefaultProps = {
  active: true,
  discriminator: 'ReffAnimal',
  animalFunctionality: 'unknown',
  animalMarbling: 'unknown',
  animalMusculature: 'unknown',
  animalSkeleton: 'unknown',
  animalTemperament: 'unknown',
  breedingStatus: 'unknown',
  dNAType: 'unknown',
  dryWetStatus: 'unknown',
  fromEmbryoTransfer: false,
  registerSection: 'unknown',
  underSelection: false,
  forScrap: 'unknown',
  eIDType: 'UNKNOWN',
  hornStatusID: 'UNKNOWN',
  typeInput: 'UNKNOWN',
  weaningStatus: 'UNKNOWN',
  breedingFor: 'UNKNOWN',
};


const selectedValue = (val, array, prop) => {
  const selected = array.find((element) => {
    return element[prop] === val;
  });
  return selected;
};

class ExternalAnimalsModal extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      dataItem: {
        ...props.dataItem,
        ...animalDefaultProps,
      },
      submitDisabled: true,
    };
    this.toggleDialog = this.toggleDialog.bind(this);
    this.handleFormChange = this.handleFormChange.bind(this);
  }

  toggleDialog() {
    const { visible, } = this.state;

    this.setState({
      visible: !visible,
    });

    if (!visible) {
      this.resetState();
    }
  }


  handleFormChange(e) {
    const { dataItem, submitDisabled, } = this.state;
    const objCopy = deepCopy(dataItem);
    let isSubmitDisabled = submitDisabled;

    if (e.target.name === 'typeExistID') {
      objCopy[e.target.name] = e.target.value.id;
    } else if (e.target.name === 'sex') {
      objCopy[e.target.name] = e.target.value.id;
    } else if (e.target.name === 'countryFrom') {
      objCopy[e.target.name] = e.target.value.id;
    } else if (e.target.name === 'breedsID') {
      objCopy[e.target.name] = e.target.value.breedsId;
    } else if (e.target.name === 'colorID') {
      objCopy[e.target.name] = e.target.value.colorID;
    } else if (e.target.name === 'damID' || e.target.name === 'sireID') {
      objCopy[e.target.name] = e.target.value.animalId;
      if (e.target.name === 'damID') {
        objCopy.dam = { lID: e.target.value.lID, };
      } else {
        objCopy.sire = { lID: e.target.value.lID, };
      }
    } else if (e.target.name === 'classID') {
      objCopy[e.target.name] = e.target.value.classID;
    } else {
      objCopy[e.target.name] = e.target.value;
    }

    if (!objCopy.typeExistID || !objCopy.sex || !objCopy.breedsID || !objCopy.colorID || !objCopy.classID || !objCopy.lID) {
      isSubmitDisabled = true;
    } else {
      isSubmitDisabled = false;
    }

    this.setState({
      dataItem: objCopy,
      submitDisabled: isSubmitDisabled,
    });
  }

  handleSubmit(e, mutationTrigger) {
    const { dataItem, } = this.state;

    e.preventDefault();
    this.toggleDialog();
    const objCopy = { ...dataItem, };
    removeObjKeys(objCopy, keysToRemove);

    if (!objCopy.dateOfBirth) {
      objCopy.dateOfBirth = new Date();
    }

    mutationTrigger({
      variables:
            {
              dataItem: objCopy,
            },
    });
  }

  resetState() {
    const { dataItem, } = this.state;
    const copyData = {
      ...dataItem,
      ...animalDefaultProps,
    };
    this.setState({
      dataItem: copyData,
      submitDisabled: true,
    });
  }

  render() {
    const { dataItem, visible, submitDisabled, } = this.state;
    const {
      action, breeds, colors, aClasses, dams, sires,
    } = this.props;

    const isAddActionType = isActionTypeAndGetQuery(action, 'add', ADD_EXTERNAL_ANIMAL, UPDATE_EXTERNAL_ANIMAL);
    return (
      <Mutation
        mutation={isAddActionType.query}
        awaitRefetchQueries
        refetchQueries={[{ query: GET_EXTERNAL_ANIMALS, variables: {}, }, { query: GET_SIRES_AND_DAMS, variables: {}, }]}
      >
        {(mutationTrigger) => (
          <div className="d-inline-block">
            <button type="button" className={isAddActionType.isActionType ? 'btn btn-primary mr-2' : 'button-icon'} onClick={this.toggleDialog}>
              {isAddActionType.isActionType ? 'Добави външно животно' : <FontAwesomeIcon icon="pen" />}
            </button>
            {visible && (
            <form onSubmit={e => this.handleSubmit(e, mutationTrigger)}>
              <Dialog
                title={`${isAddActionType.isActionType ? 'Добави' : 'Редактирай'} външно животно`}
                onClose={this.toggleDialog}
                minWidth="500px"
                height="70%"
              >
                <Row>
                  <Col lg={{ size: 8, offset: 2, }}>
                    <div className="form-group">
                      <label>Присъства от</label>
                      <DropDownList
                        data={TYPE_EXIST}
                        dataItemKey="id"
                        textField="text"
                        name="typeExistID"
                        value={dataItem.typeExistID ? selectedValue(dataItem.typeExistID, TYPE_EXIST, 'id') : ''}
                        onChange={this.handleFormChange}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label>Пол</label>
                      <DropDownList
                        style={{ width: '100%', }}
                        data={SEXES}
                        dataItemKey="id"
                        textField="text"
                        name="sex"
                        onChange={this.handleFormChange}
                        value={dataItem.sex ? selectedValue(dataItem.sex, SEXES, 'id') : ''}
                      />
                    </div>
                    <div className="form-group">
                      <label>Име</label>
                      <Input type="text" name="name" value={dataItem.name ? dataItem.name : ''} onChange={this.handleFormChange} />
                    </div>
                    <div className="form-group">
                      <label>Ветис №</label>
                      <Input type="text" name="lID" value={dataItem.lID ? dataItem.lID : ''} onChange={this.handleFormChange} required />
                    </div>
                    <div className="form-group">
                      <label>ВИН</label>
                      <Input type="text" name="vID" value={dataItem.vID ? dataItem.vID : ''} onChange={this.handleFormChange} />
                    </div>
                    <div className="form-group">
                      <label>Порода</label>
                      <DropDownList
                        style={{ width: '100%', }}
                        data={breeds}
                        dataItemKey="breedsId"
                        textField="breedName"
                        name="breedsID"
                        onChange={this.handleFormChange}
                        value={dataItem.breedsID ? selectedValue(dataItem.breedsID, breeds, 'breedsId') : ''}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label>Цвят</label>
                      <DropDownList
                        style={{ width: '100%', }}
                        data={colors}
                        dataItemKey="colorID"
                        textField="colorName"
                        name="colorID"
                        onChange={this.handleFormChange}
                        value={dataItem.colorID ? selectedValue(dataItem.colorID, colors, 'colorID') : ''}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label>Категория</label>
                      <DropDownList
                        style={{ width: '100%', }}
                        data={aClasses}
                        dataItemKey="classID"
                        textField="className"
                        name="classID"
                        onChange={this.handleFormChange}
                        value={dataItem.classID ? selectedValue(dataItem.classID, aClasses, 'classID') : ''}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label>Дата на раждане</label>
                      <DatePicker
                        name="dateOfBirth"
                        onChange={this.handleFormChange}
                        value={dataItem.dateOfBirth}
                      />
                    </div>
                    <div className="form-group">
                      <label>Произход държава</label>
                      <DropDownList
                        style={{ width: '100%', }}
                        data={COUNTRIES}
                        dataItemKey="id"
                        textField="text"
                        name="countryFrom"
                        onChange={this.handleFormChange}
                        value={dataItem.countryFrom ? selectedValue(dataItem.countryFrom, COUNTRIES, 'id') : ''}
                      />
                    </div>
                    <div className="form-group">
                      <label>Майка</label>
                      <CustomComboBox
                        name="damID"
                        onChange={this.handleFormChange}
                        value={dataItem.damID ? { animalId: dataItem.damID, lID: dataItem.dam.lID, } : ''}
                        data={dams}
                        textField="lID"
                        dataItemKey="animalId"
                      />
                    </div>
                    <div className="form-group">
                      <label>Баща</label>
                      <CustomComboBox
                        name="sireID"
                        onChange={this.handleFormChange}
                        value={dataItem.sireID ? { animalId: dataItem.sireID, lID: dataItem.sire.lID, } : ''}
                        data={sires}
                        textField="lID"
                        dataItemKey="animalId"
                      />
                    </div>
                    <div className="form-group">
                      <label>Бележка</label>
                      <textarea
                        className="k-textarea"
                        name="comment"
                        value={dataItem.comment ? dataItem.comment : ''}
                        onChange={this.handleFormChange}
                      />
                    </div>
                  </Col>
                </Row>
                <DialogActionsBar>
                  <div className="d-flex">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={this.toggleDialog}
                    >
                      Отказ
                    </button>
                    <button
                      type="submit"
                      disabled={submitDisabled}
                      className="btn btn-primary ml-3"
                    >
                      {isAddActionType.isActionType ? 'Добави' : 'Редактирай'}
                    </button>
                  </div>
                </DialogActionsBar>
              </Dialog>
            </form>
            )}
          </div>
        )}
      </Mutation>
    );
  }
}

export default ExternalAnimalsModal;
