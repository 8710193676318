import * as React from 'react';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import Query from '../../../../data/query';
import GET_VERSION_LOGS from '../../../../data/version-log/version-log-query';


const RodeoTableLogs = props => {
  return (
    <div>
      <h5 className="text-center mb-4">Последни събития</h5>
      <Query {...props} query={GET_VERSION_LOGS}>
        {({ versionLogs, }) => (
          <Grid
            data={versionLogs.items}
            total={versionLogs.totalCount}
            className="table"
            style={{ height: '400px', }}
          >
            <Column field="versionLogComment" title="Коментар" />
            <Column field="versionLogNumber" title="Номер" width="100px" />
            <Column field="versionLogDate" title="Дата" width="100px" />
          </Grid>
        )}
      </Query>
    </div>

  );
};

export default RodeoTableLogs;
