/* eslint react/no-did-update-set-state:0 */
/* eslint react/destructuring-assignment:0 */
import * as React from 'react';
import { NavLink } from 'react-router-dom';
import DatesHelper from '../../../../tools/DatesHelper';


class DppGroups extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      groupsDPP: props.groupsDPP,
    };
    this.generateTableRows = this.generateTableRows.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.groupsDPP !== prevProps.groupsDPP) {
      this.setState({
        groupsDPP: this.props.groupsDPP,
      });
    }
  }

      generateTableRows = () => {
        return this.state.groupsDPP.map((groupsDPP, i) => {
          return (
            // eslint-disable-next-line react/no-array-index-key
            <tr key={i}>
              <td>
                {groupsDPP.groupZPPName}
              </td>
              <td>
                {DatesHelper.dateToAppFormat(groupsDPP.groupZPPDate)}
              </td>
              <td>
                <div className="d-flex justify-content-center">
                  <td><NavLink className="link-color-primary" to={`/groups/dpp/${groupsDPP.groupZPPID}`}>Отчитане на резултати</NavLink></td>
                </div>
              </td>
            </tr>
          );
        });
      }

      render() {
        return (
          <div>
            <table className="table">
              <thead>
                <tr>
                  <th>Име</th>
                  <th>Дата</th>
                  <th>Отчитане на резултати</th>
                </tr>
              </thead>
              <tbody>
                {this.generateTableRows()}
              </tbody>
            </table>
          </div>
        );
      }
}

export default DppGroups;
