import { gql } from 'apollo-boost';


export const GET_FIRMS_IN_FARMS = gql`
{
 firmsInFarms
 {
   items
   {
     firmID
     firmName
     firmEIK
     firmCountry
     firmCity
     firmAddress
     firmMOL
     childrenObject
     {
       animalObjectID
       animalObjectName
       animalObjectNumber
       animalObjectAddress
       animalObjectShortName
      animalObjectGeoLocation
      parentFirm{
        firmID
        firmName
      }
     }
   }
 }
}
`;

export const GET_FIRMS_IN_FARMS_NAMES_AND_ID = gql`
{
 firmsInFarms
 {
   items
   {
     firmID
     firmName
   }
 }
}
`;


export const ADD_COMPANY = gql`
  mutation CreateCompany($dataItem: firmInFarm) {
    createFirm(firm: $dataItem){
      firmID
    }
  }
`;

export const EDIT_COMPANY = gql`
  mutation EditCompany($dataItem: firmInFarm) {
    editFirm(firm: $dataItem){
      firmID
    }
  }
`;

export const ADD_ANIMAL_OBJECT = gql`
 mutation CreateAnimalObject($dataItem: animalObject){
  createAnimalObject(animalObject:$dataItem){
   firmID
   animalObjectNumber
  }
 }
`;

export const EDIT_ANIMAL_OBJECT = gql`
mutation EditAnimalObject($dataItem: animalObject){
 editAnimalObject(animalObject:$dataItem){
  firmID
  animalObjectNumber
 }
}
`;
