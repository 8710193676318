/* eslint no-prototype-builtins:0 */
/* eslint max-len:0 */
/* eslint react/no-array-index-key:0 */
/* eslint no-param-reassign:0 */
/* eslint react/prop-types:0 */
import * as React from 'react';
import { mapAnimalTuberBrucelType } from '../../../animal-mapping-functions';
import DatesHelper from '../../../../../../tools/DatesHelper';


const TuberBrucelTable = props => {
  const bruceInfo = (tuberOrBro) => {
    return (
      <div>
        <div>
          {' '}
          Дата кръвна проба |
          {tuberOrBro.animalBrucellosisDate ? DatesHelper.parseAndFormatDate(tuberOrBro.animalBrucellosisDate) : '-'}
        </div>
        <div>
          {' '}
          Взета проба |
          {tuberOrBro.animalBrucellosisNumber ? tuberOrBro.animalBrucellosisNumber : '-'}
        </div>
        <div>
          {' '}
          Резултат |
          {tuberOrBro.brucellosisCheckTypeID ? mapAnimalTuberBrucelType(tuberOrBro.brucellosisCheckTypeID) : '-'}
        </div>
      </div>
    );
  };
  const tuberInfo = (tuberOrBro) => {
    return (
      <div>
        <div>
          {' '}
          Поставени |
          {tuberOrBro.tuberculinDose ? `${tuberOrBro.tuberculinDose} мл.` : '-' }
        </div>
        <div>
          {' '}
          Туберкулин |
          {tuberOrBro.tuberculin ? `${tuberOrBro.tuberculin.tuberculinPackage} мл.` : '-' }
        </div>
        <div>
          {' '}
          Размер(мм.) |
          {tuberOrBro.applySize ? tuberOrBro.applySize : '-'}
        </div>
        <div>
          {' '}
          Дата(очитане) |
          {tuberOrBro.dateCheck ? DatesHelper.parseAndFormatDate(tuberOrBro.dateCheck) : '-'}
        </div>
        <div>
          {' '}
          Размер отчитане(мм.) |
          {tuberOrBro.checkSize ? tuberOrBro.checkSize : '-'}
        </div>
        <div>
          {' '}
          Резултат |
          {tuberOrBro.tuberculosisCheckTypeID ? mapAnimalTuberBrucelType(tuberOrBro.tuberculosisCheckTypeID) : '-'}
        </div>
        <div>
          {' '}
          Партида |
          {tuberOrBro.tuberculin ? tuberOrBro.tuberculin.tuberculinDate : '-'}
        </div>
      </div>
    );
  };

  const generateTableRows = () => {
    console.log(props.animal);
    const mappedBruce = props.animal.animalBrucellosis.map((el) => { el.date = el.animalBrucellosisDate; return el; });
    const mappedTuber = props.animal.animalTuberculosis.map((el) => { el.date = el.dateApply; return el; });
    const data = [...mappedBruce, ...mappedTuber].sort((a, b) => {
      return Date.parse(b.date) - Date.parse(a.date);
    });

    return data.map((tuberOrBro, i) => {
      return (
        <tr key={i}>
          <td>{tuberOrBro.hasOwnProperty('animalBrucellosisDate') ? DatesHelper.parseAndFormatDate(tuberOrBro.animalBrucellosisDate) : DatesHelper.parseAndFormatDate(tuberOrBro.dateApply)}</td>
          <td>{tuberOrBro.hasOwnProperty('animalBrucellosisDate') ? 'Бруцелоза' : 'Туберкулоза'}</td>
          <td>
            {tuberOrBro.hasOwnProperty('animalBrucellosisDate') ? bruceInfo(tuberOrBro) : tuberInfo(tuberOrBro)}
          </td>
        </tr>
      );
    });
  };

  return (
    <table className="table">
      <thead>
        <tr>
          <th>Дата</th>
          <th>Тип</th>
          <th>Информация</th>
        </tr>
      </thead>
      <tbody>
        {generateTableRows()}
      </tbody>
    </table>
  );
};

export default TuberBrucelTable;
