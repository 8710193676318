/* eslint-disable max-len */
/* eslint import/prefer-default-export:0 */
/* eslint react/prop-types:0 */
/* eslint react/destructuring-assignment:0 */
/* eslint no-unused-vars:0 */
import * as React from 'react';
import { DatePicker as KendoDatePicker } from '@progress/kendo-react-dateinputs';
import { LocalizationProvider, IntlProvider } from '@progress/kendo-react-intl';

export const DATE_FORMAT = 'dd.MM.yyyy';

const DEFAULT_MIN_DATE = new Date(1970, 1, 1);
const DEFAULT_MAX_DATE = new Date(2099, 1, 1);

const RodeoDatePicker = props => {
  return (
    <LocalizationProvider language="bg-BG">
      <IntlProvider locale="bg">
        <KendoDatePicker
          min={DEFAULT_MIN_DATE}
          max={DEFAULT_MAX_DATE}
          {...props}
          value={props.value ? new Date(props.value) : null}
          format={DATE_FORMAT}
          defaultValue={props.defaultValue || null}
          validationMessage={`Моля, изберете дата от ${props.min?.getFullYear() || DEFAULT_MIN_DATE.getFullYear()} до ${props.max?.getFullYear() || DEFAULT_MAX_DATE.getFullYear()}`}
        />
      </IntlProvider>
    </LocalizationProvider>
  );
};

export const DatePicker = RodeoDatePicker;
