import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import RevaccinationReport from './revaccination/revaccination-report';
import RodeoVetisReport from './rodeo-vetis/rodeo-vetis-report';
import WeightsReport from './weights/weights-report';
import PregnancyReport from './pregnancy/pregnancy-report';
import LocationsReport from './locations/locations-report';
import DroppedReport from './dropped-animals/dropped-report';
import BCSReport from './bcs/bcs-report';
import DeadBornReport from './dead-born/dead-born-report';
import Query from '../../../data/query';
import { GET_ALL_WEIGHT_SESSIONS } from '../../../data/reports/reports-query';
import WeightSessions from './weights-sessions/all-sessions';
import WeightSession from './weights-sessions/weight-session';
import AssistReport from './assistance/assistance-report';
import RetagReport from './retag-report/retag-report';


const Reports = props => (
  <Switch>
    <Route path="/reports/dead-born" render={() => <DeadBornReport {...props} />} />
    <Route path="/reports/bcs" render={() => <BCSReport {...props} />} />
    <Route path="/reports/dropped-animals" render={() => <DroppedReport {...props} />} />
    <Route path="/reports/locations" render={() => <LocationsReport {...props} />} />
    <Route path="/reports/pregnancy" render={() => <PregnancyReport />} />
    <Route path="/reports/weights" render={() => <WeightsReport />} />
    <Route path="/reports/revaccination" render={() => <RevaccinationReport />} />
    <Route path="/reports/rodeo-vetis" render={() => <RodeoVetisReport {...props} />} />
    <Route path="/reports/assist" render={() => <AssistReport {...props} />} />
    <Route path="/reports/retag-report" render={() => <RetagReport {...props} />} />
    <Route
      exact
      path="/reports/sessions"
      render={() => {
        return (
          <Query query={GET_ALL_WEIGHT_SESSIONS}>
            {({ weightSessions, }) => <WeightSessions weightSessions={weightSessions.items} />}
          </Query>
        );
      }}
    />
    <Route path="/reports/sessions/:id" render={(sessionid) => <WeightSession {...sessionid} />} />
  </Switch>

);

export default Reports;
