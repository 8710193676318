/* eslint react/no-array-index-key:0 */
/* eslint react/prop-types:0 */
import * as React from 'react';
import DatesHelper from '../../../../../../tools/DatesHelper';
import { mapPregnancyType } from '../../../animal-mapping-functions';

const PregnancyTable = props => {
  const generateTableRows = () => {
    return props.pregnancyEvents.map((pregnancy, i) => {
      return (
        <tr key={i}>
          <td>{DatesHelper.parseAndFormatDate(pregnancy.pregnancyDate)}</td>
          <td>
            {pregnancy.pregnancyCheck !== 'ABORT'
              ? `Бременност: ${mapPregnancyType(pregnancy.pregnancyCheck)}`
              : mapPregnancyType(pregnancy.pregnancyCheck)}
          </td>
          <td>{pregnancy.pregnancyDays}</td>
          <td>{pregnancy.comment}</td>
        </tr>
      );
    });
  };

  return (

    <table className="table">
      <thead>
        <tr>
          <th>Дата</th>
          <th>Събитие</th>
          <th>Стадиий в дни</th>
          <th>Коментар</th>
        </tr>
      </thead>
      <tbody>
        {generateTableRows()}
      </tbody>
    </table>
  );
};

export default PregnancyTable;
