/* eslint react/prop-types:0 */
/* eslint react/destructuring-assignment:0 */
import * as React from 'react';
import { groupBy } from 'lodash';
import DashboardCard from '../../../lib/dashboard-card/dashboard-card';

const AllAnimalsAggregate = props => {
  const groupedAnimals = groupBy(props.animals, a => a.aClass.classID);
  return (
    <DashboardCard className="mb-3">
      <div className="card">
        <div className="card-body">
          <h5 className="mb-3">Животни</h5>
          <p>
            Общо
            {props.animals.length}
          </p>
        </div>
      </div>
      <div className="card">
        <div className="card-body">
          <h5 className="mb-3 text-center">Бикове разплод</h5>
          <h2 className="text-center">{(groupedAnimals['7'] || []).length}</h2>
          {' '}
          {/* Bulls */}
        </div>
      </div>
      <div className="card">
        <div className="card-body">
          <h5 className="mb-3 text-center">Крави</h5>
          <h2 className="text-center">{(groupedAnimals['5'] || []).length}</h2>
          {' '}
          {/* Cows */}
        </div>
      </div>
      <div className="card">
        <div className="card-body">
          <h5 className="mb-3 text-center">Юници</h5>
          <h2 className="text-center">{(groupedAnimals['4'] || []).length}</h2>
          {' '}
          {/* Heifer */}
        </div>
      </div>
      <div className="card">
        <div className="card-body">
          <h5 className="mb-3 text-center">Телета</h5>
          <h2 className="text-center">
            {(groupedAnimals['1'] || []).length}
            {' '}
            /
            {' '}
            {(groupedAnimals['2'] || []).length}
          </h2>
          {' '}
          {/* Calf M/F */}
          <h5 className="text-center">М/Ж</h5>
        </div>
      </div>
      <div className="card">
        <div className="card-body">
          <h5 className="mb-3 text-center">Кастрат</h5>
          <h2 className="text-center">{(groupedAnimals['3'] || []).length}</h2>
          {' '}
          {/* Bullock */}
        </div>
      </div>
    </DashboardCard>
  );
};

export default AllAnimalsAggregate;
