/* eslint react/no-array-index-key:0 */
/* eslint react/prop-types:0 */
import * as React from 'react';
import DatesHelper from '../../../../../../tools/DatesHelper';
import { mapYesNo } from '../../../animal-mapping-functions';

const HeatTable = props => {
  const generateTableRows = () => {
    return props.heatEvents.map((heat, i) => {
      return (
        <tr key={i}>
          <td>{DatesHelper.parseAndFormatDate(heat.heatDate)}</td>
          <td>
            Отчетен еструс -
            {mapYesNo(heat.heatCheck)}
          </td>
          <td>{heat.comment}</td>
        </tr>
      );
    });
  };

  return (
    <table className="table">
      <thead>
        <tr>
          <th>Дата</th>
          <th>Събитие</th>
          <th>Коментар</th>
        </tr>
      </thead>
      <tbody>
        {generateTableRows()}
      </tbody>
    </table>
  );
};

export default HeatTable;
