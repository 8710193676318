import * as React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import EventsGroupWeighting from './group-weighting/events-group-weighting';
import AddVaccinationAnimals from './vaccination/add-vaccinations-animals';
import AddTubercolosisAnimals from './tubelcilintion/add-tubelculions-animals';
import AddInseminationAnimals from './artificial-insemination/add-artificial-insemination';
import Query from '../../../data/query';
import { GET_ANIMALS_GROUP_WEIGHT_DATA } from '../../../data/animal/animal-query';


const Events = props => (
  <main className="mb-5">
    <Switch>
      <Route path="/events" exact>
        <Redirect to="/events/group-weighting" />
      </Route>
      <Route path="/events/vaccination" render={() => <AddVaccinationAnimals {...props} />} />
      <Route path="/events/tuberculinum" render={() => <AddTubercolosisAnimals {...props} />} />
      <Route path="/events/insemination" render={() => <AddInseminationAnimals {...props} />} />
      <Query query={GET_ANIMALS_GROUP_WEIGHT_DATA}>
        {({ animals, }) => (
          <Route path="/events/group-weighting" exact render={() => <EventsGroupWeighting {...props} animals={animals.items} />} />
        )}
      </Query>


    </Switch>
  </main>
);

export default Events;
