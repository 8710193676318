import { gql } from 'apollo-boost';

export const CREATE_DPP_GROUP = gql`
mutation addGroupDPP($dataItem : dPPInput){
    addGroupDPP(dPPInput : $dataItem){
    groupZPPID
    groupZPPDate
    groupZPPName
    farmClientID 
  }
}
`;

export const SINGLE_DPP_GROUP = gql`
query AnimalTuberBrucel($id: ID!){
  groupDPP(id: $id)
  {
    groupZPPName
    groupZPPID
    animalTuberculosis{
    animalTuberculosisID
    groupZPPID
    animalID
    animal
    {
      animalId
      lID
    }
      dateApply
      dateCheck
      applySize
      checkSize
      tuberculinDose
      tuberculosisCheckTypeID
      tuberculin{
        tuberculinDate
        tuberculinPackage
      }
    }
     animalBrucellosis{
      animalBrucellosisDate
      animalBrucellosisNumber
      brucellosisCheckTypeID
      animalID
    animal
    {
      animalId
      lID
    }
    }
  }   
}
 `;

export const EDIT_ANIMAL_TUBERCULOSIS = gql`
  mutation editAnimalTuberculosis($dataItem: [tuberculosisInput])
  {
    editAnimalTuberculosis(tuberculosisInput: $dataItem){
     animalID
     animalTuberculosisID
     tuberculosisCheckTypeID
    }
  }
`;
