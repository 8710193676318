/* eslint react/destructuring-assignment:0 */
/* eslint no-shadow:0 */
/* eslint no-unused-vars:0 */
import * as React from 'react';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { DropDownListChangeEvent } from '@progress/kendo-react-dropdowns/dist/npm/DropDownList/DropDownListProps';

export const DEFAULT_VALUE_DROPDOWN = 'Всички';
export type DropdownFilterCellProps<T, TValue, TEvent> = T & {
    value: TValue,
    itemRender?: (li, itemProps) => JSX.Element,
    getValue?: (event: TEvent, defaultValue: string) => string,
    name?: string
}

export const dropdownFilterCell = <T, >(props: DropdownFilterCellProps<T, string, DropDownListChangeEvent>, data, isColor?: boolean) => {
  const defaultGetValue = event => (event.target.value !== DEFAULT_VALUE_DROPDOWN ? event.target.value : '');
  const onChangeDropdown = (event, props, defaultValue) => {
    let filterType = '';
    if (isColor) {
      filterType = 'eq';
    } else {
      filterType = 'contains';
    }
    props.onChange({
      ...event,
      operator: event.target.value !== defaultValue ? filterType : '',
      value: props.getValue ? props.getValue(event, defaultValue) : defaultGetValue(event),
      syntheticEvent: event.syntheticEvent,
    });
  };

  return (
    <div className="table-filter-dropdown">
      <DropDownList
        data={data}
        defaultItem={DEFAULT_VALUE_DROPDOWN}
        itemRender={(li, itemProps) => {
          if (props.itemRender) {
            return props.itemRender(li, itemProps);
          }
          return React.cloneElement(li, li.props);
        }}
        name={props.name}
        onChange={event => onChangeDropdown(event, props, DEFAULT_VALUE_DROPDOWN)}
        value={props.value || DEFAULT_VALUE_DROPDOWN}
      />
    </div>
  );
};
