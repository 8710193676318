/* eslint no-plusplus:0 */
/* eslint consistent-return:0 */
/* eslint no-shadow:0 */
/* eslint react/prop-types:0 */
/* eslint react/destructuring-assignment:0 */
import * as React from 'react';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import Query from '../../../../../data/query';
import { GET_FARMCIENTS } from '../../../../../data/animal/animal-query';

const FarmClientDropdown = props => {
  const HEALTH_TABLES_FARM_CLIENT_TYPES = {
    bCS: ['vet'],
    vaccine: ['vet'],
    entries: ['FARM', 'ANIMAL_PROVIDER', 'ANIMAL_DEALER'],
    dropAnimals: ['FARM', 'ANIMAL_PROVIDER', 'ANIMAL_DEALER', 'BUTCHER', 'OTHER'],
    transClients: ['ANIMAL_TRANS', 'ANIMAL_EMPLOYEE'],
    dropAnimalsVet: ['vet', 'ANIMAL_EMPLOYEE'],
  };

  const FARM_CLIENT_TYPE = HEALTH_TABLES_FARM_CLIENT_TYPES[props.tableType];

  const compareFarmTypes = (curFarmClient, curType, FARM_CLIENT_TYPE) => {
    for (let i = 0; i < FARM_CLIENT_TYPE.length; i++) {
      if (curType === FARM_CLIENT_TYPE[i].toLocaleLowerCase()) {
        return curFarmClient;
      }
    }
  };

  const filterFarmClients = (farmClients) => {
    const result = farmClients.items.filter((el) => {
      const curFarmClient = el;
      return el.farmClientsInType.find((type) => {
        const curType = type.toLowerCase();
        return compareFarmTypes(curFarmClient, curType, FARM_CLIENT_TYPE);
      });
    }).map((el) => {
      return {
        farmClientID: el.farmClientID,
        farmClientName: el.farmClientName,
      };
    });
    return result;
  };
  return (
    <Query query={GET_FARMCIENTS}>
      {({ farmClients, }) => {
        const farmClList = filterFarmClients(farmClients);
        let selected;
        if (props.farmClientID) {
          selected = farmClList.find((el) => {
            return el.farmClientID === props.farmClientID;
          });
        }
        return (
          <DropDownList
            style={{ width: '100%', }}
            data={farmClList}
            dataItemKey="farmClientID"
            textField="farmClientName"
            name={props.name ? props.name : 'farmClientID'}
            value={selected}
            onChange={props.handleFormChange}
          />
        );
      }}
    </Query>
  );
};

export default FarmClientDropdown;
