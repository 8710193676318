/* eslint max-len:0 */
import * as React from 'react';
import moment from 'moment';
import RevaccinationTable from './revaccination-table';
import Query from '../../../../data/query';
import { GET_REVACCINATION_DATA } from '../../../../data/reports/reports-query';
import { animalSexTranslation, reportAnimalSummary } from '../../../../tools/function-helpers';
import DatesHelper, { appDateFormat } from '../../../../tools/DatesHelper';

const RevaccinationReport = props => (
  <Query query={GET_REVACCINATION_DATA}>
    {({ animals, }) => {
      const animalsNew = [];
      animals.items.forEach((animal) => {
        const sexTranslation = animalSexTranslation(animal.sex);
        animal.vaccine.forEach((vaccine) => {
          animalsNew.push({
            ...vaccine,
            animalVaccineDate: vaccine.animalVaccineDate ? moment(vaccine.animalVaccineDate).format(appDateFormat) : null,
            dateNextVaccination: vaccine.animalVaccineEndMonths ? moment(DatesHelper.addDaysToDateAPIformat(vaccine.animalVaccineDate, vaccine.animalVaccineEndMonths)).format(appDateFormat) : '',
            animalSummary: reportAnimalSummary(animal, sexTranslation),
          });
        });
      });
      return (
        <RevaccinationTable {...props} animals={animalsNew} />
      );
    }}
  </Query>
);

export default RevaccinationReport;
