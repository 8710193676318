/* eslint react/button-has-type:0 */
/* eslint jsx-a11y/control-has-associated-label:0 */
/* eslint react/no-access-state-in-setstate:0 */
/* eslint no-plusplus:0 */
/* eslint jsx-a11y/click-events-have-key-events:0 */
/* eslint consistent-return:0 */
/* eslint react/no-array-index-key:0 */
/* eslint jsx-a11y/no-static-element-interactions:0 */
/* eslint max-len:0 */
/* eslint react/sort-comp:0 */
/* eslint react/no-did-update-set-state:0 */
/* eslint react/destructuring-assignment:0 */
import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class ListBoxComponent extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      items: props.items,
      itemsNoGroupIndex: [],
      group: [],
      printGroup: props.getGroup ? props.getGroup() : [],
      isPressedCtr: false,
      isPressedShift: false,
      startIndex: 0,
      endIndex: 0,
      indexRemoveFromGroup: -1,
    };
    this.printList = this.printList.bind(this);
    this.onSelectItem = this.onSelectItem.bind(this);
    this.isSelected = this.isSelected.bind(this);
    this.addToPrintGroup = this.addToPrintGroup.bind(this);
    this.onKeyPressed = this.onKeyPressed.bind(this);
    this.onKeyUnPressed = this.onKeyUnPressed.bind(this);
    this.removeFromPrintGroup = this.removeFromPrintGroup.bind(this);
    this.addRemoveAllFromGroup = this.addRemoveAllFromGroup.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.items !== prevProps.items) {
      this.setState({
        items: this.props.items,
      });
    }
  }

  isSelected(item) {
    return this.state.group.find((el) => {
      return el.id === item.id;
    });
  }

  printList() {
    return this.state.items.map((item, index) => {
      return (
        <div
          className={`item ${this.isSelected(item) ? 'selected' : ''}`}
          tabIndex={index}
          key={index}
          onClick={(event) => this.onSelectItem(event, index)}
          onKeyUp={(event) => this.onKeyUnPressed(event)}
          onKeyDown={(event) => this.onKeyPressed(event)}
        >
          {item.text}
        </div>
      );
    });
  }

  printGroup() {
    if (this.state.printGroup.length > 0) {
      return this.state.printGroup.map((item, index) => {
        return (
          <div
            key={index}
            className={`item ${this.state.indexRemoveFromGroup === index ? 'selected' : ''}`}
            onClick={(event) => this.onSelectInGroup(event, index)}
          >
            {item.text}
          </div>
        );
      });
    }
  }

  onSelectItem(event, index) {
    let copyGroup = [];
    let copyItemsNoGroupIndex = [];
    let { startIndex, } = this.state;
    let { endIndex, } = this.state;

    if (this.state.isPressedCtr) {
      copyGroup = [...this.state.group];
      copyItemsNoGroupIndex = [...this.state.itemsNoGroupIndex];
    }

    if (this.state.isPressedShift) {
      endIndex = index;
    } else {
      startIndex = index;
    }

    if (this.state.isPressedShift) {
      const start = startIndex < endIndex ? startIndex : endIndex;
      const len = startIndex > endIndex ? startIndex : endIndex;

      for (let i = start; i <= len; i++) {
        copyGroup.push(this.state.items[i]);
        copyItemsNoGroupIndex.push(i);
      }
    } else {
      const isInGroup = copyItemsNoGroupIndex.findIndex((el) => el === index);
      if (isInGroup !== -1 && this.state.isPressedCtr) {
        copyGroup.splice(isInGroup, 1);
        copyItemsNoGroupIndex.splice(isInGroup, 1);
      } else {
        copyGroup.push(this.state.items[index]);
        copyItemsNoGroupIndex.push(index);
      }
    }

    this.setState({
      group: copyGroup,
      itemsNoGroupIndex: copyItemsNoGroupIndex,
      startIndex,
      endIndex,
    });
  }

  onKeyPressed(event) {
    if (event.keyCode === 91 || event.keyCode === 17) {
      this.setState({
        isPressedCtr: true,
      });
    }

    if (event.keyCode === 16) {
      this.setState({
        isPressedShift: true,
      });
    }
  }

  onKeyUnPressed(event) {
    if (event.keyCode === 91 || event.keyCode === 17) {
      this.setState({
        isPressedCtr: false,
      });
    }

    if (event.keyCode === 16) {
      this.setState({
        isPressedShift: false,
      });
    }
  }

  addToPrintGroup() {
    const copyItems = [...this.state.items];
    this.state.itemsNoGroupIndex
      .sort((a, b) => a - b)
      .forEach((indexElement, index) => {
        copyItems.splice(indexElement - index, 1);
      });
    const updateGroup = [...this.state.group, ...this.state.printGroup];

    this.setState({
      printGroup: updateGroup,
      items: copyItems,
      itemsNoGroupIndex: [],
      group: [],
    });
    this.props.updateGroup(updateGroup);
  }

  onSelectInGroup(event, index) {
    const printGroupCopy = [...this.state.printGroup];
    printGroupCopy.splice(index, 1);
    this.setState({
      indexRemoveFromGroup: index,
    });
  }

  removeFromPrintGroup() {
    if (this.state.indexRemoveFromGroup !== -1) {
      const printGroupCopy = [...this.state.printGroup];
      const deletedItem = printGroupCopy.splice(this.state.indexRemoveFromGroup, 1);
      const copyItems = [...this.state.items, ...deletedItem];

      this.setState({
        group: [],
        printGroup: printGroupCopy,
        items: copyItems,
        indexRemoveFromGroup: -1,
      });

      this.props.updateGroup(printGroupCopy);
    }
  }

  addRemoveAllFromGroup(action) {
    const isRemoveAction = action === 'remove';
    const updateItems = isRemoveAction ? [...this.state.items, ...this.state.printGroup] : [];
    const updatePrintGroup = isRemoveAction ? [] : [...this.state.printGroup, ...this.state.items];

    this.setState({
      group: [],
      printGroup: updatePrintGroup,
      items: updateItems,
    });
    this.props.updateGroup(updatePrintGroup);
  }

  render() {
    return (
      <div className="list-box d-flex justify-content-between">
        <div className="flex-fill mr-2 w-50">
          <h2>{`${this.props.listTitle ? this.props.listTitle : 'Изберете от списъка:'} ${this.state.items?.length}`}</h2>
          <div className="elements-list">{this.printList()}</div>
        </div>
        <div className="actions">
          <div>
            <button disabled={this.state.group.length === 0} onClick={this.addToPrintGroup} className="btn btn-secondary mb-1">
              <FontAwesomeIcon className="m-0" icon="angle-right" />
            </button>
          </div>
          <div>
            <button disabled={this.state.indexRemoveFromGroup === -1} onClick={this.removeFromPrintGroup} className="btn btn-secondary mb-1">
              <FontAwesomeIcon icon="angle-left" className="m-0" />
            </button>
          </div>
          <div>
            <button disabled={this.state.items.length === 0} onClick={this.addRemoveAllFromGroup} className="btn btn-secondary mb-1">
              <FontAwesomeIcon icon="angle-double-right" className="m-0" />
            </button>
          </div>
          <div>
            <button disabled={this.state.printGroup.length === 0} onClick={() => this.addRemoveAllFromGroup('remove')} className="btn btn-secondary">
              <FontAwesomeIcon icon="angle-double-left" className="m-0" />
            </button>
          </div>
        </div>
        <div className="flex-fill ml-2 w-50">
          <h2>{`${this.props.groupTitle ? this.props.groupTitle : 'Брой:'} ${this.state.printGroup.length}`}</h2>
          <div className="elements-group">{this.printGroup()}</div>
        </div>
      </div>
    );
  }
}

export default ListBoxComponent;
