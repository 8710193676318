import { gql } from 'apollo-boost';

export const GET_ANIMALS_FOR_GROUPS = gql`
{
  animals {
    items {
      id: animalId
      animalLocation {
        moveDate
        location{
          locationName
          locationParent{
            locationName
            locationParent{
              locationName
            }
          }
        }
      }
      breed {
        breedsId
        breedName
        breedShort
      }
      color {
        colorID
        colorName
      }
      aClass{
        classID
        className
      }
      sex
      lID
      vID
      dateOfBirth
      name
      classID
    }
  }
}  
`;

export const GET_ALL_ANIMAL_GROUPS = gql`
{
  groups
  {
    items
    {
      managementGroupsID
      managementGroupsName
      managementGroupsComment
      managementGroupsShortName
      groupTypeID
      whenCreate
      groupDate
      animals {
          id: animalId
          breed {
            breedsId
            breedName
            breedShort
          }
          color {
            colorID
            colorName
          }
          aClass{
            classID
            className
          }
          sex
          lID
          vID
          dateOfBirth
          name
      }
    }
  }
} 
`;

export const GET_ALL_GROUPS_APPLICABLE_FOR_SERVICE = gql`
{
  groups(groupType: HERD)
  
  {
    items
    {
      managementGroupsID
      managementGroupsName
      animals {
        animalId
      }
    }
  }
}
`;

export const GET_ALL_SERVICE_GROUPS = gql`
{
  serviceGroups
  {
    items
    {
      groupID
      groupName
      dateCreate
      dateExpires
      active
      daysLong
      ownerID
      comment
      animalServiceProp
      {
        animalID
        id: animalID
        dateIn
        dateOut
        animalInGroupID
        comment
        animals
        {
          animalId
          id: animalId
          name
          vID
          breed {
            breedShort
          }
          aClass {
            className
          }
          sex
          color{
            colorName
          }
          vetis
          {
            animalID
          }
          dateOfBirth
          lID
          animalLocation {
            location{
              locationName
              locationParent{
                locationName
                locationParent{
                  locationName
                }
              }
            }
          }
        }
      }
    }
  }
}`;

export const GET_ANIMALS_IN_GROUP = gql`
query AnimalsInGroup($id: ID!) {
  group(id:$id){
  animals{
    id: animalId
      breed {
        breedsId
        breedName
        breedShort
      }
      color {
        colorID
        colorName
      }
      aClass{
        classID
        className
      }
      sex
      lID
      vID
      dateOfBirth
      name
    }
  }
}
`;

export const ADD_GROUP = gql`
mutation addGroup($dataItem: groupInput){
  addGroup(group: $dataItem){
   managementGroupsID
    managementGroupsName
  }
}
`;

export const ADD_SERVICE_GROUP = gql`
mutation addServiceGroup($serviceGroup: serviceGroupInput)
{
  addServiceGroup(serviceGroup: $serviceGroup){
   groupID
   groupName
    animalServiceProp
    {
      animalID
    }
  }
}
`;

export const EDIT_GROUP_DATA = gql`
mutation editGroup($dataItem: groupInput){
  editGroup(group: $dataItem){
    managementGroupsID
    managementGroupsName
  }
}
`;

export const EDIT_SERVICE_GROUP_DATA = gql`
mutation editServiceGroup($serviceGroup: serviceGroupInput)
{
  editServiceGroup(serviceGroup: $serviceGroup){
   groupID
   groupName
    active
    
    animalServiceProp
    {
      animalID
    }
  }
}
`;

export const ADD_ANIMALS_IN_GROUP = gql`
mutation addAnimalFromGroup($dataItem: [animalInGroupInput]){
  addAnimalInGroup(animalInGroup: $dataItem){
   managementGroupsID
    animalID
  }
}
`;

export const ADD_ANIMAL_IN_SERVICE_GROUP = gql`
mutation addAnimalInServiceGroup($animalInServiceGroup: [animalInServiceGroupInput])
{
  addAnimalInServiceGroup(animalInServiceGroup: $animalInServiceGroup){
   groupID
   animalID
   dateIn
  }
}
`;

export const DELETE_ANIMAL_IN_GROUP = gql`
mutation removeAnimalFromGroup($dataItem: animalInGroupInput){
  removeAnimalFromGroup(animalInGroup: $dataItem){
   managementGroupsID
    animalID
  }
}
`;

export const DELETE_ANIMAL_IN_SERVICE = gql`
  mutation removeAnimalInServiceGroup($dataItem: animalInServiceGroupInput)
  {
    removeAnimalInServiceGroup(animalInServiceGroup: $dataItem){
     groupID
     animalID
     dateIn
    }
  }
`;

export const GET_SERVICE_GROUP = gql`
query ServiceGroup($id: ID!) {
  serviceGroup(id:$id)
  {
    groupID
    groupName
    animals
    {
      animalId
      vID
    }
  }
}
`;

const generateServiceAnimalQuery = bulls => gql`
{
  ${bulls ? 'serviceBulls' : 'serviceAnimals'}
  {
    items
    {
      id: animalId
      animalLocation {
        location{
          locationName
          locationParent{
            locationName
            locationParent{
              locationName
            }
          }
        }
      }
      breed {
        breedsId
        breedName
        breedShort
      }
      color {
        colorID
        colorName
      }
      aClass{
        classID
        className
      }
      sex
      lID
      vID
      dateOfBirth
      name
    }
  }
}
`;

export const GetAllDPPgroups = gql`
  {
    groupsDPP
    {
     items
      {
        groupZPPID
        groupZPPName
        groupZPPDate
      }
    }
  }
`;

export const GET_ANIMALS_FOR_SERVICE_GROUP = generateServiceAnimalQuery(false);
export const GET_BULLS_FOR_SERVICE_GROUP = generateServiceAnimalQuery(true);

export const ADD_ANIMAL_GROUP_WEIGHTS = gql`
  mutation addAnimalGroupWeight($animalWeights: [animalGroupWeightInput]) 
  {
    addAnimalGroupWeight(animalWeights: $animalWeights){
      lID
    }
  }
`;
