/* eslint react/prop-types:0 */
/* eslint react/destructuring-assignment:0 */
import * as React from 'react';
import { differenceBy } from 'lodash';
import { Row, Col } from 'reactstrap';
import ListBoxComponent from '../../../../list-box/list-box';
import { mapAnimalsForListBox } from '../../../../../tools/function-helpers';
import ListBoxFiltersComponent from '../../../../list-box-filters/list-box-filters';
import { DatePicker } from '../../../../utils/date-picker';

const ServiceAnimalChoice = props => {
  const {
    aClasses, breeds, color, groups,
  } = props.otherProps;
  const { dateInAnimal, } = props;
  const renderListView = animals => {
    return (
      <>
        <ListBoxFiltersComponent
          animals={props.allAnimals}
          aClasses={aClasses}
          breeds={breeds}
          intersectionOfAnimals
          colors={color}
          locations={props.allLocations}
          groups={groups}
          updateAnimalsToMap={filteredAnimals => props.setAnimalState({
            availableAnimals: filteredAnimals,
            allAnimals: props.allAnimals,
            selectedAnimals: props.selectedAnimals,
          })}
        />
        {props.inModal && (
        <Row>
          <Col lg="3">
            <div className="form-group">
              <label>Дата на влизане в сървис</label>
              <DatePicker
                name="dateInAnimals"
                onChange={(e) => dateInAnimal.setDateIn(e.target.value)}
                value={dateInAnimal?.dateIn}
              />
            </div>
          </Col>
        </Row>
        )}
        <ListBoxComponent
          items={mapAnimalsForListBox(animals)}
          listTitle="Изберете животни:"
          groupTitle="Брой животни:"
          getGroup={() => props.selectedAnimals}
          updateGroup={selectedAnimals => props.setAnimalState({
            availableAnimals: props.availableAnimals,
            allAnimals: props.allAnimals,
            selectedAnimals,
          })}
        />
      </>
    );
  };

  return renderListView(differenceBy(props.availableAnimals, props.selectedAnimals, 'id'));
};

export default ServiceAnimalChoice;
