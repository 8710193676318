/* eslint react/destructuring-assignment:0 */
/* eslint react/sort-comp:0 */
/* eslint react/no-unused-state:0 */
import * as React from 'react';
import { Row, Col } from 'reactstrap';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import TabNavButtons from '../../tab-nav-buttons';
import { BREEDING_FOR } from '../../../../../../../tools/translation-constants';
import LocationsSelect from '../../../../../../locations-select/locations-select';
import Query from '../../../../../../../data/query';
import { GET_ANIMAL_LOCATIONS } from '../../../../../../../data/animal/animal-query';

class GroupAndLocationTab extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      animal: props.getGroupObject(),
      locations: null,
      updateGroupObject: props.updateGroupObject,
    };

    this.handleFormChange = this.handleFormChange.bind(this);
    this.onLocationChange = this.onLocationChange.bind(this);
  }

  handleFormChange(e) {
    const objCopy = { ...this.state.animal, };
    if (e.target.name === 'breedingFor') {
      objCopy[e.target.name] = e.target.value.id;
      this.setState({
        animal: objCopy,
      });
      this.state.updateGroupObject(objCopy);
    }
  }

  onLocationChange(location) {
    const objCopy = { ...this.state.animal, };
    objCopy.animalLocation[0].locationId = location.locationId;
    objCopy.selectedLocations = location.selected;
    this.state.updateGroupObject(objCopy);
  }

  render() {
    return (
      <div className="tab">
        <h3 className="mb-2">ДАННИ ЗА ЛОКАЦИЯТА | НАПРАВЛЕНИЕ</h3>
        <p className="mb-2">Определете локацията, на която ще се отглеждат животните!</p>
        <Query query={GET_ANIMAL_LOCATIONS}>
          {({ locations, }) => {
            return (
              <LocationsSelect
                locations={locations.items}
                selectedLocations={this.state.animal.selectedLocations ? this.state.animal.selectedLocations : null}
                updateLocationIDandSelected={this.onLocationChange}
              />
            );
          }}
        </Query>
        <form>
          <Row>
            <Col lg="2">
              <div className="form-group">
                <label>Направление</label>
                <DropDownList
                  data={BREEDING_FOR}
                  dataItemKey="id"
                  textField="text"
                  name="breedingFor"
                  onChange={this.handleFormChange}
                  value={this.state.animal.breedingFor ? BREEDING_FOR.find(breedingFor => breedingFor.id === this.state.animal.breedingFor) : ''}
                />
              </div>
            </Col>
          </Row>
          <TabNavButtons parentProps={this.props} />
        </form>
      </div>
    );
  }
}

export default GroupAndLocationTab;
