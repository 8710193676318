/* eslint max-len:0 */
/* eslint react/no-access-state-in-setstate:0 */
/* eslint react/destructuring-assignment:0 */
/* eslint no-underscore-dangle:0 */
/* eslint react/sort-comp:0 */
import * as React from 'react';
import { Row, Col } from 'reactstrap';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Input } from '@progress/kendo-react-inputs';
import { AnimalWeightEnum } from '../../animal-enums';
import { ADD_WEIGHT, GET_ANIMAL_DETAILS, GET_ANIMAL_ADG } from '../../../../../data/animal/animal-query';
import { GET_WEIGHTS_DATA } from '../../../../../data/reports/reports-query';
import Mutation from '../../../../../data/mutation';
import { DatePicker } from '../../../../utils/date-picker';

class AddWeightModal extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      weightToAdd: {
        animalID: props.animalID,
        weightType: AnimalWeightEnum.controlWeight(),
        weight: null,
        dateWeight: new Date(),
        comment: '',
      },
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.setWeightType = this.setWeightType.bind(this);
    this.handleFormChange = this.handleFormChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  openModal() {
    this._resetWeight();
    this.setState({
      visible: true,
    });
  }

  closeModal() {
    this.setState({
      visible: false,
    });
  }

  handleSubmit(event, mutationTrigger) {
    event.preventDefault();
    mutationTrigger({
      variables:
        { weightToAdd: this.state.weightToAdd, },
    });
  }

  setWeightType(event) {
    const newWeightType = event.target.dataset.type;
    const { weightToAdd, } = this.state;
    weightToAdd.weightType = newWeightType;
    this.setState({ weightToAdd, });
  }

  _resetWeight() {
    this.setState({
      weightToAdd: {
        animalID: this.props.animalID,
        weightType: AnimalWeightEnum.controlWeight(),
        weight: null,
        dateWeight: new Date(),
        comment: '',
      },
    });
  }

  handleFormChange(event) {
    const { target, } = event;
    const { value, } = target;
    const { name, } = target;
    const currentWeightToAdd = { ...this.state.weightToAdd, };
    currentWeightToAdd[name] = value;
    this.setState({ weightToAdd: currentWeightToAdd, });
  }

  render() {
    return (
      <Mutation
        mutation={ADD_WEIGHT}
        refetchQueries={[
          { query: GET_ANIMAL_ADG, variables: { id: this.props.animalID, }, },
          { query: GET_ANIMAL_DETAILS, variables: { id: this.props.animalID, }, },
          { query: GET_WEIGHTS_DATA, }
        ]}
        awaitRefetchQueries
        onCompleted={() => this.closeModal()}
      >
        {(addWeight) => (
          <div className="align-self-end mt-2">
            <button type="button" className="btn btn-primary" onClick={this.openModal}>ДОБАВИ ТЕГЛО</button>
            {!this.state.visible ? ''
              : (
                <Dialog title="Добавяне на запис" minWidth="500px" onClose={this.closeModal}>
                  <form>
                    <Row>
                      <Col lg={{ size: 8, offset: 2, }}>
                        <div className="form-group">
                          <label>Тип</label>
                          <fieldset onChange={e => this.setWeightType(e)}>
                            <input type="radio" id="r1" name="weightType" data-type={AnimalWeightEnum.controlWeight()} className="k-radio" defaultChecked />
                            <label className="k-radio-label" htmlFor="r1">Контролно тегло</label>
                            <br />
                            <input type="radio" id="r2" name="weightType" data-type={AnimalWeightEnum.birthWeight()} className="k-radio" />
                            <label className="k-radio-label" htmlFor="r2">Тегло при раждане</label>
                            <br />
                            <input type="radio" id="r3" name="weightType" data-type={AnimalWeightEnum.weaningWeight()} className="k-radio" />
                            <label className="k-radio-label" htmlFor="r3">Тегло при отбиване</label>
                            <br />
                            <input type="radio" id="r4" name="weightType" data-type={AnimalWeightEnum.yearlingWeight()} className="k-radio" />
                            <label className="k-radio-label" htmlFor="r4">Тегло на една година</label>
                            <br />
                            <input type="radio" id="r5" name="weightType" data-type={AnimalWeightEnum.matureWeight()} className="k-radio" />
                            <label className="k-radio-label" htmlFor="r5">Тегло на възрастно животно</label>
                            <br />
                            <input type="radio" id="r6" name="weightType" data-type={AnimalWeightEnum.techWeight()} className="k-radio" />
                            <label className="k-radio-label" htmlFor="r6">Тегло при клане</label>
                            <br />
                          </fieldset>
                        </div>
                        <div className="form-group">
                          <label>Тегло</label>
                          <Input type="number" name="weight" onChange={e => this.handleFormChange(e)} value={this.state.weightToAdd.weight || ''} />
                        </div>
                        <div className="form-group">
                          <label>Дата на претеглянето</label>
                          <DatePicker
                            value={this.state.weightToAdd.dateWeight}
                            name="dateWeight"
                            onChange={this.handleFormChange}
                            min={new Date(this.props.animaldateOfBirth)}
                            max={new Date()}
                          />
                        </div>
                        <div className="form-group">
                          <label>Бележка</label>
                          <textarea className="k-textarea" name="comment" onChange={e => this.handleFormChange(e)} value={this.state.weightToAdd.comment} />
                        </div>
                      </Col>
                    </Row>
                  </form>
                  <DialogActionsBar>
                    <div className="d-flex">
                      <button type="button" className="btn btn-secondary" onClick={this.closeModal}>Отказ</button>
                      <button type="submit" onClick={e => this.handleSubmit(e, addWeight)} className="btn btn-primary ml-3">Добави</button>
                    </div>
                  </DialogActionsBar>
                </Dialog>
              )}
          </div>
        )}

      </Mutation>
    );
  }
}

export default AddWeightModal;
