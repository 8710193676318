/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */
import moment from 'moment';
import { partition } from 'lodash';
import DatesHelper from '../../../tools/DatesHelper';
import { DRY_WET_STATUSES, REGISTERS, COUNTRIES } from '../../../tools/translation-constants';

export const getAnimalAgeInMonthsOLD = animalBirthDate => moment().diff(moment(animalBirthDate), 'months');

export const getAnimalAgeInMonths = (animalBirthDate: Date, fateDate?: Date, bornDead?: boolean) => {
  if (bornDead) {
    return 0;
  }
  if (fateDate) {
    return moment(fateDate).diff(moment(animalBirthDate), 'months');
  }
  return moment().diff(moment(animalBirthDate), 'months');
};

export const getAnimalLastWeightObj = (animal) => {
  const { weights, } = animal;
  const result = weights.length > 0 ? weights[weights.length - 1] : { weight: '-', dateWeight: '', };
  return result;
};

export const getAnimalWeightPerType = (animal, weightType) => animal.weights.filter(w => w.weightType === weightType)[0];

export const mapEventCell = animal => {
  // TODO The events cell content should be fetched in a different way
  const { events, } = animal;
  const eventTypes = [
    'Раждане',
    'Отелване',
    'Въвеждане в системата',
    'Постъпване в сървис',
    'Ваксинация',
    'Отчитане на бременност',
    'Отбиване',
    'Разгонване',
    'Пресушаване'
  ];
  let result = '';
  if (events && events.length > 0) {
    const lastDate = events.reduce((prev, current) => {
      return (new Date(prev.date) > new Date(current.date)) ? prev : current;
    });
    result = eventTypes[lastDate.animalEventTypeID - 1];
  }
  return result;
};

export const generateBreedCompositionString = ({ breed, breedComposition, }) => {
  if (!breed && breed !== null && breedComposition.length === 0) {
    return '-';
  }

  if (!breedComposition || breedComposition.length === 0) {
    return breed.breedShort ? `100% (${breed.breedShort})` : '-';
  }

  return breedComposition.map(breedObj => `${100 * breedObj.breedPercent}% (${breedObj.breed.breedShort})`).join(', ');
};

export const generateLocationString = ({ animalLocation, }) => {
  if (!animalLocation || animalLocation.length === 0 || animalLocation[0] == null) {
    return '-';
  }

  const i = animalLocation.length - 1;

  const currentLocation = animalLocation[i].location;
  const locationNames = [];
  if (currentLocation.locationName) {
    locationNames.push(currentLocation.locationName);
  }
  if (currentLocation.locationParent !== null && currentLocation.locationParent.locationName) {
    locationNames.push(currentLocation.locationParent.locationName);
  }
  if (
    currentLocation.locationParent !== null
    && currentLocation.locationParent.locationParent !== null
    && currentLocation.locationParent.locationParent.locationName
  ) {
    locationNames.push(currentLocation.locationParent.locationParent.locationName);
  }

  const locationString = locationNames.join(', ');
  const moveDate = animalLocation[i].moveDate ? DatesHelper.parseAndFormatDate(animalLocation[i].moveDate) : '';

  return moveDate ? `${locationString} (от ${moveDate})` : locationString;
};

export const generateSelectionString = ({ underSelection, holdTo, }) => {
  const date = holdTo !== null ? ` (до ${DatesHelper.parseAndFormatDate(holdTo)})` : '';
  return underSelection ? `Да${date}` : `Не${date}`;
};

export const generateDryWetStatusString = ({ dryWetStatus, cowBirthCount, }) => {
  let dryWetStatusStr = DRY_WET_STATUSES.find(dws => dws.id === 'UNKNOWN').text;
  if (dryWetStatus === 'WET') {
    dryWetStatusStr = 'Да';
  }
  if (dryWetStatus === 'DRY') {
    dryWetStatusStr = 'Не';
  }
  return `${dryWetStatusStr} (${cowBirthCount})`;
};

export const calculateAnimalMaleFemeleChildren = (animal) => {
  const result = {
    maleCount: 0,
    femaleCount: 0,
  };
  if (animal.sex === 'MALE') {
    return false;
  }

  if (animal.children && animal.children.length > 0) {
    animal.children.forEach((child) => {
      if (child.sex === 'MALE') {
        result.maleCount++;
      }
      if (child.sex === 'FEMALE') {
        result.femaleCount++;
      }
    });
    return result;
  }
  return result;
};

export const generatePEDString = (animal) => {
  const animalRegister = animal.register ? REGISTERS.find(register => register.id === animal.register).text : '-';
  const animalCountry = COUNTRIES.find(country => country.id === animal.countryFrom).text;
  const animalHerdBook = animal.animalHerdBook ? animal.herdBookOut : '-';

  return `${animalRegister} | ${animalCountry} | ${animalHerdBook}`;
};

export const getLocationsByType = (locations) => {
  const byreGrassland = [];
  const sector = [];
  const boxPadok = [];
  for (let i = 0; i < locations.length; i++) {
    const currElement = { locationID: locations[i].locationID, locationName: locations[i].locationName, };
    if (locations[i].locationParent === null) {
      byreGrassland.push(currElement);
    } else if (locations[i].locationParent !== null && locations[i].locationParent.locationParent !== null) {
      boxPadok.push(currElement);
    } else if (locations[i].locationParent !== null && locations[i].locationParent.locationParent === null) {
      sector.push(currElement);
    }
  }
  return {
    byreGrassland,
    sector,
    boxPadok,
  };
};

export const transformLocations = locations => {
  const [topLevelLocations, rest] = partition(locations, loc => !loc.locationParent);
  const mapped = topLevelLocations.map(loc => {
    const [
      secondLevelLocation,
      thirdLevelLocation
    ] = partition(rest, l => l.locationParent?.locationID === loc.locationID);
    loc.children = secondLevelLocation.map(sec => {
      sec.children = thirdLevelLocation.filter(a => a.locationParent?.locationID === sec.locationID);
      return sec;
    });
    return loc;
  });
  const res = mapped.reduce((boxArr, box) => {
    box.fullLocationName = box.locationName;
    const subItemsMidLevel = box.children.reduce((sectorArr, sector) => {
      sector.secondLevelLocation = true;
      sector.fullLocationName = `${sector.locationName}, ${box.locationName}`;
      const subItemsBottomLevel = sector.children.map(padok => {
        padok.thirdLevelLocation = true;
        padok.fullLocationName = `${padok.locationName}, ${sector.locationName}, ${box.locationName}`;
        return padok;
      });
      return sectorArr.concat([sector, ...subItemsBottomLevel]);
    }, []);
    return boxArr.concat([box, ...subItemsMidLevel]);
  }, []);

  return res;
};
