/* eslint react/prop-types:0 */
import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import { adopt } from 'react-adopt';
import Query from '../../../data/query';
import { GET_EXTERNAL_ANIMALS } from '../../../data/external-animals/external-animals-queries';
import { GET_WAREHOUSE_ANIMALS } from '../../../data/warehouse-animals/warehouse-animals-queries';
import { DATA_TAB_ENUMS } from '../../../data/animal/tabs-queries';
import { GET_FARMCIENTS } from '../../../data/animal/animal-query';

import Movements from './movements/movements';
import AnimalsListWrapper from './animals-list-wrapper';
import AnimalCardContainer from './animal-card';
import ExternalAnimals from './external-animals/external-animals';
import WarehouseTable from './warehouse/warehouse-table';

const Composed = adopt({
  firstQ: ({ render, }) => <Query query={DATA_TAB_ENUMS}>{ render }</Query>,
  secondQ: ({ render, }) => <Query query={GET_FARMCIENTS}>{ render }</Query>,
  thirdQ: ({ render, }) => <Query query={GET_WAREHOUSE_ANIMALS}>{ render }</Query>,
});

const Animals = otherProps => {
  return (
    <main className="mb-5">
      <Switch>
        <Route
          path="/animals/warehouse"
          render={props => (
            <Composed>
              {({ firstQ, secondQ, thirdQ, }) => (
                <WarehouseTable
                  {...props}
                  animals={thirdQ.animalSemen}
                  breeds={firstQ.breeds.items.map(breed => breed.breedName)}
                  farmClients={secondQ.farmClients}
                />
              )}
            </Composed>
          )}
        />
        <Route path="/animals/movement" component={Movements} />
        <Route
          path="/animals/external-animals"
          render={props => (
            <Query query={GET_EXTERNAL_ANIMALS} {...otherProps}>
              {({ animalsReff, }) => <ExternalAnimals {...props} externalAnimals={animalsReff.items} />}
            </Query>
          )}
        />
        <Route path="/animals/list/:id" render={props => <AnimalCardContainer animalId={+props.match.params.id} {...props} />} />
        <Route path="/animals/list" render={props => <AnimalsListWrapper {...props} {...otherProps} />} />
      </Switch>

    </main>
  );
};
export default Animals;
