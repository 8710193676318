/* eslint react/no-unescaped-entities:0 */
/* eslint react/no-array-index-key:0 */
/* eslint max-len:0 */
/* eslint react/prop-types:0 */
import * as React from 'react';
import LocationsSelect from '../../../../locations-select/locations-select';
import Query from '../../../../../data/query';
import { GET_ANIMAL_LOCATIONS } from '../../../../../data/animal/animal-query';

export default ({
  selectedAnimals, selectedBulls, onLocationChange, location,
}) => {
  const errors = [];
  if (!location) {
    errors.push('Не е избрана локация за сървис групата');
  }

  if (selectedAnimals.length === 0) {
    errors.push('Не са избрани животни за сървис групата. Моля, изберете животни на стъпка 1.');
  }

  if (selectedBulls.length === 0) {
    errors.push('Не са избрани бикове за сървис групата. Моля, изберете поне един бик на стъпка 2.');
  }


  return (
    <>
      <>
        <h4 className="mb-2">Задайте локация за сървис групата</h4>
        <Query query={GET_ANIMAL_LOCATIONS}>
          {({ locations, }) => {
            return <LocationsSelect locations={locations.items} selectedLocations={location ? location.selected : null} updateLocationIDandSelected={onLocationChange} />;
          }}
        </Query>
      </>
      {errors.length
        ? errors.map((err, index) => <div key={index} className="error"><span className="text-center"><p>{err}</p></span></div>)
        : <h5>Вие приключихте създаването на сървис група, натиснете "Създай Група" за да приключите процеса!</h5>}
    </>
  );
};
