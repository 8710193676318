import { gql } from 'apollo-boost';

export default gql`
{
    versionLogs {
        totalCount
        items {
        versionLogComment
        versionLogDate
        versionLogNumber
        }
    }
}
`;
