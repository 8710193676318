/* eslint react/no-did-update-set-state:0 */
/* eslint react/destructuring-assignment:0 */
import * as React from 'react';
import { NavLink } from 'react-router-dom';
import DatesHelper from '../../../../tools/DatesHelper';


class WeightSessions extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      weightSessions: props.weightSessions,
    };
    this.generateTableRows = this.generateTableRows.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.weightSessions !== prevProps.weightSessions) {
      this.setState({
        weightSessions: this.props.weightSessions,
      });
    }
  }

      generateTableRows = () => {
        return this.state.weightSessions.map((weightSessions, i) => {
          const avg = weightSessions.weights.reduce((a, { weight, }) => a + weight, 0) / weightSessions.weights.length;
          return (
            // eslint-disable-next-line react/no-array-index-key
            <tr key={i}>
              <td>
                {weightSessions.sessionName}
              </td>
              <td>
                {DatesHelper.dateToAppFormat(weightSessions.sessionDate)}
              </td>
              <td>
                {`${avg} кг.`}
              </td>
              <td>
                {weightSessions.weights.length}
              </td>
              <td>
                {DatesHelper.dateToAppFormat(weightSessions.whenSync)}
              </td>
              <td>
                <div className="d-flex justify-content-center">
                  <td><NavLink className="link-color-primary" to={`/reports/sessions/${weightSessions.sessionID}`}>Преглед</NavLink></td>
                </div>
              </td>
            </tr>
          );
        });
      }

      render() {
        return (
          <div>
            <h3 className="mb-4">Справка синхронизирани теглови сесии</h3>
            <table className="table">
              <thead>
                <tr>
                  <th>Име на сесията</th>
                  <th>Дата</th>
                  <th>Средно тегло</th>
                  <th>Брой животни</th>
                  <th>Дата на синхронизация</th>
                  <th>Операции</th>
                </tr>
              </thead>
              <tbody>
                {this.generateTableRows()}
              </tbody>
            </table>
          </div>
        );
      }
}

export default WeightSessions;
