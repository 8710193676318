/* eslint radix:0 */
/* eslint no-unused-vars:0 */
/* eslint react/destructuring-assignment:0 */
/* eslint react/prop-types:0 */
import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import { withRouter } from 'react-router';
import { adopt } from 'react-adopt';
import GroupsManagment from './groups-managment/groups-managment';
import AddGroup from './groups-managment/add-group';
import EditGroup from './groups-managment/edit-group';
import DppGroups from './groups-dpp/dpp-groups';
import GroupsServiceAdd from './groups-service/add-service-group/add-service-group';
import Query from '../../../data/query';
import {
  GET_ANIMALS_FOR_GROUPS,
  GET_ALL_SERVICE_GROUPS,
  GET_ALL_ANIMAL_GROUPS,
  GET_ALL_GROUPS_APPLICABLE_FOR_SERVICE,
  GetAllDPPgroups
} from '../../../data/groups/groups-query';
import { DATA_TAB_ENUMS } from '../../../data/animal/tabs-queries';
import ServiceGroups from './groups-service/service-groups';
import ServiceGroupEdit from './groups-service/edit-service-group/edit-service-group';
import DPPEdit from './groups-dpp/dpp-edit';

const Groups = props => {
  const Composed = adopt({
    firstQ: ({ render, }) => <Query query={DATA_TAB_ENUMS} {...props}>{ render }</Query>,
    secondQ: ({ render, }) => <Query query={GET_ALL_SERVICE_GROUPS} {...props}>{ render }</Query>,
    thirdQ: ({ render, }) => <Query query={GET_ALL_GROUPS_APPLICABLE_FOR_SERVICE} {...props}>{ render }</Query>,
  });
  return (
    <>
      { props.location.pathname.includes('groups-managment') ? (
        <Switch>
          <Query query={GET_ALL_ANIMAL_GROUPS}>
            {({ groups, }) => (
              <Query query={DATA_TAB_ENUMS}>
                {({ aClasses, breeds, color, }) => (
                  <Query query={GET_ANIMALS_FOR_GROUPS}>
                    {({ animals, }) => (
                      <>
                        <Route
                          exact
                          path="/groups/groups-managment"
                          render={() => <GroupsManagment groups={groups.items} {...props} />}
                        />
                        <Route
                          path="/groups/groups-managment/edit-group"
                          render={() => (
                            <EditGroup
                              {...props}
                              animals={animals.items}
                              aClasses={aClasses.items}
                              breeds={breeds.items}
                              colors={color.items}
                              groups={groups.items}
                            />
                          )}
                        />
                        <Route
                          path="/groups/groups-managment/add-group"
                          render={() => (
                            <AddGroup
                              animals={animals.items}
                              aClasses={aClasses.items}
                              breeds={breeds.items}
                              colors={color.items}
                              groups={groups.items}
                            />
                          )}
                        />
                      </>
                    )}
                  </Query>
                )}
              </Query>
            )}
          </Query>
        </Switch>
      ) : (
        <Switch>
          <Composed>
            {({ firstQ, secondQ, thirdQ, }) => {
              const {
                aClasses: {
                  items: aClasses,
                },
                color: {
                  items: colors,
                },
                breeds: {
                  items: breeds,
                },
              } = firstQ;

              const { serviceGroups: { items: sg, }, } = secondQ;
              const { groups: { items: groups, }, } = thirdQ;
              return (
                <>
                  <Route exact path="/groups/groups-service" render={_ => <ServiceGroups groups={sg} />} />
                  <Route
                    path="/groups/groups-service/:id"
                    render={serviceProps => {
                      const {
                        match: {
                          params: {
                            id,
                          },
                        },
                      } = serviceProps;
                      return id === 'new' ? (
                        <GroupsServiceAdd
                          {...serviceProps}
                          otherProps={{
                            aClasses,
                            breeds,
                            colors,
                            groups,
                          }}
                        />
                      )
                        : <ServiceGroupEdit group={sg.find(it => it.groupID === parseInt(id))} />;
                    }}
                  />
                </>
              );
            }}
          </Composed>
        </Switch>
      )}
      <Route
        exact
        path="/groups/dpp"
        render={() => {
          return (
            <Query query={GetAllDPPgroups}>
              {({ groupsDPP, }) => <DppGroups groupsDPP={groupsDPP.items} />}
            </Query>
          );
        }}
      />
      <Route path="/groups/dpp/:id" render={(groupid) => <DPPEdit {...groupid} />} />
    </>
  );
};

export default withRouter(Groups);
