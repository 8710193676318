/* eslint no-nested-ternary:0 */
/* eslint max-len:0 */
/* eslint no-unused-vars:0 */
import * as React from 'react';
import DroppedAnimalsTable from './dropped-animals-table';
import Query from '../../../../data/query';
import { GET_DROPPED_ANIMALS_DATA } from '../../../../data/reports/reports-query';
import {
  animalSexTranslation, dateFormatForTableFilters, getTranslation, reportAnimalSummary
} from '../../../../tools/function-helpers';
import { FATE_REASON, ANIMAL_DIE_TYPE, FATE_REASON_SHORT } from '../../../../tools/translation-constants';
import DatesHelper from '../../../../tools/DatesHelper';


const animalInfoCellData = (animal) => {
  if (animal.fateReason === 'DIE') {
    const dieLen = animal.animalDie;
    const {
      [dieLen.length - 1]: lastDie,
    } = dieLen;

    return lastDie
      ? `Причина: ${getTranslation(lastDie.animalDieTypeID, ANIMAL_DIE_TYPE)},
Екарисажна бележка: ${lastDie?.skeletonDoc ? lastDie?.skeletonDoc : '-'} 
      ` : `
      Причина: -,
      Екарисажна бележка: -,
      `;


    // const text = 'Причина:';
    // return dieLen > 0 ? animal.animalDie[dieLen - 1].animalDieTypeID ? `${text} ${getTranslation(animal.animalDie[dieLen - 1].animalDieTypeID, ANIMAL_DIE_TYPE)},
    // Екарисажна Бележка: ` : `${text} -`
    //   : `${text} -`;
  }
  if (animal.fateReason === 'SALE') {
    const sellLen = animal.animalSell;
    const {
      [sellLen.length - 1]: last,
    } = sellLen;

    return last
      ? `Клиент: ${last?.client ? last?.client.farmClientName : '-'},
Направление: ${last?.animalSellDirection ? getTranslation(last?.animalSellDirection, FATE_REASON_SHORT) : '-'}, 
Пътен лист ВЕТИС: ${last?.animalSellBABHTrasportDoc ? last?.animalSellBABHTrasportDoc : '-'}
        ` : `
Клиент: -,
Направление: -,
Пътен лист ВЕТИС: -
        `;
  }

  return '';
};


const DroppedReport = props => {
  return (
    <Query query={GET_DROPPED_ANIMALS_DATA}>
      {({ animalsExit, }) => {
        const mappedAnimals = animalsExit.items.map((animal) => {
          const sexTranslation = animalSexTranslation(animal.sex);
          return {
            ...animal,
            fateDate: animal.fateDate ? dateFormatForTableFilters(animal.fateDate) : null,
            dropReason: getTranslation(animal.fateReason, FATE_REASON),
            animalSummary: reportAnimalSummary(animal, sexTranslation),
            animalInfo: animalInfoCellData(animal),
          };
        });
        const fateTypes = FATE_REASON.map(fr => fr.text);
        return (
          <DroppedAnimalsTable {...props} animals={mappedAnimals.reverse()} fateTypes={fateTypes} />
        );
      }}
    </Query>
  );
};

export default DroppedReport;
