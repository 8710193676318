/* eslint max-len:0 */
/* eslint no-param-reassign:0 */
/* eslint prefer-destructuring:0 */
/* eslint react/prop-types:0 */
/* eslint react/destructuring-assignment:0 */
import * as React from 'react';
import { Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Query from '../../../data/query';
import DatesHelper from '../../../tools/DatesHelper';
import { GET_ANIMAL_DETAILS } from '../../../data/animal/animal-query';
import {
  getAnimalAgeInMonths,
  getAnimalLastWeightObj,
  generateBreedCompositionString,
  generateLocationString,
  generateSelectionString,
  generateDryWetStatusString,
  generatePEDString
} from './animal-utilities';
import {
  mapTypeInput, mapBreedingStatus, mapHornStatus, mapAnimalWeaningSatus
} from './animal-mapping-functions';
import AnimalCardTabs from './tabs/animal-card-tabs';
import AnimalPicBox from './animal-pic-box';
import { setAnimal } from '../../../data/redux/actions/animal-actions';

const AnimalCard = props => {
  const id = props.animalId;

  const generateVetisStatus = animal => {
    const vetisArr = animal.vetis;
    const vetisObj = animal.vetis[0];

    if (!vetisArr || vetisArr.length === 0 || !vetisObj || !vetisObj.animalVetisLID
      || !vetisObj.animalVetisDOB || !vetisObj.animalVetisSex
      || !vetisObj.color || !vetisObj.color.colorID || !vetisObj.animalVetisDamLID || !vetisObj.breed || !vetisObj.breed.breedsId) {
      return <span className="text-inf">Липсват данни</span>;
    }

    if (vetisObj.animalVetisLID !== animal.lID
      || `${vetisObj.animalVetisDOB}` !== `${animal.dateOfBirth}`
      || vetisObj.animalVetisSex !== animal.sex
      || !animal.color || vetisObj.color.colorID !== animal.color.colorID
      || !animal.dam || vetisObj.animalVetisDamLID !== animal.dam.lID
      || !animal.breed || vetisObj.breed.breedsId !== animal.breed.breedsId) {
      return <span className="text-accent">Грешки</span>;
    }
    return <span className="text-success">ОК</span>;
  };

  return (
    <Query {...props} query={GET_ANIMAL_DETAILS} variables={{ id, }}>
      {({ animal, }) => {
        props.setCurrentAnimal(animal);
        animal.ageInMonths = animal.dateOfBirth && getAnimalAgeInMonths(animal.dateOfBirth, animal.fateDate, animal.bornDead);
        animal.lastWeight = getAnimalLastWeightObj(animal);
        animal.formattedBreedComposition = generateBreedCompositionString(animal);
        animal.animalADG = animal.animalADG || [];
        const currentLocationAnimal = [];
        currentLocationAnimal.push(animal.curentLocation);

        return (
          <div>
            <div className="container-fluid container-s mb-4">
              <Row>
                <Col md="4">
                  <AnimalPicBox animal={animal} />
                </Col>
                <Col md="4">
                  <table className="table table-small">
                    <thead>
                      <tr>
                        <th colSpan={2}>Общи данни</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="col-head">ВЕТИС №</td>
                        <td>{animal.lID}</td>
                      </tr>
                      <tr>
                        <td className="col-head">ВИН №</td>
                        <td>{animal.vID}</td>
                      </tr>
                      <tr>
                        <td className="col-head">EID №</td>
                        <td>{animal.electronicId || '-'}</td>
                      </tr>
                      <tr>
                        <td className="col-head">Име</td>
                        <td>{animal.name}</td>
                      </tr>
                      <tr>
                        <td className="col-head">Паспорт №</td>
                        <td>{animal.passportNumber || '-'}</td>
                      </tr>
                      <tr>
                        <td className="col-head">ДР</td>
                        <td>
                          {DatesHelper.parseAndFormatDate(animal.dateOfBirth)}
                          {' '}
                          (
                          {animal.ageInMonths}
                          {' '}
                          м)
                        </td>
                      </tr>
                      <tr>
                        <td className="col-head">Пол / Кат.</td>
                        <td>
                          {animal.sex === 'MALE' ? 'М' : 'Ж'}
                          {' '}
                          (
                          {animal.aClass.className}
                          )
                        </td>
                      </tr>
                      <tr>
                        <td className="col-head">Порода</td>
                        <td>{animal.formattedBreedComposition}</td>
                      </tr>
                      <tr>
                        <td className="col-head">Рога</td>
                        <td>{mapHornStatus(animal.hornStatusID)}</td>
                      </tr>
                      <tr>
                        <td className="col-head">Цвят</td>
                        <td>{animal.color.colorName}</td>
                      </tr>
                      <tr>
                        <td className="col-head">Локация</td>
                        <td>{generateLocationString({ animalLocation: currentLocationAnimal, })}</td>
                      </tr>

                    </tbody>
                  </table>
                </Col>
                <Col md="4">
                  <table className="table table-small">
                    <thead>
                      <tr>
                        <th colSpan={2}>Други данни</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="col-head">Стадо</td>
                        <td>-</td>
                      </tr>
                      <tr>
                        <td className="col-head">Придобиване</td>
                        <td>{mapTypeInput(animal)}</td>
                      </tr>
                      <tr>
                        <td className="col-head">Собственост</td>
                        <td>{animal.animalOwner ? animal.animalOwner.firmName : '-'}</td>
                      </tr>
                      <tr>
                        <td className="col-head">Субсидии / Задържане</td>
                        <td>{generateSelectionString(animal)}</td>
                      </tr>
                      <tr>
                        <td className="col-head">Статус крава</td>
                        <td>{mapBreedingStatus(animal)}</td>
                      </tr>
                      <tr>
                        <td className="col-head">Статус теле</td>
                        <td>{mapAnimalWeaningSatus(animal.weaningStatus)}</td>
                      </tr>
                      <tr>
                        <td className="col-head">Лактираща</td>
                        <td>{generateDryWetStatusString(animal)}</td>
                      </tr>
                      <tr>
                        <td className="col-head">Инвентарен статус</td>
                        <td>
                          {animal.active ? 'Активна' : 'Неактивна'}
                          {' '}
                          {animal.forScrap === 'FORSCRAP' ? '| ЗА БРАК' : ''}
                        </td>
                      </tr>
                      <tr>
                        <td className="col-head">ВЕТИС статус</td>
                        <td>{generateVetisStatus(animal)}</td>
                      </tr>
                      <tr>
                        <td className="col-head">PED регистър</td>
                        <td>{generatePEDString(animal)}</td>
                      </tr>
                      <tr>
                        <td className="col-head">Баща</td>
                        <td>
                          {animal.sire && animal.sire.animalId
                            ? <Link className="text-inf" to={`/animals/list/${animal.sire.animalId}`}>{`${animal.sire.lID} ${generateBreedCompositionString(animal.sire)}`}</Link>
                            : <span className="text-inf">-</span>}
                        </td>
                      </tr>
                      <tr>
                        <td className="col-head">Майка</td>
                        <td>
                          {animal.dam && animal.dam.animalId
                            ? <Link className="text-inf" to={`/animals/list/${animal.dam.animalId}`}>{`${animal.dam.lID} ${generateBreedCompositionString(animal.dam)}`}</Link>
                            : <span className="text-inf">-</span>}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Col>
              </Row>
            </div>
            <AnimalCardTabs animal={animal} />
          </div>
        );
      }}
    </Query>
  );
};

export default connect((state: any) => ({ auth: state.auth, }), dispatch => bindActionCreators({ setCurrentAnimal: setAnimal, }, dispatch))(AnimalCard);
