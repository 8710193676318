/* eslint react/prop-types:0 */
/* eslint react/destructuring-assignment:0 */
import * as React from 'react';
import Query from '../../../../data/query';
import { GET_ANIMAL_VACCINE, GET_ANIMAL_TUBERCULOSIS_BRUCELLOSIS } from '../../../../data/animal/animal-query';
import VaccineTable from './health/vaccine-table/vaccine-table';
import AddVaccineModal from './health/vaccine-table/add-vaccine-modal';
import TuberBrucelTable from './health/tuber-brucel-table/tuber-brucel-table';

const HealthTab = props => {
  const id = props.currentAnimalId;
  return (
    <div className="tab">
      <div className="tab-head">
        <div className="d-flex align-items-center mb-4">
          <h3 className="mb-0">ЗДРАВЕ</h3>
          {/* <div className="d-flex ml-3">
            <strong className="ml-2 text-success">| ЗДРАВА |</strong>
          </div> */}
        </div>
      </div>
      <Query {...props} query={GET_ANIMAL_TUBERCULOSIS_BRUCELLOSIS} variables={{ id, }}>
        {({ animal, }) => {
          return (
            <div className="table-inner mb-5">
              <h4>ДПП | ТУБЕРКУЛОЗА | БРУЦЕЛОЗА | ВНД | СИН ЕЗИК</h4>
              <p className="mb-3"><small>Събития по държавна профилактична програма</small></p>

              <TuberBrucelTable animal={animal} />
            </div>
          );
        }}
      </Query>
      <Query {...props} query={GET_ANIMAL_VACCINE} variables={{ id, }}>
        {({ animal, }) => {
          return (
            <div className="table-inner mb-5">
              <h4>Ветеринарни | Лечение | Диагностика | Манипулации</h4>
              <p className="mb-3"><small>Тук добавяте всички ветеринарни събития!</small></p>
              <AddVaccineModal animalID={id} animaldateOfBirth={props.animaldateOfBirth} />
              <VaccineTable vaccine={animal.vaccine} animaldateOfBirth={props.animaldateOfBirth} />
            </div>
          );
        }}
      </Query>
    </div>
  );
};

export default HealthTab;
