/* eslint react/no-array-index-key:0 */
/* eslint react/destructuring-assignment:0 */
/* eslint react/prop-types:0 */
import * as React from 'react';

import DashboardCard from '../../../../lib/dashboard-card/dashboard-card';
import { mapWeightType } from '../../animal-mapping-functions';
import DatesHelper from '../../../../../tools/DatesHelper';
import DeleteWeightModal from './delete-weight-modal';
import EditWeightModal from './edit-weight-modal';


const WeightsTable = props => {
  const { animalADG, } = props.animal;

  const generateControlledWeightsTable = () => {
    return props.animal.weights.map((weightObj, i) => {
      const animalANGObj = animalADG.find(a => a.aNGdate === weightObj.dateWeight);

      return (
        <tr key={i}>
          <td>{DatesHelper.parseAndFormatDate(weightObj.dateWeight)}</td>
          <td>{weightObj.weight}</td>
          <td>{animalANGObj ? animalANGObj.animalANG.toFixed(2) : 0}</td>
          <td>{mapWeightType(weightObj.weightType)}</td>
          <td>{weightObj.comment}</td>
          <td className="d-flex justify-content-center">
            <DeleteWeightModal animalID={props.animal.animalId} weightID={weightObj.weightID} />
            <EditWeightModal animalID={props.animal.animalId} currentWeight={weightObj} animaldateOfBirth={props.animaldateOfBirth} />
          </td>
        </tr>
      );
    });
  };

  return (
    <DashboardCard className="overflow-auto" isLight>
      <div className="card-heading">
        <h5>
          <span className="text-success">ТЕГЛА</span>
          {' '}
          |
          {' '}
          {props.animal.lID}
          {' '}
          |
          {' '}
          {props.animal.vID}
          {' '}
          |
          {' '}
          {props.animal.breed.breedShort}
          {' '}
          |
          {' '}
          {props.animal.ageInMonths}
          {' '}
          |
          {' '}
          {props.animal.lastWeight.weight}
        </h5>
        <hr />
      </div>
      <table className="table">
        <thead>
          <tr>
            <th>Дата</th>
            <th>Тегло</th>
            <th>СДП</th>
            <th>Вид</th>
            <th>Пояснение / Бележка</th>
            <th>Инструменти</th>
          </tr>
        </thead>
        <tbody>
          {generateControlledWeightsTable()}
        </tbody>
      </table>
    </DashboardCard>
  );
};

export default WeightsTable;
