/* eslint-disable prefer-destructuring */
/* eslint no-restricted-globals:0 */
/* eslint no-nested-ternary:0 */
/* eslint max-len:0 */
import * as React from 'react';
import moment from 'moment';
import PregnancyTable from './pregnancy-table';
import Query from '../../../../data/query';
import { GET_PREGNANCY_DATA } from '../../../../data/reports/reports-query';
import {
  animalSexTranslation, getTranslation, dateFormatForTableFilters, reportAnimalSummary
} from '../../../../tools/function-helpers';
import { PREGNANCY_TYPES } from '../../../../tools/translation-constants';
import DatesHelper from '../../../../tools/DatesHelper';
import { generateLocationString } from '../../animals/animal-utilities';

const PregnancyReport = (props) => {
  return (
    <Query query={GET_PREGNANCY_DATA}>
      {({ animals, }) => {
        const mappedAnimals = animals.items.map((animal) => {
          const sexTranslation = animalSexTranslation(animal.sex);
          const animalChildrenLen = animal.children.length;
          const currentLocationAnimal = [];
          currentLocationAnimal.push(animal.curentLocation);

          const location = generateLocationString({ animalLocation: currentLocationAnimal, });

          const sortedAnimalGroup = animal.animalInService.length > 0
            ? animal.animalInService.sort((a, b) => {
              return new Date(a.group.dateCreate).getTime() - new Date(b.group.dateCreate).getTime();
            })
            : [];
          const lastService = sortedAnimalGroup[animal.animalInService.length - 1];
          const lastDateOfBirth = animalChildrenLen > 0
            ? animal.children[animalChildrenLen - 1].dateOfBirth
              ? animal.children[animalChildrenLen - 1].dateOfBirth
              : ''
            : '';
          const sortedPrEvents = animal.events.pregnancyEvents.sort(
            (a, b) => {
              const dateA = moment(a.pregnancyDate);
              const dateB = moment(b.pregnancyDate);
              return dateA.diff(dateB);
            }
          );
          const lastPrEvent = sortedPrEvents.length > 0 ? sortedPrEvents.at(-1) : '';
          const testPrdate = lastPrEvent && lastPrEvent.pregnancyDate ? lastPrEvent.pregnancyDate : '';
          const prdays = lastPrEvent && lastPrEvent.pregnancyDays ? lastPrEvent.pregnancyDays : null;
          const testPrResult = lastPrEvent && lastPrEvent.pregnancyCheck ? getTranslation(lastPrEvent.pregnancyCheck, PREGNANCY_TYPES) : null;
          const prInterval = animalChildrenLen > 0
            ? animalChildrenLen > 1
              ? DatesHelper.differenceBetweenDates(
                animal.children[animalChildrenLen - 1].dateOfBirth,
                animal.children[animalChildrenLen - 2].dateOfBirth
              )
              : 0
            : 0;
          return {
            ...animal,
            lastDateOfBirth: lastDateOfBirth ? dateFormatForTableFilters(lastDateOfBirth) : null,
            testPrDate: testPrdate ? dateFormatForTableFilters(testPrdate) : null,
            prdays,
            location,
            lastService,
            testPrResult,
            prInterval: isNaN(prInterval) ? '' : Math.abs(prInterval),
            animalSummary: reportAnimalSummary(animal, sexTranslation),
            animalChildrenLen,
          };
        });
        return <PregnancyTable {...props} animals={mappedAnimals} />;
      }}
    </Query>
  );
};

export default PregnancyReport;
