/* eslint no-shadow:0 */
/* eslint no-param-reassign:0 */
/* eslint react/destructuring-assignment:0 */
/* eslint no-unused-vars:0 */
/* eslint import/no-extraneous-dependencies:0 */
import * as React from 'react';
import {
  Chart,
  ChartLegend,
  ChartSeries,
  ChartSeriesItem
} from '@progress/kendo-react-charts';
import '@progress/kendo-react-popup';
import '@progress/kendo-react-inputs';
import '@progress/kendo-react-intl';
import '@progress/kendo-data-query';
import '@progress/kendo-drawing';
import { groupBy, uniqBy } from 'lodash';
import { Animal, Child } from '../../../../data/domains/Animal';


type RodeoChartCalvesProductionProps = {
    animals: Animal[]
}

const RodeoChartCalvesProduction = (props: RodeoChartCalvesProductionProps) => {
  const grouped = groupBy(props.animals.map(a => {
    a.children = uniqBy(a.children, (a: Child) => a.dateOfBirth);
    return a;
  }), a => a.children.length);

  const sumMost = grouped => {
    const nine = grouped['9'] ? grouped['9'].length : 0;
    const ten = grouped['10'] ? grouped['10'].length : 0;
    const eleven = grouped['11'] ? grouped['11'].length : 0;
    const twelve = grouped['12'] ? grouped['12'].length : 0;

    return nine + ten + eleven + twelve;
  };

  const seriesData = [{
    calving: '1',
    number: grouped['1']?.length,
  }, {
    calving: '2',
    number: grouped['2']?.length,
  }, {
    calving: '3',
    number: grouped['3']?.length,
  },
  {
    calving: '4',
    number: grouped['4']?.length,
  },
  {
    calving: '5',
    number: grouped['5']?.length,
  },
  {
    calving: '6',
    number: grouped['6']?.length,
  },
  {
    calving: '7',
    number: grouped['7']?.length,
  },
  {
    calving: '8',
    number: grouped['8']?.length,
  },

  {
    calving: '9+',
    number: sumMost(grouped) || null,
    color: '#dc3545',
  }];
  return (
    <div>
      <h5 className="text-center mb-4">Крави по брой отелвания</h5>
      <Chart>
        <ChartLegend position="bottom" />
        <ChartSeries>
          <ChartSeriesItem
            data={seriesData}
            type="pie"
            labels={{
              visible: true,
              content: e => {
                if (e.value) {
                  return `${e.value} / ${(e.percentage * 100).toFixed(2)}%`;
                }
                return e.category;
              },
            }}
            field="number"
            categoryField="calving"
          />
        </ChartSeries>
      </Chart>
    </div>
  );
};

export default RodeoChartCalvesProduction;
