/* eslint no-nested-ternary:0 */
/* eslint react/button-has-type:0 */
/* eslint max-len:0 */
/* eslint no-underscore-dangle:0 */
/* eslint react/destructuring-assignment:0 */
/* eslint react/no-access-state-in-setstate:0 */
import * as React from 'react';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Row, Col } from 'reactstrap';
import { Input } from '@progress/kendo-react-inputs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import Mutation from '../../../../data/mutation';
import {
  GET_FIRMS_IN_FARMS, EDIT_COMPANY, EDIT_ANIMAL_OBJECT, ADD_COMPANY, ADD_ANIMAL_OBJECT
} from '../../../../data/companies/companies-queries';
import { deepCopy, isActionTypeAndGetQuery } from '../../../../tools/function-helpers';

const getQuery = (type, action) => {
  if (type === 'company') {
    if (action === 'add') {
      return ADD_COMPANY;
    }
    return EDIT_COMPANY;
  }
  if (action === 'add') {
    return ADD_ANIMAL_OBJECT;
  }
  return EDIT_ANIMAL_OBJECT;
};


class CompaniesTableModal extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      dataItem: props.dataItem,
      submitDisabled: true,
    };
    this.toggleDialog = this.toggleDialog.bind(this);
    this.handleFormChange = this.handleFormChange.bind(this);
  }

  toggleDialog() {
    this.setState({
      visible: !this.state.visible,
    });

    if (!this.state.visible) {
      this.resetState();
    }
  }


  handleFormChange(e) {
    const objCopy = deepCopy(this.state.dataItem);
    let isFormSubmitable = false;
    delete objCopy.__typename;

    if (this.props.type === 'company') {
      delete objCopy.childrenObject;
      if (e.target.value !== '') {
        objCopy[e.target.name] = e.target.value;
      } else {
        delete objCopy[e.target.name];
      }

      if (this.props.action === 'add') {
        isFormSubmitable = !(Object.keys(objCopy).length > 5);
      } else {
        isFormSubmitable = !(Object.keys(objCopy).length > 6);
      }
    } else {
      if (e.target.name === 'firmID') {
        objCopy[e.target.name] = e.target.value.firmID;
      } else {
        objCopy[e.target.name] = e.target.value;
      }
      isFormSubmitable = !(objCopy.animalObjectNumber && objCopy.animalObjectNumber !== '');
    }
    this.setState({
      dataItem: objCopy,
      submitDisabled: isFormSubmitable,
    });
  }

  handleSubmit(e, mutationTrigger) {
    e.preventDefault();

    const objCopy = { ...this.state.dataItem, };
    if (this.props.type === 'child') {
      delete objCopy.parentFirm;
    }
    this.toggleDialog();
    mutationTrigger({
      variables:
            {
              dataItem: objCopy,
            },
    });
  }

  resetState() {
    const copyData = { ...this.props.dataItem, };
    this.setState({
      dataItem: copyData,
      submitDisabled: true,
    });
  }

  render() {
    const getParentFirmsDropdown = () => {
      if (this.state.dataItem.firmID || this.props.dataItem.parentFirm) {
        return this.props.parentFirms.find((item) => {
          return this.state.dataItem.firmID ? item.firmID === this.state.dataItem.firmID : item.firmID === this.props.dataItem.parentFirm.firmID;
        });
      }
      return null;
    };
    const isAddActionType = isActionTypeAndGetQuery(this.props.action, 'add', '', '');
    return (
      <Mutation
        mutation={getQuery(this.props.type, this.props.action)}
        awaitRefetchQueries
        refetchQueries={[{ query: GET_FIRMS_IN_FARMS, variables: {}, }]}
      >
        {(mutationTrigger) => (
          <div className="d-inline-block">
            <button className={isAddActionType.isActionType ? 'btn btn-primary mr-2' : 'button-icon'} onClick={this.toggleDialog}>{isAddActionType.isActionType ? this.props.type === 'company' ? 'Добави фирма' : 'Добави Ж.О.' : <FontAwesomeIcon icon="pen" />}</button>
            {this.state.visible && (
            <form onSubmit={e => this.handleSubmit(e, mutationTrigger)}>
              <Dialog title={`${isAddActionType.isActionType ? 'Добави' : 'Редактирай'} ${this.props.type === 'company' ? 'фирма' : 'животновъден обект'}`} onClose={this.toggleDialog} minWidth="500px" height="70%">
                <Row>
                  {this.props.type === 'company' && (
                  <Col lg={{ size: 8, offset: 2, }}>
                    <div className="form-group">
                      <label>Име</label>
                      <Input type="text" name="firmName" value={this.state.dataItem.firmName ? this.state.dataItem.firmName : ''} onChange={this.handleFormChange} />
                    </div>
                    <div className="form-group">
                      <label>ЕИК/ЕГН</label>
                      <Input type="text" name="firmEIK" value={this.state.dataItem.firmEIK ? this.state.dataItem.firmEIK : ''} onChange={this.handleFormChange} />
                    </div>
                    <div className="form-group">
                      <label>Страна</label>
                      <Input type="text" name="firmCountry" value={this.state.dataItem.firmCountry ? this.state.dataItem.firmCountry : ''} onChange={this.handleFormChange} />
                    </div>
                    <div className="form-group">
                      <label>Град/Село</label>
                      <Input type="text" name="firmCity" value={this.state.dataItem.firmCity ? this.state.dataItem.firmCity : ''} onChange={this.handleFormChange} />
                    </div>
                    <div className="form-group">
                      <label>Адрес</label>
                      <Input type="text" name="firmAddress" value={this.state.dataItem.firmAddress ? this.state.dataItem.firmAddress : ''} onChange={this.handleFormChange} />
                    </div>
                    <div className="form-group">
                      <label>МОЛ</label>
                      <Input type="text" name="firmMOL" value={this.state.dataItem.firmMOL ? this.state.dataItem.firmMOL : ''} onChange={this.handleFormChange} />
                    </div>
                  </Col>
                  )}
                  {this.props.type === 'child' && (
                  <Col lg={{ size: 8, offset: 2, }}>
                    <div className="form-group">
                      <label>Име/Местност</label>
                      <Input type="text" name="animalObjectName" value={this.state.dataItem.animalObjectName ? this.state.dataItem.animalObjectName : ''} onChange={this.handleFormChange} />
                    </div>
                    <div className="form-group">
                      <label>Кратко име</label>
                      <Input type="text" name="animalObjectShortName" value={this.state.dataItem.animalObjectShortName ? this.state.dataItem.animalObjectShortName : ''} onChange={this.handleFormChange} />
                    </div>
                    <div className="form-group">
                      <label>Номер</label>
                      <Input type="text" name="animalObjectNumber" value={this.state.dataItem.animalObjectNumber ? this.state.dataItem.animalObjectNumber : ''} onChange={this.handleFormChange} required />
                    </div>
                    <div className="form-group">
                      <label>Адрес</label>
                      <Input type="text" name="animalObjectAddress" value={this.state.dataItem.animalObjectAddress ? this.state.dataItem.animalObjectAddress : ''} onChange={this.handleFormChange} />
                    </div>
                    <div className="form-group">
                      <label>Локация</label>
                      <Input type="text" name="animalObjectGeoLocation" value={this.state.dataItem.animalObjectGeoLocation ? this.state.dataItem.animalObjectGeoLocation : ''} onChange={this.handleFormChange} />
                    </div>
                    <div className="form-group">
                      <label>Собственик:</label>
                      <DropDownList
                        data={this.props.parentFirms}
                        dataItemKey="firmID"
                        textField="firmName"
                        name="firmID"
                        value={getParentFirmsDropdown()}
                        onChange={this.handleFormChange}
                        required
                      />
                    </div>
                  </Col>
                  )}
                </Row>
                <DialogActionsBar>
                  <div className="d-flex">
                    <button className="btn btn-secondary" onClick={this.toggleDialog}>Отказ</button>
                    <button type="submit" disabled={this.state.submitDisabled} className="btn btn-primary ml-3">{isAddActionType.isActionType ? 'Добави' : 'Редактирай'}</button>
                  </div>
                </DialogActionsBar>
              </Dialog>
            </form>
            )}
          </div>
        )}
      </Mutation>
    );
  }
}

export default CompaniesTableModal;
