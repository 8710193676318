/* eslint react/button-has-type:0 */
/* eslint react/no-did-update-set-state:0 */
/* eslint react/no-access-state-in-setstate:0 */
/* eslint react/no-unused-state:0 */
/* eslint no-underscore-dangle:0 */
/* eslint react/sort-comp:0 */
/* eslint react/prop-types:0 */
/* eslint react/destructuring-assignment:0 */
/* eslint max-len:0 */
import * as React from 'react';
import {
  Grid, GridColumn as Column, GridToolbar, GridColumnMenuFilter
} from '@progress/kendo-react-grid';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { withLocalize } from 'react-localize-redux';
import { filterBy, orderBy } from '@progress/kendo-data-query';

import { NavLink } from 'react-router-dom';
import {
  LocalizationProvider, loadMessages, load, IntlProvider
} from '@progress/kendo-react-intl';
import CustomColumnModal from '../../../custom-column/custom-column';

import {
  TABLE_BG_MESSAGES, bgDateFields, bgGregorian, weekData, numbers, COUNTRIES
} from '../../../../tools/translation-constants';
import { dropdownFilterCell } from '../../../../tools/dropdown-filter-cell';
import {
  dateFormatForTableFilters, reportAnimalSummary, animalSexTranslation, selectedValue, filterFarmClients
} from '../../../../tools/function-helpers';

import WarehouseTableModal from './warehouse-table-modal';

import Query from '../../../../data/query';
import { GET_SIRES_AND_DAMS } from '../../../../data/animal/animal-query';

load(
  weekData,
  numbers,
  bgDateFields,
  bgGregorian
);

loadMessages(TABLE_BG_MESSAGES, 'bg-BG');

const PAGE_TAKE = 10;

const DataCell = (props) => <td><NavLink className="link-color-primary" to={`/animals/list/${props.dataItem.animalID}`}>{props.dataItem.animalSummary ? props.dataItem.animalSummary : '(празно)'}</NavLink></td>;

const FARM_CLIENT_TYPES = {
  semen: ['Farm', 'Vet', 'AnimalDealer', 'AnimalProvider', 'AnimalEmployee', 'Other'],
};

const mappedAnimals = (animals) => {
  return animals.map((animal) => {
    return {
      ...animal,
      dateInput: animal.dateInput ? dateFormatForTableFilters(animal.dateInput) : null,
      animalSummary: reportAnimalSummary(animal.animal[0], animalSexTranslation(animal.animal[0].sex)),
      animalBreedName: animal.animal[0].breed ? animal.animal[0].breed.breedName : '',
      countryFromTranslation: animal.animal[0].countryFrom ? selectedValue(animal.animal[0].countryFrom, COUNTRIES, 'id').text : '',
    };
  });
};


class WarehouseTable extends React.Component<any, any> {
    _export;

    export = () => {
      this._export.save();
    };

    constructor(props) {
      super(props);
      this.state = {
        animals: mappedAnimals(props.animals.items),
        breeds: props.breeds,
        farmClients: filterFarmClients(props.farmClients, FARM_CLIENT_TYPES.semen),
        filter: undefined,
        sort: [],
        sortable: {
          allowUnsort: true,
          mode: 'single',
        },
        skip: 0,
        take: PAGE_TAKE,
        pageSize: PAGE_TAKE,
        pageable: {
          buttonCount: 5,
          info: true,
          type: 'numeric',
          previousNext: true,
          pageSizes: [5, 10, 20, props.animals.items.length],
        },
        columns: {
          animalSummary: {
            title: 'Бик',
            visible: true,
          },
          semenCode: {
            title: 'Код Сперма',
            visible: true,
          },
          semenID: {
            title: 'Сперма id',
            visible: false,
          },
          animalBreedName: {
            title: 'Порода',
            visible: true,
          },
          countryFromTranslation: {
            title: 'Произход Държава',
            visible: true,
          },
          farmClientName: {
            title: 'Доставчик Фирма',
            visible: true,
          },
          dateInput: {
            title: 'Дата на придобиване',
            visible: true,
          },
          semenQuantity: {
            title: 'Количество',
            visible: true,
          },
          price: {
            title: 'Ед. Цена',
            visible: true,
          },
          container: {
            title: 'Съд',
            visible: true,
          },
          canister: {
            title: 'Канистър',
            visible: true,
          },
        },
      };
      this.pageChange = this.pageChange.bind(this);
      this.updateTableVisibleCols = this.updateTableVisibleCols.bind(this);
    }

    pageChange = (event) => {
      this.setState({
        skip: event.page.skip,
        take: event.page.take,
        pageSize: event.page.take,
      });
    };

    filterChange = (event) => {
      this.setState({
        animals: filterBy(mappedAnimals(this.props.animals.items), event.filter),
        filter: event.filter,
        skip: 0,
        tate: PAGE_TAKE,
      });
    };

    sortChange = (event) => {
      this.setState({
        animals: orderBy(this.state.animals, event.sort),
        sort: event.sort,
      });
    };

    updateTableVisibleCols = (columns) => {
      this.setState({
        columns,
      });
    };

    componentDidUpdate(prevProps) {
      if (this.props.animals.items !== prevProps.animals.items) {
        this.setState({
          animals: mappedAnimals(this.props.animals.items),
        });
      }
    }

    render() {
      return (
        <Query query={GET_SIRES_AND_DAMS}>
          {({ sires, }) => (
            <div className="container-fluid">
              <h3 className="mb-4">Списък на семенния материал във фермата</h3>

              <WarehouseTableModal
                action="add"
                dataItem={{}}
                sires={sires.items}
              />

              <LocalizationProvider language="bg-BG">
                <IntlProvider locale="bg">
                  <ExcelExport
                    data={this.state.animals}
                    fileName="animals-semen.xlsx"
                    ref={exporter => { this._export = exporter; }}
                  >
                    <Grid
                      data={this.state.animals.slice(this.state.skip, this.state.take + this.state.skip)}
                      className="table table-advanced"
                      skip={this.state.skip}
                      total={this.state.animals.length}
                      onPageChange={this.pageChange}
                      onFilterChange={this.filterChange}
                      filter={this.state.filter}
                      filterable
                      pageable={this.state.pageable}
                      pageSize={this.state.pageSize}
                      onSortChange={this.sortChange}
                      sort={this.state.sort}
                      sortable={this.state.sortable}
                      resizable
                      reorderable
                    >
                      <GridToolbar>
                        <div className="d-flex justify-content-between align-items-center">
                          <span className="list-counter">
                            Брой Животни:
                            {this.state.animals.length}
                          </span>
                          <div className="d-flex d-inline-block">
                            <CustomColumnModal columns={this.state.columns} updateTableVisibleCols={this.updateTableVisibleCols} />
                            <button
                              title="Export Excel"
                              className="btn btn-secondary ml-2"
                              onClick={this.export}
                            >
                              Експорт в Excel
                            </button>
                          </div>
                        </div>
                      </GridToolbar>
                      {this.state.columns.semenID.visible && <Column field="semenID" title="Сперма ID" width="170px" />}
                      {this.state.columns.animalSummary.visible && <Column field="animalSummary" title="Бик" width="240px" cell={(props) => <DataCell {...props} />} />}
                      {this.state.columns.semenCode.visible && <Column field="semenCode" title="Код Сперма" width="170px" />}
                      {this.state.columns.animalBreedName.visible && <Column field="animalBreedName" title="Порода" width="160px" filterCell={(props) => dropdownFilterCell(props, this.props.breeds)} />}
                      {this.state.columns.countryFromTranslation.visible && <Column field="countryFromTranslation" title="Произход Държава" width="230px" filterCell={(props) => dropdownFilterCell(props, COUNTRIES.map(c => c.text))} />}
                      {this.state.columns.farmClientName.visible && <Column field="supplier.farmClientName" title="Доставчик Фирма" width="220px" filterCell={(props) => dropdownFilterCell(props, this.state.farmClients.map(f => f.farmClientName))} />}
                      {this.state.columns.dateInput.visible && <Column field="dateInput" filter="date" format="{0:dd.MM.yyy}" title="Дата на придобиване" width="250px" columnMenu={props => <GridColumnMenuFilter {...props} />} />}
                      {this.state.columns.semenQuantity.visible && <Column field="semenQuantity" title="Количество" width="170px" filter="numeric" columnMenu={props => <GridColumnMenuFilter {...props} />} />}
                      {this.state.columns.price.visible && <Column field="price" title="Ед. Цена" width="130px" filter="numeric" columnMenu={props => <GridColumnMenuFilter {...props} />} />}
                      {this.state.columns.container.visible && <Column field="container" title="Съд" width="120px" />}
                      {this.state.columns.canister.visible && <Column field="canister" title="Канистър" width="120px" />}
                      {this.state.columns.animalSummary.visible && <Column field="123" title="Редакция" width="120px" cell={(props) => <WarehouseTableModal {...props} sires={sires.items} />} />}
                    </Grid>
                  </ExcelExport>
                </IntlProvider>
              </LocalizationProvider>
            </div>
          )}
        </Query>
      );
    }
}

export default withLocalize(WarehouseTable);
