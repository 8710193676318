import * as React from 'react';
import { Row, Col } from 'reactstrap';
import { Input } from '@progress/kendo-react-inputs';
import { DropDownList } from '@progress/kendo-react-dropdowns';

import TabNavButtons from '../../tab-nav-buttons';
import {
  MALEACLASSES, FEMALEACLASSES, SEXES, EID_TYPES, HORN_STATUSES, TYPE_INPUTS, VETIS_PREFIX_TYPES, COUNTRIES
} from '../../../../../../../tools/translation-constants';
import DatesHelper from '../../../../../../../tools/DatesHelper';
import BreedcompModal from '../breedcomp-modal';
import { generateBreedCompositionString } from '../../../../animal-utilities';
import { selectedValue } from '../../../../../../../tools/function-helpers';
import { DatePicker } from '../../../../../../utils/date-picker';


class GroupDataTab extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      animal: props.getGroupObject(),
      updateGroupObject: props.updateGroupObject,
    };
    this.handleFormChange = this.handleFormChange.bind(this);
    this.addBreedComposition = this.addBreedComposition.bind(this);
    this.getAnimalBreedComposition = this.getAnimalBreedComposition.bind(this);
  }

  getAnimalBreedComposition() {
    const { animal, } = this.state;
    return animal.breedComposition ? animal.breedComposition : [];
  }

  addBreedComposition(breedComposition) {
    const { animal, updateGroupObject, } = this.state;
    const objCopy = { ...animal, };
    objCopy.breedComposition = breedComposition.breedComposition;
    this.setState({
      animal: objCopy,
    });
    updateGroupObject(objCopy);
  }


  handleFormChange(e) {
    const { animal, updateGroupObject, } = this.state;

    const objCopy = { ...animal, };
    if (e.target.name === 'classID') {
      objCopy[e.target.name] = e.target.value.id;
    } else if (e.target.name === 'breedsID') {
      objCopy[e.target.name] = e.target.value.breedsId;
    } else if (e.target.name === 'colorID') {
      objCopy[e.target.name] = e.target.value.colorID;
    } else if (e.target.name === 'dateOfBirthNumber') {
      objCopy.dateOfBirth = DatesHelper.subtractMonthsFromDate(new Date().toDateString(), e.target.value);
      objCopy[e.target.name] = e.target.value;
    } else if (e.target.name === 'firmID') {
      objCopy[e.target.name] = e.target.value.firmID;
    } else if (e.target.name === 'registrationDate') {
      objCopy[e.target.name] = e.target.value;
      objCopy.animalLocation[0].moveDate = e.target.value;
    } else {
      const { value, } = e.target;
      if (value && typeof value === 'object' && value.constructor === Object) {
        objCopy[e.target.name] = value.id;
      } else {
        objCopy[e.target.name] = value;
      }
    }
    if (e.target.name === 'numberAnimals' && animal.animalPurchase[0].pricePerHead) {
      objCopy.animalPurchase[0].priceTotal = parseFloat((e.target.value * animal.animalPurchase[0].pricePerHead).toFixed(2));
    }

    this.setState({
      animal: objCopy,
    });
    updateGroupObject(objCopy);
  }

  render() {
    const { animal, } = this.state;
    const { firmsInFarms, breeds, color, } = this.props;

    const { sex, classID, } = animal;
    let dropdown;
    if (sex === 'FEMALE') {
      dropdown = (
        <DropDownList
          style={{ width: '100%', }}
          data={FEMALEACLASSES}
          dataItemKey="id"
          textField="text"
          name="classID"
          onChange={this.handleFormChange}
          value={classID ? selectedValue(classID, FEMALEACLASSES, 'id') : ''}
        />
      );
    } else if (sex === 'MALE') {
      dropdown = (
        <DropDownList
          style={{ width: '100%', }}
          data={MALEACLASSES}
          dataItemKey="id"
          textField="text"
          name="classID"
          onChange={this.handleFormChange}
          value={classID ? selectedValue(classID, MALEACLASSES, 'id') : ''}
        />
      );
    } else {
      dropdown = (
        <DropDownList
          disabled
          style={{ width: '100%', }}
        />
      );
    }
    return (
      <div className="tab">
        <form>
          <h3 className="mb-2">ДАННИ ЗА ГРУПОВОТО ВПИСВАНЕ</h3>
          <p className="mb-2">
            Въведете данни за групата, която вписвате. При вписване над 10 животни с различни белези. Направете еднородни групи за удобство!
          </p>
          <Row>
            <Col className="d-flex flex-column justify-content-end" lg="2">
              <div className="form-group">
                <label>Брой животни</label>
                <Input
                  type="number"
                  min="0"
                  name="numberAnimals"
                  onChange={this.handleFormChange}
                  value={animal.numberAnimals ? animal.numberAnimals : ''}
                />
              </div>
            </Col>
            <Col className="d-flex flex-column justify-content-end" lg="2">
              <div className="form-group">
                <label>Възраст в месеци</label>
                <Input
                  type="number"
                  min="0"
                  name="dateOfBirthNumber"
                  onChange={this.handleFormChange}
                  value={animal.dateOfBirthNumber ? animal.dateOfBirthNumber : ''}
                />
              </div>
            </Col>
            <Col className="d-flex flex-column justify-content-end" lg="2">
              <div className="form-group">
                <label>Пол</label>
                <DropDownList
                  style={{ width: '100%', }}
                  data={SEXES}
                  dataItemKey="id"
                  textField="text"
                  name="sex"
                  onChange={this.handleFormChange}
                  value={animal.sex ? selectedValue(animal.sex, SEXES, 'id') : ''}
                />
              </div>
            </Col>
            <Col className="d-flex flex-column justify-content-end" lg="2">
              <div className="form-group">
                <label>Категория</label>
                {dropdown}
              </div>
            </Col>
            <Col className="d-flex flex-column justify-content-end" lg="2">
              <div className="form-group">
                <label>Дата заприхождаване</label>
                <DatePicker
                  name="registrationDate"
                  onChange={this.handleFormChange}
                  value={animal?.registrationDate}
                />
              </div>
            </Col>
            <Col className="d-flex flex-column justify-content-end" lg="2">
              <div className="form-group">
                <label>Вид заприхождаване</label>
                <DropDownList
                  style={{ width: '100%', }}
                  data={TYPE_INPUTS}
                  dataItemKey="id"
                  textField="text"
                  name="typeInput"
                  onChange={this.handleFormChange}
                  value={animal.typeInput ? selectedValue(animal.typeInput, TYPE_INPUTS, 'id') : ''}
                />
              </div>
            </Col>

            <Col className="d-flex flex-column justify-content-end" lg="2">
              <div className="form-group">
                <label>Собственик</label>
                <DropDownList
                  style={{ width: '100%', }}
                  data={firmsInFarms.items}
                  dataItemKey="firmID"
                  textField="firmName"
                  name="firmID"
                  onChange={this.handleFormChange}
                  value={animal.firmID ? selectedValue(animal.firmID, firmsInFarms.items, 'firmID') : ''}
                />
              </div>
            </Col>

          </Row>

          <h3 className="mb-2">ДАННИ ЗА ИДЕНТИФИКАЦИЯ</h3>
          <p className="mb-2">Данни за идентификация!</p>
          <Row>
            <Col className="d-flex flex-column justify-content-end" lg="3">
              <div className="form-group">
                <label>Префикс</label>
                <Row>
                  <Col className="d-flex flex-column justify-content-end" lg="4">
                    <div>
                      <DropDownList
                        style={{ width: '100%', }}
                        data={VETIS_PREFIX_TYPES}
                        dataItemKey="id"
                        textField="text"
                        name="vetisPrefix"
                        onChange={this.handleFormChange}
                        value={animal.vetisPrefix ? selectedValue(animal.vetisPrefix, VETIS_PREFIX_TYPES, 'id') : ''}
                      />
                    </div>
                  </Col>
                  <Col className="d-flex flex-column justify-content-end" lg="8">
                    <div>
                      <Input name="lID" onChange={this.handleFormChange} value={animal.lID ? animal.lID : ''} />
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col className="d-flex flex-column justify-content-end" lg="2">
              <div className="form-group">
                <label>ВИН №</label>
                <Input name="vID" onChange={this.handleFormChange} value={animal.vID ? animal.vID : ''} />
              </div>
            </Col>
            <Col className="d-flex flex-column justify-content-end" lg="2">
              <div className="form-group">
                <label>EID №</label>
                <Input
                  name="electronicId"
                  onChange={this.handleFormChange}
                  value={animal.electronicId ? animal.electronicId : ''}
                />
              </div>
            </Col>
            <Col className="d-flex flex-column justify-content-end" lg="2">
              <div className="form-group">
                <label>Вид EID</label>
                <DropDownList
                  style={{ width: '100%', }}
                  data={EID_TYPES}
                  dataItemKey="id"
                  textField="text"
                  name="eIDType"
                  onChange={this.handleFormChange}
                  value={animal.eIDType ? selectedValue(animal.eIDType, EID_TYPES, 'id') : ''}
                />
              </div>
            </Col>
            <Col className="d-flex flex-column justify-content-end" lg="2">
              <div className="form-group">
                <label>Име</label>
                <Input name="name" onChange={this.handleFormChange} value={animal.name ? animal.name : ''} />
              </div>
            </Col>
          </Row>

          <h3 className="mb-2">ДАННИ ЗА ПОРОДАТА</h3>
          <p className="mb-2">Породни данни, композит, регистър, цвят!</p>
          <Row>
            <Col className="d-flex flex-column justify-content-end" lg="2">
              <div className="form-group">
                <label>Порода</label>
                <DropDownList
                  style={{ width: '100%', }}
                  data={breeds}
                  dataItemKey="breedsId"
                  textField="breedName"
                  name="breedsID"
                  onChange={this.handleFormChange}
                  value={animal.breedsID ? selectedValue(animal.breedsID, breeds, 'breedsId') : ''}
                />
              </div>
            </Col>
            <Col className="d-flex flex-column justify-content-end" lg="2">
              <div className="form-group">
                <label>Породен композит</label>
                <div className="d-flex justify-content-between">
                  <span className="align-self-center">
                    {animal.breedComposition ? generateBreedCompositionString(animal) : '-'}
                  </span>
                  <BreedcompModal
                    breeds={breeds}
                    addBreedComposition={this.addBreedComposition}
                    getAnimalBreedComposition={this.getAnimalBreedComposition}
                  />
                </div>
              </div>
            </Col>
            <Col className="d-flex flex-column justify-content-end" lg="2">
              <div className="form-group">
                <label>Произход държава</label>
                <DropDownList
                  style={{ width: '100%', }}
                  data={COUNTRIES}
                  dataItemKey="id"
                  textField="text"
                  name="countryFrom"
                  onChange={this.handleFormChange}
                  value={animal.countryFrom ? selectedValue(animal.countryFrom, COUNTRIES, 'id') : ''}
                />
              </div>
            </Col>
            <Col className="d-flex flex-column justify-content-end" lg="2">
              <div className="form-group">
                <label>Цвят</label>
                <DropDownList
                  style={{ width: '100%', }}
                  data={color}
                  dataItemKey="colorID"
                  textField="colorName"
                  name="colorID"
                  onChange={this.handleFormChange}
                  value={animal.colorID ? selectedValue(animal.colorID, color, 'colorID') : ''}
                />
              </div>
            </Col>
            <Col className="d-flex flex-column justify-content-end" lg="2">
              <div className="form-group">
                <label>Рога</label>
                <DropDownList
                  style={{ width: '100%', }}
                  data={HORN_STATUSES}
                  dataItemKey="id"
                  textField="text"
                  name="hornStatusID"
                  onChange={this.handleFormChange}
                  value={animal.hornStatusID ? selectedValue(animal.hornStatusID, HORN_STATUSES, 'id') : ''}
                />
              </div>
            </Col>
          </Row>

          <TabNavButtons
            parentProps={this.props}
          />
        </form>
      </div>
    );
  }
}

export default GroupDataTab;
