/* eslint react/destructuring-assignment:0 */
import * as React from 'react';
import { ComboBox } from '@progress/kendo-react-dropdowns';
import { filterBy } from '@progress/kendo-data-query';

class CustomComboBox extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      dataItems: props.data,
    };
  }

  filterChange = (event) => {
    this.setState({
      dataItems: this.filterData(event.filter),
    });
  }

  filterData(filter) {
    const data = this.props.data.slice();
    return filterBy(data, filter);
  }

  render() {
    return (
      <ComboBox
        style={{ width: '100%', }}
        name={this.props.name}
        data={this.state.dataItems}
        filterable
        className={this.props.className}
        textField={this.props.textField}
        dataItemKey={this.props.dataItemKey}
        onFilterChange={this.filterChange}
        onChange={this.props.onChange}
        value={this.props.value}
        disabled={this.props.disabled}
      />
    );
  }
}

export default CustomComboBox;
