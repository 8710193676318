import * as React from 'react';
import DeadBornReportTable from './dead-born-report-table';
import Query from '../../../../data/query';
import { GET_DEAD_BORN_ANIMALS } from '../../../../data/reports/reports-query';
import {
  animalSexTranslation, dateFormatForTableFilters, getTranslation, reportAnimalSummary
} from '../../../../tools/function-helpers';
import { STO_TYPES, STO_TYPE_TYPES } from '../../../../tools/translation-constants';

const DeadBornReport = props => {
  return (
    <Query query={GET_DEAD_BORN_ANIMALS}>
      {({ animalsBornDead, }) => {
        const mappedAnimals = animalsBornDead.items.map((animal) => {
          const birthEventsLen = animal.events.birthEvents.length;
          return {
            ...animal,
            dateOfBirth: animal.dateOfBirth ? dateFormatForTableFilters(animal.dateOfBirth) : '',
            comment: birthEventsLen > 0 ? animal.events.birthEvents[0].comment : '',
            damSummary: animal.dam ? reportAnimalSummary(animal.dam, animalSexTranslation(animal.dam.sex)) : '',
            sireSummary: animal.sire ? reportAnimalSummary(animal.sire, animalSexTranslation(animal.sire.sex)) : '',
            sTO: birthEventsLen > 0 ? getTranslation(animal.events.birthEvents[0].sTO, STO_TYPES) : '',
            sTOTypeID: birthEventsLen > 0 ? getTranslation(animal.events.birthEvents[0].sTOTypeID, STO_TYPE_TYPES) : '',
          };
        });
        const StoTypes = STO_TYPES.map(sto => sto.text);
        const StoTypeTypes = STO_TYPE_TYPES.map(stoType => stoType.text);
        return (
          <DeadBornReportTable {...props} animals={mappedAnimals} stoTypes={StoTypes} stoTypeTypes={StoTypeTypes} />
        );
      }}
    </Query>
  );
};

export default DeadBornReport;
