/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
/* eslint-disable no-plusplus */
/* eslint no-shadow:0 */
/* eslint no-param-reassign:0 */
/* eslint no-return-assign:0 */
/* eslint no-unused-vars:0 */
/* eslint import/no-extraneous-dependencies:0 */
import * as React from 'react';
import { Spreadsheet } from '@progress/kendo-spreadsheet-react-wrapper';
import { Row, Col } from 'reactstrap';
// import { Upload } from '@progress/kendo-react-upload';
// import moment from 'moment';
// import { ADD_ANIMAL_GROUP_WEIGHTS } from '../../../data/groups/groups-query';
// import Mutation from '../../../data/mutation';
import DatesHelper from '../../../../tools/DatesHelper';
// import { animalSexTranslation, reportAnimalSummary } from '../../../../tools/function-helpers';
import 'moment-timezone';
// import { AnimalWeightEnum } from '../animals/animal-enums';
import GroupWeightingModal from './group-weighting-modal';
import {
  SHEET_TITLE, VALIDATION_TYPE, VALIDATION_DATA_TYPE, VALIDATION_MESSAGES,
  INVALID_CELL_VALUE, HEADER_COMMON_SETTINGS, COLUMNS_SETTINGS, COLUMN_WIDTHS, CELL_COMMON_SETTINGS,
  generateAnimalSummary, checkValidCell, generateDropdownData
} from './group-weighting-helpers';
import { AnimalWeightEnum } from '../../animals/animal-enums';

// Code for spreadsheet file upload
let spreadsheet = null;
// const upload = null;
// const workbookConverted = null;
let spreadSheetData = [];

const AnimalWeightTypes = [
  'Контролно тегло',
  'Тегло на отелване',
  'Тегло на отбиване'
];

const getWeightTypeEnumValue = (weightTypeStr: string): string => {
  const [CONTROL_WEIGHT, BIRTH_WEIGHT, WEANING_WEIGHT] = AnimalWeightTypes;
  if (weightTypeStr === CONTROL_WEIGHT) return AnimalWeightEnum.controlWeight();
  if (weightTypeStr === BIRTH_WEIGHT) return AnimalWeightEnum.birthWeight();
  if (weightTypeStr === WEANING_WEIGHT) return AnimalWeightEnum.weaningWeight();
  return AnimalWeightEnum.controlWeight();
};

const EventsGroupWeighting = props => {
  // Code for spreadsheet file upload
  // const VALID_COLS_LOOKUP = [
  //   { originalName: 'Electronic Id', name: 'EID №', },
  //   { originalName: 'Animal Tag', name: 'ВИН №', },
  //   { originalName: 'NLIS', name: 'ВЕТИС №', },
  //   { originalName: 'weight', name: 'Тегло', }
  // ];
  // const VALID_COLS = VALID_COLS_LOOKUP.map(c => c.originalName);

  // const invalidLIDsConcat = invalidLIDs => invalidLIDs.map(a => a.lID).join(', ');

  // const [invalidLIDs, setInvalidLIDs] = React.useState([]);
  const SpreadsheetCustom = props => <Spreadsheet ref={sp => spreadsheet = sp} {...props} />;

  const NUMBER_OF_ROWS = 200;

  const START_ROW = 4;

  const START_ROW_INDEX = 3;

  const dataForSend = Array(NUMBER_OF_ROWS).fill({
    lID: null,
    weight: null,
    comment: null,
    dateWeight: null,
    weightType: null,
  });

  const dropdownsData = generateDropdownData(props.animals);

  let invalidRows = [];

  const generateRows = () => {
    const rows = [];
    const data = spreadSheetData;

    for (let i = 0; i < NUMBER_OF_ROWS; i++) {
      const index = i;

      rows.push(
        {
          cells: [
            {
              ...CELL_COMMON_SETTINGS,
              validation: {
                dataType: VALIDATION_DATA_TYPE.List,
                comparerType: VALIDATION_DATA_TYPE.List,
                from: dropdownsData.allData,
                allowNulls: true,
                type: VALIDATION_TYPE.Reject,
                titleTemplate: VALIDATION_MESSAGES.animalData.title,
                messageTemplate: VALIDATION_MESSAGES.animalData.message,
                showButton: true,
              },
              ...((data[index] && data[index][0] && data[index][0].value) && { value: data[index][0].value, }),
            },
            {
              ...CELL_COMMON_SETTINGS,
              textAlign: 'right',
              validation: {
                dataType: VALIDATION_DATA_TYPE.Custom,
                from: `AND(ISNUMBER(B4:B${NUMBER_OF_ROWS + START_ROW_INDEX})`,
                allowNulls: true,
                type: VALIDATION_TYPE.Reject,
                titleTemplate: VALIDATION_MESSAGES.animalWeight.title,
                messageTemplate: VALIDATION_MESSAGES.animalWeight.message,
              },
              ...((data[index] && data[index][3] && data[index][3].value) && { value: data[index][3].value, }),
            },
            {
              ...CELL_COMMON_SETTINGS,
              ...((data[index] && data[index][4] && data[index][4].value) && { value: data[index][4].value, }),
            },
            {
              background: '#f1f1f1',
              color: '#7d7d7d',
              enable: false,
              fontSize: 14,
            }
          ],
        }
      );
    }

    return rows;
  };

  const generateAnimalInfoCellValue = (value) => {
    const { animals, } = props;

    const animal = animals.find(
      (animal) => animal.lID === value.toString() || animal.vID === value.toString() || animal.electronicId === value.toString()
    );
    return animal ? generateAnimalSummary(animal) : '';
  };

  const filterUniqueAndDuplicateRow = (spreadsheetData) => {
    const uniqueAnimals = [];
    const map = new Map();
    const duplicateAnimals = {};
    spreadsheetData.forEach((animal) => {
      const curAnimalInfo = generateAnimalInfoCellValue(animal.lID);
      if (!map.has(curAnimalInfo)) {
        map.set(curAnimalInfo, animal);
        duplicateAnimals[animal.sheetIndex + START_ROW] = [curAnimalInfo];
        uniqueAnimals.push(animal);
      } else {
        const uniqueAnimalInMap = map.get(curAnimalInfo);
        duplicateAnimals[uniqueAnimalInMap.sheetIndex + START_ROW].push(animal.sheetIndex + START_ROW);
      }
    });

    return {
      uniqueAnimals,
      duplicateAnimals,
    };
  };

  const filterInvalidAndEmptyRows = () => {
    invalidRows = [];
    return dataForSend.filter((animal, i) => {
      if ((animal.lID && animal.lID !== INVALID_CELL_VALUE) && (animal.weight && animal.weight !== INVALID_CELL_VALUE && !isNaN(animal.weight))) {
        animal.sheetIndex = i;
        return animal;
      }

      if (checkValidCell(animal)) {
        invalidRows.push(i + START_ROW);
      }

      return false;
    });
  };

  return (
  // <Mutation
  //    // Code for spreadsheet file upload
  //   // mutation={ADD_ANIMAL_GROUP_WEIGHTS}
  //   // onCompleted={data => {
  //   //   spreadsheet.widgetInstance.fromJSON(workbookConverted);
  //   //   setInvalidLIDs(data.addAnimalGroupWeight);
  //   // }}
  // >
  //   {mutationTrigger => (
    <>
      {// Code for spreadsheet file upload
          /* <Row>
            <Col lg="12">
              <Upload
                ref={up => upload = up}
                batch={false}
                multiple={false}
                saveMethod="POST"
                defaultFiles={[]}
                saveField="file"
                withCredentials={false}
                onStatusChange={e => {
                  workbookConverted = { ...e.response.response, };
                  const [file] = upload.files;
                  const [dateWeight] = file.name.split(' ');

                  const [sheet] = workbookConverted.sheets;
                  const [headers, ...rest] = sheet.rows;

                  const filteredHeaders = headers.cells.filter(c => VALID_COLS.includes(c.value));
                  const indexes = filteredHeaders.map(c => c.index);

                  const filteredColumns = rest.map(r => {
                    r.cells = [...r.cells.filter(c => indexes.includes(c.index)).map((c, i) => {
                      c.index = i;
                      c.textAlign = 'center';
                      return c;
                    }), {
                      format: 'dd-MM-yyyy',
                      textAlign: 'center',
                      value: new Date(moment(dateWeight).format('DD-MM-YYYY')) || new Date(),
                      validation: {
                        dataType: 'date',
                        showButton: true,
                        comparerType: 'between',
                        from: 'DATEVALUE("1/1/1900")',
                        to: 'DATEVALUE("1/1/2100")',
                        type: 'reject',
                        titleTemplate: 'Selected Date validation error',
                        messageTemplate: 'Selected Date should be between year 1900 and 2100.',
                      },
                    }];
                    return r;
                  });

                  const filteredHeadersMapped = filteredHeaders.map((c, i) => {
                    const lookupValue = VALID_COLS_LOOKUP.find(cName => cName.originalName === c.value);
                    c.index = i;
                    c.textAlign = 'center';
                    c.value = lookupValue.name;
                    return c;
                  });

                  const filteredHeadersWithDate = [...filteredHeadersMapped, {
                    index: filteredHeadersMapped.length,
                    textAlign: 'center',
                    value: 'Дата',
                  }];

                  sheet.columns = filteredHeadersWithDate.map((h, i) => {
                    return {
                      index: i,
                      width: 100,
                    };
                  });

                  headers.cells = filteredHeadersWithDate;

                  sheet.rows = [headers, ...filteredColumns];
                  workbookConverted.sheets = [sheet];
                  spreadsheet.widgetInstance.fromJSON(workbookConverted);
                }}
                saveUrl="/aspnet-mvc/Spreadsheet/Upload"
              />

            </Col>
          </Row> */}
      <GroupWeightingModal
        data={dataForSend}
        filteredData={filterInvalidAndEmptyRows}
        invalidRows={() => invalidRows}
        filterUniqueAndDuplicate={() => filterUniqueAndDuplicateRow(filterInvalidAndEmptyRows())}
      />

      {/* // Code for spreadsheet file upload */}
      {/* {invalidLIDs.length > 0 && (
            <div className="error">
              <span className="text-center">
                <p>
                  Ушни марки
                  {invalidLIDsConcat(invalidLIDs)}
                  {' '}
                  не бяха намери в базата данни
                </p>
              </span>
            </div>
          )} */}
      <Row>
        <Col lg="12">
          <h3 className="mb-3">Електронна таблица</h3>
          <h4 className="mb-2">Въвеждане на тегла</h4>
          <a id="templateDownloadLink" className="d-none" href="/files/template-group-weighting.xls" download>Svali</a>
          <SpreadsheetCustom
            toolbar={{
              data: false,
              insert: false,
              home: [
                {
                  type: 'button',
                  icon: 'k-save k-i-save',
                  text: 'Запази',
                  showText: 'both',
                  id: 'btnSave',
                  // attributes: { "class": 'btn btn-primary', },
                  click: e => {
                    // Code for spreadsheet file upload

                    // const workbook = spreadsheet.widgetInstance.toJSON();
                    // const [sheet, _] = workbook.sheets;
                    // const [headers, ...restRows] = sheet.rows;
                    // const weightIndex = headers.cells.find(c => c.value === 'Тегло').index;
                    // const lidIndex = headers.cells.find(c => c.value === 'ВИН №').index;
                    // const dateIndex = headers.cells.find(c => c.value === 'Дата').index;

                    // const res = restRows.map(r => {
                    //   const { cells, } = r;
                    //   const base = (moment('1900-01-01 12:00') as any).tz('Europe/Sofia');
                    //   base.add(cells[dateIndex].value - 2, 'days');
                    //   return {
                    //     animalLID: cells[lidIndex].value,
                    //     weight: cells[weightIndex].value,
                    //     dateWeight: new Date(base),
                    //   };
                    // }).filter(c => !!c.animalLID);

                    // mutationTrigger({
                    //   variables: {
                    //     animalWeights: res,
                    //   },
                    // });
                    const modalButton = document.querySelector('#spreadSheetButton') as HTMLButtonElement;
                    modalButton.click();
                  },
                }, // spreadsheet options
                {
                  type: 'button',
                  icon: 'k-download k-i-download',
                  text: 'Свали текущия файл',
                  showText: 'both',
                  id: 'btnDownload',
                  click: e => {
                    spreadsheet.widgetInstance.element.data('kendoSpreadsheet').saveAsExcel();
                  },
                },
                {
                  type: 'button',
                  icon: 'k-excel k-i-excel',
                  text: 'Свали шаблонен файл',
                  showText: 'both',
                  id: 'btnDownload',

                  click: e => {
                    const templateDownloadLink = document.querySelector('#templateDownloadLink') as HTMLButtonElement;
                    templateDownloadLink.click();
                  },
                }
                // 'exportAs'
                // ['bold', 'italic', 'underline'],
                // 'backgroundColor',
                // 'comments',
                // 'textColor',
                // 'borders',
                // 'fontSize', 'fontFamily',
                // 'alignment',
                // 'textWrap',
                // ['formatDecreaseDecimal', 'formatIncreaseDecimal'],
                // 'format',
                // 'merge',
                // 'freeze',
                // 'filter'
              ],
            }}
            change={(e) => {
              const spreadsheet = e.sender;
              const sheet = spreadsheet.activeSheet();
              const range = sheet.range(`A4:D${NUMBER_OF_ROWS + START_ROW_INDEX}`);
              spreadSheetData = range.values();

              sheet.batch(() => {
                const curDateWeight = DatesHelper.dateToApiFormat(new Date((new Date('01/01/1900')).setDate(sheet.range('B2').value() - 1)).toJSON());
                const weightType = sheet.range('C2')?.value();
                spreadSheetData.forEach((el, i) => {
                  const [lID, weight, comment] = el;
                  dataForSend[i] = {
                    lID,
                    weight,
                    comment,
                    dateWeight: curDateWeight,
                    weightType: getWeightTypeEnumValue(weightType),
                  };
                });
              });
              sheet.batch(() => {
                range.forEachCell((row, column, cell) => {
                  if (cell.validation && !cell.validation.value) {
                    const dataIndex = row - 3;
                    if (column === 0) {
                      dataForSend[dataIndex].lID = INVALID_CELL_VALUE;
                    }

                    if (column === 1) {
                      dataForSend[dataIndex].weight = INVALID_CELL_VALUE;
                    }
                  }

                  const lidCellValue = sheet.range(`A${row}`).value();
                  const infoCell = sheet.range(`D${row}`);
                  if (column === 0 && lidCellValue && row > 3) {
                    infoCell.value(generateAnimalInfoCellValue(lidCellValue));
                  }

                  if (infoCell.value() && !lidCellValue && row > 3) {
                    infoCell.value(null);
                  }
                });
              });
            }}
            columns={4}
            sheets={[
              {
                name: `${SHEET_TITLE}-${DatesHelper.getCurrentDate()}`,
                mergedCells: [
                  'A1:D1'
                  // 'C2:D2'
                ],
                rows: [ // table header titles
                  {
                    height: 70,
                    cells: [
                      {
                        index: 0,
                        value: SHEET_TITLE,
                        fontSize: 32,
                        ...HEADER_COMMON_SETTINGS,
                      }
                    ],
                  },
                  {
                    height: 25,
                    cells: [
                      {
                        value: 'Дата',
                        fontSize: 18,
                        ...HEADER_COMMON_SETTINGS,
                      },
                      {
                        background: '#fffed5',
                        textAlign: 'center',
                        color: 'black',
                        format: 'dd.MM.yyyy',
                        value: new Date(),
                        fontSize: 18,
                        borderBottom: ({ size: 1, color: 'black', }),
                        borderTop: ({ size: 1, color: 'black', }),
                        borderLeft: ({ size: 1, color: 'black', }),
                        borderRight: ({ size: 1, color: 'black', }),
                        bold: true,
                        validation: {
                          dataType: VALIDATION_DATA_TYPE.Date,
                          showButton: true,
                          comparerType: 'between',
                          from: 'DATEVALUE("1/1/1900")',
                          to: 'DATEVALUE("1/1/2100")',
                          allowNulls: true,
                          type: VALIDATION_TYPE.Reject,
                          titleTemplate: VALIDATION_MESSAGES.animalsDateWeight.title,
                          messageTemplate: VALIDATION_MESSAGES.animalsDateWeight.message,
                        },
                      },
                      {
                        textAlign: 'center',
                        color: 'black',
                        value: AnimalWeightTypes[0],
                        fontSize: 18,
                        borderBottom: ({ size: 1, color: 'black', }),
                        borderTop: ({ size: 1, color: 'black', }),
                        borderLeft: ({ size: 1, color: 'black', }),
                        borderRight: ({ size: 1, color: 'black', }),
                        bold: true,
                        validation: {
                          dataType: VALIDATION_DATA_TYPE.List,
                          comparerType: VALIDATION_DATA_TYPE.List,
                          from: `{ ${AnimalWeightTypes.map(type => `"${type}"`).join(', ')} }`,
                          allowNulls: true,
                          type: VALIDATION_TYPE.Reject,
                          titleTemplate: VALIDATION_MESSAGES.animalsWeightType.title,
                          messageTemplate: VALIDATION_MESSAGES.animalsWeightType.message,
                          showButton: true,
                        },
                      },
                      {
                        enable: false,
                      }
                    ],
                  },
                  { // table columns titles
                    height: 25,
                    cells: [
                      ...COLUMNS_SETTINGS
                    ],
                  },
                  ...generateRows()
                ], // set columns width
                columns: [
                  ...COLUMN_WIDTHS
                ],
              }
            ]}
            excel={{ fileName: 'group-weighting.xlsx', }}
          />
        </Col>
      </Row>
    </>
  // )}
  // </Mutation>
  );
};

export default EventsGroupWeighting;
