/* eslint max-len:0 */
import * as React from 'react';
import WeightsTable from './weights-table';
import Query from '../../../../data/query';
import { GET_WEIGHTS_DATA } from '../../../../data/reports/reports-query';
import { animalSexTranslation, dateFormatForTableFilters, reportAnimalSummary } from '../../../../tools/function-helpers';
import { getAnimalAgeInMonths } from '../../animals/animal-utilities';

const WeightsReport = props => {
  return (
    <Query query={GET_WEIGHTS_DATA}>
      {({ animals, }) => {
        const mappedAnimals = animals.items.map((animal) => {
          const sexTranslation = animalSexTranslation(animal.sex);
          const lastWeight = animal.weights && animal.weights.length > 0 ? animal.weights[animal.weights.length - 1].weight : null;
          const prevWeight = animal.weights && animal.weights.length > 1 ? animal.weights[animal.weights.length - 2].weight : null;
          const diffWeight = lastWeight - prevWeight;
          return {
            ...animal,
            ageInMonths: animal.dateOfBirth ? getAnimalAgeInMonths(animal.dateOfBirth) : '',
            adgOverall: parseFloat(animal.adgOverall.toFixed(2)),
            lastWeight,
            prevWeight,
            diffWeight,
            datePrevWeight: animal.weights && animal.weights.length > 1 ? dateFormatForTableFilters(animal.weights[animal.weights.length - 2].dateWeight) : null,
            dateLastWeight: animal.weights && animal.weights.length > 0 ? dateFormatForTableFilters(animal.weights[animal.weights.length - 1].dateWeight) : null,
            animalADG: animal.animalADG && animal.animalADG.length > 0 ? parseFloat(animal.animalADG[animal.animalADG.length - 1].animalANG.toFixed(2)) : null,
            animalSummary: reportAnimalSummary(animal, sexTranslation),
          };
        });
        return (
          <WeightsTable {...props} animals={mappedAnimals} />
        );
      }}
    </Query>
  );
};

export default WeightsReport;
