/* eslint react/destructuring-assignment:0 */
import * as React from 'react';
import Query from '../../../../../data/query';
import { GET_ANIMALS_FOR_GROUPS } from '../../../../../data/groups/groups-query';
import { GET_ANIMAL_LOCATIONS } from '../../../../../data/animal/animal-query';
import { DATA_TAB_ENUMS } from '../../../../../data/animal/tabs-queries';

import ListBoxComponent from '../../../../list-box/list-box';
import ListBoxFiltersComponent from '../../../../list-box-filters/list-box-filters';
import TabNavButtons from './tab-nav-buttons';
import { mapAnimalsForListBox } from '../../../../../tools/function-helpers';
import { generateLocationString } from '../../../animals/animal-utilities';

class SelectAnimals extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      animals: props.animals,
      animalsToMap: mapAnimalsForListBox(props.animals),
    };
    this.updateAnimalsToMap = this.updateAnimalsToMap.bind(this);
  }

  updateAnimalsToMap(animalsToMap) {
    const { getGroup, } = this.props;
    const selectedAnimalIds = getGroup()?.map(a => a.id);
    this.setState({
      animalsToMap: animalsToMap.filter(a => !selectedAnimalIds.includes(a.id)),
    });
  }

  render() {
    return (
      <div>
        <h3>ИЗБОР НА ЖИВОТНИ</h3>
        <ListBoxFiltersComponent
          animals={this.state.animals}
          aClasses={this.props.aClasses}
          breeds={this.props.breeds}
          colors={this.props.colors}
          groups={this.props.groups}
          locations={this.props.locations}
          updateAnimalsToMap={this.updateAnimalsToMap}
          mapAnimals={mapAnimalsForListBox}
          hideGroupSelect
        />

        <ListBoxComponent
          items={this.state.animalsToMap}
          listTitle="Изберете животни:"
          groupTitle="Брой животни:"
          getGroup={this.props.getGroup}
          updateGroup={this.props.updateGroup}
        />
        <TabNavButtons parentProps={this.props} />
      </div>
    );
  }
}


const SelectAnimalsWrapper = props => (
  <Query query={GET_ANIMAL_LOCATIONS}>
    {({ locations, }) => (
      <Query query={DATA_TAB_ENUMS}>
        {({ aClasses, breeds, color, }) => (
          <Query query={GET_ANIMALS_FOR_GROUPS}>
            {({ animals, }) => {
              const locationsMapped = locations.items.map((location) => {
                return {
                  ...location,
                  locationName: location.locationName ? location.locationName : '-',
                };
              });
              const mappedAnimals = animals.items.map((animal) => {
                return {
                  ...animal,
                  location: generateLocationString({ animalLocation: animal.animalLocation.reverse(), }),
                };
              });
              return (
                <SelectAnimals
                  {...props}
                  animals={mappedAnimals}
                  locations={locationsMapped}
                  aClasses={aClasses.items}
                  breeds={breeds.items}
                  colors={color.items}
                />
              );
            }}
          </Query>
        )}
      </Query>
    )}
  </Query>
);

export default SelectAnimalsWrapper;
