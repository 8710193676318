/* eslint max-len:0 */
/* eslint react/no-access-state-in-setstate:0 */
/* eslint react/no-did-update-set-state:0 */
/* eslint react/destructuring-assignment:0 */
/* eslint react/no-unused-state:0 */
/* eslint max-classes-per-file:0 */
import * as React from 'react';

import {
  TreeList, mapTree, extendDataItem, TreeListCell
} from '@progress/kendo-react-treelist';
import CompaniesTableModal from './companies-table-modal';
// import DeleteCompaniesModal from './delete-companies-modal';
import { deepCopy } from '../../../../tools/function-helpers';

const mappedParendFirms = (data) => {
  return data.map((item) => {
    return {
      firmID: item.firmID,
      firmName: item.firmName,
    };
  });
};

const subItemsField = 'childrenObject';
const expandField = 'expanded';

class Companies extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      data: props.firmsInFarms,
      itemInEdit: undefined,
      expanded: [0],
    };

    this.onExpandChange = this.onExpandChange.bind(this);
    this.processData = this.processData.bind(this);
    this.actionsCell = this.actionsCell.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.firmsInFarms !== prevProps.firmsInFarms) {
      this.setState({
        data: this.props.firmsInFarms,
      });
    }
  }

    actionsCell = (data) => {
      return class extends TreeListCell {
        render() {
          const { id, } = this.props.dataItem;
          const copyData = deepCopy(data);
          const { parentId, } = this.props.dataItem;
          const parentIndex = copyData.findIndex(item => item.firmID === parentId);
          let type = 'company';
          let dataItem = {};
          if (parentIndex !== -1) {
            dataItem = copyData[parentIndex].childrenObject.find(childrenItem => childrenItem.animalObjectID === id);
            type = 'child';
          } else {
            dataItem = copyData.find(firmItem => firmItem.firmID === id);
          }
          const parentFirms = mappedParendFirms(copyData);
          return (
            <td>
              <div className="d-flex justify-content-center">
                {/* <DeleteCompaniesModal dataItem={dataItem} type={type}/> */}
                <CompaniesTableModal dataItem={dataItem} type={type} parentFirms={parentFirms} />
              </div>
            </td>
          );
        }
      };
    }

    onExpandChange = (e) => {
      this.setState({
        expanded: e.value
          ? this.state.expanded.filter(id => id !== e.dataItem.id)
          : [...this.state.expanded, e.dataItem.id],
      });
    }

    processData = () => {
      const { expanded, data, } = this.state;
      const mappedData = data.map((item) => {
        return {
          id: item.firmID,
          name: item.firmName ? item.firmName : '(празно)',
          description: `ЕИК:${item.firmEIK ? item.firmEIK : '(празно)'}, Адрес:${item.firmCountry ? item.firmCountry : '(празно)'},${item.firmCity ? item.firmCity : '(празно)'},${item.firmAddress ? item.firmAddress : '(празно)'}, МОЛ:${item.firmMOL ? item.firmMOL : '(празно)'}`,
          childrenObject: item.childrenObject && item.childrenObject.length > 0 ? item.childrenObject.map((childrenItem) => {
            return {
              id: childrenItem.animalObjectID,
              parentId: item.firmID,
              name: `Ж.О. "${childrenItem.animalObjectName ? childrenItem.animalObjectName : '(празно)'}"`,
              description: `Номер:${childrenItem.animalObjectNumber}, Адрес:${childrenItem.animalObjectAddress ? childrenItem.animalObjectAddress : '(празно)'}, Кратко име:${childrenItem.animalObjectShortName ? childrenItem.animalObjectShortName : '(празно)'}`,
            };
          }) : [],
        };
      });

      return mapTree(mappedData, subItemsField, item => extendDataItem(item, subItemsField, {
        [expandField]: expanded.includes(item.id),
      }));
    }

    render() {
      const columns = [
        {
          field: 'name', title: 'Име', expandable: true, width: '30%',
        },
        { field: 'description', title: 'Описание', },
        { title: 'Действия', cell: this.actionsCell(this.state.data), width: '1px', }
      ];
      const copyData = deepCopy(this.state.data);

      return (
        <div>
          <div className="d-flex justify-content-start">
            <CompaniesTableModal dataItem={{}} type="company" action="add" />
            <CompaniesTableModal dataItem={{}} type="child" action="add" parentFirms={mappedParendFirms(copyData)} />
          </div>
          <TreeList
            className="table"
            data={this.processData()}
            style={{ maxHeight: '100%', overflow: 'auto', }}
            expandField={expandField}
            subItemsField={subItemsField}
            onExpandChange={this.onExpandChange}
            columns={columns}
          />
        </div>
      );
    }
}

export default Companies;
