/* eslint no-unused-vars:0 */
/* eslint no-unused-expressions:0 */
import * as React from 'react';
import { useMutation } from '@apollo/react-hooks';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

// @ts-ignore
import loader from '../assets/img/loader.svg';


export default ({ children, ...props }) => {
  const [trigger, { loading, error, data, }] = useMutation(props.mutation,
    {
      ...props,
      onCompleted: dataItem => {
        toast.success('Операцията завърши успешно!', { position: toast.POSITION.BOTTOM_RIGHT, });
        if (typeof props.onCompleted === 'function') {
          props.onCompleted(dataItem);
        }
      },
      onError: currentError => {
        toast.error('Опс, Нещо се обърка!', { position: toast.POSITION.BOTTOM_RIGHT, });
        if (typeof props.onError === 'function') {
          props.onError(currentError);
        }
      },
    });
  const auth: any = useSelector<any>(state => state?.auth);
  const location = useLocation();

  if (loading) return <p className="d-flex justify-content-center"><img src={loader} alt="loader" /></p>;

  if (error) {
    // @ts-ignore
    if (error?.networkError?.statusCode === 401) {
      localStorage.setItem('redirectUrlPath', location?.pathname);
      auth?.login();
      return <div>Redirecting...</div>;
    }

    return <div>Error :(</div>;
  }

  return children(trigger, { data, });
};

export const useRodeoMutation = props => {
  const [trigger, { loading, error, data, }] = useMutation(props.mutation, { ...props, });
  const auth: any = useSelector<any>(state => state?.auth);
  const location = useLocation();

  if (loading) return <p className="d-flex justify-content-center"><img src={loader} alt="loader" /></p>;
  if (error) {
    // @ts-ignore
    if (error?.networkError?.statusCode === 401) {
      localStorage.setItem('redirectUrlPath', location?.pathname);
      auth?.login();
      return <div>Redirecting...</div>;
    }

    if (props.onError) {
      props.onError(error);
    }

    toast.error(error?.message, {
      position: toast.POSITION.TOP_LEFT,
    });
    return trigger;
  }

  return trigger;
};

// export const useRodeoMutation = useRodeoMutation;
