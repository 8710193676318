/* eslint react/button-has-type:0 */
/* eslint max-len:0 */
/* eslint no-prototype-builtins:0 */
/* eslint guard-for-in:0 */
/* eslint no-restricted-syntax:0 */
/* eslint react/no-access-state-in-setstate:0 */
/* eslint react/no-unused-state:0 */
/* eslint react/destructuring-assignment:0 */
/* eslint no-underscore-dangle:0 */
/* eslint react/sort-comp:0 */
import * as React from 'react';
import {
  Grid, GridColumn as Column, GridToolbar, GridColumnMenuFilter
} from '@progress/kendo-react-grid';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { filterBy, orderBy } from '@progress/kendo-data-query';

import { LocalizationProvider, loadMessages, IntlProvider } from '@progress/kendo-react-intl';
import CustomColumnModal from '../../../custom-column/custom-column';

import { TABLE_BG_MESSAGES } from '../../../../tools/translation-constants';


loadMessages(TABLE_BG_MESSAGES, 'bg-BG');
const PAGE_TAKE = 50;

class LocationsTable extends React.Component<any, any> {
    _export;

    export = () => {
      this._export.save();
    };

    constructor(props) {
      super(props);
      this.state = {
        animals: props.animals,
        filter: undefined,
        sort: [],
        sortable: {
          allowUnsort: true,
          mode: 'single',
        },
        skip: 0,
        take: PAGE_TAKE,
        pageSize: PAGE_TAKE,
        pageable: {
          buttonCount: 5,
          info: true,
          type: 'numeric',
          previousNext: true,
          pageSizes: [5, 10, 20, props.animals.length],
        },
        columns: {
          animalSummary: {
            title: 'Животно',
            visible: true,
            field: 'animalSummary',
            width: '300px',
            filter: false,
          },
          curentLocation: {
            title: 'Сегашна локация',
            visible: true,
            field: 'curentLocation',
            width: '220px',
            filter: true,
          },
          moveDate: {
            title: 'Дата преместване',
            visible: true,
            field: 'moveDate',
            width: '170px',
            filter: true,
          },
          prevLocation: {
            title: 'Предишна локация',
            visible: true,
            field: 'prevLocation',
            width: '210px',
            filter: true,
          },
          lastWeight: {
            title: 'Последно тегло',
            visible: true,
            field: 'lastWeight',
            width: '150px',
            filter: true,
          },
          dateWeight: {
            title: 'Дата тегло',
            visible: true,
            field: 'dateWeight',
            width: '150px',
            filter: true,
          },
          adgOverall: {
            title: 'Общ СДП',
            visible: true,
            field: 'adgOverall',
            width: '150px',
            filter: true,
          },
        },
      };
      this.pageChange = this.pageChange.bind(this);
      this.updateTableVisibleCols = this.updateTableVisibleCols.bind(this);
      this.printCols = this.printCols.bind(this);
    }

    pageChange = (event) => {
      this.setState({
        skip: event.page.skip,
        take: event.page.take,
        pageSize: event.page.take,
      });
    }

    filterChange = (event) => {
      this.setState({
        animals: filterBy(this.props.animals, event.filter),
        filter: event.filter,
        skip: 0,
        tate: PAGE_TAKE,
      });
    }

    sortChange = (event) => {
      this.setState({
        animals: orderBy(this.state.animals, event.sort),
        sort: event.sort,
      });
    }

    updateTableVisibleCols = (columns) => {
      this.setState({
        columns,
      });
    }

    printCols() {
      const weightCols = [];
      for (const key in this.state.columns) {
        const curCol = this.state.columns[key];
        if (this.state.columns.hasOwnProperty(key) && curCol.visible) {
          if (key === 'adgOverall' || key === 'lastWeight') {
            weightCols.push(
              <Column key={key} field={curCol.field} title={curCol.title} width={curCol.width} filter="numeric" columnMenu={props => <GridColumnMenuFilter {...props} />} />
            );
          } else if (key === 'moveDate' || key === 'dateWeight') {
            weightCols.push(
              <Column
                key={key}
                field={curCol.field}
                title={curCol.title}
                width={curCol.width}
                filter="date"
                format="{0:dd.MM.yyy}"
                columnMenu={props => <GridColumnMenuFilter {...props} />}
              />
            );
          } else {
            weightCols.push(
              <Column key={key} field={curCol.field} title={curCol.title} width={curCol.width} />
            );
          }
        }
      }
      return weightCols;
    }

    render() {
      return (
        <LocalizationProvider language="bg-BG">
          <IntlProvider locale="bg">
            <ExcelExport
              data={this.state.animals}
              fileName="report-locations.xlsx"
              ref={exporter => { this._export = exporter; }}
            >
              <Grid
                data={this.state.animals.slice(this.state.skip, this.state.take + this.state.skip)}
                className="table table-advanced"
                skip={this.state.skip}
                total={this.state.animals.length}
                onPageChange={this.pageChange}
                onFilterChange={this.filterChange}
                filter={this.state.filter}
                filterable
                pageable={this.state.pageable}
                pageSize={this.state.pageSize}
                onSortChange={this.sortChange}
                sort={this.state.sort}
                sortable={this.state.sortable}
                resizable
                reorderable
              >
                <GridToolbar>
                  <div className="d-flex justify-content-between align-items-center">
                    <span className="list-counter">
                      Брой Животни:
                      {this.state.animals.length}
                    </span>
                    <div className="d-flex d-inline-block">
                      <CustomColumnModal columns={this.state.columns} updateTableVisibleCols={this.updateTableVisibleCols} />
                      <button
                        title="Export Excel"
                        className="btn btn-secondary ml-2"
                        onClick={this.export}
                      >
                        Експорт в Excel
                      </button>
                    </div>
                  </div>
                </GridToolbar>
                {this.printCols()}
              </Grid>
            </ExcelExport>
          </IntlProvider>
        </LocalizationProvider>
      );
    }
}

export default LocationsTable;
