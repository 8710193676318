import { gql } from 'apollo-boost';

export const ANIMALS_GRID = gql`
{
  animals {
    totalCount
    items {
      id: animalId
      breed {
        breedName
      }
      animalLocation {
        location{
          locationName
          locationParent{
            locationName
            locationParent{
              locationName
            }
          }
        }
      }
      aClass{
        className
      }
      animalOwner{
        firmName
      }
      forScrap
      sex
      lID
      vID
      dateOfBirth
      name
      comment
      breedingFor
      sire {
        lID
      }
      dam {
        lID
      }
      underSelection
      registrationDate
      color
      {
        colorName
      }
    }
  }
}  
`;

export const GET_ANIMAL_EVENTS = gql`
  query AnimalEvents($id: ID!) {
    animal(id: $id) {
      events {
        birthEvents {
          placenta
          dateBirth
          sTO
          sTOTypeID
          comment
        }
        heatEvents{
          heatDate
          heatCheck
          comment
        }
        pregnancyEvents{
          pregnancyDate
          pregnancyCheck
          pregnancyDays
          comment
        }
        weaningEvents{
          dateCreate
          weaningDate
          weaningEventID
          weaningWeight
          date
          dateCreate
          animalEventTypeID
        }
      }
    }
  }

`;

export const GET_ANIMAL_CHILDREN_WEANING_DATA = gql`
query AnimalWeaningData($id: ID!) {
  animal(id: $id) {
    children{
        animalId
        lID
        vID
        sex
        dateOfBirth
        weaningStatus
        bornDead
        active
    }
    events {
      weaningEvents{
        weaningDate
        weaningEventID
        weaningWeight
        date
        animalEventTypeID
      }
    }
  }
}
`;

export const CREATE_WEANING_EVENT = gql`
    mutation CreateWeaningEvent($dataItem: animalWeaningEventInput) {
      createWeaningEvent(weaningEvent: $dataItem) {
        animalID
      }
    }
`;

export const CREATE_BIRTH_EVENT = gql`
    mutation addBirth($dataItem: animalBirthEventInput) {
        addBirth(birthEvent: $dataItem) {
          animalId
        }
      }
`;

export const GET_SERVICE_GROUPS = gql`{
serviceGroups{
  items{
    groupID
    animalServiceProp{
      animals{
        lID
        classID
        breed{
          breedShort
        }
      }
    }
  }
}
}
`;

export const GET_ANIMAL_SERVICE_GROUPS = gql`
query AnimalWeaningData($id: ID!){
  animal(id:$id){
     animalInService
      {
        groupID
        dateIn
        dateOut
        comment
        group
        {
          groupName
          dateCreate
          dateExpires
          comment
        }
      }
    }
}`;

export const GET_ANIMAL_DETAILS = gql`
  query AnimalDetails($id: ID!) {
    animal(id: $id) {
      animalId
      fateDate
      dateOfBirth
      bornDead
      animalAssistance {
        assistanceID
        assistanceDateIn
        assistanceDateTo
        assistanceDays
        assistanceName
        assisYear
      }
      lID
      vID
      name
      passportNumber
      dateOfBirth
      sex
      active
      breed {
        breedShort,
        breedName
        breedsId
      }
      aClass{
        className
        classID
      }
      children{
        animalId
        dateOfBirth
        lID
        sex
        sire{
          lID
          animalId
          breedComposition{
            breedPercent
            breed{
              breedShort
            }
          }
          breed{
            breedShort
          }
        }
        animalADG{
          aNGdate
          animalANG
        }
        weights{
          dateWeight
          weight
          weightType
        }
      }
      weights {
        weightID
        weight
        dateWeight
        weightType
        comment
      }
      animalADG{
        aNGdate
        animalANG
      }
      adgOverall
      comment
      color {
        colorName
        colorID
      }
      sire {
        animalId
        lID
        breed {
          breedShort
        }
        breedComposition{
          breed{
            breedShort
          }
          breedPercent
        }
      }
      dam {
        animalId
        lID
        breed {
          breedShort
        }
        breedComposition{
          breed{
            breedShort
          }
          breedPercent
        }
      }
      breedingStatus
      breedingFor
      cowBirthCount
      typeInput
      electronicId
      eIDType
      dryWetStatus
      register
      animalPurchase
      {
       client {
         farmClientName
         farmClientID
       }
       documents
       expenseOther
       expenseTransport
       expenseVet
       farmClientID
       pricePerHead
       priceTotal
       pricePerKG
       purchaseID
       purchaseDate
      }
      registrationDate
      herdBookOut
      fromEmbryoTransfer
      breedComposition{
        breedID
        animalBreedCompositionID
        breed{
          breedShort
        }
        breedPercent
      }
      dNAnumber
      dNAType
      hornStatusID
      countryFrom
      underSelection
      holdTo
      curentLocation {
        moveDate
        locationId
        location{
          locationName
          locationParent{
            locationName
            locationParent{
              locationName
              locationType
            }
          }
        }
      }
      animalLocation {
        moveDate
        locationId
        location{
          locationName
          locationParent{
            locationName
            locationParent{
              locationName
              locationType
            }
          }
        }
      }
      forScrap
      animalOwner{
        firmID
        firmName
      }
      vetis {
        animalVetisID
        animalVetisLID
        animalVetisEID
        animalVetisDOB
        animalVetisSex
        color{
          colorID
          colorName
        }
        breed{
          breedsId
          breedName
        }
        animalVetisDamLID
        underSelection
      }
      animalPurchase
      {
        pricePerHead
      }
      weaningStatus
    }
  }
`;

export const ADD_ANIMAL_ASSIS = gql`
mutation addAnimalAssis($animalAssisAdd: animalAsissInput){
  addAnimalAssis(animalAsissInput: $animalAssisAdd){
    animalID
    assistanceDateIn
    assistanceName
  }
}`;

export const DELETE_ANIMAL_ASSIS = gql`
mutation deleteAnimalAssis($assistanceID: ID!){
  deleteAnimalAssis(assistanceID: $assistanceID){
    assistanceID
  }
}`;

export const ADD_WEIGHT = gql`
  mutation AddAnimalWeight($weightToAdd: animalWeightInput) {
    addAnimalWeight(animalWeight: $weightToAdd) {
      comment
      dateWeight
      weight
      weightID
      weightType
    }
  }
`;

export const GET_ANIMAL_ADG = gql`
query AnimalAdg($id: ID!) {
  animal(id: $id) {
    animalADG{
      aNGdate
      animalANG
    }
  }
}
`;

export const GET_ADJUSTED_WEIGHTS = gql`
query AdjustedWeights($id: ID!) {
  animal(id: $id) {
    adjustedBirthWeight
    adjustedWeaningWeight
    adjustedMatureWeight
    adjustedYearlingWeight
  }
}
`;

export const GET_ANIMAL_VACCINE = gql`
query AnimalVaccine($id: ID!) {
  animal(id: $id) {
    vaccine {
      animalVaccineDate
      animalVaccineDrug
      animalVaccineComment
      animalVaccineEndMonths
      animalVaccinePrice
      animalVaccineDose
      animalVaccineKarentDays
      farmClientID
      animalID
      animalVaccineID
      vet {
        farmClientName
        farmClientsInType
      }
    }
  }
}
`;

export const GET_FARMCIENTS = gql`
{
  farmClients{
    items{
      farmClientID
      farmClientsInType
      farmClientName      
    }
  }
}
`;

export const UPDATE_ANIMAL_VACCINE = gql`
  mutation UpdateAnimalVaccine($animalVaccineUpdates: animalVaccineInput) {
    updateAnimalVaccine(animalVaccine: $animalVaccineUpdates) {
      animalID
      animalVaccineID
    }
  }
`;

export const ADD_ANIMAL_VACCINE = gql`
  mutation AddAnimalVaccine($animalVaccineAdd: animalVaccineInput) {
    addAnimalVaccine(animalVaccine: $animalVaccineAdd) {
      animalID
    }
  }
`;

export const DELETE_ANIMAL_VACCINE = gql`
  mutation DeleteAnimalVaccine($animalVaccineDelete: ID!) {
    deleteAnimalVaccine(animalVaccineID: $animalVaccineDelete) {
      animalVaccineID
    }
  }
`;

export const GET_ANIMAL_BCS = gql`
query AnimalBCS($id: ID!) {
  animal(id: $id) {
    bCS{
      animalID
      bCSID
      bCSDate
      animalOTS
      comment
      personInCharge{
        farmClientID
        farmClientName
      }
    }
  }
}
`;

export const GET_ANIMAL_RETAGS = gql`
query AnimalRetags($id: ID!){
  animal(id:$id)
  {
     animalId
      lID
      vID
      electronicId
      eIDType
      reTag
      {
        animalID
        lID
        electronicId
        vID
        eIDType
        dateReTag
      }
  }
}
`;

export const ADD_ANIMAL_BCS = gql`
  mutation AddAnimalBCS($animalAddBCS: animalBCSInput) {
    addAnimalBCS(animalBCS: $animalAddBCS) {
      animalID
    }
  }
`;

export const UPDATE_ANIMAL_BCS = gql`
  mutation UpdateAnimalBCS($animalUpdateBCS: animalBCSInput) {
    updateAnimalBCS(animalBCS: $animalUpdateBCS) {
      animalID
      bCSID
    }
  }
`;

export const GET_ANIMAL_TUBERCULOSIS_BRUCELLOSIS = gql`
query AnimalTuberBrucel($id: ID!) {
  animal(id: $id) {
    animalTuberculosis{
      dateApply
      dateCheck
      applySize
      checkSize
      tuberculinDose
      tuberculosisCheckTypeID
      tuberculin{
        tuberculinDate
        tuberculinPackage
      }
    }
     animalBrucellosis{
      animalBrucellosisDate
      animalBrucellosisNumber
      brucellosisCheckTypeID
    }
  }
}
`;

export const UPDATE_ANIMAL = gql`
  mutation UpdateAnimal($animalUpdates: animalInput) {
    updateAnimal(animal: $animalUpdates) {
      animalId
    }
  }
`;

export const GET_SIRES_AND_DAMS = gql`
query GetSiresAndDams{
  dams:animals(classType: DAM) {
    totalCount
    items {
      animalId
      lID
    }
  }
  sires:animals(classType: SIRE) {
    totalCount
    items {
      animalId
      lID
      name
    }
  }
}
`;

export const ADD_ANIMAL_VETIS = gql`
mutation AddAnimalVetis($vetisUpdates: animalVetisInput) {
  addAnimalVetis(animalVetis: $vetisUpdates) {
    animalVetisID
    animalVetisLID
    animalVetisEID
    animalVetisDOB
    animalVetisSex
    color{
      colorID
      colorName
    }
    breed{
      breedsId
      breedName
    }
    animalVetisDamLID
    underSelection
  }
}
`;

export const UPDATE_ANIMAL_VETIS = gql`
  mutation UpdateAnimalVetis($vetisUpdates: animalVetisInput) {
    updateAnimalVetis(animalVetis: $vetisUpdates) {
      animalVetisID
      animalVetisLID
      animalVetisEID
      animalVetisDOB
      animalVetisSex
      color{
        colorID
        colorName
      }
      breed{
        breedsId
        breedName
      }
      animalVetisDamLID
      underSelection
    }
  }
`;

export const GET_ANIMAL_BREEDING_DATA = gql`
query AnimalBreedingData($id: ID!) {
  animal(id: $id) {
    animalFunctionality
    animalSkeleton
    animalMusculature
    animalTemperament
    animalMarbling
    sEUROP
    widthBack
    growthBack
    growthRibeye
    widthThigh
    heightWithers
    lengthBack
    lengthPelvic
    widthFlank
    widthPelvic
    frontLegs
    backLegs
    backLegsSide
  }
}
`;

export const UPDATE_ANIMAL_BREEDING_DATA = gql`
mutation UpdateAnimalBreedingData($breedingDataUpdates: animalInput) {
  updateAnimal(animal: $breedingDataUpdates) {
    animalFunctionality
    animalSkeleton
    animalMusculature
    animalTemperament
    animalMarbling
    sEUROP
    widthBack
    growthBack
    growthRibeye
    widthThigh
    heightWithers
    lengthBack
    lengthPelvic
    widthFlank
    widthPelvic
    frontLegs
    backLegs
    backLegsSide
  }
}
`;

export const GET_ANIMAL_NOTES = gql`
query AnimalNotes($id: ID!) {
  animal(id: $id) {
    notes{
      noteID
      note
      noteTopic
      dateNote
    }
  }
}
`;

export const ADD_NOTE = gql`
  mutation AddNote($noteToAdd: animalNoteInput) {
    addNoteAnimal(animalNote: $noteToAdd) {
      animalID
      dateNote
      note
      noteID
      noteTopic
    }
  }
`;

export const ADD_ANIMAL_PURCHASE = gql`
  mutation addPurchase($purchase: animalPurchaseInput)
  {
    addPurchase(purchase:$purchase){
     animalID
    }
  }
`;

export const EDIT_ANIMAL_PURCHASE = gql`
  mutation editPurchase($purchase: animalPurchaseInput)
  {
    editPurchase(purchase:$purchase){
     animalID
    }
  }
`;

export const EDIT_NOTE = gql`
  mutation EditNote($noteToUpdate: animalNoteInput) {
    updateNoteAnimal(animalNote: $noteToUpdate) {
      animalID
      dateNote
      note
      noteID
      noteTopic
    }
  }
`;

export const GET_ANIMAL_PEDIGREE = gql`
query AnimalPedigree($id: ID!) {
  animal(id: $id) {
    animalId
    name
    dateOfBirth
    lID
    breedComposition {
      breedPercent
      breed{
        breedShort
      }
    }
    breed {
      breedShort
    }
    sire {
      animalId
      name
      dateOfBirth
      lID
      breedComposition {
        breedPercent
        breed{
        breedShort
      }
      }
      breed {
        breedShort
      }
      dam {
        animalId
        name
        dateOfBirth
        lID
        breedComposition {
          breedPercent
          breed{
        breedShort
      }
        }
        breed {
          breedShort
        }
        sire{
          animalId
          name
          dateOfBirth
          lID
          breedComposition {
            breedPercent
            breed{
        breedShort
      }
          }
          breed {
            breedShort
          }
        }
        dam{
          animalId
          name
          dateOfBirth
          lID
          breedComposition {
            breedPercent
            breed{
        breedShort
      }
          }
          breed {
            breedShort
          }
        }
      }
      sire {
        animalId
        name
        dateOfBirth
        lID
        breedComposition {
            breedPercent
            breed{
        breedShort
      }
          }
          breed {
            breedShort
          }
         sire{
          animalId
          name
          dateOfBirth
          lID
          breedComposition {
            breedPercent
            breed{
        breedShort
      }
          }
          breed {
            breedShort
          }
        }
        dam{
          animalId
          name
          dateOfBirth
          lID
          breedComposition {
            breedPercent
            breed{
        breedShort
      }
          }
          breed {
            breedShort
          }
        }
      }
    }
    dam {
      animalId
      name
      dateOfBirth
      lID
      breedComposition {
            breedPercent
            breed{
        breedShort
      }
          }
          breed {
            breedShort
          }
       dam {
        animalId
        name
        dateOfBirth
        lID
        breedComposition {
            breedPercent
            breed{
        breedShort
      }
          }
          breed {
            breedShort
          }
        sire{
          animalId
          name
          dateOfBirth
          lID
          breedComposition {
            breedPercent
            breed{
        breedShort
      }
          }
          breed {
            breedShort
          }
        }
        dam{
          animalId
          name
          dateOfBirth
          lID
          breedComposition {
            breedPercent
            breed{
        breedShort
      }
          }
          breed {
            breedShort
          }
        }
      }
      sire {
        animalId
        name
        dateOfBirth
        lID
        breedComposition {
            breedPercent
            breed{
        breedShort
      }
          }
          breed {
            breedShort
          }
        sire{
          animalId
          name
          dateOfBirth
          lID
          breedComposition {
            breedPercent
            breed{
        breedShort
      }
          }
          breed {
            breedShort
          }
        }
        dam{
          animalId
          name
          dateOfBirth
          lID
          breedComposition {
            breedPercent
            breed{
        breedShort
      }
          }
          breed {
            breedShort
          }
        }
      }
    }
  }
}`;

export const ADD_ANIMAL_PREGNANCY = gql`
mutation AddAnimalPregnancy($animalPregnancyAdd: animalPregnancyInput){
  createPregnancyCheck(pregnancyCheck: $animalPregnancyAdd){
    pregnancyID
    pregnancyDays
    comment
    pregnancyDate
  }
}`;

export const GET_ANIMAL_BREEDS = gql`
{
breeds{
  items{
    breedsId
    breedName
    breedShort
  }
}
}
`;

export const CREATE_UPDATE_BREED_COMPOSITION = gql`
  mutation CreateUpdateBreedComposittion($breedToCreateUpdate: [animalBreedCompositionInput]) {
    createUpdateBreedComposition(breedComposition: $breedToCreateUpdate) {
      animalID
      breedID
     }
   }
 `;

export const DELETE_WEIGHT = gql`
  mutation DeleteWeight($animalWeightID: ID!) {
    deleteAnimalWeight(animalWeightID: $animalWeightID) {
      weightID
    }
  }
`;

export const UPDATE_WEIGHT = gql`
  mutation UpdateWeight($targetWeight: animalWeightInput) {
    updateAnimalWeight(animalWeight: $targetWeight) {
      weight
      weightID
      weightType
      dateWeight
    }
  }
`;

export const ADD_ANIMAL_HEAT = gql`
mutation AddAnimalHeat($animalHeatAdd: animalHeatEventInput){
  createHeatEvent(heatEvent: $animalHeatAdd){
    heatDate
    heatCheck
    comment
  }
}`;

export const GET_ANIMAL_MOVEMENTS_LOCATION = gql`
  query AnimalLocations($id: ID!) {
    animal(id: $id) {
      animalId
      animalLocation {
        animalLocationId
        animalLocationType
        moveDate
        animalID
        location{
          locationName
          locationParent{
            locationName
            locationParent{
              locationName
              locationType
            }
          }
        }
      }
    }
  }
`;

export const GET_ANIMAL_LOCATIONS = gql`
{
  locations{
    items{
      locationID
      locationName
      locationParent{
        locationID
        locationName
        locationParent{
          locationID
          locationName
          locationParent{
            locationID
            locationName
          }
        }
      }
    }
  }
}
`;

export const ADD_ANIMAL_LOCATION = gql`
  mutation AddAnimalLocation($animalAddLocation: animalLocationInput) {
    createAnimalLocation(animalLocation: $animalAddLocation) {
      animalLocationId
    }
  }
`;

export const GET_ANIMAL_FATE = gql`
  query AnimalFate($id: ID!) {
    animal(id: $id) {
      animalId
      active
      fateCode
      fateDate
      fateDoc
      fateReason
      animalSell{
      animalSellDirection
      animalSellCarPlate
      animalSellBABHTrasportDoc
      animalSellKgInFarm
      animalSellKgInClient
      animalSellPricePerKG
      animalSellPricePerAnimal
      animalSellPriceOther
      animalSellTransportPrice
      client{
        farmClientName
      }
      driver{
        farmClientName
      }
    }
    animalDie{
      animalDieTypeID
      animalDieDiagnosticID
      animalDiePriceDiagnostic
      animalDieDriver
      skeletonDoc
      personInCharge{
        farmClientName
      }
    }
    }
  }`;

export const GET_ANIMAL_MAIN_BOARD = gql`
{
  animals  
  {
    totalCount
    items
    {
      sex
      aClass
      {
        classID
        className
      }
      breedingStatus
      breedingFor
      weights {
        weight
      }
      weaningStatus
       children
      {
        lID
        dateOfBirth
        sex
        aClass
        {
         className
        }
      }
    }
  }
}`;

export const GET_ANIMALS_GROUP_WEIGHT_DATA = gql`
{
  animals{
    items{
      lID
      electronicId
      vID
      sex
      breed{
        breedShort
      }
      dateOfBirth
      aClass{
        className
    }
    }
  }
}
`;

export const DELETE_MOVEMENT = gql`
  mutation DeleteMovement($animalLocationId: ID!) {
    deleteAnimalLocation(animalLocationId: $animalLocationId) {
      animalLocationId
    }
  }
`;

export const GET_ANIMAL_AI_DATA = gql`
query AnimalAIData($id: ID!) {
  animal(id: $id) {
    animalId
      vID
      animalAI
      {
        dateAI
        animalAIID
        semenID
        commentAI
        vet{
          farmClientName
        }
        semen{
          semenCode
          animalID
          animal
          {
            lID
            vID
            animalId
            breed
            {
              breedShort
            }
          }
        }
      }
  }
}
`;
