/* eslint no-shadow:0 */
/* eslint react/prop-types:0 */
/* eslint react/destructuring-assignment:0 */
import * as React from 'react';
import { NavLink, Route, Switch } from 'react-router-dom';

import DataTab from './data-tab';
import ReproductionTab from './reproduction/reproduction-tab';
import HealthTab from './health-tab';
import BreedingTab from './breeding-tab';
import EventsTab from './events-tab';
import WeightsTab from './weights-tab';


const AnimalCardTabs = props => {
  const currentAnimal = props.animal;
  const isFemale = currentAnimal.sex === 'FEMALE';

  return (
    <div>
      <div className="tabs container-fluid">
        <ul className="nav nav-pills nav-fill">
          <li className="nav-item">
            <NavLink exact className="nav-link" to={`/animals/list/${currentAnimal.animalId}`}>Данни</NavLink>
          </li>
          { isFemale && (
          <li className="nav-item">
            <NavLink className="nav-link" to={`/animals/list/${currentAnimal.animalId}/reproduction`}>Репродукция</NavLink>
          </li>
          ) }
          <li className="nav-item">
            <NavLink className="nav-link" to={`/animals/list/${currentAnimal.animalId}/health`}>Здраве</NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link" to={`/animals/list/${currentAnimal.animalId}/breeding`}>Развъдни</NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link" to={`/animals/list/${currentAnimal.animalId}/events`}>Събития</NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link" to={`/animals/list/${currentAnimal.animalId}/weights`}>Тегла</NavLink>
          </li>
        </ul>
      </div>
      <div className="container-fluid">
        <Switch>
          <Route path="/animals/list/:id/reproduction" component={ReproductionTab} />
          <Route
            path="/animals/list/:id/health"
            render={(props) => (
              <HealthTab
                {...props}
                currentAnimalId={currentAnimal.animalId}
                animaldateOfBirth={currentAnimal.dateOfBirth}
              />
            )}
          />
          <Route
            path="/animals/list/:id/breeding"
            render={(props) => <BreedingTab {...props} currentAnimal={currentAnimal} animaldateOfBirth={currentAnimal.dateOfBirth} />}
          />
          <Route
            path="/animals/list/:id/events"
            component={(props) => (
              <EventsTab
                {...props}
                currentAnimalId={currentAnimal.animalId}
                animaldateOfBirth={currentAnimal.dateOfBirth}
              />
            )}
          />
          <Route path="/animals/list/:id/weights" render={(props) => <WeightsTab {...props} currentAnimal={currentAnimal} />} />
          <Route path="/animals/list/:id/" render={(props) => <DataTab {...props} currentAnimal={currentAnimal} />} />
        </Switch>
      </div>
    </div>
  );
};

export default AnimalCardTabs;
