import * as React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

// @ts-ignore
import loader from '../assets/img/loader.svg';

export default ({ children, ...props }) => {
  const { loading, error, data, } = useQuery(props?.query, { ...props, });
  const auth: any = useSelector<any>(state => state?.auth);
  const location = useLocation();

  if (loading) return <p className="d-flex justify-content-center"><img src={loader} alt="loader" /></p>;

  if (error) {
    // @ts-ignore
    if (error?.networkError?.statusCode === 401) {
      localStorage.setItem('redirectUrlPath', location?.pathname);
      auth.login();
      return <div>Redirecting...</div>;
    }
    toast.error(error?.message, {
      position: toast.POSITION.TOP_LEFT,
    });


    return <div>Error :(</div>;
  }

  return children(data);
};
