/* eslint react/prop-types:0 */
/* eslint react/destructuring-assignment:0 */
/* eslint jsx-a11y/anchor-is-valid:0 */
/* eslint jsx-a11y/no-static-element-interactions:0 */
/* eslint jsx-a11y/click-events-have-key-events:0 */
/* eslint react/no-array-index-key:0 */
import * as React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { mapAnimalsForListBox } from '../../../../tools/function-helpers';
import DatesHelper from '../../../../tools/DatesHelper';

const ServiceGroups = props => {
  const [activeTab, setActiveTab] = React.useState('activeGroups');

  const animalNamePrettier = group => {
    const animals = group.animalServiceProp.map(ap => ap.animals);
    const mappedAnimals = mapAnimalsForListBox(animals.filter(u => u.sex === 'MALE'));
    return mappedAnimals.map(str => str.text).join(', ');
  };
  const generateTableRows = (groups) => {
    return groups.map((group, i) => {
      return (
        <tr key={i}>
          <td>{group.groupName}</td>
          <td>{group.daysLong}</td>
          <td>{DatesHelper.dateToAppFormat(group.dateCreate)}</td>
          <td>{DatesHelper.dateToAppFormat(group.dateExpires)}</td>
          <td>{group.comment}</td>
          <td>{animalNamePrettier(group)}</td>
          <td>
            <div className="d-flex justify-content-center">
              <Link
                className="button-icon"
                to={{
                  pathname: `/groups/groups-service/${group.groupID}`,
                }}
              >
                <FontAwesomeIcon icon="pen" />
              </Link>
            </div>
          </td>
        </tr>
      );
    });
  };
  return (
    <>
      <div className="tabs">
        <ul className="nav nav-pills nav-fill">
          <li className="nav-item col-sm-2 p-0">
            <a
              onClick={e => {
                e.preventDefault();
                setActiveTab('activeGroups');
              }}
              className={`nav-link ${activeTab === 'activeGroups' ? 'active' : ''}`}
            >
              Активни
            </a>
          </li>
          <li className="nav-item col-sm-2 p-0">
            <a
              onClick={e => {
                e.preventDefault();
                setActiveTab('inActiveGroups');
              }}
              className={`nav-link ${activeTab === 'inActiveGroups' ? 'active' : ''}`}
            >
              Неактивни
            </a>
          </li>
        </ul>
      </div>
      <Link className="btn btn-primary" to="/groups/groups-service/new">Добави група</Link>
      <table className="table">
        <thead>
          <tr>
            <th>Име</th>
            <th>Период</th>
            <th>Начало</th>
            <th>Край</th>
            <th>Коментар</th>
            <th>Разплодници</th>
            <th>Действия</th>
          </tr>
        </thead>
        <tbody>
          {generateTableRows(props.groups.filter(g => g.active === (activeTab === 'activeGroups')))}
        </tbody>
      </table>
    </>
  );
};

export default ServiceGroups;
