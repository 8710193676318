/* eslint-disable no-unused-vars */
/* eslint-disable import/no-unresolved */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/sort-comp */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/button-has-type */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/no-unused-state */
/* eslint react/no-did-update-set-state:0 */
import * as React from 'react';
import {
  Grid, GridColumn as Column, GridColumnMenuFilter, GridToolbar
} from '@progress/kendo-react-grid';
import { filterBy, orderBy } from '@progress/kendo-data-query';
import { ExcelExport } from '@progress/kendo-react-excel-export';

import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import { SEXES, TYPE_EXIST, COUNTRIES } from '../../../../tools/translation-constants';
import ExternalAnimalsModal from './external-animals-modal';
import Query from '../../../../data/query';
import { DATA_TAB_ENUMS } from '../../../../data/animal/tabs-queries';
import { GET_SIRES_AND_DAMS } from '../../../../data/animal/animal-query';
import DatesHelper from '../../../../tools/DatesHelper';
import CustomColumnModal from '../../../custom-column/custom-column';
import { dropdownFilterCell } from '../../../../tools/dropdown-filter-cell';

const getTranslation = (value, translations) => {
  const result = translations.find((item) => item.id === value);
  return result ? result.text : '';
};
const getMappedExternalAnimals = (animals) => {
  return animals.map((item) => {
    console.log('date: ', new Date(item.dateOfBirth));
    return {
      ...item,
      dateOfBirthFormated: item.dateOfBirth ? DatesHelper.parseDate(item.dateOfBirth) : null,
      typeExistTranslation: getTranslation(item.typeExistID, TYPE_EXIST),
      sexTranslation: getTranslation(item.sex, SEXES),
      countryTranslation: getTranslation(item.countryFrom, COUNTRIES),
    };
  });
};

class ExternalAnimals extends React.Component<any, any> {
  _export;

  export = () => {
    this._export.save();
  };

  constructor(props) {
    super(props);
    this.state = {
      externalAnimals: getMappedExternalAnimals(props.externalAnimals),
      breeds: props.breeds,
      sexes: props.sexes,
      colors: props.colors,
      aClasses: props.aClasses,
      filter: undefined,
      sort: [],
      sortable: {
        allowUnsort: true,
        mode: 'single',
      },
    };
  }

  componentDidUpdate(prevProps) {
    const { externalAnimals, } = this.props;
    if (externalAnimals !== prevProps.externalAnimals) {
      this.setState({
        externalAnimals: getMappedExternalAnimals(externalAnimals),
      });
    }
  }

  filterChange = (event) => {
    const { externalAnimals, } = this.props;

    console.log(event.filter);

    this.setState({
      externalAnimals: filterBy(getMappedExternalAnimals(externalAnimals), event.filter),
      filter: event.filter,
    });
  };

  sortChange = (event) => {
    this.setState({
      externalAnimals: orderBy(this.state.externalAnimals, event.sort),
      sort: event.sort,
    });
  };

  updateTableVisibleCols = (columns) => {
    this.setState({
      columns,
    });
  };

  render() {
    const { externalAnimals, filter, } = this.state;

    return (
      <Query {...this.props} query={DATA_TAB_ENUMS}>
        {({ aClasses, breeds, color, }) => {
          const colorNames = color.items.map((clr) => (clr.colorName));
          const breedNames = breeds.items.map((brds) => (brds.breedName));
          const aClassNames = aClasses.items.map((cls) => (cls.className));
          const sexes = SEXES.map((sex) => (sex.text));
          const countryOrigin = COUNTRIES.map((country) => (country.text));
          const typeExist = TYPE_EXIST.map((type) => (type.text));
          return (
          <Query query={GET_SIRES_AND_DAMS}>
            {({ sires, dams, }) => (
              <LocalizationProvider language="bg-BG">
                <IntlProvider locale="bg">
              <div className="container">
                <h3 className="mb-4">Списък на външните животни</h3>
                <ExternalAnimalsModal
                  action="add"
                  dataItem={{}}
                  aClasses={aClasses.items}
                  breeds={breeds.items}
                  colors={color.items}
                  sires={sires.items}
                  dams={dams.items}
                />
                <ExcelExport
                  data={this.state.externalAnimals}
                  fileName="animal-list.xlsx"
                  ref={(exporter) => {
                    this._export = exporter;
                  }}
                />
                <Grid
                  className="table table-advanced"
                  filterable
                  data={externalAnimals}
                  filter={filter}
                  onFilterChange={this.filterChange}
                  onSortChange={this.sortChange}
                  sort={this.state.sort}
                  sortable={this.state.sortable}
                  total={this.state.externalAnimals.length}
                >
                  <GridToolbar>
                    <div className="d-flex justify-content-between align-items-center">
                      <span className="list-counter">
                        Брой Животни:
                        {this.state.externalAnimals.length}
                      </span>
                      <div className="d-flex d-inline-block">
                        <CustomColumnModal columns={this.state.columns} updateTableVisibleCols={this.updateTableVisibleCols} />
                        <button title="Export Excel" className="btn btn-secondary ml-2" onClick={this.export}>
                          Експорт в Excel
                        </button>
                      </div>
                    </div>
                  </GridToolbar>
                  <Column
                    field="lID"
                    title="Ветис №"
                    width="150px"
                  />
                  <Column
                    field="vID"
                    title="ВИН"
                    width="150px"
                  />
                  <Column
                    field="name"
                    title="Име"
                    width="150px"
                  />
                  <Column
                    field="sexTranslation"
                    title="Пол"
                    width="85px"
                    filterCell={(props) => dropdownFilterCell(props, sexes)}
                  />
                  <Column
                    field="breed.breedName"
                    title="Порода"
                    width="150px"
                    filterCell={(props) => dropdownFilterCell(props, breedNames)}
                  />
                  <Column
                    field="aClass.className"
                    title="Категория"
                    width="150px"
                    filterCell={(props) => dropdownFilterCell(props, aClassNames)}
                  />
                  <Column
                    field="dateOfBirthFormated"
                    filter="date"
                    format="{0:dd.MM.yyy}"
                    title="Дата на раждане"
                    width="150px"
                    columnMenu={props => <GridColumnMenuFilter {...props} />}
                  />
                  <Column
                    field="countryTranslation"
                    title="Произход"
                    width="150px"
                    filterCell={(props) => dropdownFilterCell(props, countryOrigin)}
                  />
                  <Column
                    field="color.colorName"
                    title="Цвят"
                    width="150px"
                    filterCell={(props) => dropdownFilterCell(props, colorNames, true)}
                  />
                  <Column
                    field="typeExistTranslation"
                    title="Тип заприхождаване"
                    width="230px"
                    filterCell={(props) => dropdownFilterCell(props, typeExist)}
                  />
                  <Column
                    field=""
                    filterable={false}
                    title="Действия"
                    width="112px"
                    cell={(props) => (
                      <td className="d-flex justify-content-center">
                        <ExternalAnimalsModal
                          {...props}
                          aClasses={aClasses.items}
                          breeds={breeds.items}
                          colors={color.items}
                          sires={sires.items}
                          dams={dams.items}
                        />
                      </td>
                    )}
                  />
                </Grid>
              </div>
              </IntlProvider>
        </LocalizationProvider>
            )}
          </Query>
          );
        }}
      </Query>
    );
  }
}

export default ExternalAnimals;
