/* eslint max-len:0 */
/* eslint react/button-has-type:0 */
/* eslint jsx-a11y/control-has-associated-label:0 */
/* eslint react/no-access-state-in-setstate:0 */
/* eslint react/destructuring-assignment:0 */
import * as React from 'react';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Row, Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Mutation from '../../../../../../data/mutation';
import { DELETE_ANIMAL_VACCINE, GET_ANIMAL_VACCINE } from '../../../../../../data/animal/animal-query';
import { GET_REVACCINATION_DATA } from '../../../../../../data/reports/reports-query';

class DeleteVaccineModal extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      vaccineObj: props.vaccineObj,
    };
    this.toggleDialog = this.toggleDialog.bind(this);
  }

  toggleDialog() {
    this.setState({
      visible: !this.state.visible,
    });
  }


  handleSubmit(e, deleteAnimalVaccineTrigger) {
    e.preventDefault();
    deleteAnimalVaccineTrigger({
      variables:
            {
              animalVaccineDelete: this.state.vaccineObj.animalVaccineID,
            },
    });
  }

  render() {
    return (
      <Mutation
        mutation={DELETE_ANIMAL_VACCINE}
        awaitRefetchQueries={false}
        refetchQueries={[{ query: GET_ANIMAL_VACCINE, variables: { id: this.state.vaccineObj.animalID, }, }, { query: GET_REVACCINATION_DATA, }]}
        onCompleted={() => this.toggleDialog()}
      >
        {(deleteAnimalVaccineTrigger) => (
          <div className="d-inline-block">
            <button className="button-icon mr-4" onClick={this.toggleDialog}><FontAwesomeIcon icon="trash" /></button>
            {this.state.visible && (
            <Dialog className="health-modal" title="Изтрий събитие" onClose={this.toggleDialog} minWidth="500px">
              <form className="health-form">
                <Row>
                  <Col lg={{ size: 12, }}>
                    <div className="mb-4 text-center">
                      Сигурни ли сте,че искате да изтриете събитието?
                    </div>
                  </Col>
                </Row>
              </form>
              <DialogActionsBar>
                <div className="d-flex justify-content-center">
                  <button className="btn btn-secondary" onClick={this.toggleDialog}>Отказ</button>
                  <button type="submit" onClick={e => this.handleSubmit(e, deleteAnimalVaccineTrigger)} className="btn btn-primary ml-3">Изтрий</button>
                </div>
              </DialogActionsBar>
            </Dialog>
            )}
          </div>
        )}
      </Mutation>
    );
  }
}

export default DeleteVaccineModal;
